import {
  ILockedSeats,
  IMapDetails,
  ISeats,
  ISingleBookingMapDetails,
} from '../_models/newBookingInterface'

export const getBookingsUpdatedSeats = (
  seatsData: ISeats[],
  mapDetails: IMapDetails | ISingleBookingMapDetails | null,
) => {
  const bookingsUpdatedSeatsData = seatsData.map((seat: ISeats) => {
    if (
      mapDetails &&
      mapDetails.lockedSeats.some(
        (selectedSeat: ILockedSeats) => selectedSeat.seat_name === seat.seat_name,
      )
    ) {
      return { ...seat, status: 1 }
    } else {
      return seat
    }
  })
  return bookingsUpdatedSeatsData
}

export const getBookingsUpdatedAvailableSeatsCount = (
  availableSeats: number,
  mapDetails: IMapDetails | ISingleBookingMapDetails | null,
) => {
  if (mapDetails) {
    return availableSeats + mapDetails.lockedSeats.length
  } else {
    return availableSeats
  }
}
