import { IIcon } from './iconConstants'
const ExchangeLineIcon = (props: IIcon) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox='0 0 20 20'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g fill='none' fillRule='evenodd'>
        <path d='M-1-1h22v22H-1z' />
        <path
          d='M10 19.167A9.166 9.166 0 0 1 .833 10 9.166 9.166 0 0 1 10 .833 9.166 9.166 0 0 1 19.167 10 9.166 9.166 0 0 1 10 19.167zm0-1.834a7.333 7.333 0 1 0 0-14.666 7.333 7.333 0 0 0 0 14.666zm-4.583-6.416h8.25v1.833H10v2.75l-4.583-4.583zM10 7.25V4.5l4.583 4.583h-8.25V7.25H10z'
          fill={props.fill}
          fillRule='nonzero'
        />
      </g>
    </svg>
  )
}
export default ExchangeLineIcon
