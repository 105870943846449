import { ILoginFormData, ISignUpFormData } from '../_models/authenticationInterface'

import { get, post, put } from './apiServices'

const signIn = async (userCredentials: ILoginFormData) => {
  const response = post('/login', userCredentials)
  return response
}
const signUp = async (userCredentials: ISignUpFormData) => {
  const response = post('/register', userCredentials)
  return response
}
const resendVerificationMail = async () => {
  const response = get('/send-verification-email')
  return response
}
const InviteUserAcceptStatus = async (token: string) => {
  const response = post(`/accept-user-invitation/${token}`)
  return response
}
const userVerificationStatus = async (params: { expires: string }) => {
  const response = post('/verify-email', params)
  return response
}
const setUserData = async (userCredentials: ISignUpFormData, token: string | number) => {
  const response = put(`/update-guest-user?expires=${token}`, userCredentials)
  return response
}
const logout = async () => {
  const response = post('/logout')
  return response
}

const authenticationService = {
  signIn,
  signUp,
  resendVerificationMail,
  InviteUserAcceptStatus,
  userVerificationStatus,
  setUserData,
  logout,
}

export default authenticationService
