import { IIcon } from './iconConstants'
const BuildingWithGearIcon = ({ width, height, fill }: IIcon) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.41 14.882c-.117.489-.117.998 0 1.487l-.91.525.917 1.587.909-.525c.365.346.806.6 1.288.744v1.05h1.833V18.7a3.204 3.204 0 0 0 1.288-.744l.91.525.916-1.587-.91-.526a3.217 3.217 0 0 0 0-1.486l.91-.526-.916-1.587-.91.525c-.36-.341-.8-.6-1.288-.744V11.5h-1.833v1.05a3.204 3.204 0 0 0-1.288.744l-.91-.525-.916 1.587.91.526zm4.496.743a1.375 1.375 0 1 1-2.75 0 1.375 1.375 0 0 1 2.75 0z'
        fill={fill}
      />
      <path
        d='M17 9.5v1h2v-1h-2zM12 19h1v-2h-1v2zM.5 19H3v-2H.5v2zM4 18V4H2v14h2zM4 4h11V2H4v2zm11 0v3.5h2V4h-2zM3 19h7.5v-2H3v2zm8.5-1V8.5h-2V18h2zm0-9.5H17v-2h-5.5v2zm-1 10.5H12v-2h-1.5v2zM17 8.5v1h2v-1h-2zm-5.5 0v-2a2 2 0 0 0-2 2h2zM15 4h2a2 2 0 0 0-2-2v2zm2 4.5h2a2 2 0 0 0-2-2v2zM4 4V2a2 2 0 0 0-2 2h2zM5.5 5.5h2v2h-2zM5.5 9.5h2v2h-2zM5.5 13.5h2v2h-2z'
        fill={fill}
      />
    </svg>
  )
}
export default BuildingWithGearIcon
