import { PERMISSIONS } from '../../_constants'
import { ITab } from '../../_models/tabsInterface'

export const headerTabs: ITab[] = [
  {
    id: 1,
    label: 'dashboard',
    path: '/admin/dashboard',
  },
  {
    id: 2,
    label: 'bookingManagement',
    path: '/admin/booking-management',
    permissions: [PERMISSIONS.BOOKING_MANAGEMENT_VIEW],
  },
  {
    id: 3,
    label: 'scanner',
    permissions: [PERMISSIONS.SCANNER_VIEW],
  },
]
