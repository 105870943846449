import { IIcon } from './iconConstants'
const CircleWithSlashIcon = (props: IIcon) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox='0 0 17 17'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.54 0C13.213 0 17 3.787 17 8.54A8.459 8.459 0 0 1 8.54 17C3.787 17 0 13.213 0 8.54A8.494 8.494 0 0 1 8.54 0zM3.2 12.405 12.405 3.2c-1.12-.88-2.481-1.361-4.002-1.361-3.683 0-6.565 2.882-6.565 6.565 0 1.52.48 2.882 1.36 4.002zm10.612-7.81L4.595 13.81c1.112.795 2.542 1.351 4.052 1.351 3.575 0 6.515-2.94 6.515-6.515 0-1.51-.556-2.94-1.35-4.052z'
        fill={props.fill}
        fillRule='nonzero'
        stroke={props.stoke}
      />
    </svg>
  )
}
export default CircleWithSlashIcon
