import {
  IAnnounceTokenParams,
  ICancelBookingParams,
  IChangeTokenStatusParams,
  IGetFacilitiesFromActiveTokenParams,
  INextTokenParams,
  ITokenListingParams,
} from './../_models/bookingManagementInterface'
/* eslint-disable camelcase */

import { IStartFacility } from '../_models/bookingManagementInterface'
import { get, post } from './apiServices'

const getAllFacilitiesInBookingManagement = async () => {
  const response = await get('/booking-management/facilities')
  return response
}
const checkFacilityStatus = async (facilityId: string) => {
  const response = await get(`/booking-management/check-facility-status?facility_id=${facilityId}`)
  return response
}
const startFacility = async (facilityId: IStartFacility) => {
  const response = await post('/booking-management/start', facilityId)
  return response
}
const pauseFacility = async (facilityId: IStartFacility) => {
  const response = await post('/booking-management/pause', facilityId)
  return response
}
const resumeFacility = async (facilityId: IStartFacility) => {
  const response = await post('/booking-management/resume', facilityId)
  return response
}

const tokenListing = async (tokenListing: ITokenListingParams) => {
  const response = await get('/booking-management/tokens-list', tokenListing)
  return response
}

const changeTokenStatus = async (changeTokenStatus: IChangeTokenStatusParams) => {
  const response = await post('/booking-management/change-status', changeTokenStatus)
  return response
}
const announceToken = async (announceTokenParams: IAnnounceTokenParams) => {
  const response = await get('/booking-management/announce', announceTokenParams)
  return response
}
const stopFurtherBooking = async (facilityId: IStartFacility) => {
  const response = await post('/booking-management/stop-further-bookings', facilityId)
  return response
}

const resumeFurtherBooking = async (facilityId: IStartFacility) => {
  const response = await post('/booking-management/resume-further-bookings', facilityId)
  return response
}
const stopFacility = async (facilityId: IStartFacility) => {
  const response = await post('/booking-management/stop', facilityId)
  return response
}
const cancelBooking = async (cancelBookingParams: ICancelBookingParams) => {
  const response = await post('/booking-management/cancel-token', cancelBookingParams)
  return response
}
const nextToken = async (nextTokenParams: INextTokenParams) => {
  const response = await get('/booking-management/next-token', nextTokenParams)
  return response
}
const getFacilitiesFromActiveToken = async (params: IGetFacilitiesFromActiveTokenParams) => {
  const response = await get('/booking-management/facilities-with-location', params)
  return response
}

const bookingManagementService = {
  getAllFacilitiesInBookingManagement,
  startFacility,
  pauseFacility,
  resumeFacility,
  tokenListing,
  changeTokenStatus,
  announceToken,
  stopFurtherBooking,
  resumeFurtherBooking,
  stopFacility,
  cancelBooking,
  checkFacilityStatus,
  nextToken,
  getFacilitiesFromActiveToken,
}
export default bookingManagementService
