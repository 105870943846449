// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function Link(theme: any) {
  return {
    MuiLink: {
      styleOverrides: {
        root: {
          color: theme.palette.error.main,
          cursor: 'pointer',
          textDecoration: 'none',
          '&:hover': {
            color: theme.palette.error.main,
            fill: theme.palette.error.main,
          },
        },
      },
    },
  }
}
