import { IDeleteAccountFormData, IUpdateDetails } from '../_models/userInterface'
import { _delete, get, post, postFileData, put } from './apiServices'

const getUserDetails = async () => {
  const response = get('customer/details')
  return response
}

const updateDetails = async (userDetails: IUpdateDetails) => {
  const response = put('customer/update-profile', userDetails)
  return response
}

const updateProfileImage = async (image: File) => {
  const response = await postFileData('customer/update-profile-image', { image: image })
  return response
}

const deleteProfileImage = async () => {
  const response = _delete('customer/profile-image')
  return response
}
const deleteAccount = async (userCredentials: IDeleteAccountFormData) => {
  const response = post('customer/delete-account', userCredentials)
  return response
}

const getProfileInformation = async () => {
  const response = get('customer/details')
  return response
}

const customerUserService = {
  getUserDetails,
  updateDetails,
  updateProfileImage,
  deleteProfileImage,
  deleteAccount,
  getProfileInformation,
}

export default customerUserService
