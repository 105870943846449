import { Box, ClickAwayListener, styled } from '@mui/material'

import Popper, { PopperPlacementType } from '@mui/material/Popper'
import React, { FC, ReactNode, useEffect } from 'react'

interface ICustomPopper {
  open: boolean
  anchorEl: HTMLElement | null
  placement?: PopperPlacementType
  children?: ReactNode
  popperStyle?: Record<string, unknown>
  sx?: Record<string, unknown>
  offsetDistance?: number
  arrow?: boolean
  disablePortal?: boolean
  clickAway?: boolean
  handleClose: () => void
}
const Arrow = styled(Box)(() => ({
  '& .arrow': {
    position: 'absolute',
    fontSize: ' 0.938rem',
    width: '5em',
    height: '5em',

    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid',
    },
  },
}))
export const StyledPopper = styled(Popper)(({ theme }) => ({
  '&[data-popper-placement*="bottom"] .arrow': {
    top: 0,
    right: 0,
    marginTop: '-0.9em',
    width: '3em',
    height: '1em',
    '&::before': {
      borderWidth: '0 1em 1em 1em',
      borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
    },
  },
  '&[data-popper-placement*="bottom-start"] .arrow': {
    top: 0,
    left: 0,
    marginTop: '-0.9em',
    width: '3em',
    height: '1em',
    '&::before': {
      borderWidth: '0 1em 1em 1em',
      borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
    },
  },
  '&[data-popper-placement*="top"] .arrow': {
    bottom: 0,
    right: 0,
    marginBottom: '-0.9em',
    width: '3em',
    height: '1em',
    '&::before': {
      borderWidth: '1em 1em 0 1em',
      borderColor: `${theme.palette.background.paper} transparent transparent transparent`,
    },
  },
  '&[data-popper-placement*="right"] .arrow': {
    left: 0,
    marginLeft: '-0.9em',
    height: '3em',
    width: '1em',
    '&::before': {
      borderWidth: '1em 1em 1em 0',
      borderColor: `transparent ${theme.palette.background.paper} transparent transparent`,
    },
  },
  '&[data-popper-placement*="left"] .arrow': {
    right: 0,
    marginRight: '-0.9em',
    height: '3em',
    width: '1em',
    '&::before': {
      borderWidth: '1em 0 1em 1em',
      borderColor: `transparent transparent transparent ${theme.palette.background.paper}`,
    },
  },
  '&.MuiAutocomplete-popper': {
    paddingBottom: '0.625rem',
  },
}))

const CustomPopper: FC<ICustomPopper> = (props) => {
  const [open, setOpen] = React.useState<boolean>(props.open)
  const id = open ? 'simple-popover' : undefined

  const handleClickAway = () => {
    setOpen(false)
    props.handleClose()
  }
  useEffect(() => {
    setOpen(props.open)
  }, [props.open])

  return (
    <>
      <StyledPopper
        id={id}
        open={open}
        anchorEl={props.anchorEl}
        placement={props.placement}
        disablePortal={props.disablePortal}
        sx={props.sx}
      >
        {props.arrow && (
          <Arrow>
            <Box component='span' className='arrow' />
          </Arrow>
        )}
        {props.clickAway ? (
          <ClickAwayListener onClickAway={handleClickAway}>
            <Box>{props.children}</Box>
          </ClickAwayListener>
        ) : (
          <Box>{props.children}</Box>
        )}
      </StyledPopper>
    </>
  )
}

export default CustomPopper
