/* eslint-disable camelcase */
import { Box, Button, Grid, TextField, Typography, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { CountryData } from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import { useAppDispatch, useAppSelector } from '../../../_helpers/hooks'
import { IContactUs } from '../../../_models/commonInterface'
import PhoneNumberInput from '../../../components/phoneInput'
import BgCircleImage from '../../../images/bg-shape.svg'
import ContactImage from '../../../images/contactUs.svg'
import BgShapesImage from '../../../images/shapes.svg'
import { contactUs, publicPagesState } from '../../../slices/publicPagesSlice'
const ContactUs = () => {
  type FormInputs = {
    name: string
    organization?: string
    email: string
    phone?: string
    message: string
  }
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const {
    handleSubmit,
    control,
    formState: { isSubmitted },
    reset,
    setValue,
  } = useForm<FormInputs>({
    mode: 'onChange',
  })
  const [countryData, setCountryData] = useState<CountryData | Record<string, never>>()
  const translatedInput = useAppSelector((state) => state?.translation.translatedData)
  const { isContactFormSubmitted } = useAppSelector(publicPagesState)
  const onSubmit: SubmitHandler<FormInputs> = (data) => {
    let modifiedFormData: IContactUs
    if (countryData?.dialCode !== undefined && data.phone !== '' && data.phone !== undefined) {
      modifiedFormData = {
        ...data,
        phone: parseInt(data.phone?.replace(countryData.dialCode, ''), 10),
        country_code: parseInt(countryData.dialCode, 10),
      }
    } else {
      modifiedFormData = {
        ...data,
        phone: null,
        country_code: null,
      }
    }
    dispatch(contactUs(modifiedFormData))
  }
  useEffect(() => {
    if (isContactFormSubmitted) {
      reset()
      setValue('phone', '1')
    }
  }, [isContactFormSubmitted, reset, setValue])
  const handleChange = (data: CountryData | Record<string, never>) => {
    setCountryData(data)
  }

  return (
    <Grid
      container
      spacing={1}
      sx={{
        [theme.breakpoints.down(550)]: {
          zoom: '70%',
        },
        [theme.breakpoints.down(400)]: {
          zoom: '60%',
        },
      }}
      mr={4}
      mb={{ xs: 20, md: 10 }}
    >
      <Grid item xs={12} sm={12} md={5} mt={17}>
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          ml={{ xs: 5, sm: 7, lg: 0 }}
        >
          <Box>
            <Typography
              variant='h1'
              fontSize={'3.25rem'}
              color={'publicPages.light'}
              lineHeight={'3.638rem'}
              fontWeight={'bold'}
            >
              {translatedInput?.landingPage?.contactUsText5}&#39;
              {translatedInput?.landingPage?.contactUsText6}{' '}
              <span style={{ color: theme.palette.primary.main }}>
                {translatedInput?.landingPage?.contactUsText3}
              </span>{' '}
              <br />
              {translatedInput?.landingPage?.contactUsText4}
            </Typography>
            <Typography
              variant='subtitle1'
              fontSize={'1.25rem'}
              color={'publicPages.main'}
              lineHeight={'1.875rem'}
              fontWeight={300}
              sx={{ my: 5 }}
            >
              {translatedInput?.landingPage?.contactUsText1}{' '}
              <span style={{ color: theme.palette.primary.main, fontWeight: 500 }}>
                {translatedInput?.landingPage?.infoslashQEmail}
              </span>
              .
              <br /> {translatedInput?.landingPage?.contactUsText2}
            </Typography>
            <Box mt={6}>
              <Box component='img' src={ContactImage} />
            </Box>
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12} sm={12} md={7} mt={5} mb={4}>
        <Box
          sx={{
            position: 'relative',
            width: '95%',
            height: '90%',
          }}
        >
          <Box
            component='img'
            src={BgCircleImage}
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
            }}
          />
          <Box
            component='img'
            src={BgShapesImage}
            sx={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              top: '78%',
            }}
          />
          <Box
            ml={{ xs: '15%', sm: 15, md: 23 }}
            mt={8}
            sx={{
              borderRadius: '1rem',
              boxShadow: '0 0.125rem 1.5rem 0 rgba(0, 0, 0, 0.1)',
              backgroundColor: 'common.white',
              maxWidth: { xs: '100%', lg: '75%' },
              padding: '2.25rem',
              position: 'absolute',
              [theme.breakpoints.down(1035)]: {
                position: 'relative',
              },
            }}
          >
            <Typography
              variant='h1'
              fontSize={'1.625rem'}
              color={'iconColors.main'}
              lineHeight={1.12}
              fontWeight={'bold'}
            >
              {translatedInput?.pricingPage?.contactUsBtn}
            </Typography>
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
              <Box sx={{ mt: 1 }}>
                <Controller
                  name='name'
                  control={control}
                  defaultValue=''
                  rules={{
                    required: translatedInput?.common?.nameIsRequired,
                    minLength: {
                      value: 1,
                      message: translatedInput?.common?.nameLengthMinMax,
                    },
                    maxLength: {
                      value: 50,
                      message: translatedInput?.common?.nameLengthMinMax,
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      margin='normal'
                      fullWidth
                      label={`${translatedInput?.common?.name} *`}
                      error={isSubmitted && !!error}
                      helperText={isSubmitted && error ? error.message : null}
                      sx={{ mt: 2 }}
                    />
                  )}
                />
                <Controller
                  name='email'
                  control={control}
                  defaultValue=''
                  rules={{
                    required: translatedInput?.common?.emailAddressRequired,
                    maxLength: {
                      value: 64,
                      message: translatedInput?.common?.emailMaxLengthError,
                    },
                    pattern: {
                      value: /^[a-zA-Z0-9._+]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/,

                      message: translatedInput?.common?.emailValid,
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      margin={'dense'}
                      fullWidth
                      label={`${translatedInput?.common?.emailPlaceholder} *`}
                      error={isSubmitted && !!error}
                      helperText={isSubmitted && error ? error.message : null}
                      sx={{ mt: 2 }}
                    />
                  )}
                />

                <Box sx={{ mt: 1.5 }}>
                  <Controller
                    name='phone'
                    control={control}
                    defaultValue=''
                    rules={{
                      pattern: {
                        value: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4,9}$/,
                        message: `${translatedInput?.common?.phoneErrorMsg}`,
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <Box
                        component='div'
                        className={error && isSubmitted ? 'error' : ''}
                        sx={{ mt: 3, mb: 1 }}
                      >
                        <PhoneNumberInput
                          style={{ width: '100%' }}
                          phoneField={{
                            name: field.name,
                            value: field.value,
                            onBlur: field.onBlur,
                            isValid: !(isSubmitted && error?.message),
                            onChange: (value, data) => {
                              field.onChange(value)
                              handleChange(data)
                            },
                          }}
                          isMandatory={false}
                        />
                        {isSubmitted && error?.message ? (
                          <Typography variant='caption' color='error.main' sx={{ ml: 1.5 }}>
                            {error.message}
                          </Typography>
                        ) : null}
                      </Box>
                    )}
                  />
                </Box>
                <Controller
                  name='organization'
                  control={control}
                  defaultValue=''
                  rules={{
                    minLength: {
                      value: 1,
                      message: translatedInput?.landingPage?.organisationNameCharLimit,
                    },
                    maxLength: {
                      value: 255,
                      message: translatedInput?.landingPage?.organisationNameCharLimit,
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      margin={'normal'}
                      fullWidth
                      label={translatedInput?.landingScreen?.organizationText}
                      error={isSubmitted && !!error}
                      helperText={isSubmitted && error ? error.message : null}
                      sx={{ mt: 2 }}
                    />
                  )}
                />
                <Controller
                  name='message'
                  control={control}
                  defaultValue=''
                  rules={{
                    required: translatedInput?.landingPage?.messageRequired,
                    minLength: {
                      value: 1,
                      message: translatedInput?.landingPage?.messageCharLimit,
                    },
                    maxLength: {
                      value: 800,
                      message: translatedInput?.landingPage?.messageCharLimit,
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      multiline
                      rows={5}
                      {...field}
                      margin={'normal'}
                      fullWidth
                      label={'Message *'}
                      error={isSubmitted && !!error}
                      helperText={isSubmitted && error ? error.message : null}
                      sx={{ mt: 2 }}
                    />
                  )}
                />

                <Button
                  type='submit'
                  variant='contained'
                  sx={{ mt: 3, mb: 1, height: 60, width: 200 }}
                  size='large'
                >
                  <Typography variant='h5'>
                    {' '}
                    {translatedInput?.landingPage?.sendMessageButton}
                  </Typography>
                </Button>
              </Box>
            </form>
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}

export default ContactUs
