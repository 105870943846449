import { IIcon } from './iconConstants'
const ArtBoardLineWithCloseIcon = ({ width, height, fill, stoke }: IIcon) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 46 46'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.947 42.208a1.5 1.5 0 1 0 2.122 2.122l-2.122-2.122zm2.122 2.122 9.498-9.5-2.12-2.12-9.5 9.498 2.122 2.122zM35.98 42.208a1.5 1.5 0 0 1-2.12 2.122l2.12-2.122zm-2.12 2.122-9.5-9.5 2.122-2.12 9.499 9.498-2.122 2.122z'
        fill={fill}
      />
      <path d='M21.633 41.304a1.5 1.5 0 0 0 3 0h-3zm3 0v-6.879h-3v6.879h3z' fill={fill} />
      <path
        d='M10.692 7.566H7.926v20.308h31.056V7.566h-2.766M3.998 34.097h38.59'
        stroke={stoke}
        strokeWidth='3'
        strokeLinecap='round'
      />
      <path
        d='M23.3 20.758a8.926 8.926 0 1 0 0-17.851 8.926 8.926 0 0 0 0 17.851zM26.422 8.708l-6.248 6.248M20.174 8.708l6.248 6.248'
        stroke={stoke}
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
export default ArtBoardLineWithCloseIcon
