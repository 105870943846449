import { CheckCircle } from '@mui/icons-material'
import {
  Avatar,
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import { FC } from 'react'
import { decodeTokenDetails, getUserTypeFromToken } from '../../_helpers/cookie'
import { useAppDispatch, useAppSelector } from '../../_helpers/hooks'
import { handleMessages } from '../../_helpers/utilities'
import { IBusinessProfile } from '../../_models/businessInterface'
import { errorToast } from '../../slices/toastSlice'
import { userState } from '../../slices/userSlice'
interface IBusinessProfiles {
  profiles: IBusinessProfile[]
  switchProfile: (profileType: string, profileData?: IBusinessProfile) => void
}

const ListBusinessProfiles: FC<IBusinessProfiles> = ({ profiles, switchProfile }) => {
  const theme = useTheme()
  const tokenDetails = decodeTokenDetails()
  const userType = getUserTypeFromToken()
  const userDetails = useAppSelector(userState)
  const { userProfileInformation } = userDetails
  const translatedInput = useAppSelector((state) => state?.translation.translatedData)
  // eslint-disable-next-line camelcase
  const activeBusinessId = tokenDetails?.active_business_id
  const dispatch = useAppDispatch()

  return (
    <>
      {profiles.map((profile) => {
        return (
          <List key={profile.id}>
            <Divider sx={{ mb: 1, ml: 2, mr: 2 }} />
            <ListItem
              disablePadding
              sx={{ '&:hover .show-on-hover': { display: { sm: 'block' } } }}
            >
              <ListItemButton
                onClick={() => {
                  // eslint-disable-next-line camelcase
                  if (activeBusinessId !== profile.id) {
                    if (
                      profile.hasActivePlan ||
                      (!profile.hasActivePlan && profile.hasSubscriptionAddPermission)
                    ) {
                      switchProfile('Business', profile)
                    } else if (!profile.hasActivePlan && !profile.hasSubscriptionAddPermission) {
                      dispatch(
                        errorToast(
                          handleMessages(translatedInput?.header?.contactBusinessAdminText),
                        ),
                      )
                    }
                  }
                }}
              >
                <Stack
                  direction='row'
                  justifyContent='space-between'
                  sx={{ width: '100%' }}
                  alignItems='center'
                >
                  <Box
                    display='flex'
                    alignItems='center'
                    sx={{
                      overflow: 'hidden',
                    }}
                  >
                    <Avatar src={profile.cover} alt={profile.name} />
                    <Stack direction='column' ml={2}>
                      <Tooltip title={profile?.name} placement='bottom-start'>
                        <Typography
                          lineHeight={1}
                          variant='subtitle1'
                          color='common.black'
                          fontWeight={'normal'}
                          sx={{
                            [theme.breakpoints.up('md')]: {
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                              maxWidth: '10rem',
                            },
                          }}
                        >
                          {profile?.name}
                        </Typography>
                      </Tooltip>
                      {!profile.hasActivePlan && (
                        <Box
                          sx={{
                            backgroundColor: theme.palette.publicPages.colorOneHover,
                            borderRadius: '0.5rem',
                            px: [1],
                            mt: 0.5,
                          }}
                        >
                          <Typography color='white' variant='subtitle2' textAlign={'center'}>
                            {translatedInput?.header?.subscriptionExpired}
                          </Typography>
                        </Box>
                      )}
                      <Tooltip title={profile?.role} placement='bottom-start'>
                        <Typography
                          variant='subtitle2'
                          color='description.light'
                          fontWeight={300}
                          sx={{
                            [theme.breakpoints.up('md')]: {
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                              maxWidth: '10rem',
                            },
                          }}
                        >
                          {profile?.role}
                        </Typography>
                      </Tooltip>
                    </Stack>
                  </Box>
                  <Box display='flex' justifyContent='flex-end'>
                    <Box>
                      {
                        // eslint-disable-next-line camelcase
                        activeBusinessId !== profile.id ? (
                          <Box ml={4} className='show-on-hover' display='none'>
                            <Typography variant='subtitle1' color='primary.main' fontWeight={600}>
                              {translatedInput?.common?.switchText}
                            </Typography>
                          </Box>
                        ) : (
                          <CheckCircle color='success' />
                        )
                      }
                    </Box>
                  </Box>
                </Stack>
              </ListItemButton>
            </ListItem>
          </List>
        )
      })}

      <ListItem disablePadding sx={{ '&:hover .show-on-hover': { display: { sm: 'block' } } }}>
        <ListItemButton onClick={() => switchProfile('Customer')}>
          <Stack
            direction='row'
            justifyContent='space-between'
            sx={{ width: '100%' }}
            alignItems='center'
          >
            <Box display='flex' alignItems='center'>
              <Avatar
                src={userProfileInformation.image}
                alt={userProfileInformation.customer_name}
              />
              <Stack direction='column' ml={2}>
                <Typography
                  lineHeight={1}
                  variant='subtitle1'
                  color='common.black'
                  fontWeight={'normal'}
                >
                  {translatedInput?.common?.personalText}
                </Typography>
              </Stack>
            </Box>
            <Box display='flex' justifyContent='flex-end'>
              <Box>
                {
                  <Box>
                    {userType === 'customer' ? (
                      <CheckCircle color='success' />
                    ) : (
                      <Box ml={4} className='show-on-hover' display='none'>
                        <Typography variant='subtitle1' color='primary.main' fontWeight={600}>
                          {translatedInput?.common?.switchText}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                }
              </Box>
            </Box>
          </Stack>
        </ListItemButton>
      </ListItem>
    </>
  )
}

export default ListBusinessProfiles
