import { CloseOutlined } from '@mui/icons-material'
import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../_helpers/hooks'
import { ITab } from '../../../_models/tabsInterface'
import { IUserData } from '../../../_models/userInterface'
import ProfileDrawer from '../../../features/profileDrawer'
import { useUserPermissionAuthorization } from '../../../hooks'
import { openScannerModal } from '../../../slices/dashboardSlice'
import { pricingData, setPricingAlertModal } from '../../../slices/pricingSlice'
import { headerTabs } from '../items'

interface IMainDrawerProps {
  window?: () => Window
  handleDrawerToggle: () => void
  mobileOpen: boolean
  userData: IUserData | Record<string, never>
  handleDrawerOnPopoverClick: () => void
}

const CustomerDrawer = (props: IMainDrawerProps) => {
  const [isPermitted] = useUserPermissionAuthorization()
  const filteredHeaderTabs = headerTabs.filter((tabItem) => {
    // If the menu item has permission requirement and permission is not satisfied, filter it out
    return !tabItem.permissions || isPermitted(tabItem.permissions)
  })

  const dispatch = useAppDispatch()
  const translatedInput = useAppSelector((state) => state?.translation.translatedData)
  const { subscriptionName } = useAppSelector(pricingData)

  const { window, handleDrawerToggle, mobileOpen, handleDrawerOnPopoverClick } = props
  const navigate = useNavigate()
  const container = window !== undefined ? () => window().document.body : undefined
  const drawerWidth = '100vw'
  const popoverPlacement = 'center'

  const handleClickOnListItem = (item: ITab) => {
    handleDrawerToggle()
    // Booking management and Scanner features are not available for Basic plan
    if ((item.id === 2 || item.id === 3) && subscriptionName?.toLowerCase() === 'basic')
      dispatch(
        setPricingAlertModal({
          status: true,
          content: translatedInput?.subscription?.pleaseUpgradeSubscriptionForFeature,
        }),
      )
    else if (item.id === 3) dispatch(openScannerModal())
    else navigate(item.path)
  }

  // Drawer - mobile view
  const drawer = (
    <>
      <Box onClick={handleDrawerToggle} sx={{ textAlign: 'end' }}>
        <Box>
          <IconButton
            color='primary'
            aria-label='open drawer'
            edge='end'
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <CloseOutlined />
          </IconButton>
        </Box>
      </Box>
      <Box display='flex' alignItems='center' justifyContent='center' sx={{ my: 2 }}>
        <ProfileDrawer
          handleDrawerToggle={handleDrawerToggle}
          userData={props?.userData}
          popoverPlacement={popoverPlacement}
          handleDrawerOnPopoverClick={handleDrawerOnPopoverClick}
        />
      </Box>
      <Box onClick={handleDrawerToggle} sx={{ textAlign: 'end' }}>
        <List>
          {filteredHeaderTabs.map((item: ITab, index: number) => (
            <ListItem key={index}>
              <ListItemButton
                sx={{ textAlign: 'center' }}
                onClick={() => handleClickOnListItem(item)}
              >
                <ListItemText primary={translatedInput?.appHeader[item?.label]} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    </>
  )
  return (
    <>
      <Drawer
        container={container}
        variant='temporary'
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
      >
        {drawer}
      </Drawer>
    </>
  )
}

export default CustomerDrawer
