import { IIcon } from './iconConstants'
const LocationIcon = (props: IIcon) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox='0 0 16 19'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g fill='none' fillRule='evenodd'>
        <path d='M-2-1h20v20H-2z' />
        <path
          d='m8 16.417 4.125-4.125a5.833 5.833 0 1 0-8.25 0L8 16.417zm0 2.356L2.697 13.47a7.5 7.5 0 1 1 10.606 0L8 18.773zm0-8.94A1.667 1.667 0 1 0 8 6.5a1.667 1.667 0 0 0 0 3.333zM8 11.5a3.333 3.333 0 1 1 0-6.667A3.333 3.333 0 0 1 8 11.5z'
          fill={props.fill}
          fillRule='nonzero'
        />
      </g>
    </svg>
  )
}
export default LocationIcon
