import { InfoOutlined } from '@mui/icons-material'
import { Box, Divider, IconButton } from '@mui/material'
import Popover from '@mui/material/Popover'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import { useAppSelector } from '../../_helpers/hooks'

export default function PasswordInfo() {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

  const translatedInput = useAppSelector((state) => state?.translation.translatedData)

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <div>
      <IconButton
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup='true'
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <InfoOutlined color='primary' fontSize='small' />
      </IconButton>
      <Popover
        id='mouse-over-popover'
        sx={{
          pointerEvents: 'none',
          margin: '10px',
        }}
        disableScrollLock={true}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Box sx={{ padding: '15px' }}>
          <Typography variant='subtitle1' color='primary.main' sx={{ fontSize: '16px' }}>
            {translatedInput?.profile?.passwordShouldContain}
          </Typography>
          <Divider variant='fullWidth' sx={{ marginY: '15px' }} />

          <Typography variant='subtitle1' color='secondary.main'>
            <li color='primary.main'> {translatedInput?.profile?.passwordUpperCase} </li>
          </Typography>
          <Typography variant='subtitle1' color='secondary.main'>
            <li color='primary'> {translatedInput?.profile?.passwordNumber}</li>
          </Typography>
          <Typography variant='subtitle1' color='secondary.main'>
            <li color='primary'> {translatedInput?.profile?.passwordMinMax}</li>
          </Typography>
          <Typography variant='subtitle1' color='secondary.main'>
            <li>{translatedInput?.profile?.passwordSplChars}</li>
          </Typography>
        </Box>
      </Popover>
    </div>
  )
}
