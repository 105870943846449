// FOR COMMON PAGES PAGE NOT FOUND,ACCESS DENIED WITH HEADER AND FOOTER
import { Box } from '@mui/material'

import { useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { getUserTypeFromToken, isAuthenticated } from '../../_helpers/cookie'
import { useAppDispatch, useAppSelector } from '../../_helpers/hooks'
import { translation, translationData } from '../../slices/translationSlice'
import { userDetails, userState } from '../../slices/userSlice'
import AdminDrawer from '../adminLayout/Drawer'
import AdminHeader from '../adminLayout/header'
import CustomerDrawer from '../customerLayout/Drawer'
import CustomerHeader from '../customerLayout/header'
import MainDrawer from '../publicLayout/Drawer'
import PublicHeader from '../publicLayout/Header'
import Footer from './footer'

const CommonPageLayout = () => {
  const dispatch = useAppDispatch()

  const navigate = useNavigate()
  const [mobileOpen, setMobileOpen] = useState(false)
  const userInformation = useAppSelector(userState)
  const { userData } = userInformation
  const loggedInUserType = getUserTypeFromToken()
  const translationState = useAppSelector(translationData)
  const { selectedLanguage } = translationState

  useEffect(() => {
    dispatch(translation(selectedLanguage))
    if (isAuthenticated()) {
      dispatch(userDetails())
    }
  }, [dispatch, selectedLanguage])

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const handleDrawerOnPopoverClick = () => {
    setMobileOpen(false)
  }

  const handleNavigateToSignUp = (
    redirectionPath: string,
    routeState?: Record<string, unknown>,
  ) => {
    navigate(redirectionPath, routeState)
  }

  return (
    <>
      <Box height={'80vh'}>
        {!isAuthenticated() && (
          <PublicHeader
            handleNavigate={handleNavigateToSignUp}
            handleDrawerToggle={handleDrawerToggle}
          >
            <Box component='nav'>
              <MainDrawer
                mobileOpen={mobileOpen}
                handleNavigate={handleNavigateToSignUp}
                handleDrawerToggle={handleDrawerToggle}
              />
            </Box>
          </PublicHeader>
        )}
        {isAuthenticated() && loggedInUserType === 'customer' && (
          <CustomerHeader
            handleDrawerOnPopoverClick={handleDrawerOnPopoverClick}
            handleDrawerToggle={handleDrawerToggle}
            userData={userData}
          >
            {' '}
            <Box component='nav'>
              <CustomerDrawer
                mobileOpen={mobileOpen}
                handleDrawerToggle={handleDrawerToggle}
                userData={userData}
                handleDrawerOnPopoverClick={handleDrawerOnPopoverClick}
              />
            </Box>
          </CustomerHeader>
        )}
        {isAuthenticated() && loggedInUserType === 'business' && (
          <AdminHeader
            handleDrawerOnPopoverClick={handleDrawerOnPopoverClick}
            handleDrawerToggle={handleDrawerToggle}
            userData={userData}
          >
            <Box component='nav'>
              <AdminDrawer
                mobileOpen={mobileOpen}
                handleDrawerToggle={handleDrawerToggle}
                userData={userData}
                handleDrawerOnPopoverClick={handleDrawerOnPopoverClick}
              />
            </Box>
          </AdminHeader>
        )}

        <Outlet />
        <Footer />
      </Box>
    </>
  )
}

export default CommonPageLayout
