import { useEffect, useState } from 'react'

type anchorEl = HTMLButtonElement | null
type open = boolean
type CopyFn = (text: string, event: React.MouseEvent<HTMLButtonElement>) => Promise<boolean> // Return success
type closeCopiedInfo = () => void

function useCopyToClipboard(): [anchorEl, open, CopyFn, closeCopiedInfo] {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [open, setOpen] = useState(false)

  const copy: CopyFn = async (text, event) => {
    setAnchorEl(event.currentTarget)

    if (!navigator?.clipboard) {
      console.warn('Clipboard not supported')
      return false
    }

    // Try to save to clipboard then save it in the state if worked
    try {
      await navigator.clipboard.writeText(text)
      setOpen(true)

      return true
    } catch (error) {
      console.warn('Copy failed', error)
      return false
    }
  }

  const closeCopiedInfo = () => {
    setOpen(false)
  }

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setOpen(false)
      }, 5000)
    }
  }, [open])

  return [anchorEl, open, copy, closeCopiedInfo]
}

export default useCopyToClipboard
