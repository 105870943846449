/* eslint-disable camelcase */
import { useAppSelector } from '../_helpers/hooks'
import { pricingData } from '../slices/pricingSlice'

const limitCheck = (planLimit: number, userLimit: number) => {
  if (planLimit) {
    return userLimit >= planLimit
  } else {
    return false
  }
}
export const usePricingLimits = () => {
  const { pricingLimits } = useAppSelector(pricingData)
  const plan_limits = pricingLimits?.plan_limits
  const user_limits = pricingLimits?.user_limits
  const floorPlansLimitCheck = () =>
    limitCheck(plan_limits?.floor_plan?.count, user_limits?.floor_plans)
  const facilitiesLimitCheck = () =>
    limitCheck(plan_limits?.facility?.count, user_limits?.facilities)
  const tokenBoardLimitCheck = () =>
    limitCheck(plan_limits?.token_board?.boards_count, user_limits?.token_boards)
  const rolesLimitCheck = () =>
    limitCheck(plan_limits?.user_management?.roles_count, user_limits?.roles)
  const usersLimitCheck = () =>
    limitCheck(plan_limits?.user_management?.users_count, user_limits?.users)
  const bookingsLimitCheck = () =>
    limitCheck(plan_limits?.bookings_per_month?.count, user_limits?.bookings)

  return {
    floorPlansLimitCheck,
    facilitiesLimitCheck,
    tokenBoardLimitCheck,
    rolesLimitCheck,
    usersLimitCheck,
    bookingsLimitCheck,
  }
}
