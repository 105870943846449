/* eslint-disable camelcase */

import {
  IAddLocation,
  ICheckDayFacilityConfigEvents,
  ICheckFacilityConfigEvents,
  ICreateFacilityBasicInfo,
  ICreateFacilityConfiguration,
  IDayDetails,
  IFacilityStatusDetails,
  IGetBusinessDetails,
  IGetFacilityBookingCount,
  IUpdateFacilityBasicInfo,
  IUpdateFacilityConfiguration,
  IUpdateFacilityDaySettings,
} from '../_models/facilityInterface'
import { IFilters } from './../_models/commonInterface'

import { get, post, postFileData, put } from './apiServices'

const getAllFacilities = async (filters: IFilters) => {
  const response = await get('facilities', filters)
  return response
}

const createFacilityBasicInfo = async (facilityBasicInfoDetails: ICreateFacilityBasicInfo) => {
  const response = await postFileData('/facilities/basic-info', facilityBasicInfoDetails)
  return response
}

const getAllLocations = async () => {
  const response = await get('/business/locations')
  return response
}

const addLocation = async (location: IAddLocation) => {
  const response = await post('/business/locations', location)
  return response
}

const getLocationFacilities = async (params: { location: number; inactive: number }) => {
  const response = await get('facilities', params)
  return response
}

const createFacilityConfiguration = async (facilityConfiguration: ICreateFacilityConfiguration) => {
  const response = await post('/facilities/config', facilityConfiguration)
  return response
}

const updateFacilityDaySettings = async (facilityDaySettings: IUpdateFacilityDaySettings) => {
  const response = await post('/facilities/day-settings', facilityDaySettings)
  return response
}

const getFacilityDetails = async (facilityId: string) => {
  const response = await get('/facilities/details', {
    facility_id: facilityId,
  })
  return response
}

const updateFacilityBasicInfo = async (facilityBasicInfoDetails: IUpdateFacilityBasicInfo) => {
  const response = await postFileData('/facilities/basic-info/update', facilityBasicInfoDetails)
  return response
}

const updateFacilityConfiguration = async (facilityConfiguration: IUpdateFacilityConfiguration) => {
  const response = await put('/facilities/config', facilityConfiguration)
  return response
}

const getFacilityDayConfiguration = async (dayDetails: IDayDetails) => {
  const response = await get(`/facilities/config/${dayDetails.dayDate}`, {
    facility_id: dayDetails.facilityId,
  })
  return response
}

const checkFacilityConfigurationEvents = async (
  facilityConfiguration: ICheckFacilityConfigEvents | ICheckDayFacilityConfigEvents,
) => {
  const response = await post('/facilities/config-update-check', facilityConfiguration)
  return response
}

const getFacilityBookingCount = async (facilityDetails: IGetFacilityBookingCount) => {
  const response = await get('/facilities/bookings/count', facilityDetails)
  return response
}

const deleteFacility = async (facilityId: string) => {
  const response = await post('/facilities/delete', {
    facility_id: facilityId,
  })
  return response
}
const updateFacilityStatus = async (facilityStatus: IFacilityStatusDetails) => {
  const response = await post('/facilities/update-status', {
    facility_id: facilityStatus.facilityId,
    status: facilityStatus.status,
  })
  return response
}
// ### Customer -> Business List-> Business Details & facility list  ####

const getFacilityListByBusinessUrl = async (params: IGetBusinessDetails) => {
  const response = await get(`/customer/businesses/${params.businessUrl}/facility`, params.filter)
  return response
}

const facilityService = {
  getAllFacilities,
  createFacilityBasicInfo,
  getAllLocations,
  addLocation,
  getLocationFacilities,
  createFacilityConfiguration,
  updateFacilityDaySettings,
  getFacilityDetails,
  updateFacilityBasicInfo,
  getFacilityDayConfiguration,
  updateFacilityConfiguration,
  checkFacilityConfigurationEvents,
  deleteFacility,
  updateFacilityStatus,
  getFacilityBookingCount,
  getFacilityListByBusinessUrl,
}
export default facilityService
