import React, { useState } from 'react'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
interface CustomTooltipProps extends TooltipProps {
  needAutoClose?: boolean
  disableHoverListener?: boolean
}

const LightTooltip = styled(
  ({ className, needAutoClose, disableHoverListener, ...props }: CustomTooltipProps) => {
    const [isTooltipOpen, setTooltipOpen] = useState(false)
    const handleTouchStart = () => {
      if (!disableHoverListener) setTooltipOpen(true)
    }

    const handleTouchEnd = () => {
      setTooltipOpen(false)
    }
    const handleTooltipOpen = () => {
      if (!disableHoverListener) setTooltipOpen(true)
      setTimeout(() => {
        handleTouchEnd()
      }, 2000)
    }
    return (
      <Tooltip
        {...props}
        arrow
        classes={{ popper: className }}
        open={isTooltipOpen}
        enterDelay={0}
        enterTouchDelay={0}
        leaveTouchDelay={0}
        onTouchStart={needAutoClose ? handleTooltipOpen : handleTouchStart}
        onTouchEnd={handleTouchEnd}
        onMouseEnter={handleTouchStart}
        onMouseLeave={handleTouchEnd}
        onMouseDown={needAutoClose ? handleTooltipOpen : handleTouchStart}
      />
    )
  },
)(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[3],
    borderRadius: 5,
    padding: 15,
    fontSize: '0.75rem',
    fontWeight: 500,
    lineHeight: 1.71,
    color: theme.palette.textGrey.contrastText,
  },
}))

export default LightTooltip
