import { IIcon } from './iconConstants'
const UserVoiceIcon = (props: IIcon) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox='0 0 22 22'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g fill='none' fillRule='evenodd'>
        <path d='M-1 0h24v24H-1z' />
        <path
          d='M0 22a8 8 0 1 1 16 0h-2a6 6 0 1 0-12 0H0zm8-9c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm0-2c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zM20.548.784A13.942 13.942 0 0 1 22 7c0 2.233-.523 4.344-1.452 6.216l-1.645-1.196A11.955 11.955 0 0 0 20 7a11.96 11.96 0 0 0-1.097-5.02L20.548.784zm-3.302 2.4A9.97 9.97 0 0 1 18 7a9.97 9.97 0 0 1-.754 3.816l-1.677-1.22A7.99 7.99 0 0 0 16 7a7.99 7.99 0 0 0-.43-2.596l1.676-1.22z'
          fill={props.fill}
          fillRule='nonzero'
        />
      </g>
    </svg>
  )
}
export default UserVoiceIcon
