import { IIcon } from './iconConstants'
const ShieldCheckIcon = (props: IIcon) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox='0 0 16 20'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <title>Group</title>
      <desc>Created with Sketch.</desc>
      <g
        id='Page-1'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
        opacity={props.opacity ? props.opacity : '.5'}
      >
        <g
          id='22_Settings_Profile_Business_Subscription'
          transform='translate(-139.000000, -629.000000)'
        >
          <g id='Tab-Copy-7' transform='translate(137.000000, 629.000000)'>
            <g id='Group'>
              <polygon id='Path' points='0 0 20 0 20 20 0 20' />
              <path
                d='M10,0.833333333 L16.8475,2.355 C17.2283333,2.44 17.5,2.7775 17.5,3.16833333 L17.5,11.4908333 C17.5,13.1625 16.6641667,14.7241667 15.2733333,15.6508333 L10,19.1666667 L4.72666667,15.6508333 C3.335,14.7233333 2.5,13.1625 2.5,11.4916667 L2.5,3.16833333 C2.5,2.7775 2.77166667,2.44 3.1525,2.355 L10,0.833333333 Z M10,2.54083333 L4.16666667,3.83666667 L4.16666667,11.4908333 C4.16666667,12.605 4.72333333,13.6458333 5.65083333,14.2641667 L10,17.1641667 L14.3491667,14.2641667 C15.2766667,13.6458333 15.8333333,12.6058333 15.8333333,11.4916667 L15.8333333,3.83666667 L10,2.54166667 L10,2.54083333 Z M13.71,6.85166667 L14.8891667,8.03 L9.58583333,13.3333333 L6.05,9.7975 L7.22833333,8.61916667 L9.585,10.9758333 L13.71,6.85083333 L13.71,6.85166667 Z'
                id='Shape'
                fill={props.fill}
                fillRule='nonzero'
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
export default ShieldCheckIcon
