// ==============================|| OVERRIDES - INPUT LABEL ||============================== //

export default function InputLabel(theme: any) {
  return {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: theme.palette.secondary.dark,
          fontSize: '.875rem !important',
          lineHeight: '1.1375em',
        },
        outlined: {
          '&.Mui-error': {
            color: theme.palette.secondary.dark,
          },
          '&.Mui-focused': {
            color: theme.palette.common.black,
          },
          // lineHeight: '0.8em',
          '&.MuiInputLabel-sizeSmall': {
            // lineHeight: '1em'
          },
          '&.MuiInputLabel-shrink': {
            background: theme.palette.background.paper,
            padding: '0 8px',
            paddingTop: '5px',
            marginLeft: -6,
            lineHeight: '1.2375em',
          },
        },
      },
    },
  }
}
