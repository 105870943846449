import { useEffect, useRef, useState } from 'react'

const useLongPress = (
  onLongPress: () => void,
  onClick?: () => void,
  duration = 500, // default duration in milliseconds
) => {
  const [isPressing, setIsPressing] = useState(false)
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    if (isPressing) {
      timeoutRef.current = setTimeout(onLongPress, duration)
    } else {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [isPressing])

  const handleMouseDown = () => {
    setIsPressing(true)
  }

  const handleMouseUp = () => {
    setIsPressing(false)
    if (onClick && timeoutRef.current) {
      clearTimeout(timeoutRef.current)
      onClick()
    }
  }

  return {
    onMouseDown: handleMouseDown,
    onMouseUp: handleMouseUp,
    onTouchStart: handleMouseDown,
    onTouchEnd: handleMouseUp,
  }
}

export default useLongPress
