import MenuIcon from '@mui/icons-material/Menu'
import { Stack, useTheme } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Tab, { TabProps } from '@mui/material/Tab'
import Tabs, { TabsProps } from '@mui/material/Tabs'
import Toolbar from '@mui/material/Toolbar'
import { styled } from '@mui/material/styles'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../../_helpers/hooks'
import { ITab } from '../../../_models/tabsInterface'
import { IUserData } from '../../../_models/userInterface'
import ProfileDrawer from '../../../features/profileDrawer'
import { useUserPermissionAuthorization } from '../../../hooks'
import Logo from '../../../images/logo.svg'
import { headerTabs } from '../items'

interface IHeader {
  children: React.ReactNode
  handleDrawerToggle: () => void
  userData: IUserData | Record<string, never>
  handleDrawerOnPopoverClick: () => void
}
const CustomTabs = styled(Tabs)<TabsProps>(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  paddingLeft: 0,
  paddingRight: 0,

  '& .indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    height: '.1875rem',

    '& > span': {
      width: '80%',
      backgroundColor: theme.palette.primary.main,
      height: '.1875rem',
    },
  },
  '& .flexContainer': {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'fit-content',
  },
}))

const CustomTab = styled(Tab)<TabProps>(({ theme }) => ({
  borderRadius: '0.625rem',
  minHeight: 'initial',
  '&.Mui-selected': {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontWeight: 500,
  },
  '&.MuiButtonBase-root': {
    paddingBottom: '1.59rem',
  },
}))

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const CustomerHeader: React.FC<IHeader> = ({
  handleDrawerToggle,
  children,
  handleDrawerOnPopoverClick,
  userData,
}) => {
  const theme = useTheme()
  const [value, setValue] = React.useState(0)
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const translatedInput = useAppSelector((state) => state?.translation.translatedData)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()
  const handleClick = (id: number) => {
    document.body.classList.remove('no-scroll')
    switch (id) {
      case 1:
        navigate('/customer/business')
        setOpen(false)
        break
      case 2:
        navigate('/customer/my-bookings')
        setOpen(false)
        break

      default:
        setOpen(false)
        break
    }
  }
  const goToBusinessListing = () => {
    document.body.classList.remove('no-scroll')
    setOpen(false)
    navigate('/customer/business')
  }

  const popoverPlacement = 'right'
  const checkPath = () => {
    switch (window.location.pathname) {
      case '/customer/business':
        setValue(0)
        break
      case '/customer/my-bookings':
        setValue(1)
        break
      default:
        setValue(-1)
        break
    }
  }

  useEffect(() => {
    checkPath()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname])

  const [isPermitted] = useUserPermissionAuthorization()
  const filteredHeaderTabs = headerTabs.filter((tabItem) => {
    // If the menu item has permission requirement and permission is not satisfied, filter it out
    return !tabItem.permissions || isPermitted(tabItem.permissions)
  })

  return (
    <>
      <Box
        display='flex'
        sx={{
          minHeight: '5.438rem',
        }}
      >
        <AppBar component='nav' sx={{ py: 0, boxShadow: theme.customShadows.z1 }}>
          <Toolbar
            sx={{
              marginRight: 'calc(-1 * (100vw - 100%))',
              '&.MuiToolbar-root': {
                minHeight: '5.438rem',
              },
            }}
          >
            <Box
              component='div'
              sx={{
                flexGrow: 1,
                display: { sm: 'block' },
                textAlign: { xs: 'left', md: 'left', lg: 'left' },
                cursor: 'pointer',
              }}
              ml={{ xs: 1, md: 4 }}
            >
              <img src={Logo} alt='' onClick={goToBusinessListing} />
            </Box>
            <Box
              display='flex'
              alignItems='end'
              justifyContent='end'
              sx={{
                height: '100%',
                display: { xs: 'none', sm: 'block' },
              }}
            >
              <Box display='flex' sx={{ px: { sm: 0, md: 0, lg: 3 }, pt: 0.5 }}>
                <CustomTabs
                  value={value}
                  onChange={handleChange}
                  aria-label='profile settings tabs'
                  indicatorColor='primary'
                  classes={{ indicator: 'indicator' }}
                  TabIndicatorProps={{ children: <span /> }}
                  sx={{ px: { sm: 0, md: 0, lg: 3 }, pt: 1, pb: 0 }}
                >
                  {filteredHeaderTabs.map((item: ITab, index: number) => {
                    return (
                      <CustomTab
                        sx={{
                          backgroundColor: 'common.white',
                          py: 2,
                          px: { sm: 1, md: 1, lg: 2 },
                          my: 1,
                          mx: { sm: 0, md: 2, lg: 3 },
                        }}
                        key={index}
                        label={translatedInput?.appHeader[item?.label]}
                        onClick={() => handleClick(item.id)}
                        {...a11yProps(index)}
                      />
                    )
                  })}
                </CustomTabs>
                <Stack direction='row' spacing={2} display='flex' py={2}>
                  <Box pt={0.2}>
                    <ProfileDrawer
                      handleDrawerOnPopoverClick={handleDrawerOnPopoverClick}
                      handleDrawerToggle={handleDrawerToggle}
                      userData={userData}
                      popoverPlacement={popoverPlacement}
                    />
                  </Box>
                </Stack>
              </Box>
            </Box>
            <IconButton
              color='primary'
              aria-label='open drawer'
              edge='start'
              size='large'
              onClick={handleDrawerToggle}
              sx={{ display: { sm: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        {/* <Box component='main' p={{ xs: 0, sm: 2, md: 2, lg: 2, xl: 2 }}>
          <Toolbar />
        </Box> */}
        <Box mt={9.2}>{children}</Box>
      </Box>
    </>
  )
}

export default CustomerHeader
