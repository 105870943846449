// ==============================|| OVERRIDES - APP BAR ||============================== //

export default function AppBar(theme: any) {
  return {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.common.white,
          boxShadow: 'none',
        },
      },
    },
  }
}
