import { IIcon } from './iconConstants'
const ArrowRightIcon = (props: IIcon) => {
  return (
    <svg
      xmlnsXlink='http://www.w3.org/1999/xlink'
      viewBox='0 0 16 16'
      xmlns='http://www.w3.org/2000/svg'
      height={props.height}
      width={props.width}
    >
      <g fill={props.fill}>
        <path d='M9.707 8l-6 6L3 13.293 8.293 8 3 2.707 3.707 2z' />
        <path d='M13.707 8l-6 6L7 13.293 12.293 8 7 2.707 7.707 2z' />
      </g>
    </svg>
  )
}
export default ArrowRightIcon
