import React from 'react'
import { Stack, Typography, useTheme } from '@mui/material'
import { ProIcon } from '../../components/icons'
import { useAppSelector } from '../../_helpers/hooks'

interface ProBadgeProps {
  positionStyles?: React.CSSProperties
}

const ProBadge: React.FC<ProBadgeProps> = ({ positionStyles = {} }) => {
  const translatedInput = useAppSelector((state) => state?.translation.translatedData)
  const theme = useTheme()
  return (
    <Stack
      direction={'row'}
      spacing={0.5}
      sx={{
        py: 0.2,
        px: 0.75,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundImage: `linear-gradient(to bottom, ${theme.palette.primary.main}, ${theme.palette.chart.main})`,
        borderRadius: '1.75rem',
        ...positionStyles,
      }}
    >
      <ProIcon
        width='0.94rem'
        height='0.94rem'
        fill={theme.palette.common.white}
        stoke={theme.palette.common.white}
      />
      <Typography
        variant='subtitle2'
        color={theme.palette.common.white}
        fontSize={'0.75rem'}
        sx={{ display: { xs: 'block', sm: 'block', md: 'block' } }}
      >
        {translatedInput?.landingScreen?.proText}
      </Typography>
    </Stack>
  )
}

export default ProBadge
