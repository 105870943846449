import { IIcon } from './iconConstants'
const UserUploadLargeIcon = (props: IIcon) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox='0 0 28 31'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <title>Group</title>
      <desc>Created with Sketch.</desc>
      <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g
          id='23_Settings_Profile_Business_Account_Settings2'
          transform='translate(-935.000000, -402.000000)'
        >
          <g id='Group-4' transform='translate(888.000000, 312.000000)'>
            <g id='Bitmap' transform='translate(0.000000, 47.000000)'>
              <g id='Group' transform='translate(42.000000, 42.000000)'>
                <polygon id='Path' points='0 0 34 0 34 34 0 34' />
                <path
                  d='M19.8333333,20.1903333 L19.8333333,23.1511667 C17.2314799,22.2312725 14.344979,22.6317726 12.09186,24.2252911 C9.83874091,25.8188096 8.499473,28.4069844 8.5,31.1666667 L5.6666663,31.16525 C5.6657756,27.6594839 7.28736148,24.3506514 10.0586402,22.2034646 C12.829919,20.0562779 16.4388898,19.312475 19.8333333,20.1889167 L19.8333333,20.1903333 Z M17,18.4166667 C12.30375,18.4166667 8.5,14.6129167 8.5,9.91666667 C8.5,5.22041667 12.30375,1.41666667 17,1.41666667 C21.69625,1.41666667 25.5,5.22041667 25.5,9.91666667 C25.5,14.6129167 21.69625,18.4166667 17,18.4166667 Z M17,15.5833333 C20.1308333,15.5833333 22.6666667,13.0475 22.6666667,9.91666667 C22.6666667,6.78583333 20.1308333,4.25 17,4.25 C13.8691667,4.25 11.3333333,6.78583333 11.3333333,9.91666667 C11.3333333,13.0475 13.8691667,15.5833333 17,15.5833333 Z M25.0849167,25.6714167 L22.49525,28.2625 L20.4906667,26.2579167 L26.5015833,20.2484167 L32.5125,26.2579167 L30.5079167,28.2625 L27.91825,25.6714167 L27.91825,30.7515833 L25.0849167,30.7515833 L25.0849167,25.6714167 Z'
                  id='Shape'
                  fill={props.fill}
                  fillRule='nonzero'
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
export default UserUploadLargeIcon
