import { IIcon } from './iconConstants'
const CalenderIcon2 = (props: IIcon) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox='0 0 14 14'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11.569 1.205h-.794V.652a.65.65 0 0 0-.332-.565.672.672 0 0 0-.663 0 .65.65 0 0 0-.331.565v.553H4.55V.652a.65.65 0 0 0-.332-.565.672.672 0 0 0-.663 0 .65.65 0 0 0-.331.565v.553H2.43c-.645 0-1.263.252-1.719.7A2.373 2.373 0 0 0 0 3.597v8.01c0 .635.256 1.244.712 1.692.456.449 1.074.701 1.72.701h9.137c.645 0 1.263-.252 1.719-.7A2.373 2.373 0 0 0 14 11.607V3.597c0-.635-.256-1.243-.712-1.692a2.452 2.452 0 0 0-1.72-.7zM2.433 2.51h.794v.987a.65.65 0 0 0 .331.565.672.672 0 0 0 .663 0 .65.65 0 0 0 .332-.565V2.51h4.896v.987a.65.65 0 0 0 .331.565.672.672 0 0 0 .663 0 .65.65 0 0 0 .332-.565V2.51h.793c.294 0 .575.114.782.318.207.204.324.48.324.769v1.979H1.326v-1.98c0-.288.116-.564.324-.768.207-.204.488-.318.78-.318h.003zm9.136 10.185H2.43c-.293 0-.574-.114-.781-.318a1.08 1.08 0 0 1-.324-.769V6.881h11.348v4.727c0 .288-.117.565-.324.769a1.114 1.114 0 0 1-.782.318z'
        fill={props.fill}
        fillRule='nonzero'
      />
    </svg>
  )
}
export default CalenderIcon2
