// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function Stepper(theme: any) {
  return {
    MuiStepIcon: {
      styleOverrides: {
        root: {
          orientation: 'horizontal',
          alternativeLabel: true,
          '&.MuiStepIcon-root': {
            width: '1.875rem',
            height: '1.875rem',
            border: `0.063rem solid ${theme.palette.tab.main}`,
            borderRadius: '50%',
            '&.MuiStepConnector-root': {
              left: 'calc(-50% + 16px)',
              right: 'calc(-50% + 16px)',
              '&:MuiStepConnector-line': {
                borderColor: theme.palette.tab.main,
              },
            },
          },
          text: {
            fill: theme.palette.tab.main,
            fontSize: '0.745rem',
            fontWeight: 500,
          },

          '&.Mui-active': {
            border: `0.063rem solid ${theme.palette.common.white}`,
            text: {
              fill: theme.palette.common.white,
            },
            '& circle': {
              color: theme.palette.tab.main,
            },
          },
          '&.Mui-completed': {
            color: theme.palette.tab.main,
            border: `0.063rem solid ${theme.palette.common.white}`,
          },
          '& circle': {
            color: theme.palette.common.white,
          },
        },
      },
    },
  }
}
