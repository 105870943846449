import { IIcon } from './iconConstants'
const TwitterIcon = ({ width, height, fill, stoke, backgroundColor }: IIcon) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      fill='none'
      viewBox='0 0 512 512'
      id='twitter'
    >
      <g clipPath='url(#clip0_84_15697)'>
        <rect width='512' height='512' fill={fill} rx='60' />
        <path
          fill={stoke}
          d='M355.904 100H408.832L293.2 232.16L429.232 412H322.72L239.296 302.928L143.84 412H90.8805L214.56 270.64L84.0645 100H193.28L268.688 199.696L355.904 100ZM337.328 380.32H366.656L177.344 130.016H145.872L337.328 380.32Z'
        />
      </g>
      <defs>
        <clipPath id='clip0_84_15697'>
          <rect width='512' height='512' fill={backgroundColor} />
        </clipPath>
      </defs>
    </svg>
  )
}
export default TwitterIcon
