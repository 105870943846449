/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box } from '@mui/material'
import { InteractiveFloorComponentBookingView } from 'floor-plan-editor'
import { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
interface mobileDataParams {
  loadingInMobileView: boolean
  lockedSeats: string[]
  lockedSeatsByOthers: string[]
  image: string
  bookingLimit: number
  bookedIds: Record<string, string>
  otherUserEmailIds: string[]
  currentUserEmailId: string
  isBookForOthers: boolean
  seatToFocus: string
  colorCodes: Record<string, string>
  loadingInSingleBookingView: boolean
}
const FloorPlanBookingView = () => {
  const [initialDataFromMobileView, setInitialDataFromMobileView] = useState<mobileDataParams>({
    loadingInMobileView: true,
    loadingInSingleBookingView: false,
    lockedSeats: [],
    lockedSeatsByOthers: [],
    image: '',
    bookingLimit: 0,
    bookedIds: {},
    otherUserEmailIds: [],
    currentUserEmailId: '',
    isBookForOthers: false,
    seatToFocus: '',
    colorCodes: {},
  })

  useEffect(() => {
    if ((window as any).initialData) {
      setInitialDataFromMobileView({
        ...initialDataFromMobileView,
        ...(window as any).initialData,
      })
    }
  }, [])
  const seatSelectionCallback = (
    seatName: any,
    capacity: any,
    previousSelection: any,
    userEmailId: any,
  ) => {
    const obj = {
      seatName,
      capacity,
      previousSelection,
      userEmailId,
    }
    const dataToSend = { type: 'addSeat', data: obj }
    ;(window as any).ReactNativeWebView.postMessage(JSON.stringify(dataToSend))
  }
  const bookingViewRef = useRef(null)
  ;(window as any).searchSeat = (seatName: string) => {
    if (bookingViewRef?.current) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const currentRef = bookingViewRef.current as any
      currentRef.findPathAndNavigateWithSeatName(
        seatName,
        initialDataFromMobileView.isBookForOthers ? false : true,
      )
    }
  }
  ;(window as any).updateInitialState = (newState: any) => {
    setInitialDataFromMobileView({ ...initialDataFromMobileView, ...newState })
  }
  ;(window as any).removeSeat = (seatCode: string) => {
    if (bookingViewRef?.current) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const currentRef = bookingViewRef.current as any
      currentRef.handleClose()
      currentRef.removeSeatFromSelectedSeats(seatCode)
    }
  }

  const removeSeatCallback = (seatName: any, previousSelection: any) => {
    const obj = {
      seatName,
      previousSelection,
    }
    const dataToSend = { type: 'removeSeat', data: obj }
    ;(window as any).ReactNativeWebView.postMessage(JSON.stringify(dataToSend))
  }
  const sendAlert = (msg: any) => {
    const dataToSend = { type: 'alert', data: msg }
    ;(window as any).ReactNativeWebView.postMessage(JSON.stringify(dataToSend))
  }

  return (
    <>
      <Helmet>
        <meta
          name='viewport'
          content='width=device-width, initial-scale=0.70, minimum-scale=0.70, maximum-scale=1.0, user-scalable=no'
        />
      </Helmet>
      <Box sx={{ height: '100vh' }}>
        <InteractiveFloorComponentBookingView
          ref={bookingViewRef}
          colorCodes={initialDataFromMobileView.colorCodes}
          lockedSeats={initialDataFromMobileView.lockedSeats}
          lockedSeatsByOthers={initialDataFromMobileView.lockedSeatsByOthers}
          sendAlert={sendAlert}
          image={initialDataFromMobileView.image}
          bookingLimit={initialDataFromMobileView.bookingLimit}
          seatSelectionCallback={seatSelectionCallback}
          removeSeatCallback={removeSeatCallback}
          bookedIds={initialDataFromMobileView.bookedIds}
          otherUserEmailIds={initialDataFromMobileView.otherUserEmailIds}
          currentUserEmailId={initialDataFromMobileView.currentUserEmailId}
          isBookForOthers={initialDataFromMobileView.isBookForOthers}
          loadingInMobileView={initialDataFromMobileView.loadingInMobileView}
          seatToFocus={initialDataFromMobileView.seatToFocus}
          loadingInSingleBookingView={initialDataFromMobileView.loadingInSingleBookingView}
        />
      </Box>
    </>
  )
}

export default FloorPlanBookingView
