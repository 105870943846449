import BlockIcon from '@mui/icons-material/Block'
import { Box, Button, Grid, IconButton, Link, Typography, useTheme } from '@mui/material'
import moment from 'moment'
import { FC } from 'react'
import { useAppSelector } from '../../_helpers/hooks'
import { ISeats } from '../../_models/newBookingInterface'
import ShowMoreContainer from '../../components/common/showMoreItem'
import { WarningIcon } from '../../components/icons'
import { newBookingData } from '../../slices/newBookingSlice'
import ActionAlertModal from '../actionAlertModal'

interface IBookingInfo {
  date: string
  timeSlot: string
  noOfSeats: number
  noOfDays: number
  floorName: string
  facilityName: string
  selectedSeats: ISeats[] | []
  selectedEmails?: string[]
}

interface IBookingConfirmationModal {
  showModal: boolean
  bookingInfo: IBookingInfo
  updateBookingConfirmationStatus: (bookingStatus: string) => void
}
const BookingConfirmationModal: FC<IBookingConfirmationModal> = ({
  showModal,
  bookingInfo,
  updateBookingConfirmationStatus,
}) => {
  const translatedInput = useAppSelector((state) => state?.translation.translatedData)
  const theme = useTheme()
  const newBookingState = useAppSelector(newBookingData)
  const { seatAvailable, unregisteredEmails, isBookForOthers } = newBookingState
  return (
    <ActionAlertModal
      showAlert={showModal}
      title={translatedInput?.bookingConfirmation?.confirmBookingTitle}
      alertIcon={
        <WarningIcon
          fill={theme.palette.iconColors.contrastText}
          width='46'
          height='46'
          stoke={theme.palette.common.white}
        />
      }
    >
      <Box
        mt={'1.875rem'}
        sx={{
          padding: '1.063rem 1.313rem 1.063rem 1.188rem',
          borderRadius: '0.625rem',
          backgroundColor: 'table.main',
          width: '100%',
        }}
      >
        <Box display={{ xs: 'block', sm: 'flex' }} pt={0.7}>
          <Typography
            color='toggleButtonGroup.main'
            variant='subtitle1'
            pr={{ xs: 0, md: 1.5 }}
            flex={2}
          >
            {translatedInput?.common?.facilityLabel}
          </Typography>
          <Typography
            color='toggleButtonGroup.main'
            variant='subtitle1'
            flex={0.3}
            display={{ xs: 'none', sm: 'block' }}
          >
            :
          </Typography>
          <Typography
            fontSize={'1rem'}
            color={{ xs: 'common.black', sm: 'toggleButtonGroup.main' }}
            variant='subtitle1'
            sx={{ wordBreak: 'break-word' }}
            flex={4}
          >
            {bookingInfo.facilityName}
          </Typography>
        </Box>
        <Box display={{ xs: 'block', sm: 'flex' }} pt={0.5}>
          <Typography
            color='toggleButtonGroup.main'
            variant='subtitle1'
            pr={{ xs: 0, md: 1.5 }}
            flex={2}
          >
            {translatedInput?.bookingConfirmation?.dateLabel}
          </Typography>
          <Typography
            color='toggleButtonGroup.main'
            variant='subtitle1'
            flex={0.3}
            display={{ xs: 'none', sm: 'block' }}
          >
            :
          </Typography>
          <Typography
            fontSize={'1rem'}
            color={{ xs: 'common.black', sm: 'toggleButtonGroup.main' }}
            variant='subtitle1'
            flex={4}
            sx={{ wordBreak: 'break-word' }}
          >
            {moment(bookingInfo.date).format('MMMM DD, YYYY')}
          </Typography>
        </Box>
        <Box display={{ xs: 'block', sm: 'flex' }} pt={0.5}>
          <Typography
            flex={2}
            color='toggleButtonGroup.main'
            variant='subtitle1'
            pr={{ xs: 0, md: 1.5 }}
          >
            {translatedInput?.bookingConfirmation?.timeSlotLabel}
          </Typography>
          <Typography
            color='toggleButtonGroup.main'
            variant='subtitle1'
            flex={0.3}
            display={{ xs: 'none', sm: 'block' }}
          >
            :
          </Typography>
          <Typography
            fontSize={'1rem'}
            color={{ xs: 'common.black', sm: 'toggleButtonGroup.main' }}
            variant='subtitle1'
            flex={4}
            sx={{ wordBreak: 'break-word' }}
          >
            {bookingInfo.timeSlot}
          </Typography>
        </Box>

        {bookingInfo.noOfDays > 1 && (
          <Box display={{ xs: 'block', sm: 'flex' }} pt={0.5}>
            <Typography
              flex={2}
              color='toggleButtonGroup.main'
              variant='subtitle1'
              pr={{ xs: 0, md: 1.5 }}
            >
              {translatedInput?.bookingConfirmation?.noOfDaysRecurringLabel}
            </Typography>
            <Typography
              color='toggleButtonGroup.main'
              variant='subtitle1'
              flex={0.3}
              display={{ xs: 'none', sm: 'block' }}
            >
              :
            </Typography>
            <Typography
              fontSize={'1rem'}
              color={{ xs: 'common.black', sm: 'toggleButtonGroup.main' }}
              variant='subtitle1'
              flex={4}
              sx={{ wordBreak: 'break-word' }}
            >
              {bookingInfo.noOfDays}
            </Typography>
          </Box>
        )}
        <Box display={{ xs: 'block', sm: 'flex' }} pt={0.5}>
          <Typography
            flex={2}
            color='toggleButtonGroup.main'
            variant='subtitle1'
            pr={{ xs: 0, md: 1.5 }}
          >
            {translatedInput?.bookingConfirmation?.noOfSeatsLabel}
          </Typography>
          <Typography
            color='toggleButtonGroup.main'
            variant='subtitle1'
            flex={0.3}
            display={{ xs: 'none', sm: 'block' }}
          >
            :
          </Typography>
          <Typography
            fontSize={'1rem'}
            color={{ xs: 'common.black', sm: 'toggleButtonGroup.main' }}
            variant='subtitle1'
            flex={4}
            sx={{ wordBreak: 'break-word' }}
          >
            {bookingInfo.noOfSeats}
          </Typography>
        </Box>

        {bookingInfo.selectedSeats.length > 0 && (
          <>
            <Box display={{ xs: 'block', sm: 'flex' }} pt={0.5}>
              <Typography
                flex={2}
                color='toggleButtonGroup.main'
                variant='subtitle1'
                pr={{ xs: 0, md: 1.5 }}
              >
                {translatedInput?.common?.floorLabel}
              </Typography>
              <Typography
                color='toggleButtonGroup.main'
                variant='subtitle1'
                flex={0.3}
                display={{ xs: 'none', sm: 'block' }}
              >
                :
              </Typography>
              <Box flex={4} display={'flex'}>
                <Typography
                  fontSize={'1rem'}
                  color={{ xs: 'common.black', sm: 'toggleButtonGroup.main' }}
                  variant='subtitle1'
                  sx={{ wordBreak: 'break-word' }}
                >
                  {bookingInfo.floorName}
                </Typography>
              </Box>
            </Box>
            <Box display={{ xs: 'block', sm: 'flex' }} pt={0.5}>
              <Typography
                flex={2}
                color='toggleButtonGroup.main'
                variant='subtitle1'
                pr={{ xs: 0, md: 1.5 }}
              >
                {translatedInput?.newBookingScreenText?.selectedSeatsText}
              </Typography>
              <Typography
                color='toggleButtonGroup.main'
                variant='subtitle1'
                flex={0.3}
                display={{ xs: 'none', sm: 'block' }}
              >
                :
              </Typography>
              <Box flex={4} display={'flex'} sx={{ wordBreak: 'break-word' }}>
                <ShowMoreContainer
                  colorOfItem={'toggleButtonGroup.main'}
                  colorOfItemXs={'common.black'}
                  colorOfOption={'error.main'}
                  fontSizeOfItem={'1rem'}
                  fontWeightOfOption='normal'
                  itemsToShowInitially={2}
                  isItemLink={false}
                  mtRemove={true}
                  data={bookingInfo.selectedSeats.map((seat) => seat.seat_name)}
                />
              </Box>
            </Box>
          </>
        )}
        {bookingInfo?.selectedEmails?.length && (
          <Box display={{ xs: 'block', sm: 'flex' }} pt={0.5}>
            <Typography
              flex={2}
              color='toggleButtonGroup.main'
              variant='subtitle1'
              pr={{ xs: 0, md: 1.5 }}
            >
              {translatedInput?.newBooking?.bookingEmails}
            </Typography>
            <Typography
              color='toggleButtonGroup.main'
              variant='subtitle1'
              flex={0.3}
              display={{ xs: 'none', sm: 'block' }}
            >
              :
            </Typography>
            <Box flex={4} display={'flex'} sx={{ wordBreak: 'break-word' }}>
              <ShowMoreContainer
                colorOfItem={'toggleButtonGroup.main'}
                colorOfItemXs={'common.black'}
                colorOfOption={'error.main'}
                fontSizeOfItem={'1rem'}
                fontWeightOfOption='normal'
                itemsToShowInitially={1}
                isItemLink={false}
                mtRemove={true}
                data={bookingInfo?.selectedEmails ?? []}
              />
            </Box>
          </Box>
        )}
      </Box>
      {bookingInfo.selectedSeats.length === 0 &&
      bookingInfo.noOfDays === 1 &&
      seatAvailable === 1 ? (
        <Box mt={2} display={'flex'} width={'100%'}>
          <Box flex={0.3} pt={0.1} pr={{ xs: 1, sm: 0 }}>
            <WarningIcon
              fill={theme.palette.toggleButtonGroup.main}
              width='14'
              height='14'
              stoke={theme.palette.common.white}
            />
          </Box>

          <Typography color='toggleButtonGroup.main' variant='subtitle2' flex={6}>
            {translatedInput?.bookingConfirmation?.noSeatsSelection}
          </Typography>
        </Box>
      ) : (
        <Box display={'flex'} width={'100%'}>
          {bookingInfo.selectedSeats.length < bookingInfo.noOfSeats &&
            bookingInfo.noOfDays === 1 &&
            seatAvailable === 1 && (
              <>
                <Box flex={0.3} pt={0.1} mt={2}>
                  <WarningIcon
                    fill={theme.palette.toggleButtonGroup.main}
                    width='14'
                    height='14'
                    stoke={theme.palette.common.white}
                  />
                </Box>

                <Typography color='toggleButtonGroup.main' variant='subtitle2' flex={6} mt={2}>
                  {translatedInput?.bookingConfirmation?.partialSeatSelection1}{' '}
                  {bookingInfo.selectedSeats.length}{' '}
                  {translatedInput?.bookingConfirmation?.partialSeatSelection2}{' '}
                  {bookingInfo.noOfSeats}
                </Typography>
              </>
            )}
        </Box>
      )}
      {unregisteredEmails.length > 0 && isBookForOthers && (
        <Box>
          <Typography color='toggleButtonGroup.main' variant='subtitle2' mt={1} display={'inline'}>
            <span>
              {translatedInput?.bookingConfirmation?.newEmails} {' :'}
            </span>
            <ShowMoreContainer
              colorOfItem={'common.black'}
              colorOfItemXs={'common.black'}
              colorOfOption={'error.main'}
              fontSizeOfItem={'.85rem'}
              fontSizeOfOption={'.85rem'}
              fontWeightOfOption='normal'
              itemsToShowInitially={4}
              isItemLink={false}
              mtRemove={true}
              data={unregisteredEmails}
            />
          </Typography>
        </Box>
      )}
      <Box width={'100%'} mt={'1.4375rem'}>
        <Grid container spacing={'1.25rem'}>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <Button
              sx={{ width: '100%' }}
              variant='contained'
              size='large'
              onClick={() => {
                updateBookingConfirmationStatus('Confirm')
              }}
            >
              {translatedInput?.common?.confirmBtn}
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <Button
              sx={{ width: '100%' }}
              variant='outlined'
              size='large'
              onClick={() => {
                updateBookingConfirmationStatus('Cancel')
              }}
            >
              {translatedInput?.common?.cancelBtn}
            </Button>
          </Grid>
        </Grid>
        <Box pt={3} display={'flex'} justifyContent={'center'} columnGap={1}>
          <IconButton
            sx={{ p: 0 }}
            onClick={() => {
              updateBookingConfirmationStatus('Discard')
            }}
          >
            <BlockIcon sx={{ height: ' 1.125rem', width: ' 1.125rem' }} color='error' />
          </IconButton>
          <Link
            onClick={() => {
              updateBookingConfirmationStatus('Discard')
            }}
          >
            <Typography variant='subtitle1' color='error.main' className='mob-hide'>
              {translatedInput?.bookingConfirmation?.discardBookingBtn}
            </Typography>
          </Link>
        </Box>
      </Box>
    </ActionAlertModal>
  )
}

export default BookingConfirmationModal
