import { IIcon } from './iconConstants'
const MailIcon = (props: IIcon) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox='0 0 18 16'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M.889 0h16c.49 0 .889.398.889.889V15.11c0 .491-.398.889-.89.889h-16A.889.889 0 0 1 0 15.111V.89C0 .398.398 0 .889 0zM16 3.767l-7.047 6.311-7.175-6.33v10.474H16V3.767zM2.232 1.777 8.943 7.7l6.614-5.922H2.232z'
        fill={props.fill}
        fillRule='nonzero'
      />
    </svg>
  )
}
export default MailIcon
