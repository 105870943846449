import * as React from 'react'

import LanguageIcon from '@mui/icons-material/Language'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import {
  Avatar,
  Box,
  Button,
  Divider,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Tooltip,
  Typography,
  createTheme,
  useTheme,
} from '@mui/material'

import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../_helpers/hooks'
import { IUserData } from '../../_models/userInterface'

import { useEffect, useState } from 'react'
import { PERMISSIONS } from '../../_constants'
import { getUserTypeFromToken } from '../../_helpers/cookie'
import { IBusinessProfile } from '../../_models/businessInterface'
import { ILanguage } from '../../_models/translationInterface'
import { LogoutBoxRLineIcon } from '../../components/icons'
import { useUserPermissionAuthorization } from '../../hooks'
import { logout } from '../../slices/authenticationSlice'
import { businessData, switchBusinessList, switchProfile } from '../../slices/businessSlice'
import { closeDrawer } from '../../slices/newBookingSlice'
import {
  getLanguages,
  setLanguage,
  translation,
  translationData,
} from '../../slices/translationSlice'
import { userState } from '../../slices/userSlice'
import ListBusinessProfiles from './listBusinessProfiles'

interface IPopper {
  userData: IUserData | Record<string, never>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  popoverPlacement: any
  handleDrawerToggle: () => void
  handleDrawerOnPopoverClick: () => void
}
export default function SimplePopper({
  userData,
  popoverPlacement,
  handleDrawerOnPopoverClick,
}: IPopper) {
  const translationState = useAppSelector(translationData)
  const businessDetails = useAppSelector(businessData)
  const userDetails = useAppSelector(userState)
  const { userProfileInformation } = userDetails
  const { allocatedBusinessList } = businessDetails
  const { languages } = translationState
  const [isPermitted] = useUserPermissionAuthorization()
  const translatedInput = useAppSelector((state) => state?.translation.translatedData)

  // eslint-disable-next-line camelcase

  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const themes = useTheme()

  const [currentLanguage, setCurrentLanguage] = useState('English')
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [languageAnchorEl, setLanguageAnchorEl] = React.useState<null | HTMLElement>(null)

  const open = Boolean(anchorEl)
  const languageOpen = Boolean(languageAnchorEl)

  const id = open ? 'simple-popper' : undefined
  const languagePopperId = languageOpen ? 'simple-popper' : undefined
  const loggedInUserType = getUserTypeFromToken()

  useEffect(() => {
    dispatch(getLanguages())
    dispatch(switchBusinessList())
  }, [dispatch])

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleSwitchProfile = (profileType: string, profileData?: IBusinessProfile) => {
    let payload
    if (profileType === 'Business' && profileData) {
      payload = {
        type: profileType,
        // eslint-disable-next-line camelcase
        business_id: profileData.id,
      }
    } else {
      payload = {
        type: profileType,
      }
    }
    dispatch(switchProfile(payload))
    handleClose()
  }

  const handleLanguageClick = (event: React.MouseEvent<HTMLElement>) => {
    setLanguageAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    document.body.classList.remove('no-scroll')
    setAnchorEl(null)
    dispatch(closeDrawer())
  }

  const handleLanguageClose = () => {
    setLanguageAnchorEl(null)
  }

  const handleLogout = () => {
    dispatch(logout())
  }

  const handleLanguage = (languageCode: string) => {
    dispatch(setLanguage(languageCode))
    dispatch(translation(languageCode))
  }

  const handleSettingsClick = () => {
    if (loggedInUserType && loggedInUserType === 'customer') {
      navigate('/customer/profile-settings')
    }
    if (
      loggedInUserType &&
      loggedInUserType === 'business' &&
      isPermitted([
        PERMISSIONS.VIEW_BUSINESS_SETTINGS,
        PERMISSIONS.BUSINESS_RESTRICTION_ACCESS,
        PERMISSIONS.SUBSCRIPTION_VIEW,
      ])
    ) {
      navigate('/admin/settings/business-settings')
    } else if (loggedInUserType && loggedInUserType === 'business') {
      navigate('/admin/settings/profile-settings')
    }

    handleClose()
    handleDrawerOnPopoverClick()
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 640,
        md: 1024,
        lg: 1200,
        xl: 1520,
      },
    },
  })

  const showSettings =
    loggedInUserType === 'business' ||
    (loggedInUserType === 'customer' && isPermitted([PERMISSIONS.CUSTOMER_PROFILE_VIEW]))
  return (
    <>
      <Button onClick={handleClick}>
        <Avatar
          alt='Profile Pic'
          src={userData?.image}
          aria-describedby={id}
          imgProps={{ sx: { objectFit: 'contain' } }}
        />
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: popoverPlacement,
        }}
        onClose={handleClose}
        disablePortal
        PaperProps={{
          sx: {
            minWidth: 350,
            maxWidth: { xs: 280, sm: 500 },
          },
        }}
      >
        <Box display={'flex'} p={2} mr={2}>
          <Avatar src={userProfileInformation.image} alt={userProfileInformation.name} />
          <Box pt={0.5} overflow={'hidden'}>
            <Tooltip title={userProfileInformation?.name} placement='bottom-start'>
              <Typography
                variant='subtitle1'
                color='common.black'
                fontWeight={'normal'}
                ml={2}
                lineHeight={1}
                sx={{
                  [theme.breakpoints.up('md')]: {
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    maxWidth: '10rem',
                  },
                }}
              >
                {userProfileInformation?.name}
              </Typography>
            </Tooltip>
            <Tooltip title={userProfileInformation?.email} placement='bottom-start'>
              <Typography
                variant='subtitle2'
                color='description.light'
                fontWeight={300}
                ml={2}
                sx={{
                  [theme.breakpoints.up('md')]: {
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    maxWidth: '10rem',
                  },
                }}
              >
                {userProfileInformation?.email}
              </Typography>
            </Tooltip>
          </Box>
        </Box>
        {/* {isPermitted([PERMISSIONS.BUSINESS_SWITCH_DROPDOWN]) && ( */}
        <>
          <ListBusinessProfiles
            profiles={allocatedBusinessList}
            switchProfile={handleSwitchProfile}
          />
        </>
        {/* )} */}
        <Box pl={2} pr={2}>
          <Divider sx={{ mt: 1 }} />
        </Box>
        <List>
          {showSettings && (
            <>
              <ListItem disablePadding onClick={handleSettingsClick}>
                <ListItemButton>
                  <ListItemIcon sx={{ mr: 2 }}>
                    <SettingsOutlinedIcon sx={{ width: 20, height: 20 }} />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography variant='subtitle1' color='description.light' fontWeight={'normal'}>
                      {translatedInput?.header?.settingsMenu}
                    </Typography>
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            </>
          )}
          <ListItem disablePadding onClick={handleLanguageClick}>
            <ListItemButton>
              <ListItemIcon sx={{ mr: 2 }}>
                <LanguageIcon sx={{ width: 20, height: 20 }} />
              </ListItemIcon>
              <ListItemText>
                <Box display={'flex'} justifyContent={'space-between'}>
                  <Box>
                    <Typography variant='subtitle1' color='description.light' fontWeight={'normal'}>
                      {translatedInput?.profileScreenText?.languageLabel}
                    </Typography>
                  </Box>
                  <Box ml={4}>
                    <Typography variant='subtitle1' color='selection.main'>
                      {currentLanguage}
                    </Typography>
                  </Box>
                </Box>
              </ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem
            disablePadding
            onClick={() => {
              handleClose()
              // handleDrawerToggle()
              handleDrawerOnPopoverClick()
            }}
          >
            {/* <ListItemButton>
              <ListItemIcon sx={{ mr: 2 }}>
                <CodeSquareSlashIcon
                  width={'1.25rem'}
                  height={'1.25rem'}
                  fill={themes.palette.toggleButtonGroup.contrastText}
                />
              </ListItemIcon>
              <ListItemText>
                <Typography variant='subtitle1' color='description.light' fontWeight={'normal'}>
                  {translatedInput?.common?.APIDocMenu}
                </Typography>
              </ListItemText>
            </ListItemButton> */}
          </ListItem>
          <ListItem
            disablePadding
            onClick={() => {
              handleLogout()
            }}
          >
            <ListItemButton>
              <ListItemIcon sx={{ mr: 2 }}>
                <LogoutBoxRLineIcon
                  width={'1.25rem'}
                  height={'1.25rem'}
                  fill={themes.palette.toggleButtonGroup.contrastText}
                />
              </ListItemIcon>
              <ListItemText>
                <Typography variant='subtitle1' color='description.light' fontWeight={'normal'}>
                  {translatedInput?.generalText?.signOutText}
                </Typography>
              </ListItemText>
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>

      {/* Language Options Popover */}
      <Popover
        id={languagePopperId}
        open={languageOpen}
        anchorEl={languageAnchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleLanguageClose}
        disablePortal
      >
        <List>
          {languages.map((languageData: ILanguage) => (
            <ListItem
              onClick={() => {
                setCurrentLanguage(languageData.language)
                handleLanguageClose()
              }}
              key={languageData.language}
              sx={{ alignItems: 'flex-start' }}
            >
              <ListItemButton>
                <ListItemText>
                  <Link onClick={() => handleLanguage(languageData.lang_code)}>
                    <Typography variant='subtitle1' color='description.light'>
                      {languageData.language}
                    </Typography>
                  </Link>
                </ListItemText>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Popover>
    </>
  )
}
