import { IIcon } from './iconConstants'
const MailWithTickIcon = (props: IIcon) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox='0 0 61 44'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g fillRule='nonzero' fill='none'>
        <path
          d='M50.232 4.162C50.232 1.866 48.356 0 46.046 0H4.186C1.877 0 0 1.865 0 4.162v24.967c0 2.296 1.876 4.162 4.186 4.162h33.586C38.462 38.756 43.158 43 48.837 43 54.99 43 60 38.02 60 31.903c0-5.646-4.27-10.313-9.767-11l-.001-16.741zm-4.765-1.389-17.393 17.29a4.211 4.211 0 0 1-5.923 0L4.766 2.773h40.701zM2.792 4.736l17.393 17.29a6.971 6.971 0 0 0 4.932 2.026 6.973 6.973 0 0 0 4.933-2.026l17.393-17.29v16.167c-5.037.632-9.035 4.605-9.67 9.613H4.187a1.395 1.395 0 0 1-1.395-1.387V4.736z'
          fill={props.backgroundColor}
        />
        <path
          d='M48.5 44C41.604 44 36 38.396 36 31.5S41.604 19 48.5 19 61 24.604 61 31.5 55.396 44 48.5 44z'
          fill={props.stoke}
        />
        <path
          d='m54.007 30.113-5.582 5.548c-.272.27-.627.41-.983.41s-.712-.14-.984-.41l-2.79-2.773a1.384 1.384 0 0 1 0-1.963c.544-.541 1.43-.541 1.974 0l1.807 1.796 4.598-4.57a1.373 1.373 0 0 1 1.96 0c.544.54.544 1.421 0 1.962z'
          fill={props.fill}
        />
      </g>
    </svg>
  )
}
export default MailWithTickIcon
