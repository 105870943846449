import { IIcon } from './iconConstants'
const Linkedin2Icon = (props: IIcon) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox='0 0 40 41'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g filter='url(#9q071r01la)'>
        <g filter='url(#mdn99fbz1b)'>
          <rect x='4' y='-.006' width='32' height='32.184' rx='7.111' fill={props.fill} />
        </g>
        <path
          d='M15.596 23.26h-3.313V12.53h3.313v10.73zm-1.658-12.193a1.92 1.92 0 0 1-1.787-1.188 1.95 1.95 0 0 1 .407-2.115 1.925 1.925 0 0 1 2.098-.432 1.947 1.947 0 0 1 1.2 1.784c.002.515-.2 1.008-.559 1.374a1.93 1.93 0 0 1-1.36.577zm14.06 12.193H24.69v-5.224c0-1.244-.025-2.84-1.723-2.84-1.722 0-1.986 1.352-1.986 2.751v5.313h-3.31V12.53h3.177v1.464h.044a3.49 3.49 0 0 1 1.33-1.302 3.466 3.466 0 0 1 1.806-.43c3.353 0 3.97 2.22 3.97 5.104v5.894h-.002z'
          fill={props.backgroundColor}
        />
      </g>
      <defs>
        <filter
          id='9q071r01la'
          x='0'
          y='-.006'
          width='40'
          height='40.184'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='4' />
          <feGaussianBlur stdDeviation='2' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
          <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_621_6857' />
          <feBlend in='SourceGraphic' in2='effect1_dropShadow_621_6857' result='shape' />
        </filter>
        <filter
          id='mdn99fbz1b'
          x='-3.249'
          y='-7.255'
          width='46.498'
          height='46.682'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImageFix' stdDeviation='3.624' />
          <feComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_621_6857' />
          <feBlend in='SourceGraphic' in2='effect1_backgroundBlur_621_6857' result='shape' />
        </filter>
      </defs>
    </svg>
  )
}
export default Linkedin2Icon
