import { IIcon } from './iconConstants'
const UserWithCloseIcon = ({ width, height, fill, stoke }: IIcon) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 46 46'
      fill={fill}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6 38.25V34.5a7.5 7.5 0 0 1 7.5-7.5H19M21 19.5a7.5 7.5 0 1 0 0-15 7.5 7.5 0 0 0 0 15zM31.3 41.508a8.926 8.926 0 1 0 0-17.851 8.926 8.926 0 0 0 0 17.851zM34.422 29.458l-6.248 6.248M28.174 29.458l6.248 6.248'
        stroke={stoke}
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
export default UserWithCloseIcon
