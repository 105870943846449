import { Box, IconButton, styled, TextField } from '@mui/material'
import { validateNumber } from '../../_helpers/validation'

const TextFieldInput = styled(TextField)(() => ({
  '& .MuiOutlinedInput-input': {
    textAlign: 'center',
    paddingTop: '0.6rem !important',
    paddingBottom: '0.6rem !important',
  },
}))

interface ICounter {
  count: number
  maxLimit: number
  onIncrement: (count: number) => void
  onDecrement: (count: number) => void
  onChange: (count: string) => void
  maximumSlotAvailable?: number
}

const Counter = (props: ICounter) => {
  const { count, maxLimit, onChange, onDecrement, onIncrement, maximumSlotAvailable } = props
  // Create handleIncrement event handler
  const handleIncrement = () => {
    if (maxLimit) {
      if (count < maxLimit) {
        onIncrement(count + 1)
      }
    }
  }

  // Create handleDecrement event handler
  const handleDecrement = () => {
    if (count > 1) {
      onDecrement(count - 1)
    }
  }

  const checkCountMaxLimit = () => {
    if (!maximumSlotAvailable) return count >= maxLimit
    else {
      const maxCountLimit = Math.min(maximumSlotAvailable, maxLimit)
      return count >= maxCountLimit
    }
  }

  return (
    <Box display={'flex'} mt={2}>
      <IconButton
        onClick={handleDecrement}
        sx={{
          maxWidth: '1.938rem',
          minHeight: '1.938rem',
          borderRadius: '0.625rem',
          backgroundColor: 'table.main',
          padding: '0rem 0.75rem',
          color: 'table.contrastText',
          margin: '0.313rem 0rem',
        }}
        size='small'
      >
        <Box>-</Box>
      </IconButton>

      <TextFieldInput
        onChange={(e) => {
          onChange(e.target.value)
        }}
        value={count || ''}
        id='seatCount'
        defaultValue={1}
        //  type='number'
        InputProps={{ inputProps: { min: 1 } }}
        onBlur={(e) => {
          if (e?.target?.value == '' || !validateNumber(e?.target?.value)) {
            onChange('1')
          }
        }}
        autoComplete='off'
        sx={{
          maxWidth: 70,
          margin: '0rem 0.625rem',
        }}
      />

      <IconButton
        onClick={handleIncrement}
        sx={{
          maxWidth: '1.938rem',
          minHeight: '1.938rem',
          borderRadius: '0.625rem',
          backgroundColor: 'table.main',
          padding: '0rem 0.75rem',
          color: 'table.contrastText',
          margin: '0.313rem 0rem',
        }}
        size='small'
        disabled={checkCountMaxLimit()}
      >
        <Box>+</Box>
      </IconButton>
    </Box>
  )
}

export default Counter
