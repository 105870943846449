import { IFilters } from '../_models/commonInterface'
import { IRoleData } from '../_models/rolesInterface'
import { _delete, get, post, put } from './apiServices'

const getRoleList = async (filters: IFilters) => {
  const response = await get('/business/roles', filters)
  return response
}

const getRoleDetailsById = async (roleId: number) => {
  const response = await get(`/business/role/${roleId}`)
  return response
}

const getPermissionList = async () => {
  const response = await get('/business/permissions')
  return response
}

const createRole = async (newRoleData: IRoleData) => {
  const response = await post('business/role', newRoleData)
  return response
}

const updateRole = async (roleId: number, newRoleData: IRoleData) => {
  const response = await put(`business/roles/${roleId}`, newRoleData)
  return response
}
const deleteRole = async (roleId: number) => {
  const response = await _delete(`business/roles/${roleId}`)
  return response
}

const fetchUserCount = async (roleId: number) => {
  const response = await get(`business/invite-user/user-count/${roleId}`)
  return response
}

const roleService = {
  getRoleList,
  getPermissionList,
  createRole,
  getRoleDetailsById,
  updateRole,
  deleteRole,
  fetchUserCount,
}

export default roleService
