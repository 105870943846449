import { ToggleButtonGroup } from '@mui/material'
import { styled } from '@mui/material/styles'

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    margin: '.75rem .75rem .75rem 0rem',
    display: 'inline-block',
    border: 0,
    '&.Mui-disabled': {
      border: 0,
    },
    '&.Mui-selected': {
      color: `${theme.palette.common.white} !important`,
      fontFamily: 'Lexend',
      fontSize: '0.875rem',
      fontWeight: 600,
      backgroundColor: `${theme.palette.tab.main} !important`,
    },
    '&:not(:first-of-type)': {
      borderRadius: '0.625rem',
      backgroundColor: theme.palette.table.main,
      padding: '.5rem 1.5rem',
      fontFamily: 'Lexend',
      fontSize: '0.875rem',
      fontWeight: 500,
      color: 'toggleButtonGroup.main',
    },
    '&:first-of-type': {
      borderRadius: '0.625rem',
      backgroundColor: theme.palette.table.main,
      padding: '.5rem 1.5rem',
      fontFamily: 'Lexend',
      fontSize: '0.875rem',
      fontWeight: 500,
      color: 'toggleButtonGroup.main',
    },
  },
}))
