import { Box, styled } from '@mui/material'
import Fade from '@mui/material/Fade'
import Paper from '@mui/material/Paper'
import Popper, { PopperPlacementType } from '@mui/material/Popper'
import { FC, ReactNode, useState } from 'react'
interface ICustomPopper {
  open: boolean
  anchorEl: HTMLElement | null
  placement?: PopperPlacementType
  children?: ReactNode
  popperStyle?: Record<string, unknown>
  sx?: Record<string, unknown>
  offsetDistance?: number
  arrow?: boolean
}
export const StyledPopper = styled(Popper)(({ theme }) => ({
  '&[data-popper-placement*="bottom"] .arrow': {
    top: 0,
    right: 0,
    marginTop: '-0.9em',
    width: '3em',
    height: '1em',
    '&::before': {
      borderWidth: '0 1em 1em 1em',
      borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
    },
  },
  '&[data-popper-placement*="top"] .arrow': {
    bottom: 0,
    right: 0,
    marginBottom: '-0.9em',
    width: '3em',
    height: '1em',
    '&::before': {
      borderWidth: '1em 1em 0 1em',
      borderColor: `${theme.palette.background.paper} transparent transparent transparent`,
    },
  },
  '&[data-popper-placement*="right"] .arrow': {
    left: 0,
    marginLeft: '-0.9em',
    height: '3em',
    width: '1em',
    '&::before': {
      borderWidth: '1em 1em 1em 0',
      borderColor: `transparent ${theme.palette.background.paper} transparent transparent`,
    },
  },
  '&[data-popper-placement*="left"] .arrow': {
    right: 0,
    marginRight: '-0.9em',
    height: '3em',
    width: '1em',
    '&::before': {
      borderWidth: '1em 0 1em 1em',
      borderColor: `transparent transparent transparent ${theme.palette.background.paper}`,
    },
  },
  '&.MuiAutocomplete-popper': {
    paddingBottom: '0.625rem',
  },
}))
const Arrow = styled(Box)(() => ({
  '& .arrow': {
    position: 'absolute',
    fontSize: 12,
    width: '3em',
    height: '3em',

    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid',
    },
  },
}))

const CustomPopperWithModifers: FC<ICustomPopper> = ({
  open,
  anchorEl,
  placement,
  children,
  popperStyle,
  sx,
  arrow,
  offsetDistance,
}) => {
  const [arrowRef, setArrowRef] = useState<HTMLElement | null>(null)

  return (
    <>
      <StyledPopper
        open={open}
        anchorEl={anchorEl}
        placement={placement}
        style={{ ...popperStyle }}
        transition
        modifiers={
          arrow
            ? [
                {
                  name: 'arrow',
                  enabled: true,
                  options: {
                    element: arrowRef,
                  },
                },
                {
                  name: 'offset',
                  options: {
                    offset: [0, offsetDistance],
                  },
                },
              ]
            : []
        }
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={200}>
            <Box>
              {arrow && (
                <Arrow>
                  <Box component='span' ref={setArrowRef} className='arrow' />
                </Arrow>
              )}
              <Paper sx={sx}>{children}</Paper>
            </Box>
          </Fade>
        )}
      </StyledPopper>
    </>
  )
}

export default CustomPopperWithModifers
