import { IIcon } from './iconConstants'
const ImageAddIcon = (props: IIcon) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox='0 0 40 40'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g fill='none' fillRule='evenodd'>
        <circle fill='#FFF' cx='20' cy='20' r='20' />
        <g opacity='.5'>
          <path d='M9.143 9.143h21.943v21.943H9.143z' />
          <path
            d='M28.343 22.857V25.6h2.743v1.829h-2.743v2.742h-1.829V27.43h-2.743V25.6h2.743v-2.743h1.829zm.007-10.971c.501 0 .907.407.907.908v8.547a5.477 5.477 0 0 0-1.828-.312v-7.315H12.8v12.8l8.496-8.496a.913.913 0 0 1 1.207-.077l.085.078 3.242 3.245a5.488 5.488 0 0 0-3.574 7.08l-10.378-.001a.908.908 0 0 1-.907-.908V12.794a.914.914 0 0 1 .907-.908H28.35zm-11.893 3.657a1.829 1.829 0 1 1 0 3.657 1.829 1.829 0 0 1 0-3.657z'
            fill={props.fill}
            fillRule='nonzero'
          />
        </g>
      </g>
    </svg>
  )
}
export default ImageAddIcon
