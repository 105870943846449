/* eslint-disable camelcase */

import {
  IAddTokenBoard,
  ITokenBoardActivationCode,
  ITokenBoardFacilityFilters,
} from '../_models/tokenBoardInterface'
import { IFilters } from './../_models/commonInterface'
import { _delete, get, post, put } from './apiServices'

const getAllTokenBoards = async (filters: IFilters) => {
  const response = await get('/token-boards', filters)
  return response
}

const getTokenBoardDetails = async (tokenBoardId: string) => {
  const response = await get(`/token-boards/${tokenBoardId}`)
  return response
}
const deleteTokenBoard = async (tokenBoardId: string) => {
  const response = await _delete(`/token-boards/${tokenBoardId}`)
  return response
}

const addTokenBoard = async (tokenBoardData: IAddTokenBoard) => {
  const response = await post('/token-boards', tokenBoardData)
  return response
}

const editTokenBoard = async (tokenBoardId: string, tokenBoardData: IAddTokenBoard) => {
  const response = await put(`/token-boards/${tokenBoardId}`, tokenBoardData)
  return response
}

const getTokenBoardFacilities = async (filters: ITokenBoardFacilityFilters) => {
  const response = await get('/token-boards/facilities', filters)
  return response
}

const checkTokenBoardActivationCode = async (activationCodeInfo: ITokenBoardActivationCode) => {
  const response = await post('/token-board/check-code', activationCodeInfo)
  return response
}

const tokenBoardService = {
  getAllTokenBoards,
  getTokenBoardDetails,
  deleteTokenBoard,
  addTokenBoard,
  editTokenBoard,
  getTokenBoardFacilities,
  checkTokenBoardActivationCode,
}

export default tokenBoardService
