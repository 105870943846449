/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { IVoyagerState } from '../_models/voyagerInterface'
import voyagerService from '../_services/voyagerService'
import { RootState } from '../_store/store'

const initialState: IVoyagerState = {
  businessConfig: {
    max_active_bookings: 0,
    max_bulk_bookings: 0,
    max_recurring_bookings: 0,
  },
}

//  BUSINESS USER VOYAGER CONFIGURATION

export const businessVoyagerConfiguration = createAsyncThunk(
  '/businessVoyagerConfiguration',
  async (_, { rejectWithValue }) => {
    try {
      const response = await voyagerService.getBusinessVoyagerConfiguration()
      return response
    } catch (err: any) {
      return rejectWithValue(err)
    }
  },
)

export const voyagerSlice = createSlice({
  name: 'voyager',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(businessVoyagerConfiguration.fulfilled, (state, action) => {
      state.businessConfig = action.payload.data.config
    })
  },
})

export const voyagerState = (state: RootState) => state.voyager
const voyagerReducer = voyagerSlice.reducer
export default voyagerReducer
