export const validateNumber = (value: string) => {
  const numberOnlyPattern = /^\d+$/
  if (value && numberOnlyPattern.test(value)) {
    return true
  } else {
    return false
  }
}

export const replacePrecedingZero = (value: string) => {
  const numWithoutZeros = value.replace(/^0+/, '')
  return numWithoutZeros
}
