import Brightness1Icon from '@mui/icons-material/Brightness1'
import { Box, Container, Divider, Link, List, ListItem, Typography, useTheme } from '@mui/material'
import { useAppSelector } from '../../../../_helpers/hooks'

const TermsOfService = () => {
  const theme = useTheme()
  const translatedInput = useAppSelector((state) => state?.translation.translatedData)
  const subTitle = {
    fontSize: { xs: '0.75rem', sm: '0.75rem', md: '0.875rem', lg: '0.875rem' },
    lineHeight: { xs: '1.5', lg: ' 1.438' },
    color: theme.palette.secondary.main,
    '& .highlighted-text': {
      color: theme.palette.common.black,
    },
  }

  const titleStyle = {
    fontSize: { xs: '1.125rem', lg: '1.75rem' },
    fontWeight: '600',
    lineHeight: '1.875rem',
    letterSpacing: 'normal',
    color: theme.palette.common.black,
    marginBottom: '1.25rem',
    scrollMarginTop: '100px',
  }
  const textStyle = {
    fontSize: { xs: '0.6rem', sm: '1rem', lg: '1rem' },
    lineHeight: { xs: '0.9rem', sm: '1.563rem', lg: '1.563rem' },
    fontWeight: 300,
    span: {
      color: theme.palette.common.black,
      fontWeight: 500,
    },
  }
  const subHeadingStyle = {
    fontSize: { xs: '1rem', lg: '1.25rem' },
    scrollMarginTop: '100px',
  }
  const bulletItemStyle = {
    fontSize: '0.625rem',
    paddingRight: '0.625rem',
    paddingTop: '0.450rem',
    color: theme.palette.primary.main,
  }

  const listItemStyle = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    paddingLeft: '0.5rem',
    paddingBottom: '0',
    paddingTop: '0',
  }
  const informationList = [
    {
      label: translatedInput?.termsOfService?.termsInformationListLabel1,
      value: 0,
    },
    {
      label: translatedInput?.termsOfService?.termsInformationListLabel2,
      value: 1,
    },
    {
      label: translatedInput?.termsOfService?.termsInformationListLabel3,
      value: 2,
    },
    {
      label: translatedInput?.termsOfService?.termsInformationListLabel4,
      value: 3,
    },
    {
      label: translatedInput?.termsOfService?.termsInformationListLabel5,
      value: 4,
    },
    {
      label: translatedInput?.termsOfService?.termsInformationListLabel6,
      value: 5,
    },
    {
      label: translatedInput?.termsOfService?.termsInformationListLabel7,
      value: 6,
    },
  ]

  const contentList = [
    {
      Heading: translatedInput?.termsOfService?.contentListHeader1,
      text: translatedInput?.termsOfService?.contentListText1,
      value: 0,
    },
    {
      Heading: translatedInput?.termsOfService?.contentListHeader2,
      text: translatedInput?.termsOfService?.contentListText2,
      value: 1,
    },
    {
      Heading: translatedInput?.termsOfService?.contentListHeader3,
      text: translatedInput?.termsOfService?.contentListText3,
      value: 2,
    },
    {
      Heading: translatedInput?.termsOfService?.contentListHeader4,
      text: translatedInput?.termsOfService?.contentListText4,
      value: 3,
    },
    {
      Heading: translatedInput?.termsOfService?.contentListHeader5,
      text: translatedInput?.termsOfService?.contentListText5,
      value: 4,
    },
    {
      Heading: translatedInput?.termsOfService?.contentListHeader6,
      text: translatedInput?.termsOfService?.contentListText6,
      value: 5,
    },
    {
      Heading: translatedInput?.termsOfService?.contentListHeader7,
      text: translatedInput?.termsOfService?.contentListText7,
      value: 6,
    },
    {
      Heading: translatedInput?.termsOfService?.contentListHeader8,
      text: translatedInput?.termsOfService?.contentListText8,
      value: 7,
    },
  ]

  const contentListTwo = [
    {
      Heading: translatedInput?.termsOfService?.contentListTwoHeading1,
      text: translatedInput?.termsOfService?.contentListTwoText1,
      value: 0,
    },
    {
      Heading: translatedInput?.termsOfService?.contentListTwoHeading2,
      text: translatedInput?.termsOfService?.contentListTwoText2,
      value: 1,
    },
    {
      Heading: translatedInput?.termsOfService?.contentListTwoHeading3,
      text: translatedInput?.termsOfService?.contentListTwoText3,
      value: 2,
    },
    {
      Heading: translatedInput?.termsOfService?.contentListTwoHeading8,
      text: translatedInput?.termsOfService?.contentListTwoText8,
      value: 3,
    },
    {
      Heading: translatedInput?.termsOfService?.contentListTwoHeading4,
      text: translatedInput?.termsOfService?.contentListTwoText4,
      value: 4,
    },
    {
      Heading: translatedInput?.termsOfService?.contentListTwoHeading5,
      text: translatedInput?.termsOfService?.contentListTwoText5,
      value: 5,
    },
    {
      Heading: translatedInput?.termsOfService?.contentListTwoHeading6,
      text: translatedInput?.termsOfService?.contentListTwoText6,
      value: 6,
    },
    {
      Heading: translatedInput?.termsOfService?.contentListTwoHeading7,
      text: translatedInput?.termsOfService?.contentListTwoText7,
      value: 7,
    },
  ]
  return (
    <Container
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        padding: { sm: '0 1rem 5.563rem', lg: '0 0 5.563rem' },
        maxWidth: '100rem',
        margin: '0 auto',
        fontFamily: 'Lexend',
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: 'auto',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          margin: '2.188rem 0 0.938rem',
        }}
      >
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: { xs: '1.5rem', md: '2.25rem', lg: '2.25rem' },
            lineHeight: { xs: '1.5rem', md: '3.375rem', lg: '3.375rem' },
          }}
        >
          {translatedInput?.termsOfService?.TermsConditionTitle}
        </Typography>

        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row', lg: 'row' },
            marginTop: '0.313rem',
          }}
        >
          <Typography sx={subTitle}>
            {translatedInput?.termsOfService?.lastUpdatedText}{' '}
            <span className='highlighted-text'>
              {translatedInput?.privacyPolicyPage?.lastUpdatedDate}
            </span>
            <span style={{ paddingRight: '0.625rem', paddingLeft: '0.625rem' }}> |</span>
          </Typography>
          <Typography sx={subTitle}>
            {translatedInput?.termsOfService?.effectiveText}{' '}
            <span className='highlighted-text'>
              {' '}
              {translatedInput?.privacyPolicyPage?.lastUpdatedDate}
            </span>
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          marginBottom: '1.75rem',
        }}
      >
        <Typography sx={textStyle}>
          {translatedInput?.termsOfService?.termsConditionLegalText}
        </Typography>
      </Box>

      <Divider
        style={{
          padding: ' 0 2.063rem',
          width: ' 100%',
          height: '0.063rem',
          background: theme.palette.profileIcon.colorDivider,
        }}
      />
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'start',
          justifyContent: 'start',
          marginTop: '2.063rem',
        }}
      >
        <Box
          sx={{
            minWidth: '12 rem',
            height: '100%',
            display: { xs: 'none', sm: 'flex', md: 'flex', lg: 'flex' },
            flexDirection: 'column',
            alignItems: 'start',
            justifyContent: 'start',
          }}
        >
          <Box
            sx={{
              padding: '0 0.813rem',
              position: 'sticky',
              top: '6.25rem',
              fontSize: '0.875rem',
              fontWeight: '500',
              lineHeight: '1.64',
              letterSpacing: 'normal',
              color: theme.palette.primary.main,
            }}
          >
            <List sx={{ listStyle: 'decimal' }}>
              <ListItem
                sx={{ display: 'list-item', marginBottom: '0.25rem', padding: '0 0 0 0.938rem' }}
              >
                <Link href='#sectionP'>
                  {translatedInput?.termsOfService?.userRepresentationText}
                </Link>
              </ListItem>
              {contentList.map((item) => (
                <ListItem
                  key={item.value}
                  sx={{ display: 'list-item', marginBottom: '0.25rem', padding: '0 0 0 0.938rem' }}
                >
                  <Link href={`#${item.Heading}`}>{item.Heading}</Link>
                </ListItem>
              ))}
              <List sx={{ listStyle: 'lower-roman', paddingLeft: '1rem' }}>
                <ListItem
                  sx={{ display: 'list-item', marginBottom: '0.25rem', padding: '0 0 0 0.938rem' }}
                >
                  <Link href='#Subscription'>{translatedInput?.termsOfService?.freeTrialText}</Link>
                </ListItem>
                <ListItem
                  sx={{ display: 'list-item', marginBottom: '0.25rem', padding: '0 0 0 0.938rem' }}
                >
                  <Link href='#Subscription'>
                    {translatedInput?.termsOfService?.paidSubscriptionText}
                  </Link>
                </ListItem>
              </List>

              {contentListTwo.map((item) => (
                <ListItem
                  key={item.value}
                  sx={{ display: 'list-item', marginBottom: '0.25rem', padding: '0 0 0 0.938rem' }}
                >
                  <Link href={`#${item.Heading}`}>{item.Heading}</Link>
                </ListItem>
              ))}
            </List>
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            width: '100%',
            height: '100%',
            flexDirection: 'column',
            gap: '1.5rem',
          }}
        >
          <Box
            sx={{
              display: 'flex',

              flexDirection: 'column',
            }}
          >
            <Typography id='sectionP' sx={titleStyle}>
              {translatedInput?.termsOfService?.userRepresentationText}
            </Typography>

            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography sx={textStyle}>
                {translatedInput?.termsOfService?.termsWarrantText}
              </Typography>

              <List sx={{ marginBottom: '0.938rem' }}>
                {informationList.map((item: { value: number; label: string }) => (
                  <ListItem sx={listItemStyle} key={item.value}>
                    <span style={bulletItemStyle}>
                      <Brightness1Icon fontSize='inherit' />
                    </span>
                    <Typography
                      sx={{
                        ...textStyle,
                        paddingTop: { xs: '0.4rem', sm: '0', lg: '0' },
                      }}
                    >
                      {item.label}
                    </Typography>
                  </ListItem>
                ))}
              </List>
              <Typography sx={textStyle}>
                {translatedInput?.termsOfService?.termsInformationText}
              </Typography>
            </Box>
          </Box>

          {contentList.map((item) => (
            <Box sx={{ display: 'flex', flexDirection: 'column' }} key={item.value}>
              <Typography id={item.Heading} sx={titleStyle}>
                {item.Heading}
              </Typography>
              <Typography sx={textStyle}>{item.text}</Typography>
            </Box>
          ))}
          <Box
            sx={{ display: 'flex', flexDirection: 'column', paddingLeft: '0.6rem', gap: '0.8rem' }}
          >
            <Typography sx={subHeadingStyle}>
              {translatedInput?.termsOfService?.freeTrialText}
            </Typography>
            <Typography sx={textStyle}>
              {translatedInput?.termsOfService?.termsFreeTrialText}
            </Typography>
            <Typography sx={subHeadingStyle}>
              {translatedInput?.termsOfService?.paidSubscriptionText}
            </Typography>
            <Typography sx={textStyle}>
              {translatedInput?.termsOfService?.subscribeChooseText}
            </Typography>
          </Box>

          {contentListTwo.map((item) => (
            <Box sx={{ display: 'flex', flexDirection: 'column' }} key={item.value}>
              <Typography id={item.Heading} sx={titleStyle}>
                {item.Heading}
              </Typography>
              <Typography sx={textStyle}>{item.text}</Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Container>
  )
}

export default TermsOfService
