export default function Autocomplete(theme: any) {
  return {
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '.MuiAutocomplete-input': {
            padding: '3px !important',
          },
        },
      },
    },
  }
}
