/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */

// ## --- ADMIN ROLES & PERMISSIONS SLICE --- ##

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { handleMessages } from '../_helpers/utilities'
import { IFilters } from '../_models/commonInterface'
import { IRoleData, IRoleState } from '../_models/rolesInterface'
import roleService from '../_services/roleService'
import { RootState } from '../_store/store'
import { startLoader, stopLoader } from './loaderSlice'
import { errorToast, successToast } from './toastSlice'

const initialState: IRoleState = {
  rolesList: {
    current_page: 1,
    data: [],
    total: 20,
    per_page: 10,
    last_page: 0,
  },
  loadingRoleList: false,
  roleDetailsById: {
    id: 0,
    role_name: '',
    description: '',
    actions: [],
  },
  loadingPermissionList: false,
  isDeleteRoleModalOpen: false,
  permissionList: [],

  mode: 'create',
  isDrawerOpen: false,
  filters: {
    search: '',
    sort_by: 'role_name',
    sort_order: 'asc',
    page: 1,
    per_page: 1000,
  },
  userCountLinkedToRole: 0,
  allRoles: {
    current_page: 1,
    data: [],
    total: 20,
    per_page: 10,
    last_page: 0,
  },
}

// GET ALL ROLES

export const listRoles = createAsyncThunk(
  '/listRoles',
  async (filters: IFilters, { dispatch, rejectWithValue }) => {
    try {
      const response = await roleService.getRoleList(filters)
      return response
    } catch (err: any) {
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)

// GET ALL PERMISSIONS LIST

export const listPermissions = createAsyncThunk(
  '/listPermissions',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await roleService.getPermissionList()
      return response
    } catch (err: any) {
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)

// GET ROLE DETAILS BY ID

export const getRoleDetailsById = createAsyncThunk(
  '/getRoleDetailsById',
  async (roleId: number, { dispatch, rejectWithValue }) => {
    dispatch(startLoader())
    try {
      const permissionListResponse = await dispatch(listPermissions())
      if (permissionListResponse) {
        const response = await roleService.getRoleDetailsById(roleId)
        // dispatch(openRoleDrawer('edit'))
        dispatch(stopLoader())
        return response
      }
    } catch (err: any) {
      dispatch(stopLoader())
      dispatch(closeRoleDrawer())
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)

// CREATE NEW ROLE

export const createNewRole = createAsyncThunk(
  '/createNewRole',
  async (newRoleData: IRoleData, { dispatch, rejectWithValue, getState }) => {
    const { roles } = getState() as { roles: IRoleState }
    dispatch(startLoader())
    try {
      const response = await roleService.createRole(newRoleData)
      dispatch(stopLoader())
      dispatch(closeRoleDrawer())
      dispatch(successToast(handleMessages(response.message)))
      dispatch(changeRoleFilters({ ...roles.filters, ...initialState.filters }))
      return response
    } catch (err: any) {
      dispatch(stopLoader())
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)

// UPDATE ROLE

export const updateRole = createAsyncThunk(
  '/updateRole',
  async (
    newRoleData: { roleId: number; roleData: IRoleData },
    { dispatch, rejectWithValue, getState },
  ) => {
    const { roles } = getState() as { roles: IRoleState }
    const { roleId, roleData } = newRoleData
    dispatch(startLoader())
    try {
      const response = await roleService.updateRole(roleId, roleData)
      dispatch(stopLoader())
      dispatch(closeRoleDrawer())
      dispatch(successToast(handleMessages(response.message)))
      dispatch(changeRoleFilters({ ...roles.filters, ...initialState.filters }))
      return response
    } catch (err: any) {
      dispatch(stopLoader())
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)

// DELETE ROLE

export const deleteRole = createAsyncThunk(
  'deleteRole',
  async (roleId: number, { dispatch, rejectWithValue, getState }) => {
    const { roles } = getState() as { roles: IRoleState }
    dispatch(startLoader())
    try {
      const response = await roleService.deleteRole(roleId)
      dispatch(stopLoader())
      dispatch(closeDeleteRoleModal())

      dispatch(changeRoleFilters({ ...roles.filters, ...initialState.filters }))
      dispatch(successToast(handleMessages(response.message)))
    } catch (err: any) {
      dispatch(stopLoader())
      dispatch(closeDeleteRoleModal())
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)

// FETCH USER COUNT LINKED TO THE ROLE

export const fetchUserCount = createAsyncThunk(
  'fetchUserCount',
  async (roleId: number, { dispatch, rejectWithValue }) => {
    dispatch(startLoader())
    try {
      const response = await roleService.fetchUserCount(roleId)
      dispatch(stopLoader())
      dispatch(openDeleteRoleModal())
      return response
    } catch (err: any) {
      dispatch(stopLoader())
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)
// Fetch the list of all roles using a greater per_page filter
export const listAllRoles = createAsyncThunk(
  '/listAllRoles',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await roleService.getRoleList({
        per_page: 1000,
        sort_by: 'role_name',
        sort_order: 'asc',
      })
      return response
    } catch (err: any) {
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)

export const rolesSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    openRoleDrawer: (state, action) => {
      state.isDrawerOpen = true
      state.mode = action.payload
    },
    closeRoleDrawer: (state) => {
      state.isDrawerOpen = false
    },
    changeRoleFilters: (state, action) => {
      state.filters = action.payload
    },
    resetRoleFilters: (state) => {
      state.filters = initialState.filters
    },
    openDeleteRoleModal: (state) => {
      state.isDeleteRoleModalOpen = true
    },
    closeDeleteRoleModal: (state) => {
      state.isDeleteRoleModalOpen = false
    },
  },
  extraReducers: (builder) => {
    builder.addCase(listRoles.pending, (state) => {
      state.loadingRoleList = true
    })
    builder.addCase(listRoles.fulfilled, (state, action) => {
      state.loadingRoleList = false
      state.rolesList = action.payload.data
    })
    builder.addCase(listRoles.rejected, (state) => {
      state.loadingRoleList = false
    })
    builder.addCase(getRoleDetailsById.fulfilled, (state, action) => {
      state.roleDetailsById = action.payload.data
    })
    builder.addCase(getRoleDetailsById.rejected, (state) => {
      state.isDrawerOpen = false
    })
    builder.addCase(listPermissions.pending, (state) => {
      state.loadingPermissionList = true
    })
    builder.addCase(listPermissions.fulfilled, (state, action) => {
      state.permissionList = action.payload.data
      state.loadingPermissionList = false
    })
    builder.addCase(listPermissions.rejected, (state) => {
      state.loadingPermissionList = false
    })
    builder.addCase(fetchUserCount.fulfilled, (state, action) => {
      state.userCountLinkedToRole = action.payload.data.user_count
    })
    builder.addCase(listAllRoles.fulfilled, (state, action) => {
      state.allRoles = action.payload.data
    })
    builder.addCase(listAllRoles.rejected, (state) => {
      state.allRoles = initialState.allRoles
    })
  },
})

export const {
  openRoleDrawer,
  closeRoleDrawer,
  changeRoleFilters,
  resetRoleFilters,
  openDeleteRoleModal,
  closeDeleteRoleModal,
} = rolesSlice.actions
export const rolesState = (state: RootState) => state.roles
const rolesReducer = rolesSlice.reducer
export default rolesReducer
