import { IIcon } from './iconConstants'
const TicketIcon = (props: IIcon) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox='0 0 20 18'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g fill='none' fillRule='evenodd'>
        <path d='M-1-2h22v22H-1z' />
        <path
          d='M.833 1.667c0-.507.41-.917.917-.917h16.5c.506 0 .917.41.917.917v5.041a2.292 2.292 0 1 0 0 4.584v5.041c0 .507-.41.917-.917.917H1.75a.917.917 0 0 1-.917-.917V1.667zm5.578 13.75a1.375 1.375 0 0 1 2.594 0h8.328v-2.721a4.125 4.125 0 0 1 0-7.392v-2.72H9.005a1.375 1.375 0 0 1-2.594 0H2.667v12.833H6.41zm1.297-7.334a1.375 1.375 0 1 1 0-2.75 1.375 1.375 0 0 1 0 2.75zm0 4.584a1.375 1.375 0 1 1 0-2.75 1.375 1.375 0 0 1 0 2.75z'
          fill={props.fill}
          fillRule='nonzero'
        />
      </g>
    </svg>
  )
}
export default TicketIcon
