/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { History } from '../_helpers/history'
import { getDomainNames, isBusinessURL } from '../_helpers/routes'
import { handleMessages } from '../_helpers/utilities'
import { ILoginFormData } from '../_models/authenticationInterface'
import authenticationService from '../_services/authenticationService'
import { RESET_STATE } from '../_store/actions'
import { RootState } from '../_store/store'
import { clearTokenFromCookie, setTokenInCookie } from './../_helpers/cookie'
import { ISignUpFormData } from './../_models/authenticationInterface'
import { startLoader, stopLoader } from './loaderSlice'
import { errorToast, successToast } from './toastSlice'
import { resetSelectedLanguage } from './translationSlice'
import { updateUserDetails } from './userSlice'

// interface IFormState {
//   error?: string | Record<string, never>
//   signUpFormErrors?: string | Record<string, never>
// }
const initialState: any = {
  error: '',
  signUpFormErrors: '',
}

// SIGN IN

export const signIn = createAsyncThunk(
  '/signIn',
  async (userCredentials: ILoginFormData, { dispatch, rejectWithValue }) => {
    dispatch(startLoader())
    try {
      const response = await authenticationService.signIn(userCredentials)
      const domainNames = getDomainNames()
      const searchParams = new URLSearchParams(window.location.search)
      const isRescheduleLink = searchParams.get('reschedule') ? true : false
      const isConfirmRescheduleLink = searchParams.get('confirm-reschedule') ? true : false
      // update user details
      if (response.data.type) {
        dispatch(updateUserDetails(response.data))
      }

      if (response.data.type === 'customer') {
        setTokenInCookie(response.data.token)

        if (isBusinessURL() && !isRescheduleLink && !isConfirmRescheduleLink) {
          if (domainNames.length > 3) {
            window.location.replace(
              `${window.location.protocol}//${domainNames[1]}.slashq.${domainNames[3]}/customer/business/${domainNames[0]}`,
            )
          } else {
            window.location.replace(
              `${window.location.protocol}//slashq.io/customer/business/${domainNames[0]}`,
            )
          }
        } else if (isBusinessURL() && (isRescheduleLink || isConfirmRescheduleLink)) {
          if (domainNames.length > 3) {
            window.location.replace(
              `${window.location.protocol}//${domainNames[1]}.slashq.${domainNames[3]}/customer/business/${domainNames[0]}${window.location.search}`,
            )
          } else {
            window.location.replace(
              `${window.location.protocol}//slashq.io/customer/business/${domainNames[0]}${window.location.search}`,
            )
          }
        } else {
          History.navigate('/customer/business')
        }
      } else {
        setTokenInCookie(response.data.token)

        if (isBusinessURL() && !isRescheduleLink && !isConfirmRescheduleLink) {
          if (domainNames.length > 3) {
            window.location.replace(
              `${window.location.protocol}//${domainNames[1]}.slashq.${domainNames[3]}/admin/business/${domainNames[0]}`,
            )
          } else {
            window.location.replace(
              `${window.location.protocol}//slashq.io/admin/business/${domainNames[0]}`,
            )
          }
        } else if (isBusinessURL() && (isRescheduleLink || isConfirmRescheduleLink)) {
          if (domainNames.length > 3) {
            window.location.replace(
              `${window.location.protocol}//${domainNames[1]}.slashq.${domainNames[3]}/admin/business/${domainNames[0]}${window.location.search}`,
            )
          } else {
            window.location.replace(
              `${window.location.protocol}//slashq.io/admin/business/${domainNames[0]}${window.location.search}`,
            )
          }
        } else {
          History.navigate('/admin/dashboard')
        }
      }

      return response
    } catch (err: unknown) {
      dispatch(stopLoader())
      return rejectWithValue(err)
    }
  },
)

// SIGN UP

export const signUp = createAsyncThunk(
  '/signUp',
  async (userCredentials: ISignUpFormData, { dispatch, rejectWithValue }) => {
    dispatch(startLoader())
    try {
      const response = await authenticationService.signUp(userCredentials)
      //  setItem('token', response.data.token)
      setTokenInCookie(response.data.token)
      dispatch(stopLoader())

      // handle business url flow
      if (isBusinessURL()) {
        const domainNames = getDomainNames()
        if (domainNames.length > 3) {
          window.location.replace(
            `${window.location.protocol}//${domainNames[1]}.slashq.${domainNames[3]}/email-verify-user?email=${userCredentials.email}`,
          )
        } else {
          window.location.replace(
            `${window.location.protocol}//slashq.io/email-verify-user?email=${userCredentials.email}`,
          )
        }
      } else if (response.data.registered_by_business_invitation) {
        History.navigate('/invite-success', {
          state: {
            email: response.data.email,
            isInvited: response.data.registered_by_business_invitation,
          },
        })
      } else {
        History.navigate('/email-verify-user', { state: { email: userCredentials.email } })
      }

      return response
    } catch (err: any) {
      dispatch(errorToast(handleMessages(err.message)))
      dispatch(stopLoader())
      return rejectWithValue(err)
    }
  },
)

// invite user after clicking Accept button from mail
export const InviteUserAcceptStatus = createAsyncThunk(
  '/InviteUserAcceptStatus',
  async (token: string, { dispatch, rejectWithValue }) => {
    dispatch(startLoader())
    try {
      const response = await authenticationService.InviteUserAcceptStatus(token)
      if (
        response?.data?.is_user_existing === 0 &&
        response?.data?.data_available === 0 &&
        response?.status === 'success'
      ) {
        History.navigate('/user-registration', {
          state: {
            needUpdation: false,
            roleId: response.data.role_id,
            userName: response.data.name,
            userEmail: response.data.email,
            userRoleId: response.data.user_role_invitation_id,
          },
        })
      } else if (
        response?.data?.is_user_existing &&
        response?.data?.data_available &&
        response?.status === 'success'
      ) {
        clearTokenFromCookie()
        setTokenInCookie(response.data.token)
        History.navigate('/invite-success', {
          state: { email: response.data.email, userExist: true },
        })
      } else if (
        response?.data?.is_user_existing &&
        response?.data?.data_available === 0 &&
        response?.status === 'success'
      ) {
        History.navigate('/user-registration', {
          state: {
            needUpdation: true,
            roleId: response.data.role_id,
            userName: response.data.name,
            userEmail: response.data.email,
            userRoleId: response.data.user_role_invitation_id,
            phoneNumber: response.data.phone,
            countryCode: response.data.country_code,
            token: token,
            iso_code: response.data.iso_code,
          },
        })
      } else if (response?.already_accepted) {
        clearTokenFromCookie()
        setTokenInCookie(response.token)
        History.navigate('/invite-already-accepted')
      }
      dispatch(stopLoader())
      return response
    } catch (err: any) {
      History.navigate('/link-expired')

      dispatch(stopLoader())
      return rejectWithValue(err)
    }
  },
)

// Verify user from mail
export const userVerificationStatus = createAsyncThunk(
  '/userVerificationStatus',
  async (token: string, { dispatch, rejectWithValue }) => {
    dispatch(startLoader())
    try {
      const response = await authenticationService.userVerificationStatus({ expires: token })
      if (!response?.data?.data_available && !response?.token_status) {
        History.navigate('/user-registration', {
          state: {
            token: token,
            needUpdation: true,
            roleId: response.data.role_id,
            userName: response.data.name,
            userEmail: response.data.email,
            phoneNumber: response.data.phone,
            countryCode: response.data.country_code,
            iso_code: response.data.iso_code,
          },
        })
      } else if (response?.data?.data_available && !response?.data?.already_verified) {
        History.navigate('/email-verification-success')
      } else if (response?.data?.already_verified && response?.data?.already_verified) {
        History.navigate('/email-already-verified')
      }
      dispatch(stopLoader())
      return response
    } catch (err: any) {
      History.navigate('/link-expired')

      dispatch(stopLoader())
      return rejectWithValue(err)
    }
  },
)
// Update user details from verification mail if password/phone not available
export const setUserData = createAsyncThunk(
  '/setUserData',
  async (
    { token, userCredentials }: { token: string | number; userCredentials: ISignUpFormData },
    { dispatch, rejectWithValue },
  ) => {
    dispatch(startLoader())
    try {
      const response = await authenticationService.setUserData(userCredentials, token)
      clearTokenFromCookie()
      setTokenInCookie(response.data.token)
      if (response.data.invitation_accepted) {
        History.navigate('/invite-success', {
          state: { email: response.data.email, isInvited: response.data.invitation_accepted },
        })
      } else {
        History.navigate('/email-verification-success')
      }
      dispatch(stopLoader())

      return response
    } catch (err: any) {
      dispatch(errorToast(handleMessages(err.message)))
      dispatch(stopLoader())
      return rejectWithValue(err)
    }
  },
)

// LOGOUT

export const logout = createAsyncThunk('/logout', async (_, { dispatch, rejectWithValue }) => {
  dispatch(startLoader())
  try {
    const response = await authenticationService.logout()
    dispatch(stopLoader())
    clearTokenFromCookie()
    // Dispatch the reset state action
    dispatch({ type: RESET_STATE })
    dispatch(resetSelectedLanguage())
    History.navigate('/login')
    return response
  } catch (err: any) {
    dispatch(stopLoader())
    dispatch(errorToast(handleMessages(err.message)))
    return rejectWithValue(err)
  }
})

// RESEND VERIFICATION EMAIL

export const resendVerificationMail = createAsyncThunk(
  '/resendVerificationMail',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(startLoader())
    try {
      const response = await authenticationService.resendVerificationMail()
      dispatch(successToast(handleMessages(response.message)))
      dispatch(stopLoader())
      return response
    } catch (err: any) {
      dispatch(stopLoader())
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)

export const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(signIn.rejected, (state, action) => {
      state.error = action.payload
    })
    builder.addCase(signUp.rejected, (state, action) => {
      state.signUpFormErrors = action.payload
    })
  },
})
export const authenticationState = (state: RootState) => state.authentication
const authenticationReducer = authenticationSlice.reducer
export default authenticationReducer
