export default function Select(theme: any) {
  return {
    MuiSelect: {
      styleOverrides: {
        select: {
          paddingBottom: '13px',
          paddingTop: '12px !important',
        },
      },
    },
  }
}
