import { IIcon } from './iconConstants'

const ScannerIcon = (props: IIcon) => {
  const { height, width, stoke } = props
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 30 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2.5 11.25V8.125A5.617 5.617 0 0 1 8.125 2.5h3.125M18.75 2.5h3.125A5.617 5.617 0 0 1 27.5 8.125v3.125M27.5 20v1.875a5.617 5.617 0 0 1-5.625 5.625H20M11.25 27.5H8.125A5.617 5.617 0 0 1 2.5 21.875V18.75M13.125 8.75v2.5c0 1.25-.625 1.875-1.875 1.875h-2.5c-1.25 0-1.875-.625-1.875-1.875v-2.5c0-1.25.625-1.875 1.875-1.875h2.5c1.25 0 1.875.625 1.875 1.875zM23.125 8.75v2.5c0 1.25-.625 1.875-1.875 1.875h-2.5c-1.25 0-1.875-.625-1.875-1.875v-2.5c0-1.25.625-1.875 1.875-1.875h2.5c1.25 0 1.875.625 1.875 1.875zM13.125 18.75v2.5c0 1.25-.625 1.875-1.875 1.875h-2.5c-1.25 0-1.875-.625-1.875-1.875v-2.5c0-1.25.625-1.875 1.875-1.875h2.5c1.25 0 1.875.625 1.875 1.875zM23.125 18.75v2.5c0 1.25-.625 1.875-1.875 1.875h-2.5c-1.25 0-1.875-.625-1.875-1.875v-2.5c0-1.25.625-1.875 1.875-1.875h2.5c1.25 0 1.875.625 1.875 1.875z'
        stroke={stoke}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
export default ScannerIcon
