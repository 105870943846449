/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import { Link, Typography } from '@mui/material'
import { FC, ReactNode, useState } from 'react'
import { useAppSelector } from '../../_helpers/hooks'

interface IShowMoreOptions {
  children?: ReactNode
  colorOfItem: string
  colorOfItemXs?: string
  colorOfOption: string
  fontSizeOfItem?: string
  fontSizeOfOption?: string
  fontWeightOfItem?: string
  fontWeightOfOption?: string
  itemsToShowInitially: number
  isItemLink?: boolean
  data: string[]
  mtRemove?: boolean
  needBracket?: boolean
  onItemClick?: (item: string) => void
}

const ShowMoreContainer: FC<IShowMoreOptions> = ({
  data,
  colorOfItem,
  colorOfItemXs,
  colorOfOption,
  fontSizeOfItem,
  fontWeightOfItem,
  fontWeightOfOption,
  fontSizeOfOption,
  itemsToShowInitially,
  isItemLink,
  needBracket,
  mtRemove,
  onItemClick,
}) => {
  //   const theme = useTheme()
  const [showAllItems, setShowAllItems] = useState(false)
  const translatedInput = useAppSelector((state) => state?.translation.translatedData)
  const handleShowMore = () => {
    setShowAllItems(true)
  }
  const handleShowLess = () => {
    setShowAllItems(false)
  }
  return (
    <>
      <Typography
        color={{ xs: colorOfItemXs ? colorOfItemXs : colorOfItem, sm: colorOfItem }}
        variant='subtitle1'
        fontSize={fontSizeOfItem}
        mt={mtRemove ? 0 : 0.6}
        fontWeight={fontWeightOfItem}
      >
        {needBracket && '( '}
        {isItemLink ? (
          // Render the list of clickable items when isItemLink is true
          data.slice(0, showAllItems ? data.length : itemsToShowInitially).map((item, index) => (
            <span key={index} color={colorOfItem} style={{ wordBreak: 'break-word' }}>
              <Link
                variant='subtitle1'
                fontSize={fontSizeOfItem}
                color={colorOfItem}
                sx={{ ml: 0.5, display: 'inline' }}
                fontWeight={fontWeightOfItem}
                onClick={() => onItemClick && onItemClick(item)}
              >
                {item}
              </Link>
              {index < data.length - 1 && ', '} {/* Add a comma if it's not the last item */}
            </span>
          ))
        ) : (
          // Render items as a single string when isItemLink is false
          <span color={colorOfItem} style={{ wordBreak: 'break-word' }}>
            {showAllItems ? data.join(', ') : data.slice(0, itemsToShowInitially).join(', ')}
          </span>
        )}
        {!showAllItems && data.length > itemsToShowInitially && (
          <span>
            <Link
              variant='subtitle1'
              color={colorOfOption}
              fontSize={fontSizeOfOption}
              sx={{ ml: 0.5, display: 'inline-block' }}
              fontWeight={fontWeightOfOption}
              onClick={handleShowMore}
            >
              +{data.length - itemsToShowInitially} {translatedInput?.permissionJS?.moreText}
            </Link>
          </span>
        )}
        {showAllItems && data.length !== itemsToShowInitially && (
          <span>
            <Link
              variant='subtitle1'
              fontSize={fontSizeOfOption}
              color={colorOfOption}
              sx={{ ml: 0.5, py: 1, display: 'inline-block' }}
              fontWeight={fontWeightOfOption}
              onClick={handleShowLess}
            >
              {translatedInput?.common?.showLessText}
            </Link>
          </span>
        )}
        {needBracket && ' )'}
      </Typography>
    </>
  )
}

export default ShowMoreContainer
