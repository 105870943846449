import MenuIcon from '@mui/icons-material/Menu'
import { Stack, useTheme } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Tab, { TabProps } from '@mui/material/Tab'
import Tabs, { TabsProps } from '@mui/material/Tabs'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../_helpers/hooks'
import { ITab } from '../../../_models/tabsInterface'
import { IUserData } from '../../../_models/userInterface'
import ProfileDrawer from '../../../features/profileDrawer'
import ScannerPopup from '../../../features/scanner'
import { useUserPermissionAuthorization } from '../../../hooks'
import Logo from '../../../images/logo.svg'
import { closeScannerModal, dashboardState } from '../../../slices/dashboardSlice'
import { createNewBookingDrawer, getLocationsNewBooking } from '../../../slices/newBookingSlice'

import { PERMISSIONS, PLAN } from '../../../_constants'
import ProBadge from '../../../components/common/proBadge'
import { usePricingLimits } from '../../../hooks/pricingLimits'
import {
  pricingData,
  setPlanLimitExceededAlert,
  setPricingAlertModal,
} from '../../../slices/pricingSlice'
import { getAllScannerFacilities, getPusherConfigData } from '../../../slices/scannerSlice'
import { headerTabs } from '../items'

interface IHeader {
  children: React.ReactNode
  handleDrawerToggle: () => void
  userData: IUserData | Record<string, never>
  handleDrawerOnPopoverClick: () => void
}
const CustomTabs = styled(Tabs)<TabsProps>(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  paddingLeft: 0,
  paddingRight: 0,
  '& .indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    height: '.1875rem',
    '& > span': {
      width: '80%',
      backgroundColor: theme.palette.primary.main,
      height: '.1875rem',
    },
  },
  '& .flexContainer': {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'fit-content',
  },
}))

const CustomTab = styled(Tab)<TabProps>(({ theme }) => ({
  borderRadius: '0.625rem',
  minHeight: 'initial',
  '&.Mui-selected': {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontWeight: 500,
  },
}))

const popoverPlacement = 'right'

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const AdminHeader: React.FC<IHeader> = ({
  handleDrawerToggle,
  children,
  userData,
  handleDrawerOnPopoverClick,
}) => {
  const theme = useTheme()
  const [isPermitted] = useUserPermissionAuthorization()
  const [value, setValue] = React.useState(0)
  const dispatch = useAppDispatch()
  const dashboardData = useAppSelector(dashboardState)
  const { scannerModal } = dashboardData
  const translatedInput = useAppSelector((state) => state?.translation.translatedData)
  const { bookingsLimitCheck } = usePricingLimits()
  const { pricingLimits } = useAppSelector(pricingData)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()
  const { subscriptionPlanDetails } = useAppSelector(pricingData)
  const handleClick = (id: number) => {
    document.body.classList.remove('no-scroll')
    const handleBasicSubscription = () => {
      dispatch(
        setPricingAlertModal({
          status: true,
          content: translatedInput?.subscription?.pleaseUpgradeSubscriptionForFeature,
        }),
      )
    }
    switch (id) {
      case 1:
        navigate('/admin/dashboard')
        setOpen(false)
        setValue(0)
        break
      case 2:
        if (pricingLimits?.plan_limits?.booking_management?.has_permission === false) {
          handleBasicSubscription()
        } else {
          setValue(1)
          navigate('/admin/booking-management')
        }
        setOpen(false)
        break
      case 3:
        if (pricingLimits?.plan_limits?.scanner?.has_permission === false) {
          handleBasicSubscription()
        } else {
          setValue(2)
          dispatch(getPusherConfigData())
          dispatch(getAllScannerFacilities())
        }
        break
      default:
        setOpen(false)
        break
    }
  }
  const goToDashboard = () => {
    document.body.classList.remove('no-scroll')
    setOpen(false)
    setValue(0)
    navigate('/admin/dashboard')
  }
  const openNewBookingDrawer = () => {
    if (bookingsLimitCheck()) {
      // if the user is on a pro plan, show limit reached alert
      if (
        subscriptionPlanDetails?.subscription?.slug === PLAN.MONTHLY_PRO ||
        subscriptionPlanDetails?.subscription?.slug === PLAN.YEARLY_PRO
      ) {
        dispatch(
          setPlanLimitExceededAlert({
            status: true,
            content: translatedInput?.pricingPage?.bookingLimitReachedDescription,
          }),
        )
      }
      // else show upgrade subscription alert
      else {
        dispatch(
          setPricingAlertModal({
            status: true,
            content: translatedInput?.subscription?.pleaseUpgradeSubscription,
          }),
        )
      }
    } else {
      setOpen(false)
      dispatch(createNewBookingDrawer())
      dispatch(getLocationsNewBooking())
    }
  }

  const checkPath = () => {
    switch (window.location.pathname) {
      case '/admin/dashboard':
        setValue(0)
        break
      case '/admin/booking-management':
        setValue(1)
        break
      default:
        setValue(-1)
        break
    }
  }
  const closeModal = () => {
    dispatch(closeScannerModal())
    checkPath()
  }
  useEffect(() => {
    checkPath()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname])

  const filteredHeaderTabs = headerTabs.filter((tabItem) => {
    // If the menu item has permission requirement and permission is not satisfied, filter it out
    return !tabItem.permissions || isPermitted(tabItem.permissions) || userData?.is_business_admin
  })

  // show premium tag if the the permission is false. id = 2 and 3 is for booking management and scanner
  const showProBadge = (item: ITab) => {
    if (pricingLimits?.plan_limits) {
      if (item?.id === 2 && !pricingLimits?.plan_limits?.booking_management?.has_permission)
        return true
      else if (item?.id === 3 && !pricingLimits?.plan_limits?.scanner?.has_permission) {
        return true
      }
    }
    return false
  }

  return (
    <>
      <Box
        display='flex'
        sx={{
          minHeight: '5.438rem',
        }}
      >
        <AppBar component='nav' sx={{ py: 0, boxShadow: theme.customShadows.z1 }}>
          <Toolbar
            sx={{
              marginRight: 'calc(-1 * (100vw - 100%))',
              '&.MuiToolbar-root': {
                minHeight: '5.438rem',
              },
            }}
          >
            <Box
              component='div'
              sx={{
                flexGrow: 1,
                display: { md: 'block' },
                textAlign: { xs: 'left', md: 'left', lg: 'left' },
                cursor: 'pointer',
              }}
              ml={{ xs: 1, md: 4 }}
            >
              <img src={Logo} alt='' onClick={goToDashboard} />
            </Box>
            <Box
              display='flex'
              alignItems='end'
              justifyContent='end'
              sx={{
                height: '100%',
                display: { xs: 'none', md: 'block' },
              }}
            >
              <Box display='flex' sx={{ px: { sm: 0, md: 0, lg: 3 } }}>
                <CustomTabs
                  value={value}
                  aria-label='profile settings tabs'
                  indicatorColor='primary'
                  classes={{ indicator: 'indicator' }}
                  TabIndicatorProps={{ children: <span /> }}
                  sx={{ px: { sm: 0, md: 0, lg: 3 }, pt: 1, pb: 0 }}
                >
                  {filteredHeaderTabs.map((item: ITab, index: number) => {
                    return (
                      <CustomTab
                        sx={{
                          backgroundColor: 'common.white',
                          py: 2,
                          px: { sm: 1, md: 1, lg: 2 },
                          my: 1,
                          mx: { sm: 0, md: 2, lg: 3 },
                        }}
                        key={index}
                        label={
                          <Box display='flex' mt={1}>
                            <Typography variant='subtitle1'>
                              {translatedInput?.appHeader[item?.label]}
                            </Typography>
                            {showProBadge(item) && (
                              <ProBadge positionStyles={{ transform: 'translate(-30%, -80%)' }} />
                            )}
                          </Box>
                        }
                        onClick={() => handleClick(item.id)}
                        {...a11yProps(index)}
                      />
                    )
                  })}
                </CustomTabs>
                {isPermitted([PERMISSIONS.SCANNER_VIEW]) && (
                  <ScannerPopup isOpen={scannerModal} isClosed={closeModal} />
                )}

                <Stack direction='row' spacing={2} alignItems={'center'} display='flex' py={2}>
                  <Button
                    variant='contained'
                    size='small'
                    sx={{ px: 3, height: '2.45rem', mt: 0.5 }}
                    onClick={openNewBookingDrawer}
                  >
                    <Typography fontWeight={600} fontSize='0.9rem'>
                      {translatedInput?.bookings?.newBooking}
                    </Typography>
                  </Button>

                  <Box>
                    <ProfileDrawer
                      handleDrawerOnPopoverClick={handleDrawerOnPopoverClick}
                      handleDrawerToggle={handleDrawerToggle}
                      userData={userData}
                      popoverPlacement={popoverPlacement}
                    />
                  </Box>
                </Stack>
              </Box>
            </Box>
            <IconButton
              color='primary'
              aria-label='open drawer'
              edge='start'
              size='large'
              onClick={handleDrawerToggle}
              sx={{ display: { md: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        <Box mt={9.2}>{children}</Box>
      </Box>
    </>
  )
}

export default AdminHeader
