import { lazy } from 'react'

import FloorPlanMapView from '../pages/common/webViewPages/floorPlanMapView'
import FloorPlanBookingView from '../pages/common/webViewPages/floorplanBookingView'
import LazyLoad from './utils/LazyLoad'

const ForgotPasswordPage = LazyLoad(
  lazy(() => import('../pages/common/forgotPasswordPage/ForgotPassword')),
)
const EmailVerificationExpired = LazyLoad(
  lazy(() => import('../pages/common/emailVerification/EmailExpired')),
)
const ConfirmReschedule = LazyLoad(
  lazy(() => import('../pages/common/rescheduleConfirmPage/rescheduleConfirmPage')),
)
const EmailVerificationSuccess = LazyLoad(
  lazy(() => import('../pages/common/emailVerification/VerificationSuccess')),
)
const VerifyEmail = LazyLoad(
  lazy(() => import('../pages/common/emailVerification/EmailVerification')),
)
const ResetPassword = LazyLoad(
  lazy(() => import('../pages/common/forgotPasswordPage/ResetPassword')),
)
const PasswordRecovery = LazyLoad(
  lazy(() => import('../pages/common/forgotPasswordPage/PasswordRecovery')),
)
const PasswordUpdated = LazyLoad(
  lazy(() => import('../pages/common/forgotPasswordPage/PasswordUpdated')),
)
const ConfirmEmail = LazyLoad(lazy(() => import('../pages/common/emailVerification/ConfirmEmail')))
const LinkExpired = LazyLoad(lazy(() => import('../pages/common/linkExpired')))
const Payment = LazyLoad(lazy(() => import('../pages/admin/paymentPage')))
const ResetPasswordMail = LazyLoad(
  lazy(() => import('../pages/common/forgotPasswordPage/ResetPasswordMail')),
)
const Preview = LazyLoad(lazy(() => import('../features/tokenBoard/preview')))
const TokenBoardActivation = LazyLoad(lazy(() => import('../pages/common/tokenBoardActivation')))
const CancelBookingPage = LazyLoad(lazy(() => import('../pages/common/cancelBookingPage')))
const BookingCanceledPage = LazyLoad(lazy(() => import('../pages/common/bookingCanceledPage')))

const UserRegistrationForm = LazyLoad(
  lazy(() => import('../pages/common/userRegistration/userRegistrationForm')),
)
const AcceptUserInvitation = LazyLoad(
  lazy(() => import('../pages/common/inviteUserPage/AcceptUserInvitation')),
)
const InviteAlreadyAccepted = LazyLoad(
  lazy(() => import('../pages/common/inviteUserPage/inviteAlreadyAccepted')),
)
const InviteSuccess = LazyLoad(lazy(() => import('../pages/common/inviteUserPage/inviteSuccess')))
const EmailAlreadyVerified = LazyLoad(
  lazy(() => import('../pages/common/emailVerification/emailAlreadyVerified')),
)
const ConfirmLoggedOutPage = LazyLoad(
  lazy(() => import('../pages/common/logoutConfirmPage/logoutConfirmPage')),
)
const UserVerification = LazyLoad(
  lazy(() => import('../pages/common/emailVerification/userVerification')),
)
const PricingCheck = LazyLoad(lazy(() => import('../pages/admin/publicPages/pricingPage')))
const PricingPagePublic = LazyLoad(
  lazy(() => import('../pages/admin/publicPages/pricingPage/pricingPage')),
)
const ResetPasswordCheck = LazyLoad(
  lazy(() => import('../pages/common/forgotPasswordPage/ResetPasswordCheck')),
)

// ==============================|| PUBLIC ROUTING PAGES WITHOUT HEADER ||============================== //

const MainRoutes = {
  children: [
    {
      path: 'payment',
      element: <Payment />,
    },
    {
      path: 'forget-password',
      element: <ForgotPasswordPage />,
    },
    {
      path: 'reset-password-page',
      element: <ResetPassword />,
    },
    {
      path: 'password-recovery',
      element: <PasswordRecovery />,
    },
    {
      path: 'password-updated',
      element: <PasswordUpdated />,
    },
    {
      path: 'email-verify-user',
      element: <VerifyEmail />,
    },
    {
      path: 'email-verify',
      element: <UserVerification />,
    },
    {
      path: 'email-verification-expired',
      element: <EmailVerificationExpired />,
    },
    {
      path: 'link-expired',
      element: <LinkExpired />,
    },
    {
      path: 'email-verification-success',
      element: <EmailVerificationSuccess />,
    },
    {
      path: 'email-already-verified',
      element: <EmailAlreadyVerified />,
    },
    {
      path: 'email-confirm',
      element: <ConfirmEmail />,
    },
    {
      path: 'confirm-reschedule',
      element: <ConfirmReschedule />,
    },
    {
      path: 'reset-password-resend',
      element: <ResetPasswordMail />,
    },
    {
      path: 'digital-board/:id',
      element: <Preview />,
    },
    {
      path: 'board',
      element: <TokenBoardActivation />,
    },
    {
      path: 'floor-plan-booking-page',
      element: <FloorPlanBookingView />,
    },
    {
      path: 'floor-plan-mapview-page',
      element: <FloorPlanMapView />,
    },
    {
      path: 'mail/cancel-booking/:token',
      element: <CancelBookingPage />,
    },
    {
      path: 'mail/booking-canceled',
      element: <BookingCanceledPage />,
    },
    // Register invited user from mail
    {
      path: 'user-registration',
      element: <UserRegistrationForm />,
    },

    // To check whether needs registration or not,expired,already invited  the user
    {
      path: 'accept-invite',
      element: <AcceptUserInvitation />,
    },

    // Already accepted the invite
    {
      path: 'invite-already-accepted',
      element: <InviteAlreadyAccepted />,
    },

    // Accepted the invite if user existing/when registered successfully a new user
    {
      path: 'invite-success',
      element: <InviteSuccess />,
    },
    // Check user confirmation for logout
    {
      path: 'confirm-logout',
      element: <ConfirmLoggedOutPage />,
    },
    {
      path: 'pricing',
      element: <PricingCheck />,
    },
    {
      path: 'pricing-page',
      element: <PricingPagePublic />,
    },
    {
      path: 'reset-password',
      element: <ResetPasswordCheck />,
    },
  ],
}

export default MainRoutes
