import { lazy } from 'react'
import PublicLayout from '../layout/publicLayout'
import LazyLoad from './utils/LazyLoad'
import CheckEmailLinkValidity from './utils/checkEmailLinkValidity'
import RedirectIfLoggedIn from './utils/redirectIfLoggedIn'
const BusinessDetailsPage = LazyLoad(lazy(() => import('../pages/customer/businessDetailsPage')))

const BusinessURLRoutes = {
  path: '/business',
  element: <PublicLayout />,
  children: [
    {
      path: ':businessName',
      element: (
        <RedirectIfLoggedIn>
          <CheckEmailLinkValidity>
            <BusinessDetailsPage />
          </CheckEmailLinkValidity>
        </RedirectIfLoggedIn>
      ),
    },
  ],
}

export default BusinessURLRoutes
