import { useRoutes } from 'react-router-dom'
import AdminRoutes from './AdminRoutes'
import AuthRoutes from './AuthRoutes'
import BusinessURLRoutes from './BusinessURLRoutes'
import CustomerRoutes from './CustomerRoutes'
import MainRoutes from './MainRoutes'
import PublicRoutes from './PublicRoutes'
import commonPageRoutes from './commonPageRoutes'

// project import

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([
    MainRoutes,
    PublicRoutes,
    AuthRoutes,
    CustomerRoutes,
    AdminRoutes,
    commonPageRoutes,
    BusinessURLRoutes,
  ])
}
