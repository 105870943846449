import { IIcon } from './iconConstants'
const DirectionIcon = ({ width, height, fill }: IIcon) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#1vvuzghrda)'>
        <path
          d='M8 2.343 2.343 8 8 13.657 13.657 8 8 2.343zM8.471.93l6.6 6.6c.26.26.26.682 0 .942l-6.6 6.6a.667.667 0 0 1-.942 0l-6.6-6.6a.667.667 0 0 1 0-.942l6.6-6.6c.26-.26.682-.26.942 0zm.196 5.738V5L11 7.333 8.667 9.667V8h-2v2H5.333V7.333c0-.368.299-.666.667-.666h2.667z'
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id='1vvuzghrda'>
          <path fill='#fff' d='M0 0h16v16H0z' />
        </clipPath>
      </defs>
    </svg>
  )
}
export default DirectionIcon
