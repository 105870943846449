/* eslint-disable camelcase */

import { IAdvancedFacilityFilters, ICompleteBooking } from '../_models/dashboardInterface'
import { IFilterDashboard } from './../_models/commonInterface'
import { get, post } from './apiServices'

const getDashboardStatistics = async () => {
  const response = await get('dashboard/statistics')
  return response
}

const getBookingList = async (filters: IFilterDashboard) => {
  const response = await get('dashboard', filters)
  return response
}
const getBookingListCustomer = async (filters: IFilterDashboard) => {
  const response = await get('customer/dashboard', filters)
  return response
}

const getFacilitiesAdvancedSearch = async (filters: IAdvancedFacilityFilters) => {
  const response = await get('advanced-search-facilities', filters)
  return response
}
const completeBooking = async (data: ICompleteBooking) => {
  const response = await post('/booking/complete', data)
  return response
}

const isActiveToken = async (params: { token_ids: number[] }) => {
  const response = await post('booking/check-active-token', params)
  return response
}
const dashboardService = {
  getDashboardStatistics,
  getBookingList,
  getBookingListCustomer,
  getFacilitiesAdvancedSearch,
  completeBooking,
  isActiveToken,
}
export default dashboardService
