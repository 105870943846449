/* eslint-disable @typescript-eslint/no-explicit-any */
import { handleMessages } from './../_helpers/utilities'
import { IPlanData, IPricingDetails } from './../_models/pricingInterface'
/* eslint-disable camelcase */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getUserTypeFromToken, isAuthenticated } from '../_helpers/cookie'
import { History } from '../_helpers/history'
import { IUserState } from '../_models/userInterface'
import pricingService from '../_services/pricingService'
import { RootState } from '../_store/store'
import { switchBusinessList, switchProfileFromMail } from './businessSlice'
import { startLoader, stopLoader } from './loaderSlice'
import { errorToast, successToast } from './toastSlice'
import { userDetails } from './userSlice'

interface IPayUForm extends Record<string, any> {
  key: string
  txnid: string
  amount: string
  productinfo: string
  firstname: string
  email: string
  phone: string
  surl: string
  furl: string
  hash: string
  udf5: string
}

const initialState: IPricingDetails = {
  isOpen: false,
  closable: false,
  pricingInformation: {},
  pricingHistory: [],
  subscriptionPlanDetails: {},
  paymentDetails: {},
  pricingPageOrigin: '',
  isOpenPricingPublic: false,
  isPricingMailValid: false,
  isProfileNotUpdated: false,
  pricingLimits: {},
  subscriptionName: '',
  subscriptionEndDate: '',
  showPricingAlert: false,
  pricingAlertContent: 'Please upgrade your subscription to access this service.',
  isPaymentFailed: false,
  isPricingFetching: false,
  isProceedProModalOpen: false,
  paymentSuccessData: {},
  showSuccessPage: false,
  newPlanData: {
    message: 'You are about to purchase pro plan. Are you sure you want to proceed? ',
    has_payment: false,
    new_plan_starts_at: '',
    new_plan_ends_at: '',
    adjustment_amount: null,
    amount_payable: null,
    new_plan: {
      id: null,
      price: null,
      name: '',
    },
  },
  showPlanLimitExceededAlert: false,
  planLimitExceededContent: '',
}

// PRICING DETAILS ## data to display in pricing cards

export const getPricingDetails = createAsyncThunk(
  '/pricing-details',
  async (_, { dispatch, rejectWithValue, getState }) => {
    try {
      const { pricing } = getState() as RootState
      // call getPricingDetails API only if pricing page is not open and not fetching
      if (!pricing?.isOpen && !pricing?.isPricingFetching) {
        dispatch(startLoader())
        dispatch(setPricingFetching(true))
        const response = await pricingService.getPricingDetails()
        dispatch(stopLoader())
        return response
      }
    } catch (err: any) {
      dispatch(stopLoader())
      return rejectWithValue(err)
    }
  },
)

// PUBLIC PAGE PRICING DETAILS ## data to display in pricing cards
export const getPublicPricingDetails = createAsyncThunk(
  '/public-pricing-details',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(startLoader())
    try {
      const response = await pricingService.getPricingDetails()
      dispatch(stopLoader())
      return response
    } catch (err: any) {
      dispatch(stopLoader())
      return rejectWithValue(err)
    }
  },
)
// PRICING HISTORY ## data to display in the subscription accordions

export const getPricingHistory = createAsyncThunk(
  '/pricing-history',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(startLoader())
    try {
      const response = await pricingService.getPricingHistory()
      dispatch(stopLoader())
      return response
    } catch (err: any) {
      dispatch(stopLoader())
      return rejectWithValue(err)
    }
  },
)

// GET PURCHASE INFO  ##get purchase info to send payU

export const getPurchaseInfo = createAsyncThunk(
  '/purchase-info',
  async (planId: number, { dispatch, rejectWithValue }) => {
    dispatch(startLoader())
    try {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const redirectUrl = process.env.REACT_APP_PAY_U_PAYMENT_REDIRECT_URL!

      const response = await pricingService.getPurchaseInfo(planId, redirectUrl)
      const payUFormData: IPayUForm = {
        key: response.data.key,
        txnid: response.data.txnid,
        amount: response.data.amount.toString(),
        productinfo: response.data.productinfo.toString(),
        firstname: response.data.firstname,
        email: response.data.email,
        phone: response.data.phone,
        surl: response.data.surl,
        furl: response.data.furl,
        hash: response.data.hash,
        udf5: response.data.udf5,
      }

      if (response && payUFormData) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        submitPayU(process.env.REACT_APP_PAY_U_URL!, payUFormData, 'post')
      }
      dispatch(stopLoader())
      return response
    } catch (err: any) {
      dispatch(stopLoader())
      return rejectWithValue(err)
    }
  },
)

// PAYU SUBMIT  ##submit form data for redirect to payU hosted checkout page

const submitPayU = (path: string, params: IPayUForm, method: string) => {
  method = method || 'post'

  const form = document.createElement('form')
  form.setAttribute('method', method)
  form.setAttribute('action', path)

  for (const key in params) {
    if (params && key) {
      const hiddenField = document.createElement('input')
      hiddenField.setAttribute('type', 'hidden')
      hiddenField.setAttribute('name', key)
      hiddenField.setAttribute('value', params[key])

      form.appendChild(hiddenField)
    }
  }
  document.body.appendChild(form)
  form.submit()
}

// GET PAYMENT DETAILS  ##get payment info of the transaction.

export const getPaymentDetails = createAsyncThunk(
  '/payment/details',
  async (txnId: string, { dispatch, rejectWithValue }) => {
    dispatch(startLoader())
    try {
      const response = await pricingService.getPaymentDetails(txnId)
      dispatch(stopLoader())

      response?.data?.payment_status !== 'failure' && dispatch(switchBusinessList())
      return response
    } catch (err: any) {
      dispatch(stopLoader())
      return rejectWithValue(err)
    }
  },
)

// GET Subscription Plan Details to display in the alert box within profile settings page

export const getSubscriptionPlanDetails = createAsyncThunk(
  '/subscription-plan-details',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(startLoader())
    try {
      const response = await pricingService.getSubscriptionPlanDetails()
      dispatch(stopLoader())
      return response
    } catch (err: any) {
      dispatch(stopLoader())
      return rejectWithValue(err)
    }
  },
)

export const trialPlanDetails = createAsyncThunk(
  '/trial-plan-details',
  async (pricingId: number, { dispatch, rejectWithValue }) => {
    dispatch(startLoader())
    try {
      const response = await pricingService.trialPlanDetails(pricingId)
      dispatch(stopLoader())
      dispatch(successToast(handleMessages(response.message)))
      dispatch(getSubscriptionPlanDetails())
      dispatch(userDetails())
      dispatch(switchBusinessList())
      return response
    } catch (err: any) {
      dispatch(stopLoader())
      dispatch(errorToast(handleMessages(err.message)))

      return rejectWithValue(err)
    }
  },
)

// Get Subscription Details of the user using the token from the Mail URL
export const userSubscriptionDetails = createAsyncThunk(
  '/userSubscriptionDetails',
  async (token: string, { dispatch, rejectWithValue }) => {
    dispatch(startLoader())
    try {
      const response = await pricingService.userSubscriptionDetails(token)
      if (!response?.token_status) {
        if (isAuthenticated()) History.navigate('/pricing-page')
        else History.navigate('/login')
      }

      // if user is logged in as Personal account, switch to business account before showing subscription modal
      if (response?.data?.switch_to_business && response?.data?.business_id) {
        dispatch(
          switchProfileFromMail({
            type: 'Business',
            business_id: response?.data?.business_id,
          }),
        )
      }
      dispatch(stopLoader())
      return response
    } catch (err: any) {
      History.navigate('/link-expired')

      dispatch(stopLoader())
      return rejectWithValue(err)
    }
  },
)

export const getPricingLimits = createAsyncThunk(
  '/getPricingLimits',
  async (params: { activePlan?: boolean }, { dispatch, rejectWithValue, getState }) => {
    dispatch(startLoader())
    try {
      const loggedInUserType = getUserTypeFromToken()
      const { user } = getState() as { user: IUserState }
      // params.activePlan === true is used to fix a Bug: Pricing Plan:: Missing "Upgrade to Pro" Badges on Trial Basic Plan.
      // user?.activePlan is not getting updated sometimes when we call the getPricingLimits from userDetails
      if (
        loggedInUserType === 'business' &&
        (user?.activePlan === true || params.activePlan === true)
      ) {
        const response = await pricingService.getPricingLimits()
        dispatch(stopLoader())
        return response
      }
    } catch (err: any) {
      dispatch(stopLoader())
      dispatch(errorToast(handleMessages(err.message)))

      return rejectWithValue(err)
    }
  },
)
// When showing adjustable screen
export const getProPaymentDetails = createAsyncThunk(
  '/pro-payment-details',
  async (planId: number, { dispatch, rejectWithValue }) => {
    dispatch(startLoader())
    try {
      const response = await pricingService.getProPaymentDetails(planId)
      dispatch(stopLoader())

      return response
    } catch (err: any) {
      dispatch(stopLoader())
      return rejectWithValue(err)
    }
  },
)
// Plan without payment
export const planWithoutPayment = createAsyncThunk(
  '/plan-without-payment',
  async (planData: IPlanData, { dispatch, rejectWithValue }) => {
    dispatch(startLoader())
    try {
      const response = await pricingService.planWithoutPayment(planData)

      if (Object.keys(response.data).length !== 0) {
        History.navigate('/payment', {
          state: {
            showSuccessPageDirectly: true,
            paymentSuccessData: response.data,
          },
        })
      }
      dispatch(closePricingPage())
      dispatch(getSubscriptionPlanDetails())
      dispatch(getPricingHistory())
      dispatch(stopLoader())
      return response
    } catch (err: any) {
      dispatch(stopLoader())
      return rejectWithValue(err)
    }
  },
)
export const pricingSlice = createSlice({
  name: 'pricingCard',
  initialState,
  reducers: {
    closablePricingPage: (state) => {
      state.closable = true
    },
    closePricingPage: (state) => {
      state.isOpen = false
      state.closable = false
      state.pricingPageOrigin = ''
    },
    updatePricingPageOrigin: (state, action) => {
      state.pricingPageOrigin = action.payload
    },
    setProfileUpdatedStatus: (state) => {
      state.isProfileNotUpdated = true
    },
    resetMailValidStatus: (state) => {
      state.isPricingMailValid = false
    },
    setPricingAlertModal: (state, action) => {
      state.showPricingAlert = action?.payload?.status
      state.pricingAlertContent = action?.payload?.content ?? initialState.pricingAlertContent
    },
    setPlanLimitExceededAlert: (state, action) => {
      state.showPlanLimitExceededAlert = action?.payload?.status
      state.planLimitExceededContent = action?.payload?.content ?? initialState.pricingAlertContent
    },
    setPricingFetching: (state, action) => {
      state.isPricingFetching = action.payload
    },

    closeProceedProModal: (state) => {
      state.isProceedProModalOpen = false
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPricingDetails.fulfilled, (state, action) => {
        if (action?.payload?.data) {
          state.pricingInformation = action?.payload?.data
          state.isOpen = true
          state.isPricingFetching = false
        }
      })
      .addCase(getPricingDetails.rejected, (state) => {
        state.isPricingFetching = false
      })
      .addCase(getPublicPricingDetails.fulfilled, (state, action) => {
        ;(state.isOpenPricingPublic = true), (state.pricingInformation = action.payload.data)
      })
      .addCase(getPricingHistory.fulfilled, (state, action) => {
        state.pricingHistory = action.payload.data?.transactions
          ? action.payload.data?.transactions
          : action.payload.data
      })
      .addCase(getSubscriptionPlanDetails.fulfilled, (state, action) => {
        state.subscriptionPlanDetails = action.payload.data
      })
      .addCase(userDetails.fulfilled, (state, action) => {
        state.subscriptionPlanDetails.subscription = action.payload.data?.subscription
        state.subscriptionName = action.payload.data?.subscription?.name
      })
      .addCase(getPaymentDetails.fulfilled, (state, action) => {
        state.paymentDetails = action.payload.data
        state.isPaymentFailed =
          action.payload?.data?.payment_status === 'failure' ? true : initialState.isPaymentFailed
      })
      .addCase(getPaymentDetails.rejected, (state) => {
        state.isPaymentFailed = true
      })
      .addCase(userSubscriptionDetails.fulfilled, (state, action) => {
        state.isPricingMailValid = true
        state.subscriptionName = action.payload?.data?.subscription_name
        state.subscriptionEndDate = action.payload?.data?.subscription_end_date
      })
      .addCase(userSubscriptionDetails.rejected, (state) => {
        state.isPricingMailValid = false
      })
      .addCase(getPricingLimits.fulfilled, (state, action) => {
        state.pricingLimits = action?.payload?.data
      })
      .addCase(getPricingLimits.rejected, (state) => {
        state.pricingLimits = initialState.pricingLimits
      })
      .addCase(getProPaymentDetails.fulfilled, (state, action) => {
        state.isProceedProModalOpen = true
        state.newPlanData = action.payload.data
      })
      .addCase(planWithoutPayment.fulfilled, (state, action) => {
        state.paymentSuccessData = action.payload.data
      })
  },
})

export const {
  closablePricingPage,
  closePricingPage,
  updatePricingPageOrigin,
  setProfileUpdatedStatus,
  resetMailValidStatus,
  setPricingAlertModal,
  setPlanLimitExceededAlert,
  setPricingFetching,
  closeProceedProModal,
} = pricingSlice.actions
export const pricingData = (state: RootState) => state.pricing
export default pricingSlice.reducer
