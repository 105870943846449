import { IIcon } from './iconConstants'

const SwitchUserIcon = ({ height, width, fill }: IIcon) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 22 23'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2.5 9H18l-4.5-5M19.5 14.5H4L8 19'
        stroke={fill}
        strokeWidth='2'
        strokeLinecap='round'
      />
    </svg>
  )
}
export default SwitchUserIcon
