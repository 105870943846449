// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function Toast(theme: any) {
  return {
    MuiAlert: {
      styleOverrides: {
        standardSuccess: {
          backgroundColor: theme.palette.toast.main,
          border: `0.125rem solid ${theme.palette.success.light}`,
          borderRadius: '0.625rem',
          color: theme.palette.success.light,
          fontSize: '1.25rem',
        },
        standardError: {
          backgroundColor: theme.palette.textGrey.dark,
          border: `0.125rem solid ${theme.palette.toast.dark}`,
          borderRadius: '0.625rem',
          color: theme.palette.toast.dark,
          fontSize: '1.25rem',
        },
        standardWarning: {
          backgroundColor: theme.palette.toast.light,
          border: `0.125rem solid ${theme.palette.alertBackdrop.light}`,
          borderRadius: '0.625rem',
          color: theme.palette.alertBackdrop.light,
          fontSize: '1.25rem',
        },
        standardInfo: {
          backgroundColor: theme.palette.alertBackdrop.dark,
          border: `0.125rem solid ${theme.palette.alertBackdrop.contrastText}`,
          borderRadius: '0.625rem',
          color: theme.palette.alertBackdrop.contrastText,
          fontSize: '1.25rem',
        },
      },
    },
  }
}
