import {
  Box,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
  useTheme,
} from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../../../_helpers/hooks'
import { IPlan } from '../../../../_models/publicPageInterface'
import { PLAN } from '../../../../_constants'

interface PricingCardsProps {
  pricingData: IPlan[]
}
const cardStyle = {
  width: '24.188rem',
  // height: '30.813rem',
  borderRadius: '1rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  padding: '1.5rem',
  textAlign: 'left',
}
const PricingCards: React.FC<PricingCardsProps> = ({ pricingData }) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const translatedInput = useAppSelector((state) => state?.translation.translatedData)
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        gap: '1.875rem',
      }}
    >
      {pricingData.map((item, index) => (
        <Paper
          key={index}
          variant='outlined'
          sx={{
            ...cardStyle,
            ...(item.slug === PLAN.MONTHLY_PRO || item.slug === PLAN.YEARLY_PRO
              ? {
                  backgroundColor: theme.palette.seat.contrastText,
                  borderColor: 'transparent',
                  color: theme.palette.common.white,
                  border: 'none',
                }
              : { borderColor: `${theme.palette.publicPages.colorFive}` }),
            '&:hover': {
              boxShadow: `0 0.25rem 0.625rem 0 ${theme.palette.primary.light}`,
            },
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            {(item.slug === PLAN.MONTHLY_PRO || item.slug === PLAN.YEARLY_PRO) && (
              <Box
                display={'flex'}
                justifyContent={'end'}
                sx={{
                  [theme.breakpoints.down(400)]: {
                    mb: 2,
                  },
                }}
              >
                <Box
                  sx={{
                    padding: '0.5rem 1.25rem',
                    borderRadius: '2.875rem',
                    backgroundColor: 'publicPages.contrastTextHover',
                    fontSize: '0.75rem',
                    fontWeight: 600,
                    color: 'common.white',
                  }}
                >
                  {translatedInput?.landingScreen?.recommendedText}
                </Box>
              </Box>
            )}

            <Typography
              variant='h6'
              mb='0.75rem'
              mt={
                item?.slug === PLAN.MONTHLY_PRO || item?.slug === PLAN.YEARLY_PRO
                  ? '-1.5rem'
                  : '.5rem'
              }
            >
              {item?.name}
            </Typography>

            <Typography variant='h2' fontSize='2.25rem'>
              ₹{item.price}{' '}
              {item?.invoice_period && (
                <span
                  style={{
                    color:
                      item?.slug === PLAN.MONTHLY_PRO || item?.slug === PLAN.YEARLY_PRO
                        ? theme.palette.common.white
                        : theme.palette.secondary.main,
                    fontSize: '0.938rem',
                    fontWeight: 'normal',
                  }}
                >
                  {item?.invoice_period === 1 ? 'per ' : item?.invoice_period}{' '}
                  {item?.invoice_interval}
                </span>
              )}
            </Typography>

            <Button
              variant='contained'
              size='large'
              fullWidth
              sx={{
                mt: 3,
                mb: '.1rem',
                p: '.7rem',
                backgroundColor:
                  item?.slug === PLAN.MONTHLY_PRO || item?.slug === PLAN.YEARLY_PRO
                    ? theme.palette.common.white
                    : theme.palette.primary.main,
                color:
                  item?.slug === PLAN.MONTHLY_PRO || item?.slug === PLAN.YEARLY_PRO
                    ? theme.palette.primary.main
                    : theme.palette.common.white,
                boxShadow: 'none',
                '&:hover': {
                  backgroundColor:
                    item?.slug === PLAN.MONTHLY_PRO || item?.slug === PLAN.YEARLY_PRO
                      ? theme.palette.common.white
                      : theme.palette.primary.main,
                },
              }}
              onClick={() => navigate('/login')}
            >
              {translatedInput?.pricingPage?.subscribeNowBtn}
            </Button>
            {(item?.slug === PLAN.MONTHLY_PRO ||
              item?.slug === PLAN.YEARLY_PRO ||
              item?.slug === PLAN.MONTHLY_BASIC ||
              item?.slug === PLAN.YEARLY_BASIC) && (
              <Button
                variant='outlined'
                size='large'
                fullWidth
                sx={{
                  mt: 3,
                  mb: '.5rem',
                  p: '.7rem',
                  borderColor:
                    item?.slug === PLAN.MONTHLY_PRO || item?.slug === PLAN.YEARLY_PRO
                      ? theme.palette.common.white
                      : theme.palette.primary.main,
                  color:
                    item?.slug === PLAN.MONTHLY_PRO || item?.slug === PLAN.YEARLY_PRO
                      ? theme.palette.common.white
                      : theme.palette.primary.main,
                  boxShadow: 'none',
                  '&:hover': {
                    borderColor:
                      item?.slug === PLAN.MONTHLY_PRO || item?.slug === PLAN.YEARLY_PRO
                        ? theme.palette.common.white
                        : theme.palette.primary.main,
                  },
                }}
                onClick={() => navigate('/login')}
              >
                {translatedInput?.landingScreen?.startTrialForFifteenDays}
              </Button>
            )}

            <List dense sx={{ flexGrow: 1, mt: 2 }}>
              {item.features.map((feature, idx) => (
                <ListItem key={idx}>
                  <ListItemIcon>
                    <Box
                      sx={{
                        width: '0.625rem',
                        height: '0.625rem',
                        borderRadius: '50%',
                        backgroundColor:
                          item?.slug === PLAN.MONTHLY_PRO || item?.slug === PLAN.YEARLY_PRO
                            ? 'common.white'
                            : 'profileIcon.colorDivider',
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText primary={feature.name} />
                </ListItem>
              ))}
            </List>
          </Box>
        </Paper>
      ))}
      <Paper
        variant='outlined'
        sx={{
          ...cardStyle,
          '&:hover': {
            boxShadow: `0 0.25rem 0.625rem 0 ${theme.palette.primary.light}`,
          },
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          borderColor: 'publicPages.colorFive',
        }}
      >
        <Box>
          <Typography variant='h6' mb='0.75rem' mt='.5rem'>
            {translatedInput?.pricing?.enterprise}
          </Typography>

          <Typography component='span' variant='subtitle2' color='secondary.main'>
            {translatedInput?.landingScreen?.enterprisePlanText1}{' '}
            <span style={{ color: theme.palette.common.black, fontWeight: 700 }}>
              {translatedInput?.landingScreen?.proText}
            </span>{' '}
            {translatedInput?.landingScreen?.enterprisePlanText2}
          </Typography>
          <Button
            variant='contained'
            size='large'
            fullWidth
            sx={{
              mt: 11.8,
              mb: '.1rem',
              p: '.7rem',
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.common.white,
              boxShadow: 'none',
              '&:hover': {
                backgroundColor: theme.palette.primary.main,
              },
            }}
            onClick={() => navigate('/contact-us')}
          >
            {translatedInput?.pricingPage?.contactUsBtn}
          </Button>

          {/* <List dense sx={{ flexGrow: 1, mt: 2 }}>
            <ListItem>
              <ListItemIcon>
                <Box
                  sx={{
                    width: '0.625rem',
                    height: '0.625rem',
                    borderRadius: '50%',
                    backgroundColor: 'profileIcon.colorDivider',
                  }}
                />
              </ListItemIcon>
              <ListItemText primary={translatedInput?.landingScreen?.twentyFourSevenSupport} />
            </ListItem>
          </List> */}
        </Box>
      </Paper>
    </Box>
  )
}
export default PricingCards
