export const existingSubDomains = ['react-dev', 'react-qa', 'react-local', 'stg']
export const getBusinessURLStatus = () => {
  const domainNames = getDomainNames()
  const isBusinessURLLoaded =
    domainNames.length > 3 || (domainNames.length === 3 && !checkSubDomainExisting())
  return isBusinessURLLoaded
}
export const getDomainNames = () => {
  const host = window.location.host
  // extracted sub domains
  const domainNames = host.split('.')
  return domainNames
}

export const checkSubDomainExisting = () => {
  const domainNames = getDomainNames()
  // Check if any of the existing subdomains is included in domainNames[0]
  const isSubDomainExisting = existingSubDomains.some((subDomain) =>
    domainNames[0].includes(subDomain),
  )
  return isSubDomainExisting
}
export const isBusinessURL = () => (getBusinessURLStatus() ? true : false)
