import { IBoardRegistration } from '../_models/digitalBoardInterface'
import { get, post } from './apiServices'

const getTokenBoard = async (boardId: string) => {
  const response = await get(`/token-board?url=${boardId}`)
  return response
}
const addFirebaseToken = async (boardDetails: IBoardRegistration) => {
  const response = await post('/token-board/add-firebase-token', boardDetails)
  return response
}
const digitalBoardService = {
  getTokenBoard,
  addFirebaseToken,
}
export default digitalBoardService
