/* eslint-disable react/display-name */
import { ReactElement, Suspense, ComponentType } from 'react'

// ==============================|| LOADABLE - LAZY LOADING ||============================== //

const Loadable =
  <P extends object>(Component: ComponentType<P>) =>
  (props: P): ReactElement =>
    (
      <Suspense fallback={null}>
        <Component {...props} />
      </Suspense>
    )

export default Loadable
