import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../_store/store'
const initialState = {
  isLoading: false,
}

export const loaderSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    startLoader: (state) => {
      state.isLoading = true
    },
    stopLoader: (state) => {
      state.isLoading = false
    },
  },
})
export const { startLoader, stopLoader } = loaderSlice.actions
export const loaderData = (state: RootState) => state.loader

export default loaderSlice.reducer
