import { Box, Button, Grid, TextField, Typography, useTheme } from '@mui/material'
import { useState } from 'react'

import { PERMISSIONS } from '../../_constants'
import { useAppDispatch, useAppSelector } from '../../_helpers/hooks'
import { IScannerDetails } from '../../_models/pusherInterface'
import ShowMoreContainer from '../../components/common/showMoreItem'
import {
  ClockIcon,
  FileOutlineIcon,
  LayersIcon,
  MailIcon,
  PhoneIcon,
  SeatIcon,
  TicketIcon,
  UserIcon,
} from '../../components/icons'
import { useUserPermissionAuthorization } from '../../hooks'
import { completeBooking } from '../../slices/scannerSlice'
interface IScannerModelProps {
  scannedTokenDetails: IScannerDetails | null
  removeDataFromPusher: () => void
}

const ScannerDetailsPopup = (props: IScannerModelProps) => {
  const { removeDataFromPusher, scannedTokenDetails } = props
  const translatedInput = useAppSelector((state) => state?.translation.translatedData)
  const dispatch = useAppDispatch()
  const [isPermitted] = useUserPermissionAuthorization()
  const handleClose = () => {
    removeDataFromPusher()
  }
  const theme = useTheme()
  const [remarks, setRemarks] = useState<string>('')
  const handleSubmit = () => {
    if (scannedTokenDetails) {
      const bookingData = {
        // eslint-disable-next-line camelcase
        token_id: scannedTokenDetails?.token?.book_id,
        remarks: remarks,
      }
      dispatch(completeBooking(bookingData))
      removeDataFromPusher()
      setRemarks('')
    }
  }

  return (
    <>
      <Box>
        <Box sx={{ minWidth: { xs: '0rem', sm: '30.063rem' } }}>
          <Box mt={3}>
            <Box mb={1.5}>
              {scannedTokenDetails?.token?.status === 2 && (
                <Typography variant='subtitle2' fontSize={14} color='error'>
                  {scannedTokenDetails?.token?.message}
                </Typography>
              )}
            </Box>
            <Typography fontSize='1rem' color='common.black' variant='subtitle1' mb={3}>
              {scannedTokenDetails?.facility?.name}
            </Typography>
            <Box mt={2} display={{ xs: 'block', sm: 'flex' }}>
              <Box flex={4} display={'flex'}>
                <UserIcon
                  fill={theme.palette.toggleButtonGroup.contrastText}
                  width='16'
                  height='21'
                />
                <Typography fontSize='0.875rem' color='secondary.main' variant='subtitle1' pl={2}>
                  {`${translatedInput?.scannerDetails?.nameText}:`}
                </Typography>
              </Box>
              <Box flex={7} pt={{ xs: 2, sm: 0 }}>
                <Typography fontSize='0.875rem' color='common.black' variant='subtitle1'>
                  {scannedTokenDetails?.token?.name}
                </Typography>
              </Box>
            </Box>
            <Box mt={2} display={{ xs: 'block', sm: 'flex' }}>
              <Box flex={4} display={'flex'}>
                <TicketIcon
                  fill={theme.palette.toggleButtonGroup.contrastText}
                  width='20'
                  height='18'
                />
                <Typography fontSize='0.875rem' color='secondary.main' variant='subtitle1' pl={2}>
                  {`${translatedInput?.scannerDetails?.tokenText}:`}
                </Typography>
              </Box>
              <Box flex={7} pt={{ xs: 2, sm: 0 }}>
                <Typography fontSize='0.875rem' color='common.black' variant='subtitle1'>
                  {scannedTokenDetails?.token?.token}
                </Typography>
              </Box>
            </Box>
            <Box mt={2} display={{ xs: 'block', sm: 'flex' }}>
              <Box flex={4} display={'flex'}>
                <FileOutlineIcon
                  fill={theme.palette.toggleButtonGroup.contrastText}
                  width='17'
                  height='19'
                />
                <Typography fontSize='0.875rem' color='secondary.main' variant='subtitle1' pl={2}>
                  {`${translatedInput?.scannerDetails?.bookingIdText}:`}
                </Typography>
              </Box>
              <Box flex={7} pt={{ xs: 2, sm: 0 }}>
                <Typography fontSize='0.875rem' color='common.black' variant='subtitle1'>
                  {scannedTokenDetails?.token?.book_id}
                </Typography>
              </Box>
            </Box>
            <Box mt={2} display={{ xs: 'block', sm: 'flex' }}>
              <Box flex={4} display={'flex'}>
                <PhoneIcon
                  fill={theme.palette.toggleButtonGroup.contrastText}
                  width='18'
                  height='18'
                />
                <Typography fontSize='0.875rem' color='secondary.main' variant='subtitle1' pl={2}>
                  {`${translatedInput?.scannerDetails?.phoneNumberText}:`}
                </Typography>
              </Box>
              <Box flex={7} pt={{ xs: 2, sm: 0 }}>
                <Typography fontSize='0.875rem' color='common.black' variant='subtitle1'>
                  {scannedTokenDetails?.token?.phone}
                </Typography>
              </Box>
            </Box>
            <Box mt={2} display={{ xs: 'block', sm: 'flex' }}>
              <Box flex={4} display={'flex'}>
                <MailIcon
                  fill={theme.palette.toggleButtonGroup.contrastText}
                  width='20'
                  height='18'
                />
                <Typography fontSize='0.875rem' color='secondary.main' variant='subtitle1' pl={2}>
                  {`${translatedInput?.scannerDetails?.emailText}:`}
                </Typography>
              </Box>
              <Box flex={7} pt={{ xs: 2, sm: 0 }}>
                <Typography fontSize='0.875rem' color='common.black' variant='subtitle1'>
                  {scannedTokenDetails?.token?.email}
                </Typography>
              </Box>
            </Box>
            <Box mt={2} display={{ xs: 'block', sm: 'flex' }}>
              <Box flex={4} display={'flex'}>
                <ClockIcon
                  fill={theme.palette.toggleButtonGroup.contrastText}
                  width='20'
                  height='20'
                />
                <Typography fontSize='0.875rem' color='secondary.main' variant='subtitle1' pl={2}>
                  {`${translatedInput?.scannerDetails?.scheduledOnText}:`}
                </Typography>
              </Box>
              <Box flex={7} pt={{ xs: 2, sm: 0 }}>
                <Typography fontSize='0.875rem' color='common.black' variant='subtitle1'>
                  {scannedTokenDetails?.token?.preferred_time_status === 0
                    ? scannedTokenDetails?.token?.scheduled_on
                    : scannedTokenDetails?.token?.time_slot_period}
                </Typography>
              </Box>
            </Box>
            <Box mt={2} display={{ xs: 'block', sm: 'flex' }}>
              <Box flex={4} display={'flex'}>
                <SeatIcon
                  fill={theme.palette.toggleButtonGroup.contrastText}
                  width='20'
                  height='22'
                  stoke={theme.palette.toggleButtonGroup.contrastText}
                />
                <Typography fontSize='0.875rem' color='secondary.main' variant='subtitle1' pl={2}>
                  {`${translatedInput?.scannerDetails?.seatsText}:`}
                </Typography>
              </Box>
              <Box flex={7} pt={{ xs: 2, sm: 0 }} display={'flex'}>
                <Typography fontSize='0.875rem' color='common.black' variant='subtitle1'>
                  {scannedTokenDetails?.token?.no_of_seats}
                </Typography>
                {scannedTokenDetails?.token?.seats?.length ? (
                  <Box mt={-0.8} ml={2}>
                    <ShowMoreContainer
                      colorOfItem={'error.main'}
                      colorOfOption={'secondary.main'}
                      colorOfItemXs={'common.black'}
                      fontSizeOfItem={'0.938rem'}
                      fontWeightOfOption='normal'
                      itemsToShowInitially={3}
                      isItemLink={false}
                      data={scannedTokenDetails?.token?.seats}
                    />
                  </Box>
                ) : (
                  <></>
                )}
              </Box>
            </Box>
            {scannedTokenDetails?.token?.floor_name && (
              <Box mt={2} display={{ xs: 'block', sm: 'flex' }}>
                <Box flex={4} display={'flex'}>
                  <LayersIcon
                    fill={theme.palette.toggleButtonGroup.contrastText}
                    width='20'
                    height='20'
                  />
                  <Typography fontSize='0.875rem' color='secondary.main' variant='subtitle1' pl={2}>
                    {translatedInput?.floorPlan?.floorNameFieldText}{' '}
                  </Typography>
                </Box>
                <Box flex={7} pt={{ xs: 2, sm: 0 }}>
                  <Typography fontSize='0.875rem' color='common.black' variant='subtitle1'>
                    {scannedTokenDetails?.token?.floor_name}
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
          {isPermitted([PERMISSIONS.COMPLETE_SCANNED_TOKEN]) && (
            <Box mt={2}>
              <TextField
                id='remarks'
                label={translatedInput?.bookings?.enterRemarks}
                InputProps={{ style: { fontSize: 14 } }}
                InputLabelProps={{ style: { fontSize: 14 } }}
                size='medium'
                margin='normal'
                fullWidth
                value={remarks}
                multiline
                rows={3}
                onChange={(e) => {
                  setRemarks(e.target.value)
                }}
              />
            </Box>
          )}
          <Box>
            {scannedTokenDetails?.token?.status === 1 ? (
              <>
                <Grid container columnSpacing={'1.25rem'}>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6} mt={4}>
                    {isPermitted([PERMISSIONS.COMPLETE_SCANNED_TOKEN]) && (
                      <Button
                        type='submit'
                        sx={{ width: '100%' }}
                        variant='contained'
                        size='large'
                        onClick={handleSubmit}
                      >
                        {translatedInput?.scannerDetails?.completedBtn}
                      </Button>
                    )}
                  </Grid>

                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6} mt={4}>
                    <Button
                      sx={{ width: '100%' }}
                      variant='outlined'
                      size='large'
                      onClick={handleClose}
                    >
                      {translatedInput?.common?.cancelBtn}
                    </Button>
                  </Grid>
                </Grid>
              </>
            ) : (
              <Grid container columnSpacing={'1.25rem'}>
                <Grid item xs={12} sm={3} md={3} lg={3} xl={3} />
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6} mt={4}>
                  <Button
                    sx={{ width: '100%' }}
                    variant='outlined'
                    size='large'
                    onClick={handleClose}
                  >
                    {translatedInput?.common?.cancelBtn}
                  </Button>
                </Grid>
                <Grid item xs={12} sm={3} md={3} lg={3} xl={3} />
              </Grid>
            )}
          </Box>
        </Box>
      </Box>
    </>
  )
}
export default ScannerDetailsPopup
