import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'
const CustomScrollbarBox = styled(Box)(({ theme }) => ({
  '&::-webkit-scrollbar': {
    width: '0.513rem',
    height: '0.513rem',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: theme.palette.common.white,
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.backdrop.contrastText,
    borderRadius: '0.413rem',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    backgroundColor: theme.palette.scroll.main,
  },
}))

export { CustomScrollbarBox }
