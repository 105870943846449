/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Button, Tooltip, Typography, useTheme } from '@mui/material'
import * as React from 'react'
import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../_helpers/hooks'
import { handleMessages } from '../../../_helpers/utilities'
import { IFloorDetails, ISeats } from '../../../_models/newBookingInterface'
import LightTooltip from '../../../components/toolTip'
import { newBookingData, updateSelectedSeats } from '../../../slices/newBookingSlice'
import { errorToast } from '../../../slices/toastSlice'
interface ISeatSelectionArea {
  noOfSeat: number
  unselectedSeatId: number | null
  handleSeatSelectionChange: (unselected: number | null) => void
  seatResults: IFloorDetails
}
const CSVSeatSelection: React.FC<ISeatSelectionArea> = ({
  noOfSeat,
  handleSeatSelectionChange,
  unselectedSeatId,
  seatResults,
}) => {
  const dispatch = useAppDispatch()
  const translatedInput = useAppSelector((state) => state?.translation.translatedData)
  const newBookingState = useAppSelector(newBookingData)
  const { floorDetails, selectedSeats } = newBookingState
  const { colorCodes, seats } = floorDetails
  const theme = useTheme()
  // Function to toggle the selected state of a button
  const handleButtonClick = (value: ISeats) => {
    console.log('seat in selected seats', selectedSeats.includes(value))
    const { seat_id, seat_name } = value
    if (selectedSeats.some((v) => v.seat_id === seat_id)) {
      dispatch(updateSelectedSeats(selectedSeats.filter((v) => v.seat_id !== seat_id)))
    } else if (selectedSeats.length < noOfSeat) {
      dispatch(updateSelectedSeats([...selectedSeats, value]))
    } else {
      dispatch(errorToast(handleMessages(translatedInput.newBookingScreenText.chosenMaxSeatsToast)))
    }
  }
  // Group data by seat_group_name
  const groupedData: { [key: string]: ISeats[] } = {}
  seatResults.seats.forEach((seat: ISeats) => {
    const groupName = seat.seat_group_name as string
    if (!groupedData[groupName]) {
      groupedData[groupName] = []
    }
    groupedData[groupName].push(seat)
  })

  const getSeatProps = (seat: ISeats) => {
    return (
      <>
        {seat.seat_name.length > 25 && (
          <Box mt={1}>
            <Typography variant='subtitle2' color='common.black' fontWeight={'normal'}>
              {translatedInput?.newBookingScreenText?.seatNameText} : {seat.seat_name}
            </Typography>
          </Box>
        )}
        {seat.capacity !== undefined && seat.capacity > 1 && (
          <Box mt={1}>
            <Typography variant='subtitle2' color='common.black' fontWeight={'normal'}>
              {translatedInput?.newBookingScreenText?.seatCapacity} : {seat.capacity}
            </Typography>
          </Box>
        )}
        {seat.customer_name !== undefined && seat.status === 2 && (
          <Box mt={1}>
            <Typography variant='subtitle2' color='common.black' fontWeight={'normal'}>
              {seat.customer_name}
            </Typography>
          </Box>
        )}
      </>
    )
  }
  useEffect(() => {
    handleSeatSelectionChange(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSeats])

  useEffect(() => {
    if (selectedSeats.some((v) => v.seat_id === unselectedSeatId && unselectedSeatId != null)) {
      dispatch(updateSelectedSeats(selectedSeats.filter((v) => v.seat_id !== unselectedSeatId)))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unselectedSeatId])
  return (
    <>
      {Object.keys(groupedData).map((groupName) => (
        <Box key={groupName} mt={2}>
          <Typography variant='subtitle1' color='common.black' mt={1} fontSize={'0.938rem'}>
            {translatedInput?.seatSelection?.sectionTxt}: {groupName}
          </Typography>

          {groupedData[groupName].some((seat) => seat.status === 1) && (
            <Typography variant='subtitle2' color='toggleButtonGroup.contrastText' mt={1}>
              {translatedInput?.newBookingScreenText?.chooseSeatsText}
            </Typography>
          )}
          <Box
            display='flex'
            flexWrap='wrap'
            justifyContent='flex-start'
            alignItems='flex-start'
            gap={3}
            mt={2}
          >
            {groupedData[groupName].map((seat) => (
              <Box
                key={seat.seat_id}
                minWidth={115}
                maxWidth={{ xs: '100%', md: 254 }}
                sx={{ cursor: seat.status === 0 ? 'not-allowed' : 'pointer' }}
              >
                <LightTooltip
                  title={
                    (seat.capacity && seat.capacity > 1) ||
                    seat.seat_name.length > 25 ||
                    (seat.customer_name && seat.status === 2)
                      ? getSeatProps(seat)
                      : ''
                  }
                  enterTouchDelay={0}
                  needAutoClose={true}
                >
                  <Box>
                    {/* Booked seats */}
                    {seat.status === 2 && (
                      <Button
                        variant={'contained'}
                        sx={{
                          width: '100%',
                          minHeight: 34,
                          maxWidth: 254,
                          backgroundColor: colorCodes.booked_seat_color,
                          color: colorCodes.booked_seat_text_color,
                          border: `0.063rem solid ${colorCodes.booked_seat_border_color}`,
                          boxShadow: 'none',
                          cursor: 'default',
                          '&:hover': {
                            backgroundColor: colorCodes.booked_seat_color,
                            color: colorCodes.booked_seat_text_color,
                            borderColor: colorCodes.booked_seat_border_color,
                            boxShadow: 'none',
                          },
                        }}
                      >
                        <Tooltip title={seat.seat_name} placement='bottom-start'>
                          <Box
                            sx={{
                              [theme.breakpoints.up('md')]: {
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                              },
                            }}
                          >
                            {seat.seat_name}
                          </Box>
                        </Tooltip>
                      </Button>
                    )}
                    {/* available and selected  seats */}
                    {seat.status === 1 && (
                      <Button
                        variant={'outlined'}
                        onClick={() => handleButtonClick(seat)}
                        sx={{
                          width: '100%',
                          minHeight: 34,
                          maxWidth: 254,
                          backgroundColor: selectedSeats.some(
                            (selectedSeat) => selectedSeat.seat_id === seat.seat_id,
                          )
                            ? colorCodes.selected_seat_color
                            : colorCodes.available_seat_color,
                          color: selectedSeats.some(
                            (selectedSeat) => selectedSeat.seat_id === seat.seat_id,
                          )
                            ? colorCodes.selected_seat_text_color
                            : colorCodes.available_seat_text_color,
                          border: `0.063rem solid ${
                            selectedSeats.some(
                              (selectedSeat) => selectedSeat.seat_id === seat.seat_id,
                            )
                              ? colorCodes.selected_seat_border_color
                              : colorCodes.available_seat_border_color
                          }`,

                          '&:hover': {
                            backgroundColor: selectedSeats.some(
                              (selectedSeat) => selectedSeat.seat_id === seat.seat_id,
                            )
                              ? colorCodes.selected_seat_color
                              : colorCodes.available_seat_color,
                            color: selectedSeats.some(
                              (selectedSeat) => selectedSeat.seat_id === seat.seat_id,
                            )
                              ? colorCodes.selected_seat_text_color
                              : colorCodes.available_seat_text_color,
                            border: `0.063rem solid ${
                              selectedSeats.some(
                                (selectedSeat) => selectedSeat.seat_id === seat.seat_id,
                              )
                                ? colorCodes.selected_seat_border_color
                                : colorCodes.available_seat_border_color
                            }`,
                          },
                        }}
                      >
                        <Tooltip title={seat.seat_name} placement='bottom-start'>
                          <Box
                            sx={{
                              [theme.breakpoints.up('md')]: {
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                              },
                            }}
                          >
                            {seat.seat_name}
                          </Box>
                        </Tooltip>
                      </Button>
                    )}
                    {/* unavailable and blocked  seats */}
                    {seat.status !== 1 && seat.status !== 2 && (
                      <Button
                        variant={'contained'}
                        sx={{
                          width: '100%',
                          minHeight: 34,
                          maxWidth: 254,
                          backgroundColor:
                            seat.status === 0
                              ? colorCodes.unavailable_seat_color
                              : colorCodes.blocked_seat_color,
                          color:
                            seat.status === 0
                              ? colorCodes.unavailable_seat_text_color
                              : colorCodes.blocked_seat_text_color,
                          border: `0.063rem solid ${
                            seat.status === 0
                              ? colorCodes.unavailable_seat_border_color
                              : colorCodes.blocked_seat_border_color
                          }`,
                          boxShadow: 'none',
                          cursor: 'not-allowed',
                          '&:hover': {
                            backgroundColor:
                              seat.status === 0
                                ? colorCodes.unavailable_seat_color
                                : colorCodes.blocked_seat_color,
                            color:
                              seat.status === 0
                                ? colorCodes.unavailable_seat_text_color
                                : colorCodes.blocked_seat_text_color,
                            border: `0.063rem solid ${
                              seat.status === 0
                                ? colorCodes.unavailable_seat_border_color
                                : colorCodes.blocked_seat_border_color
                            }`,
                            boxShadow: 'none',
                          },
                        }}
                      >
                        <Tooltip title={seat.seat_name} placement='bottom-start'>
                          <Box
                            sx={{
                              [theme.breakpoints.up('md')]: {
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                              },
                            }}
                          >
                            {seat.seat_name}
                          </Box>
                        </Tooltip>
                      </Button>
                    )}
                  </Box>
                </LightTooltip>
              </Box>
            ))}
          </Box>
        </Box>
      ))}
      {seatResults.seats.length == 0 && (
        <Box mt={2}>
          <Typography variant='subtitle1' color='description.main' fontWeight={600}>
            {translatedInput?.businessDetails?.noResultsFoundText} {' !'}
          </Typography>
        </Box>
      )}
    </>
  )
}

export default CSVSeatSelection
