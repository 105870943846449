import { IIcon } from './iconConstants'
const FloorPlanWithCloseIcon = (props: IIcon) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox='0 0 46 46'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M33 43c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10zM36.5 29.5l-7 7M29.5 29.5l7 7'
        stroke={props.stoke}
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18.5 36H7.31A3.31 3.31 0 0 1 4 32.69V7.31A3.31 3.31 0 0 1 7.31 4h25.38A3.31 3.31 0 0 1 36 7.31V19'
        stroke={props.stoke}
        strokeWidth='3'
        strokeLinecap='round'
      />
      <path
        d='M18.894 28.672a1.5 1.5 0 1 0 0-3v3zM17.084 36v-7.724h-3V36h3zm-.397-7.328h2.207v-3h-2.207v3zm.397-.396a.397.397 0 0 1-.397.396v-3a2.603 2.603 0 0 0-2.603 2.604h3zM17.086 11.724a1.5 1.5 0 0 0-3 0h3zm-3 8.828a1.5 1.5 0 0 0 3 0h-3zM4 17.638h11.586v-3H4v3zm10.086-5.914v8.828h3v-8.828h-3zM29.933 19.845a1.5 1.5 0 1 0 0-3v3zM22.916 5.103v12.138h3V5.103h-3zm2.604 14.742h4.413v-3H25.52v3zm-2.604-2.604a2.603 2.603 0 0 0 2.604 2.604v-3c.218 0 .396.177.396.396h-3z'
        fill={props.fill}
      />
    </svg>
  )
}
export default FloorPlanWithCloseIcon
