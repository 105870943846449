import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ITranslationState } from '../_models/translationInterface'
import translationService from '../_services/translationService'
import { RootState } from '../_store/store'
import translatedJson from '../translation/translation.json'

const initialState: ITranslationState = {
  loader: false,
  translatedData: translatedJson,
  languages: [],
  selectedLanguage: 'en',
}

export const translation = createAsyncThunk('/translation', async (language: string) => {
  const res = await translationService.translated(language)
  return res
})

export const getLanguages = createAsyncThunk('/getLanguages', async () => {
  const res = await translationService.getLanguages()
  return res
})

const translationSlice = createSlice({
  name: 'translation',
  initialState,
  reducers: {
    setLanguage: (state, action) => {
      return {
        ...state,
        selectedLanguage: action.payload,
      }
    },
    resetSelectedLanguage: (state) => {
      state.selectedLanguage = initialState.selectedLanguage
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(translation.pending, (state) => {
        state.loader = true
      })
      .addCase(translation.fulfilled, (state, action) => {
        state.loader = true
        state.translatedData = action.payload.data.translations
      })
      .addCase(translation.rejected, (state) => {
        state.loader = true
      })
      .addCase(getLanguages.fulfilled, (state, action) => {
        state.languages = action.payload.data.language_list
      })
  },
})
export const { setLanguage, resetSelectedLanguage } = translationSlice.actions
export const translationData = (state: RootState) => state.translation
export default translationSlice.reducer
