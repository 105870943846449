export default function FormHelperText(theme: any) {
  return {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: theme.palette.error.main,
        },
      },
    },
  }
}
