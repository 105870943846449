import { Box, Button, Grid, Stack, Typography, useTheme } from '@mui/material'
import { useAppDispatch, useAppSelector } from '../../_helpers/hooks'
import CustomModal from '../../components/@extended/modal'
import { alertData, hideAlert } from '../../slices/alertSlice'
import { ErrorIcon, SuccessIcon, WarningIcon } from '../icons'
const AlertModal = () => {
  const alertState = useAppSelector(alertData)
  const dispatch = useAppDispatch()
  const theme = useTheme()
  const translatedInput = useAppSelector((state) => state?.translation.translatedData)
  const { alertMessage, alertType, showAlert } = alertState
  const removeAlert = () => {
    dispatch(hideAlert())
  }

  const alertVariant = (alertType: string) => {
    switch (alertType) {
      case 'success':
        return {
          icon: (
            <SuccessIcon
              fill={theme.palette.success.light}
              width='46'
              height='46'
              stoke={theme.palette.common.white}
            />
          ),
          title: translatedInput?.alert?.confirmationTitle,
        }
        break
      case 'error':
        return {
          icon: (
            <ErrorIcon
              fill={theme.palette.error.dark}
              width='48'
              height='48'
              stoke={theme.palette.common.white}
            />
          ),
          title: translatedInput?.alert?.failureMssgTitle,
        }
        break
      case 'warning':
        return {
          icon: (
            <WarningIcon
              fill={theme.palette.iconColors.contrastText}
              width='47'
              height='47'
              stoke={theme.palette.common.white}
            />
          ),
          title: translatedInput?.alert?.warningMssgTitle,
        }
        break
      default:
        break
    }
  }

  return (
    <CustomModal isOpen={showAlert} onClose={removeAlert}>
      <Grid
        container
        spacing={1}
        sx={{
          maxWidth: { xs: 400, lg: 366 },
          minWidth: { lg: 300 },
          p: { xs: 2, sm: 2, md: 3, xl: 3 },
        }}
      >
        <Grid item xs={12}>
          <Stack justifyContent='center' alignItems='center'>
            <Box
              sx={{
                height: 50,
                mb: 3,
              }}
            >
              {alertVariant(alertType)?.icon}
            </Box>

            <Typography
              sx={{
                fontSize: '22px',
                mb: '16px',
                lineHeight: '22px',
              }}
              variant='h2'
              textAlign='center'
            >
              {alertVariant(alertType)?.title}
            </Typography>
            {alertMessage &&
              alertMessage.map((item: string) => (
                <Typography
                  variant='subtitle1'
                  color='secondary'
                  mb={1}
                  textAlign='center'
                  key={item}
                >
                  {item}
                </Typography>
              ))}
            <Button
              fullWidth
              size='large'
              onClick={removeAlert}
              variant='contained'
              sx={{ width: '75%', marginTop: 3 }}
            >
              {translatedInput?.common?.gotItBtn}
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </CustomModal>
  )
}

export default AlertModal
