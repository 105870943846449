import { IIcon } from './iconConstants'
const MailEnvelopeWithTickIcon2 = ({ width, height, fill, stoke }: IIcon) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 50 50'
      fill={fill}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M25.34 37.8H40.74c1.98 0 3.6-1.62 3.6-3.6V12.6c0-1.98-1.62-3.6-3.6-3.6h-28.8a3.61 3.61 0 0 0-3.6 3.6V21.7'
        stroke={stoke}
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='m44.34 12.6-18 12.6-18-12.6M21.698 32.252v.736a8 8 0 1 1-4.744-7.312'
        stroke={stoke}
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='m21.7 26.588-8 8.008-2.4-2.4'
        stroke={stoke}
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
export default MailEnvelopeWithTickIcon2
