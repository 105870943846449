import { IIcon } from './iconConstants'
const AnnounceIcon = (props: IIcon) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox='0 0 17 16'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5.053 11.79s5.894.842 8.42 3.368h.843a.842.842 0 0 0 .842-.842V9.21a1.685 1.685 0 0 0 0-3.262V.842A.842.842 0 0 0 14.316 0h-.842c-2.527 2.526-8.421 3.368-8.421 3.368H1.684C.754 3.368 0 4.122 0 5.053v5.052c0 .93.754 1.684 1.684 1.684h.842L3.368 16h1.685v-4.21zm1.684-7.023a28.602 28.602 0 0 0 2.054-.52c1.413-.415 3.209-1.07 4.683-2.079V12.99C12 11.98 10.204 11.326 8.79 10.91a28.604 28.604 0 0 0-2.054-.52V4.768zm-5.053.286h3.369v5.052H1.684V5.053z'
        fill={props.fill}
        fillRule='nonzero'
      />
    </svg>
  )
}
export default AnnounceIcon
