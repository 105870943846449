import { jwtDecode } from 'jwt-decode'

declare module 'jwt-decode' {
  export interface JwtPayload {
    type: string
    active_business_id: number
  }
}

export const getAccessTokenFromCookie = () => {
  const cname = 'token='
  const cookieDecoded = decodeURIComponent(document.cookie)
  const cookieParts = cookieDecoded.split(';')

  for (let i = 0; i < cookieParts.length; i++) {
    let c = cookieParts[i]
    while (c.charAt(0) == ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(cname) == 0) {
      return c.substring(cname.length, c.length)
    }
  }
  return ''
}

export const isAuthenticated = () => {
  return getAccessTokenFromCookie() ? true : false
}
export const setTokenInCookie = (authRes: string) => {
  const expiry = new Date(Date.now() + 86400000) // 1 day in milliseconds
  document.cookie = `token=${authRes};domain=${
    process.env.REACT_APP_DOMAIN
  };expires=${expiry.toUTCString()};path=/`
}

export const clearTokenFromCookie = () => {
  document.cookie = `token=;expires=Thu, 01 Jan 1970 00:00:01 GMT;domain=${process.env.REACT_APP_DOMAIN};path=/;`
}

export const getUserTypeFromToken = () => {
  const token = getAccessTokenFromCookie()
  if (token) {
    return decodeTokenType(token)
  } else {
    return ''
  }
}

export const decodeTokenDetails = () => {
  const token = getAccessTokenFromCookie()
  if (token) {
    try {
      const decodedTokenDetails = jwtDecode(token)
      return decodedTokenDetails
    } catch (error) {
      // Invalid token specified
      return null
    }
  } else {
    // No token found
    return null
  }
}

export const decodeTokenType = (token: string) => {
  try {
    const decodedTokenDetails = jwtDecode(token)
    const { type } = decodedTokenDetails
    return type
  } catch (error) {
    return ''
  }
}
