/* eslint-disable prefer-const */
/* eslint-disable camelcase */

import moment from 'moment'
import {
  IDays,
  IFacilityDayConfiguration,
  IFacilityOperatingDays,
  IFacilitySlotConfiguration,
} from '../_models/facilityInterface'

export const generateTimeLabels = (
  startTime: string,
  interval: number,
  period: moment.unitOfTime.Base,
  endTime: string,
) => {
  const periodsInADay = moment.duration(1, 'day').as(period)
  let timeLabels: string[] = []
  const startTimeMoment = moment(startTime, 'hh:mm A')
  const endTimeMoment = moment(endTime, 'hh:mm A')

  for (let i = 0; i < periodsInADay; i += interval) {
    startTimeMoment.add(i === 0 ? 0 : interval, period)

    if (startTimeMoment.isSameOrBefore(endTimeMoment)) {
      timeLabels.push(startTimeMoment.format('hh:mm A'))
    } else {
      break
    }
  }
  return timeLabels
}

export const generateHoursLabels = (
  startHour: string,
  interval: number,
  period: moment.unitOfTime.Base,
  endHour: string,
  includeDayStartTime?: boolean,
) => {
  const periodsInADay = moment.duration(1, 'day').as(period)
  let timeLabels: string[] = []
  const startTimeMoment = moment(startHour, 'HH:mm')
  const endTimeMoment = moment(endHour, 'HH:mm')

  for (let i = 0; i < periodsInADay; i += interval) {
    startTimeMoment.add(i === 0 ? 0 : interval, period)

    if (i === 0 && includeDayStartTime) {
      timeLabels.push('00:00')
    }

    if (i > 0 && startTimeMoment.format('HH:mm') === '00:00' && endHour === '24:00') {
      timeLabels.push('24:00')
    }
    if (
      startTimeMoment.isSameOrBefore(endTimeMoment) &&
      startTimeMoment.format('HH:mm') !== '00:00'
    ) {
      timeLabels.push(startTimeMoment.format('HH:mm'))
    } else {
      break
    }
  }
  return timeLabels
}

export const calculateTimeDuration = (startTime: string, endTime: string) => {
  const startTimeMoment = moment(startTime, 'hh:mm A')
  const endTimeMoment = moment(endTime, 'hh:mm A')
  const durationMinutes = moment.duration(endTimeMoment.diff(startTimeMoment)).asMinutes()
  const totalDuration = moment('12:00 AM', 'hh:mm A')
    .add(durationMinutes, 'minutes')
    .format('HH:mm')
  return totalDuration
}

export const calculateDayDifference = (startDate: string, endDate: string) => {
  const startDateMoment = moment(startDate)
  const endDateMoment = moment(endDate)
  const daysDifference = moment.duration(endDateMoment.diff(startDateMoment)).asDays() + 1
  return daysDifference
}

export const generateSlots = (
  startTime: string,
  interval: number,
  period: moment.unitOfTime.Base,
  endTime: string,
  slotRequests: string,
  totalSlots?: string,
) => {
  let slotsData: IFacilitySlotConfiguration[] = []
  const periodsInADay = moment.duration(1, 'day').as(period)
  const slotOpenTimeMoment = moment(startTime, 'hh:mm A')
  const endTimeMoment = moment(endTime, 'hh:mm A')

  const slotRequestsData: string[] = totalSlots ? totalSlots.split('|') : []

  for (let i = 0, j = 0; i < periodsInADay; i += interval, j++) {
    slotOpenTimeMoment.add(i === 0 ? 0 : interval, period)
    const slotCloseTimeMoment = moment(slotOpenTimeMoment.format('hh:mm A'), 'hh:mm A').add(
      interval,
      period,
    )

    if (slotOpenTimeMoment.isAfter(endTimeMoment) || slotCloseTimeMoment.isAfter(endTimeMoment)) {
      break
    } else {
      const remainingTime = moment.duration(endTimeMoment.diff(slotCloseTimeMoment)).asMinutes()
      slotsData.push({
        requests: totalSlots ? slotRequestsData[j] : slotRequests,
        interval: `${slotOpenTimeMoment.format('hh:mm A')} to ${slotCloseTimeMoment.format(
          'hh:mm A',
        )}`,
      })

      if (remainingTime < interval && remainingTime > 0) {
        const extraSlotOpenTimeMoment = moment(slotCloseTimeMoment.format('hh:mm A'), 'hh:mm A')
        const extraSlotCloseTimeMoment = moment(
          extraSlotOpenTimeMoment.format('hh:mm A'),
          'hh:mm A',
        ).add(remainingTime, period)
        slotsData.push({
          requests: totalSlots ? slotRequestsData[slotRequestsData.length - 1] : slotRequests,
          interval: `${extraSlotOpenTimeMoment.format(
            'hh:mm A',
          )} to ${extraSlotCloseTimeMoment.format('hh:mm A')}`,
        })
      }
    }
  }

  return slotsData
}

export const generateWeekDayConfiguration = (
  weekDay: string,
  startTime: string,
  endTime: string,
) => {
  const weekDaysConfig: IFacilityDayConfiguration = {
    day: weekDay,
    start_time: startTime,
    end_time: endTime,
  }
  return weekDaysConfig
}

export const generateOperatingWeekDays = (openTime: string, closeTime: string, days: IDays) => {
  let weekDays: IFacilityDayConfiguration[] = []
  Object.entries(days).forEach(([key, value]) => {
    if (value) {
      weekDays.push(generateWeekDayConfiguration(key, openTime, closeTime))
    }
  })
  return weekDays
}

export const generateWeekdDaysObject = (weekDays: IFacilityDayConfiguration[]) => {
  let weekDayObj: IFacilityOperatingDays = {
    sun: false,
    mon: false,
    tue: false,
    wed: false,
    thu: false,
    fri: false,
    sat: false,
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  Object.entries(weekDayObj).forEach(([key, value]) => {
    const isDaySelected = weekDays.some((dayObj) => dayObj.day === key)
    weekDayObj[key as keyof IFacilityOperatingDays] = isDaySelected
  })
  return weekDayObj
}

export const getTimeWithUnit = (time: string) => {
  const timeMoment = moment(time, 'HH:mm')
  const timeWithUnit = timeMoment.hours() > 0 ? `${time} Hrs` : `${time} Mins`

  if (time === '24:00') {
    return `${time} Hrs`
  }
  return timeWithUnit
}

export const formatTime = (time: string) => {
  const timeMoment = moment(time, 'HH:mm')

  if (time === '24:00') {
    return timeMoment.format('k:mm') + ' hrs'
  }
  if (timeMoment.hours() > 0) {
    return timeMoment.format('k:mm') + ' hrs'
  } else {
    return timeMoment.format('m') + ' mins'
  }
}

export const getTimeInMinutes = (time: string) => {
  if (time === '24:00') {
    return '1440'
  }
  const timeMoment = moment(time, 'HH:mm')
  const hours = timeMoment.hours()
  const minutes = timeMoment.minutes()
  const totalTimeInMinutes = hours * 60 + minutes
  return totalTimeInMinutes
}

export const getIntervalTimeInMinutes = (startTime: string, endTime: string) => {
  const startTimeInMoment = moment(startTime, 'hh:mm A')
  const endTimeInMoment = moment(endTime, 'hh:mm A')
  const intervalTimeInMinutes = endTimeInMoment.diff(startTimeInMoment, 'minutes')
  return intervalTimeInMinutes
}

export const getTimeInHours = (totalMinutes: string) => {
  if (totalMinutes === '1440') {
    return '24:00'
  }
  const totalTimeInHours = moment('12:00 AM', 'hh:mm A')
    .add(totalMinutes, 'minutes')
    .format('HH:mm')
  return totalTimeInHours
}

export const checkSlotRequestCustomization = (slots: IFacilitySlotConfiguration[]) => {
  return slots.every((val, i, arr) => val.requests === arr[0].requests) ? false : true
}
