import { IIcon } from './iconConstants'
const mailEnvelopeWithThumbIcon = ({ width, height, fill, stoke }: IIcon) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 50 50'
      fill={fill}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M43.6 19.1v12.7m-39-12.7v23.4c0 2.145 1.755 3.9 3.9 3.9h14.6'
        stroke={stoke}
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M43.6 19.1 24.1 32.75 4.6 19.1M42.625 18.125l-4.94-3.9M4.6 19.1l4.875-3.9m23.27-4.875L24.1 3.5l-8.532 6.825'
        stroke={stoke}
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.451 23V12.275a1.95 1.95 0 0 1 1.95-1.95h23.4a1.95 1.95 0 0 1 1.95 1.95V23'
        stroke={stoke}
        strokeWidth='3'
      />
      <path
        d='M16.305 16.175h6.825M16.305 22.025H30.93'
        stroke={stoke}
        strokeWidth='3'
        strokeLinecap='round'
      />
      <path
        d='m31.96 37.928 3.168-7.128a2.376 2.376 0 0 1 2.376 2.376v3.168h4.483a1.585 1.585 0 0 1 1.584 1.822l-1.093 7.128a1.584 1.584 0 0 1-1.584 1.346H31.96m0-8.712v8.712m0-8.712h-2.376A1.584 1.584 0 0 0 28 39.512v5.544a1.584 1.584 0 0 0 1.584 1.584h2.376'
        stroke={stoke}
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
export default mailEnvelopeWithThumbIcon
