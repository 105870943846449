/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Autocomplete,
  Box,
  Button,
  ClickAwayListener,
  Popper,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../_helpers/hooks'
import { handleMessages } from '../../../_helpers/utilities'
import { IFloorDetails, ISeats } from '../../../_models/newBookingInterface'
import { StyledPopper } from '../../../components/@extended/customPopper'
import LightTooltip from '../../../components/toolTip'
import { newBookingData, updateSelectedSeats } from '../../../slices/newBookingSlice'
import { errorToast } from '../../../slices/toastSlice'
interface ISeatSelectionArea {
  noOfSeat: number
  unselectedSeatId: number | null
  handleSeatSelectionChange: (unselected: number | null) => void
  seatResults: IFloorDetails
  userEmails: string[] | []
}
const CSVSeatSelectionBookForOthers: React.FC<ISeatSelectionArea> = ({
  noOfSeat,
  handleSeatSelectionChange,
  unselectedSeatId,
  seatResults,
  userEmails,
}) => {
  const dispatch = useAppDispatch()
  const translatedInput = useAppSelector((state) => state?.translation.translatedData)
  const newBookingState = useAppSelector(newBookingData)
  const { floorDetails, selectedSeats } = newBookingState
  const { colorCodes } = floorDetails
  const theme = useTheme()
  const [openPopper, setOpenPopper] = useState(false)
  const [seatClicked, setSeatClicked] = useState<ISeats | null>(null)
  const [selectedEmail, setSelectedEmail] = useState<string | null>()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleEmailSelect = (email: string | null) => {
    setSelectedEmail(email)
    const updatedSeatClicked = {
      ...seatClicked,
      user_email: email,
    }
    dispatch(updateSelectedSeats([...selectedSeats, updatedSeatClicked]))
    handlePopperClose()
  }
  const handleButtonClick = (value: ISeats, event: React.MouseEvent<HTMLButtonElement>) => {
    const alreadySelectedSeat = selectedSeats.find((seat) => seat.seat_id === value.seat_id)
    if (alreadySelectedSeat) {
      dispatch(
        updateSelectedSeats(
          selectedSeats.filter((v: { seat_id: number | null }) => v.seat_id !== value.seat_id),
        ),
      )
    } else {
      if (selectedSeats.length < noOfSeat) {
        setSeatClicked(value)
        setAnchorEl(event.currentTarget)
        setOpenPopper(true)
      } else {
        dispatch(
          errorToast(handleMessages(translatedInput?.newBookingScreenText?.chosenMaxSeatsToast)),
        )
      }
    }
  }
  const handlePopperClose = () => {
    setOpenPopper(false)
    setSeatClicked(null)
    setSelectedEmail(null)
  }

  // Group data by seat_group_name
  const groupedData: { [key: string]: ISeats[] } = {}
  seatResults.seats.forEach((seat: ISeats) => {
    const groupName = seat.seat_group_name as string
    if (!groupedData[groupName]) {
      groupedData[groupName] = []
    }
    groupedData[groupName].push(seat)
  })

  const getSeatProps = (seat: ISeats) => {
    return (
      <>
        {seat.seat_name.length > 25 && (
          <Box mt={1}>
            <Typography variant='subtitle2' color='common.black' fontWeight={'normal'}>
              {translatedInput?.newBookingScreenText?.seatNameText} : {seat.seat_name}
            </Typography>
          </Box>
        )}
        {seat.capacity !== undefined && seat.capacity > 1 && (
          <Box mt={1}>
            <Typography variant='subtitle2' color='common.black' fontWeight={'normal'}>
              {translatedInput?.newBookingScreenText?.seatCapacity} : {seat.capacity}
            </Typography>
          </Box>
        )}
        {seat.customer_name !== undefined && seat.status === 2 && (
          <Box mt={1}>
            <Typography variant='subtitle2' color='common.black' fontWeight={'normal'}>
              {seat.customer_name}
            </Typography>
          </Box>
        )}
      </>
    )
  }
  useEffect(() => {
    handleSeatSelectionChange(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSeats])

  useEffect(() => {
    if (
      selectedSeats.some(
        (v: { seat_id: number | null }) =>
          v.seat_id === unselectedSeatId && unselectedSeatId != null,
      )
    ) {
      dispatch(
        updateSelectedSeats(
          selectedSeats.filter((v: { seat_id: number | null }) => v.seat_id !== unselectedSeatId),
        ),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unselectedSeatId])
  return (
    <>
      {Object.keys(groupedData).map((groupName) => (
        <Box key={groupName} mt={2}>
          <Typography variant='subtitle1' color='common.black' mt={1} fontSize={'0.938rem'}>
            {translatedInput?.seatSelection?.sectionTxt}: {groupName}
          </Typography>

          {groupedData[groupName].some((seat) => seat.status === 1) && (
            <Typography variant='subtitle2' color='toggleButtonGroup.contrastText' mt={1}>
              {translatedInput?.newBookingScreenText?.chooseSeatsText}
            </Typography>
          )}
          <Box
            display='flex'
            flexWrap='wrap'
            justifyContent='flex-start'
            alignItems='flex-start'
            gap={3}
            mt={2}
          >
            {groupedData[groupName].map((seat) => (
              <Box
                key={seat.seat_id}
                minWidth={115}
                maxWidth={{ xs: '100%', md: 254 }}
                sx={{ cursor: seat.status === 0 ? 'not-allowed' : 'pointer' }}
              >
                <Box>
                  {/* Booked seats */}
                  {seat.status === 2 && (
                    <LightTooltip
                      title={
                        (seat.capacity && seat.capacity > 1) ||
                        seat.seat_name.length > 25 ||
                        (seat.customer_name && seat.status === 2)
                          ? getSeatProps(seat)
                          : ''
                      }
                      enterTouchDelay={0}
                      needAutoClose={true}
                    >
                      <Button
                        variant={'contained'}
                        sx={{
                          width: '100%',
                          minHeight: 34,
                          maxWidth: 254,
                          backgroundColor: colorCodes.booked_seat_color,
                          color: colorCodes.booked_seat_text_color,
                          border: `0.063rem solid ${colorCodes.booked_seat_border_color}`,
                          boxShadow: 'none',
                          cursor: 'default',
                          '&:hover': {
                            backgroundColor: colorCodes.booked_seat_color,
                            color: colorCodes.booked_seat_text_color,
                            borderColor: colorCodes.booked_seat_border_color,
                            boxShadow: 'none',
                          },
                        }}
                      >
                        <Tooltip title={seat.seat_name} placement='bottom-start'>
                          <Box
                            sx={{
                              [theme.breakpoints.up('md')]: {
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                              },
                            }}
                          >
                            {seat.seat_name}
                          </Box>
                        </Tooltip>
                      </Button>
                    </LightTooltip>
                  )}
                  {/* available and selected  seats */}
                  {seat.status === 1 && (
                    <Button
                      variant={'outlined'}
                      onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
                        handleButtonClick(seat, event)
                      }
                      sx={{
                        width: '100%',
                        minHeight: 34,
                        maxWidth: 254,
                        backgroundColor: selectedSeats.some(
                          (selectedSeat: { seat_id: number }) =>
                            selectedSeat.seat_id === seat.seat_id,
                        )
                          ? colorCodes.selected_seat_color
                          : colorCodes.available_seat_color,
                        color: selectedSeats.some(
                          (selectedSeat: { seat_id: number }) =>
                            selectedSeat.seat_id === seat.seat_id,
                        )
                          ? colorCodes.selected_seat_text_color
                          : colorCodes.available_seat_text_color,
                        border: `0.063rem solid ${
                          selectedSeats.some(
                            (selectedSeat: { seat_id: number }) =>
                              selectedSeat.seat_id === seat.seat_id,
                          )
                            ? colorCodes.selected_seat_border_color
                            : colorCodes.available_seat_border_color
                        }`,

                        '&:hover': {
                          backgroundColor: selectedSeats.some(
                            (selectedSeat: { seat_id: number }) =>
                              selectedSeat.seat_id === seat.seat_id,
                          )
                            ? colorCodes.selected_seat_color
                            : colorCodes.available_seat_color,
                          color: selectedSeats.some(
                            (selectedSeat: { seat_id: number }) =>
                              selectedSeat.seat_id === seat.seat_id,
                          )
                            ? colorCodes.selected_seat_text_color
                            : colorCodes.available_seat_text_color,
                          border: `0.063rem solid ${
                            selectedSeats.some(
                              (selectedSeat: { seat_id: number }) =>
                                selectedSeat.seat_id === seat.seat_id,
                            )
                              ? colorCodes.selected_seat_border_color
                              : colorCodes.available_seat_border_color
                          }`,
                        },
                      }}
                    >
                      <Tooltip title={seat.seat_name} placement='bottom-start'>
                        <Box
                          sx={{
                            [theme.breakpoints.up('md')]: {
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                            },
                          }}
                        >
                          {seat.seat_name}
                        </Box>
                      </Tooltip>
                    </Button>
                  )}
                  {/* unavailable and blocked  seats */}
                  {seat.status !== 1 && seat.status !== 2 && (
                    <Button
                      variant={'contained'}
                      sx={{
                        width: '100%',
                        minHeight: 34,
                        maxWidth: 254,
                        backgroundColor:
                          seat.status === 0
                            ? colorCodes.unavailable_seat_color
                            : colorCodes.blocked_seat_color,
                        color:
                          seat.status === 0
                            ? colorCodes.unavailable_seat_text_color
                            : colorCodes.blocked_seat_text_color,
                        border: `0.063rem solid ${
                          seat.status === 0
                            ? colorCodes.unavailable_seat_border_color
                            : colorCodes.blocked_seat_border_color
                        }`,
                        boxShadow: 'none',
                        cursor: 'not-allowed',
                        '&:hover': {
                          backgroundColor:
                            seat.status === 0
                              ? colorCodes.unavailable_seat_color
                              : colorCodes.blocked_seat_color,
                          color:
                            seat.status === 0
                              ? colorCodes.unavailable_seat_text_color
                              : colorCodes.blocked_seat_text_color,
                          border: `0.063rem solid ${
                            seat.status === 0
                              ? colorCodes.unavailable_seat_border_color
                              : colorCodes.blocked_seat_border_color
                          }`,
                          boxShadow: 'none',
                        },
                      }}
                    >
                      <Tooltip title={seat.seat_name} placement='bottom-start'>
                        <Box
                          sx={{
                            [theme.breakpoints.up('md')]: {
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                            },
                          }}
                        >
                          {seat.seat_name}
                        </Box>
                      </Tooltip>
                    </Button>
                  )}
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      ))}
      {seatResults.seats.length == 0 && (
        <Box mt={2}>
          <Typography variant='subtitle1' color='description.main' fontWeight={600}>
            {translatedInput?.businessDetails?.noResultsFoundText} {' !'}
          </Typography>
        </Box>
      )}
      <StyledPopper
        open={openPopper}
        anchorEl={anchorEl}
        placement='bottom-start'
        disablePortal={false}
        sx={{
          boxShadow: '-0.063rem 0.313rem 0.875rem 0.775rem rgba(0, 0, 0, 0.09)',
          minWidth: { xs: '14rem', md: '18.65rem' },
          marginTop: '1.25rem !important',
          zIndex: '1000',
        }}
      >
        <span className='arrow' />
        <ClickAwayListener onClickAway={handlePopperClose}>
          <Box sx={{ backgroundColor: 'white', pl: 2, pt: 2, pr: 2, pb: 2 }}>
            <Box mt={1}>
              <Typography variant='subtitle2' color='common.black' fontWeight={'normal'}>
                {translatedInput?.newBookingScreenText?.seatNameText} : {seatClicked?.seat_name}
              </Typography>
            </Box>
            {seatClicked?.capacity !== undefined && seatClicked?.capacity > 1 && (
              <Box mt={1}>
                <Typography variant='subtitle2' color='common.black' fontWeight={'normal'}>
                  {translatedInput?.newBookingScreenText?.seatCapacity} : {seatClicked?.capacity}
                </Typography>
              </Box>
            )}
            <Box mt={2}>
              <Autocomplete
                fullWidth
                options={userEmails.filter(
                  (email) =>
                    !selectedSeats.some(
                      (seatWithEmail) =>
                        seatWithEmail.user_email === email && seatWithEmail.user_email !== null,
                    ),
                )}
                value={selectedEmail}
                onChange={(event, value) => handleEmailSelect(value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={'Assign user'}
                    InputProps={{
                      ...params.InputProps,
                    }}
                  />
                )}
                PopperComponent={(props) => (
                  <Popper
                    {...props}
                    sx={{
                      '& .MuiAutocomplete-option': { wordBreak: 'break-all', whiteSpace: 'normal' },
                    }}
                  />
                )}
              />
            </Box>
          </Box>
        </ClickAwayListener>
      </StyledPopper>
    </>
  )
}

export default CSVSeatSelectionBookForOthers
