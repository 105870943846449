import { getUserTypeFromToken, isAuthenticated } from '../_helpers/cookie'

function useUserTypeAuthorization(authorizedUserType: string): [boolean] {
  const loggedInUserType = getUserTypeFromToken()
  const isUserTypeAuthorized =
    isAuthenticated() && loggedInUserType && loggedInUserType === authorizedUserType ? true : false
  return [isUserTypeAuthorized]
}

export default useUserTypeAuthorization
