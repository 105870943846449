import { IIcon } from './iconConstants'
const buildingsWithCloseIcon = (props: IIcon) => {
  return (
    <svg
      width={props.width}
      height={props.width}
      viewBox='0 0 37 33'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g fill='none' fillRule='evenodd' opacity='.4'>
        <path d='M-1-2h39v39H-1z' />
        <path
          d='M3.875 28.875V7.262c0-.683.427-1.293 1.07-1.527L20.66.022a.813.813 0 0 1 1.09.763v8.049l10.264 3.42a1.625 1.625 0 0 1 1.111 1.543v15.078h3.25v3.25H.625v-3.25h3.25zm3.25 0H18.5V4.265L7.125 8.401v20.473zm22.75 0V14.968L21.75 12.26v16.616h8.125z'
          fill={props.fill}
          fillRule='nonzero'
        />
        <path
          d='m13.456 17 2.435-2.446a.315.315 0 0 0 0-.456.315.315 0 0 0-.456 0L13 16.543l-2.446-2.445a.315.315 0 0 0-.456 0 .315.315 0 0 0 0 .456L12.533 17l-2.435 2.446a.315.315 0 0 0 0 .456.331.331 0 0 0 .228.098.332.332 0 0 0 .228-.098L13 17.457l2.446 2.445a.331.331 0 0 0 .228.098.332.332 0 0 0 .228-.098.315.315 0 0 0 0-.456L13.456 17z'
          stroke={props.fill}
          fill={props.fill}
          fillRule='nonzero'
        />
      </g>
    </svg>
  )
}
export default buildingsWithCloseIcon
