import { IIcon } from './iconConstants'
const DeleteIcon = (props: IIcon) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox='0 0 16 19'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g fill={props.fill} fillRule='nonzero'>
        <path d='M15.162 4.44a1.896 1.896 0 0 0-1.895-1.895h-1.959A3.164 3.164 0 0 0 8.213.018H6.949a3.164 3.164 0 0 0-3.095 2.527H1.895a1.89 1.89 0 0 0-.631 3.674v9.744a3.011 3.011 0 0 0 3.007 3.008h6.62a3.011 3.011 0 0 0 3.008-3.008V6.22a1.897 1.897 0 0 0 1.263-1.779zM6.95 1.282h1.264a1.895 1.895 0 0 1 1.779 1.263H5.17a1.897 1.897 0 0 1 1.78-1.263zm3.942 16.425h-6.62a1.746 1.746 0 0 1-1.744-1.744V6.336h10.108v9.627a1.746 1.746 0 0 1-1.744 1.744zm2.376-12.635H1.895a.632.632 0 1 1 0-1.263h11.372a.632.632 0 1 1 0 1.263z' />
        <path d='M5.686 8.23a.632.632 0 0 0-.632.633v6.317a.632.632 0 1 0 1.264 0V8.863a.632.632 0 0 0-.632-.632zM9.476 8.23a.632.632 0 0 0-.631.633v6.317a.632.632 0 1 0 1.263 0V8.863a.632.632 0 0 0-.632-.632z' />
      </g>
    </svg>
  )
}
export default DeleteIcon
