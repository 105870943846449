import { Skeleton, useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'

interface IGridSkeleton {
  width: string
  height: string
  count: number
  xs: number
  sm: number
  md: number
  lg: number
  xl: number
}
// eslint-disable-next-line react/prop-types
const GridSkeleton: React.FC<IGridSkeleton> = ({ width, height, count, xs, sm, md, lg, xl }) => {
  const theme = useTheme()
  return (
    <Box mt={2}>
      <Grid container columnSpacing={{ xs: '1rem' }}>
        {Array.from(Array(count)).map((_, index) => (
          <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl} mt={1} key={index}>
            <Skeleton
              width={width}
              height={height}
              animation='pulse'
              sx={{ bgcolor: theme.palette.facilityList.colorThree }}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}
export default GridSkeleton
