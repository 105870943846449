/* eslint-disable camelcase */
import AddIcon from '@mui/icons-material/Add'
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import { useState } from 'react'
import { useAppSelector } from '../../../_helpers/hooks'
const FAQSection = () => {
  const theme = useTheme()
  const [expanded, setExpanded] = useState<string | false>(false)
  const translatedInput = useAppSelector((state) => state?.translation.translatedData)
  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }
  const panels = [
    {
      id: 'panel1',
      title: translatedInput?.landingScreen?.faqTitle1,
      content: translatedInput?.landingScreen?.faqContent1,
    },
    {
      id: 'panel2',
      title: translatedInput?.landingScreen?.faqTitle2,
      content: translatedInput?.landingScreen?.faqContent2,
    },
    {
      id: 'panel3',
      title: translatedInput?.landingScreen?.faqTitle3,
      content: translatedInput?.landingScreen?.faqContent3,
    },

    {
      id: 'panel4',
      title: translatedInput?.landingScreen?.faqTitle4,
      content: translatedInput?.landingScreen?.faqContent4,
    },
    {
      id: 'panel5',
      title: translatedInput?.landingScreen?.faqTitle5,
      content: translatedInput?.landingScreen?.faqContent5,
    },
    {
      id: 'panel6',
      title: translatedInput?.landingScreen?.faqTitle6,
      content: translatedInput?.landingScreen?.faqContent6,
    },
  ]
  const accordionStyle = {
    border: `solid 0.063rem ${theme.palette.tab.contrastText}`,
    borderRadius: '1rem',
    marginBottom: '0.625rem',
    minHeight: '4.375rem',
    boxShadow: 'none',

    '&:first-of-type': {
      borderRadius: '1rem',
    },
    '&:last-of-type': {
      borderRadius: '1rem',
    },

    '& .MuiPaper-root.MuiAccordion-root': {
      boxShadow: 'none',
    },

    '&:before': {
      display: 'none',
    },
  }

  return (
    <Box
      mt={5}
      mb={10}
      p={{ xs: 4, sm: 10 }}
      maxWidth={'100rem'}
      margin='0 auto'
      justifyContent={'center'}
      display={'flex'}
    >
      <Box>
        <Typography
          variant='h2'
          sx={{ mb: { xs: 1, sm: 2 }, color: 'iconColors.main' }}
          fontSize={'2.5rem'}
          lineHeight={'3.375rem'}
          fontWeight={600}
        >
          {translatedInput?.landingScreen?.faqHeader1}{' '}
          <span style={{ color: theme.palette.publicPages.contrastText }}>
            {translatedInput?.landingScreen?.faqHeader2}
          </span>{' '}
        </Typography>
        <Stack
          direction='row'
          spacing={{ md: 2 }}
          marginTop={{ xs: 2, sm: '5rem' }}
          display={{ xs: 'inline-block', md: 'flex' }}
        >
          {['left', 'right'].map((side, idx) => (
            <Box key={side} sx={{ flex: 1 }}>
              {panels
                .slice(idx * (panels.length / 2), idx * (panels.length / 2) + panels.length / 2)
                .map((panel) => (
                  <Accordion
                    key={panel.id}
                    expanded={expanded === panel.id}
                    onChange={handleChange(panel.id)}
                    sx={accordionStyle}
                  >
                    <AccordionSummary
                      aria-controls={`${panel.id}-content`}
                      id={`${panel.id}-header`}
                      expandIcon={
                        expanded === panel.id ? (
                          <RemoveOutlinedIcon
                            sx={{
                              color: theme.palette.publicPages.contrastText,
                              marginBottom: '1.25rem',
                            }}
                          />
                        ) : (
                          <AddIcon
                            sx={{
                              color: theme.palette.publicPages.contrastText,
                              margin: '1.25rem 0 1.25rem 0',
                            }}
                          />
                        )
                      }
                      sx={{
                        '& .MuiAccordionSummary-root': {
                          minHeight: '0.625rem',
                        },
                        [`{${panel.id}-header} .MuiAccordionSummary-content`]: {
                          margin: expanded === panel.id ? '1.25rem 0 0 0' : 0,
                        },
                        textAlign: 'left',
                      }}
                    >
                      <Typography
                        sx={{
                          color:
                            expanded === panel.id
                              ? 'publicPages.contrastText'
                              : 'publicPages.colorDivider',
                        }}
                      >
                        {panel.title}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ textAlign: 'left' }}>
                      <Typography
                        fontSize={'1rem'}
                        lineHeight={'1.563rem'}
                        fontWeight={300}
                        color={'publicPages.main'}
                      >
                        {panel.content}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                ))}
            </Box>
          ))}
        </Stack>
      </Box>
    </Box>
  )
}

export default FAQSection
