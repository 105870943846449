import { IIcon } from './iconConstants'
const ImageUploadIcon = (props: IIcon) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox='0 0 34 31'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <title>Shape</title>
      <desc>Created with Sketch.</desc>
      <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g
          id='20_Settings_Profile_Business2'
          transform='translate(-1072.000000, -418.000000)'
          fill={props.fill}
          fillRule='nonzero'
        >
          <g id='Group-5-Copy' transform='translate(888.000000, 317.000000)'>
            <g id='Bitmap' transform='translate(0.000000, 37.000000)'>
              <path
                d='M213.363636,82.6 L213.363636,87.25 L218,87.25 L218,90.35 L213.363636,90.35 L213.363636,95 L210.272727,95 L210.272727,90.35 L205.636364,90.35 L205.636364,87.25 L210.272727,87.25 L210.272727,82.6 L213.363636,82.6 Z M213.376,64 C214.222909,64 214.909091,64.68975 214.909091,65.53915 L214.909091,80.0301 C213.916341,79.6783985 212.871045,79.4991271 211.818182,79.5 L211.818182,67.1 L187.090909,67.1 L187.092455,88.8 L201.452818,74.39585 C202.002708,73.8425391 202.877432,73.7867111 203.492818,74.26565 L203.636545,74.3974 L209.116727,79.8999 C206.695336,80.6399147 204.680583,82.3401129 203.539566,84.6063225 C202.398549,86.872532 202.230363,89.5079378 203.074,91.90155 L185.533091,91.9 C184.686138,91.8991446 184,91.2102941 184,90.36085 L184,65.53915 C184.005885,64.6921611 184.688592,64.0067555 185.533091,64 L213.376,64 Z M193.272727,70.2 C194.979789,70.2 196.363636,71.5879173 196.363636,73.3 C196.363636,75.0120827 194.979789,76.4 193.272727,76.4 C191.565665,76.4 190.181818,75.0120827 190.181818,73.3 C190.181818,71.5879173 191.565665,70.2 193.272727,70.2 Z'
                id='Shape'
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
export default ImageUploadIcon
