import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../_store/store'
import { ILongPressStatus } from '../_models/longPressInterface'
const initialState: ILongPressStatus = {
  longPressStatus: false,
}

export const longPressSlice = createSlice({
  name: 'longpress',
  initialState,
  reducers: {
    setLongPressStatus: (state, action) => {
      return {
        ...state,
        longPressStatus: action.payload,
      }
    },
  },
})
export const { setLongPressStatus } = longPressSlice.actions
export const longPressState = (state: RootState) => state.longPress

export default longPressSlice.reducer
