import React, { FocusEvent, useEffect, useState } from 'react'
import PhoneInput from 'react-phone-input-2'
import { useAppSelector } from '../../_helpers/hooks'
import './phoneInput.scss'
import { COMMON } from '../../_constants'

interface ICountryData {
  countryCode: string
  dialCode: string
  format: string
  name: string
}

interface IPhoneField {
  name: string
  value: string | undefined
  isValid?: boolean
  disabled?: boolean
  onBlur: (e: FocusEvent<HTMLInputElement>) => void
  onChange: (
    value: string,
    data: Record<string, never> | ICountryData,
    event: React.ChangeEvent<HTMLInputElement>,
    formattedValue: string,
  ) => void
}

interface IPhoneNumberInput {
  phoneField: IPhoneField
  style: React.CSSProperties
  isMandatory: boolean
  isoCode?: string
}

const PhoneNumberInput = ({ phoneField, style, isMandatory, isoCode }: IPhoneNumberInput) => {
  const translatedInput = useAppSelector((state) => state?.translation.translatedData)
  const [internalValue, setInternalValue] = useState<string | undefined>(phoneField.value)
  useEffect(() => {
    setInternalValue(phoneField.value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneField.value])

  const handleChange = (
    value: string,
    data: ICountryData,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setInternalValue(value)
    phoneField.onChange(value, data, event, value)
  }

  return (
    <PhoneInput
      {...phoneField}
      value={internalValue}
      country={isoCode?.toLowerCase() || COMMON.DEFAULT_ISO_CODE}
      placeholder={translatedInput?.common?.phoneLabel}
      specialLabel={`${translatedInput?.common?.phoneLabel}${isMandatory ? '*' : ''} `}
      defaultErrorMessage={translatedInput?.common?.defaultPhoneError}
      inputStyle={style}
      inputProps={{
        required: true,
        autoFocus: false,
      }}
      disabled={phoneField.disabled}
      disableCountryGuess
      countryCodeEditable={false}
      onChange={handleChange}
    />
  )
}

export default PhoneNumberInput
