import { Divider, Link, useTheme } from '@mui/material'
import Box from '@mui/material/Box'

import IconButton from '@mui/material/IconButton'

import Typography from '@mui/material/Typography'

import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../_helpers/hooks'

import { getUserTypeFromToken } from '../../../_helpers/cookie'
import { FacebookIcon, TwitterIcon } from '../../../components/icons'
import LinkedInIcon from '../../../components/icons/linkedInIcon'
import Logo from '../../../images/logo.svg'
import { logout } from '../../../slices/authenticationSlice'
import { footerLinks } from './item'
const Footer = () => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const loggedInUserType = getUserTypeFromToken()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()
  const CopyRight = () => {
    const translatedInput = useAppSelector((state) => state?.translation.translatedData)
    return (
      <Typography variant='subtitle2' color='facilityList.contrastText' fontSize={13}>
        {'© '}
        {new Date().getFullYear()} {translatedInput?.landingScreen?.copyRightText}
        {'.'}
      </Typography>
    )
  }
  const goToDashboard = () => {
    document.body.classList.remove('no-scroll')
    setOpen(false)
    if (loggedInUserType === 'business') {
      navigate('/admin/dashboard')
    } else if (loggedInUserType === 'customer') {
      navigate('/customer/business')
    }
  }
  const handleLinkClick = async (label: string) => {
    await dispatch(logout())
    switch (label) {
      case 'Contact':
        navigate('/contact-us')
        break
      case 'FAQ':
        navigate('/faq')
        break
      case 'Blog':
        navigate('/coming-soon')
        break
    }
  }
  return (
    <>
      <Divider />
      <Box ml={{ xs: 4, md: 8 }} mr={{ xs: 2, md: 6 }} mt={2} mb={2}>
        <Box
          display={{ xs: 'flex' }}
          justifyContent={'space-between'}
          sx={{
            [theme.breakpoints.down(400)]: {
              display: 'block',
            },
          }}
        >
          <Box
            component='div'
            sx={{
              flexGrow: 1,
              display: { sm: 'block' },
              textAlign: { xs: 'left', md: 'left', lg: 'left' },
              cursor: 'pointer',
            }}
          >
            <img src={Logo} alt='' onClick={goToDashboard} />
          </Box>
          <Box display={'flex'}>
            <IconButton
              onClick={() => {
                window.open('https://www.facebook.com/slashQapp/', '_blank', 'noopener,noreferrer')
              }}
            >
              <FacebookIcon
                fill={theme.palette.primary.main}
                width='28'
                height='28'
                backgroundColor={theme.palette.facilityList.colorOne}
              />
            </IconButton>
            <IconButton
              sx={{ ml: 1 }}
              onClick={() => {
                window.open('https://twitter.com/slashQapp', '_blank', 'noopener,noreferrer')
              }}
            >
              <TwitterIcon
                width='28'
                height='28'
                fill={theme.palette.facilityList.colorOne}
                backgroundColor={theme.palette.primary.main}
                stoke={theme.palette.primary.main}
              />
            </IconButton>
            <IconButton
              sx={{ ml: 1 }}
              onClick={() => {
                window.open(
                  'https://www.linkedin.com/company/slashqapp',
                  '_blank',
                  'noopener,noreferrer',
                )
              }}
            >
              <LinkedInIcon
                fill={theme.palette.primary.main}
                width='28'
                height='28'
                stoke={theme.palette.facilityList.colorOne}
              />
            </IconButton>
          </Box>
        </Box>
        <Box display={{ sm: 'flex' }} justifyContent={'space-between'}>
          <CopyRight />
          <Box display={{ sm: 'flex' }} mt={{ xs: 1, sm: 0 }}>
            {footerLinks.map((item, index) => (
              <Link ml={4} key={index}>
                <Typography
                  onClick={() => {
                    handleLinkClick(item.label)
                  }}
                  variant='body2'
                  color='facilityList.contrastText'
                  fontSize={13}
                >
                  {item.label}
                </Typography>
              </Link>
            ))}
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default Footer
