import { IIcon } from './iconConstants'
const PlusInCircleIcon = (props: IIcon) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox='0 0 45 45'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <defs>
        <filter
          x='-38.7%'
          y='-29%'
          width='177.4%'
          height='177.4%'
          filterUnits='objectBoundingBox'
          id='9mjk8ksn3a'
        >
          <feOffset dy='3' in='SourceAlpha' result='shadowOffsetOuter1' />
          <feGaussianBlur stdDeviation='3.5' in='shadowOffsetOuter1' result='shadowBlurOuter1' />
          <feColorMatrix
            values='0 0 0 0 0.914317255 0 0 0 0 0.807379769 0 0 0 0 0.807379769 0 0 0 1 0'
            in='shadowBlurOuter1'
          />
        </filter>
        <path
          d='M15.4 0h.2C24.105 0 31 6.895 31 15.4v.2C31 24.105 24.105 31 15.6 31h-.2C6.895 31 0 24.105 0 15.6v-.2C0 6.895 6.895 0 15.4 0z'
          id='cesxzx2ejb'
        />
      </defs>
      <g fill='none' fillRule='evenodd'>
        <g transform='translate(7 4)'>
          <use fill='#000' filter='url(#9mjk8ksn3a)' xlinkHref='#cesxzx2ejb' />
          <use fill={props.backgroundColor} xlinkHref='#cesxzx2ejb' />
        </g>
        <path
          d='m23.43 19.57-.01-4.602a.42.42 0 0 0-.43-.43.42.42 0 0 0-.43.43l.01 4.602h-4.612a.42.42 0 0 0-.43.43c0 .246.194.44.43.43l4.601.01.01 4.602c0 .246.195.44.431.43a.442.442 0 0 0 .307-.123.442.442 0 0 0 .123-.307V20.43h4.612a.442.442 0 0 0 .307-.123.442.442 0 0 0 .123-.307.42.42 0 0 0-.43-.43H23.43z'
          stroke={props.stoke}
          strokeWidth='.84'
          fill={props.fill}
          fillRule='nonzero'
        />
      </g>
    </svg>
  )
}
export default PlusInCircleIcon
