import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../_store/store'
const initialState = {
  showToast: false,
  toastType: '',
  toastMessage: [],
}

export const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    successToast: (state, action) => {
      ;(state.showToast = true),
        (state.toastMessage = action.payload),
        (state.toastType = 'success')
    },
    errorToast: (state, action) => {
      ;(state.showToast = true), (state.toastMessage = action.payload), (state.toastType = 'error')
    },
    warningToast: (state, action) => {
      ;(state.showToast = true),
        (state.toastMessage = action.payload),
        (state.toastType = 'warning')
    },
    hideToast: (state) => {
      state.showToast = false
    },
    infoToast: (state, action) => {
      state.showToast = true
      state.toastMessage = action.payload
      state.toastType = 'information'
    },
  },
})
export const { successToast, errorToast, warningToast, infoToast, hideToast } = toastSlice.actions
export const toastData = (state: RootState) => state.toast

export default toastSlice.reducer
