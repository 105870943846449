import { IIcon } from './iconConstants'
const MailVerifyIcon = (props: IIcon) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox='0 0 61 34'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M59.58 11.333h-8.51V4.25A4.256 4.256 0 0 0 46.812 0H4.256A4.256 4.256 0 0 0 0 4.25v25.5A4.256 4.256 0 0 0 4.256 34h42.557a4.256 4.256 0 0 0 4.256-4.25V25.5h2.837c.78 0 1.418-.638 1.418-1.417s-.638-1.416-1.418-1.416h-7.093c-.78 0-1.418.637-1.418 1.416 0 .78.638 1.417 1.418 1.417h1.419v4.25c0 .779-.639 1.416-1.419 1.416H4.256c-.78 0-1.418-.637-1.418-1.416V4.838l17.682 17.66a7.07 7.07 0 0 0 5.015 2.067 7.073 7.073 0 0 0 5.015-2.068L48.233 4.838v6.496h-1.419c-.78 0-1.418.637-1.418 1.416 0 .78.638 1.417 1.418 1.417h12.768c.78 0 1.418-.638 1.418-1.417s-.639-1.416-1.419-1.416zm-31.037 9.16a4.268 4.268 0 0 1-6.022 0L4.846 2.832h41.38l-17.683 17.66zm29.62-2.076c0 .779-.638 1.416-1.418 1.416h-9.93c-.78 0-1.419-.637-1.419-1.416 0-.78.639-1.417 1.419-1.417h9.93c.78 0 1.418.638 1.418 1.417z'
        fill={props.fill}
        fillRule='nonzero'
      />
    </svg>
  )
}
export default MailVerifyIcon
