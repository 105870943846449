import { IIcon } from './iconConstants'
const TickInSquareIcon = (props: IIcon) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox='0 0 16 16'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M.889 0H15.11c.491 0 .889.398.889.889V15.11c0 .491-.398.889-.889.889H.89A.889.889 0 0 1 0 15.111V.89C0 .398.398 0 .889 0zm.889 1.778v12.444h12.444V1.778H1.778zm5.335 9.778-3.77-3.772 1.256-1.257 2.514 2.514 5.029-5.028 1.257 1.257-6.286 6.286z'
        fill={props.fill}
        fillRule='nonzero'
      />
    </svg>
  )
}
export default TickInSquareIcon
