import { IIcon } from './iconConstants'
const UserWithTickIcon = (props: IIcon) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox='0 0 50 50'
      fill={props.fill}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5 44.36v-4.572a9.144 9.144 0 0 1 9.143-9.143h8M23.298 23.787a9.143 9.143 0 1 0 0-18.287 9.143 9.143 0 0 0 0 18.287zM45.008 34.38v.841a9.143 9.143 0 1 1-5.422-8.357'
        stroke={props.stoke}
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='m45.01 27.907-9.142 9.152-2.743-2.743'
        stroke={props.stoke}
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
export default UserWithTickIcon
