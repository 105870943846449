import { IIcon } from './iconConstants'
const CrossIcon = (props: IIcon) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox='0 0 10 10'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5.76 5 9.82.924a.525.525 0 0 0 0-.761.525.525 0 0 0-.761 0L5 4.239.924.163a.525.525 0 0 0-.761 0 .525.525 0 0 0 0 .76L4.221 5 .163 9.076a.525.525 0 0 0 0 .761.552.552 0 0 0 .38.163.553.553 0 0 0 .381-.163L5 5.761l4.076 4.076a.552.552 0 0 0 .38.163.553.553 0 0 0 .381-.163.525.525 0 0 0 0-.76L5.761 5z'
        fill={props.fill}
        fillRule='nonzero'
      />
    </svg>
  )
}
export default CrossIcon
