import { IIcon } from './iconConstants'
const CircleIcon = (props: IIcon) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox='0 0 12 12'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle
        cx='207'
        cy='18'
        r='6'
        transform='translate(-201 -12)'
        fill={props.fill}
        fillRule='evenodd'
      />
    </svg>
  )
}
export default CircleIcon
