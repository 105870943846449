export default function Backdrop(theme: any) {
  return {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          // background: 'rgba(255,255,255, 0.9)',
        },
      },
    },
  }
}
