// ==============================|| COMMON PAGES ROUTE ||============================== //

import { lazy } from 'react'
import CommonPageLayout from '../layout/commonPageLayout'
import LazyLoad from './utils/LazyLoad'

const AccessDeniedPage = LazyLoad(lazy(() => import('../pages/common/accessDeniedPage')))
const PageNotFoundPage = LazyLoad(lazy(() => import('../pages/common/pageNotFoundPage')))

// ==============================|| COMMON PAGES PAGE NOT FOUND,ACCESS DENIED WITH HEADER AND FOOTER ||============================== //

const commonPageRoutes = {
  path: '/',
  element: <CommonPageLayout />,
  children: [
    {
      path: 'access-denied',
      element: <AccessDeniedPage />,
    },
    {
      path: '*',
      element: <PageNotFoundPage />,
    },
  ],
}

export default commonPageRoutes
