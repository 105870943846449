import { useMemo } from 'react'

const useGetProgressBarColor = (percentage: number): string => {
  const getColor = (): string => {
    if (percentage === 100) {
      return '#ff4545'
    } else if (percentage >= 80) {
      return '#ff4545'
    } else if (percentage >= 50) {
      return '#fa941d'
    } else if (percentage < 0) {
      return '#0000001f'
    } else {
      return '#4393f1'
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => getColor(), [percentage])
}

export default useGetProgressBarColor
