import { IBusinessInformation, IProfileData } from '../_models/businessInterface'
import { IFilters } from '../_models/commonInterface'
import { _delete, get, post, postFileData } from './apiServices'

const getBusinessDetails = async () => {
  const response = await get('business/details')
  return response
}

const postBusinessDetails = async (updatedBusinessDetails: IBusinessInformation) => {
  const response = await post('business/update-details', updatedBusinessDetails)
  return response
}

const updateBusinessCoverImage = async (image: File) => {
  const response = await postFileData('business/cover-image', { image: image })
  return response
}
const deleteCoverImage = async () => {
  const response = await _delete('business/cover-image')
  return response
}

// For Customer
const getBusinessList = async (filters: IFilters) => {
  const response = await get('/customer/businesses', filters)
  return response
}
const getRecentBusiness = async () => {
  const response = await get('/customer/recent-bookings')
  return response
}

const switchBusinessList = async () => {
  const response = await get('/switch-business/list')
  return response
}

const switchProfile = async (profileData: IProfileData) => {
  const response = await post('/switch-business', profileData)
  return response
}

const businessService = {
  getBusinessDetails,
  postBusinessDetails,
  updateBusinessCoverImage,
  deleteCoverImage,
  getBusinessList,
  getRecentBusiness,
  switchBusinessList,
  switchProfile,
}
export default businessService
