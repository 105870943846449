/* eslint-disable @typescript-eslint/no-non-null-assertion */
import axios from 'axios'
import { History } from '../_helpers/history'
import { handleMessages } from '../_helpers/utilities'
import { store } from '../_store/store'
import { logout } from '../slices/authenticationSlice'
import { stopLoader } from '../slices/loaderSlice'
import { errorToast, hideToast } from '../slices/toastSlice'
import { updateUserDetails } from '../slices/userSlice'
import { clearTokenFromCookie, getAccessTokenFromCookie } from './../_helpers/cookie'
import {
  closePricingPage,
  getPricingDetails,
  setProfileUpdatedStatus,
} from './../slices/pricingSlice'
import { RESET_STATE } from '../_store/actions'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const handleResponse = (response: any) => {
  if (response.status === 404) {
    alert('API request failed: Not Found')
  }
  if (response.status === 403) {
    store.dispatch(hideToast())
    store.dispatch(stopLoader())
    History.navigate('access-denied')
    return Promise.reject()
  }
  if (response.status === 401) {
    // store.dispatch(logout()) removing this to avoid multiple logout API calls, causing 'too many requests' error.
    // instead of logout API call just clearing the token from cookies
    clearTokenFromCookie()
    // removing window.location.reload() to fix the  multiple reloading in homepage and  Dispatch the reset state action to clear the state
    store.dispatch({ type: RESET_STATE })
    History.navigate('/')
    return Promise.reject(response.data)
  }
  if (response?.data?.token_status === 'unauthorized') {
    History.navigate('/confirm-logout')
    return response.data
  }
  if (
    response.data?.active_plan === false &&
    response.data?.has_subscription_add_permission === true
  ) {
    store.dispatch(getPricingDetails())
    if (response.data?.data) {
      return response.data
    } else {
      return Promise.reject()
    }
  }
  if (
    response.data?.active_plan === false &&
    response.data?.has_subscription_add_permission === false
  ) {
    store.dispatch(
      errorToast(handleMessages('Please contact the business admin for using the services.')),
    )
    store.dispatch(logout())
  }
  if (response.data?.isProfileUpdated === false) {
    store.dispatch(stopLoader())
    store.dispatch(closePricingPage())
    store.dispatch(setProfileUpdatedStatus())

    // this check ensures permissions are set before navigating to the protected page.
    if (response.data.activePermissions) {
      store.dispatch(updateUserDetails(response.data?.data))
    }
    History.navigate('/admin/settings/business-settings')
  }
  if (response.data.status === 'success') {
    return response.data
  }
  if (response.data?.isEmailVerified === false) {
    if (response?.data?.email) {
      History.navigate('/email-verify-user', { state: { email: response.data.email } })
    } else {
      store.dispatch(logout())
      History.navigate('/email-confirm')
    }
    return Promise.reject()
  }

  return Promise.reject(response.data)
}

export const axiosInstance = async function () {
  //  const token = await getAccessToken()?.replaceAll('"', '')
  const token = getAccessTokenFromCookie()
  return axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
      Authorization: `Bearer ${token}`,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      baseURL: process.env.REACT_APP_DOMAIN_URL!,
      revamp: 1,
    },
  })
}

export const axiosFileInstance = async function () {
  //  const token = await getAccessToken()?.replaceAll('"', '')
  const token = getAccessTokenFromCookie()
  return axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      baseURL: process.env.REACT_APP_DOMAIN_URL!,
      revamp: 1,
    },
  })
}
