import { IIcon } from './iconConstants'
const SVGIcon = (props: IIcon) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox='0 0 14 15'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='m1.667 6.75 1.666-1.666 3.584 4.583L9.833 6.75l2.5 2.5V1.667H1.667V6.75zm0 2.357v4.226H4.25l1.488-2.487L3.333 7.44 1.667 9.107zm3.94 4.226h6.726v-1.726l-2.5-2.5-4.226 4.226zM.833 0h12.334c.46 0 .833.373.833.833v13.334c0 .46-.373.833-.833.833H.833A.833.833 0 0 1 0 14.167V.833C0 .373.373 0 .833 0zm8.584 5.833a1.25 1.25 0 1 1 0-2.5 1.25 1.25 0 0 1 0 2.5z'
        fill={props.fill}
        fillRule='nonzero'
        opacity='.7'
      />
    </svg>
  )
}
export default SVGIcon
