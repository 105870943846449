import { IIcon } from './iconConstants'
const DeleteBgFillIcon = (props: IIcon) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox='0 0 40 40'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g fill='none' fillRule='evenodd'>
        <circle fill={props.backgroundColor} cx='20' cy='20' r='20' />
        <g fill={props.fill} fillRule='nonzero'>
          <path d='M26.748 14.437a1.772 1.772 0 0 0-1.772-1.772h-1.831a2.959 2.959 0 0 0-2.895-2.362H19.07a2.958 2.958 0 0 0-2.894 2.362h-1.832a1.768 1.768 0 0 0-.59 3.436v9.11a2.815 2.815 0 0 0 2.812 2.812h6.19a2.815 2.815 0 0 0 2.812-2.811V16.1a1.773 1.773 0 0 0 1.181-1.664zm-7.679-2.953h1.181a1.772 1.772 0 0 1 1.664 1.181h-4.508a1.773 1.773 0 0 1 1.663-1.181zm3.686 15.358h-6.19a1.633 1.633 0 0 1-1.63-1.63v-9.003h9.45v9.002a1.633 1.633 0 0 1-1.63 1.63zm2.22-11.814H14.345a.59.59 0 1 1 0-1.181h10.632a.59.59 0 1 1 0 1.181z' />
          <path d='M17.888 17.982a.59.59 0 0 0-.591.59v5.907a.59.59 0 1 0 1.181 0v-5.907a.59.59 0 0 0-.59-.59zM21.432 17.982a.59.59 0 0 0-.591.59v5.907a.59.59 0 1 0 1.181 0v-5.907a.59.59 0 0 0-.59-.59z' />
        </g>
      </g>
    </svg>
  )
}
export default DeleteBgFillIcon
