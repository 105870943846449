import { IIcon } from './iconConstants'
const FloorPlanIcon = (props: IIcon) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox='0 0 20 20'
      opacity={props.opacity ? props.opacity : '1'}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M17.071 1H2.93A1.929 1.929 0 0 0 1 2.929V17.07C1 18.137 1.863 19 2.929 19H17.07A1.929 1.929 0 0 0 19 17.071V2.93A1.929 1.929 0 0 0 17.071 1zm.643 16.071a.643.643 0 0 1-.643.643H8.714V14.5h6.429a.643.643 0 1 0 0-1.286H8.07a.643.643 0 0 0-.642.643v3.857h-4.5a.643.643 0 0 1-.643-.643V8.714h5.143V10a.643.643 0 1 0 1.285 0V6.143a.643.643 0 1 0-1.285 0v1.286H2.286v-4.5c0-.355.288-.643.643-.643h9v7.071c0 .355.287.643.642.643h2.572a.643.643 0 1 0 0-1.286h-1.929V2.286h3.857c.355 0 .643.288.643.643V17.07z'
        fill={props.fill}
        fillRule='nonzero'
        stroke={props.fill}
        strokeWidth='.5'
      />
    </svg>
  )
}
export default FloorPlanIcon
