import { IIcon } from './iconConstants'
const UserSettingsIcon = (props: IIcon) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox='0 0 18 21'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g fill='none' fillRule='evenodd'>
        <path d='M-3 0h22v22H-3z' />
        <path
          d='M8 12.833v1.834a5.5 5.5 0 0 0-5.5 5.5H.667A7.333 7.333 0 0 1 8 12.833zm0-.916a5.498 5.498 0 0 1-5.5-5.5c0-3.04 2.461-5.5 5.5-5.5s5.5 2.46 5.5 5.5c0 3.038-2.461 5.5-5.5 5.5zm0-1.834a3.666 3.666 0 0 0 3.667-3.666A3.666 3.666 0 0 0 8 2.75a3.666 3.666 0 0 0-3.667 3.667A3.666 3.666 0 0 0 8 10.083zm2.379 7.161a3.218 3.218 0 0 1 0-1.487l-.91-.526.917-1.587.91.525c.364-.346.806-.6 1.287-.744v-1.05h1.834v1.05c.487.144.927.403 1.288.744l.909-.525.917 1.587-.91.526c.116.488.116.998 0 1.486l.91.526-.917 1.587-.91-.525c-.364.346-.806.6-1.287.744v1.05h-1.834v-1.05a3.205 3.205 0 0 1-1.288-.744l-.909.525-.917-1.587.91-.525zm3.121.631a1.375 1.375 0 1 0 0-2.75 1.375 1.375 0 0 0 0 2.75z'
          fill={props.fill}
          fillRule='nonzero'
        />
      </g>
    </svg>
  )
}
export default UserSettingsIcon
