import { Box, useTheme } from '@mui/material'
import React from 'react'
import { LineWave } from 'react-loader-spinner'
interface ILoader {
  height?: string
  width?: string
  color?: string
}
const LineWaveLoader = ({ height, width, color }: ILoader) => {
  const theme = useTheme()
  return (
    <Box sx={{ textAlign: 'center', justifyContent: 'center', display: 'flex' }}>
      <LineWave
        height={height ? height : '150'}
        width={width ? width : '150'}
        color={color ? color : theme.palette.primary.main}
        ariaLabel='loading'
      />
    </Box>
  )
}

export default LineWaveLoader
