import { IIcon } from './iconConstants'
const PlusInSquareIcon = (props: IIcon) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox='0 0 17 17'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M.944 0h15.112c.521 0 .944.423.944.944v15.112a.944.944 0 0 1-.944.944H.944A.944.944 0 0 1 0 16.056V.944C0 .423.423 0 .944 0zm.945 1.889V15.11H15.11V1.89H1.89zm5.667 5.667V3.778h1.888v3.778h3.778v1.888H9.444v3.778H7.556V9.444H3.778V7.556h3.778z'
        fill={props.fill}
        fillRule='nonzero'
      />
    </svg>
  )
}
export default PlusInSquareIcon
