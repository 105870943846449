/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { handleMessages } from '../_helpers/utilities'
import { IPusherConfigData, IScannerBookingData } from '../_models/pusherInterface'
import scannerService from '../_services/scannerService'
import { RootState } from '../_store/store'
import { startLoader, stopLoader } from './loaderSlice'
import { errorToast, successToast } from './toastSlice'
import { openScannerModal } from './dashboardSlice'

const initialState: IPusherConfigData = {
  pusherAppKey: '',
  pusherAppCluster: '',
  pusherChannel: [],
  pusherWsHost: '',
  pusherEventName: '',
  pusherWssPort: '',
  scannerFacility: {
    facilities: [],
  },
}

export const getPusherConfigData = createAsyncThunk(
  '/getPusherConfigData',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(startLoader())
    try {
      const response = await scannerService.getPusherConfigDetails()
      dispatch(stopLoader())
      return response
    } catch (err: any) {
      dispatch(stopLoader())
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)

export const getAllScannerFacilities = createAsyncThunk(
  '/getAllScannerFacilities',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(startLoader())
    try {
      const response = await scannerService.getAllScannerFacilities()
      dispatch(stopLoader())
      dispatch(openScannerModal())
      return response
    } catch (err: any) {
      dispatch(stopLoader())
      return rejectWithValue(err)
    }
  },
)
export const completeBooking = createAsyncThunk(
  '/completeBooking',
  async (BookingData: IScannerBookingData, { dispatch, rejectWithValue }) => {
    dispatch(startLoader())
    try {
      const response = await scannerService.completeBooking(BookingData)
      dispatch(successToast(handleMessages(response.message)))
      dispatch(stopLoader())
      return response
    } catch (err: any) {
      dispatch(stopLoader())
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)

export const scannerSlice = createSlice({
  name: 'scanner',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPusherConfigData.fulfilled, (state, action) => {
        ;(state.pusherAppKey = action.payload.data.pusher_app_key),
          (state.pusherAppCluster = action.payload.data.pusher_app_cluster),
          (state.pusherChannel = action.payload.data.pusher_channel),
          (state.pusherWsHost = action.payload.data.pusher_ws_host),
          (state.pusherEventName = action.payload.data.pusher_event_name),
          (state.pusherWssPort = action.payload.data.pusher_wss_port)
      })
      .addCase(getAllScannerFacilities.fulfilled, (state, action) => {
        state.scannerFacility = action.payload.data
      })
  },
})
export const scannerData = (state: RootState) => state.scanner
const scannerReducer = scannerSlice.reducer
export default scannerReducer
