const PERMISSIONS = {
  // BUSINESS SETTINGS

  VIEW_BUSINESS_PROFILE: 'business_profile_view',
  VIEW_BUSINESS_SETTINGS: 'business_settings_view',

  // ACCOUNT SETTINGS
  UPDATE_PROFILE_PASSWORD: 'profile_password_update',
  UPDATE_PROFILE: 'profile_update',
  ACCOUNT_DELETE: 'account_delete',
  // SUBSCRIPTION SETTINGS
  SUBSCRIPTION_VIEW: 'subscription_view',
  SUBSCRIPTION_ADD: 'subscription_add',
  BUSINESS_RESTRICTION_ACCESS: 'business_restrict_access',
  // VALIDATIONS
  VIEW_VALIDATIONS: 'business_view_validations',

  // BOOKING MANAGEMENT
  BOOKING_MANAGEMENT_VIEW: 'booking_management',
  BOOKING_MANAGEMENT_FACILITY_LIST: 'booking_management_facility_list',
  BOOKING_MANAGEMENT_FACILITY_START: 'booking_management_start_facility',
  BOOKING_MANAGEMENT_FACILITY_PAUSE: 'booking_management_pause_facility',
  BOOKING_MANAGEMENT_RESUME_FACILITY: 'booking_management_resume_facility',
  BOOKING_MANAGEMENT_STOP_FACILITY: 'booking_management_stop_facility',
  BOOKING_MANAGEMENT_STOP_FURTHER_BOOKING: 'booking_management_stop_further_bookings',
  BOOKING_MANAGEMENT_RESUME_FURTHER_BOOKING: 'booking_management_resume_further_bookings',
  BOOKING_MANAGEMENT_SEARCH: 'booking_management_search',

  BOOKING_MANAGEMENT_ANNOUNCE_TOKEN: 'booking_management_announce_token',
  BOOKING_MANAGEMENT_MARK_TOKEN_COMPLETE: 'booking_management_mark_token_complete',
  BOOKING_MANAGEMENT_MARK_TOKEN_PENDING: 'booking_management_mark_token_pending',
  BOOKING_MANAGEMENT_CANCEL_BOOKING: 'booking_management_cancel_booking',

  BOOKING_MANAGEMENT_VIEW_UPCOMING_BOOKING: 'booking_management_view_upcoming_booking',
  BOOKING_MANAGEMENT_VIEW_PENDING_BOOKING: 'booking_management_view_pending_bookings',
  BOOKING_MANAGEMENT_VIEW_COMPLETED_BOOKING: 'booking_management_view_completed_bookings',
  BOOKING_MANAGEMENT_VIEW_CANCELED_BOOKING: 'booking_management_view_cancelled_bookings',

  // DASHBOARD
  DASHBOARD_KPI_VIEW: 'dashboard_kpis_view',
  BOOKING_MAP_VIEW: 'bookings_map_view_view',

  // BUSINESS BOOKING
  BUSINESS_BOOKING_ADD: 'business_booking_add',
  BUSINESS_BOOKING_EDIT: 'business_booking_edit',
  BUSINESS_BOOKING_VIEW: 'business_booking_view',
  BUSINESS_BOOKING_DELETE: 'business_booking_delete',
  COMPLETE_FROM_BOOKINGS: 'complete_from_bookings',

  // FACILITY

  FACILITY_VIEW: 'facility_view',
  FACILITY_ADD: 'facility_add',
  FACILITY_EDIT: 'facility_edit',
  FACILITY_DELETE: 'facility_delete',

  // TOKEN BOARD

  TOKEN_BOARD_VIEW: 'token_board_view',
  TOKEN_BOARD_ADD: 'token_board_add',
  TOKEN_BOARD_EDIT: 'token_board_edit',
  TOKEN_BOARD_DELETE: 'token_board_delete',

  // FLOOR PLAN
  FLOOR_VIEW: 'floor_view',
  FLOOR_ADD: 'floor_add',
  FLOOR_EDIT: 'floor_edit',
  FLOOR_DELETE: 'floor_delete',
  FLOOR_CONNECT_FACILITIES: 'floor_connect_facilities',

  BUSINESS_SWITCH_DROPDOWN: 'business_switch_dropdown',
  FACILITY_CHANGE_STATUS: 'facility_change_status',

  // USER MANAGEMENT
  USER_MANAGEMENT_ROLES_VIEW: 'user_management_roles_view',
  USER_MANAGEMENT_ROLE_ADD: 'user_management_role_add',
  USER_MANAGEMENT_ROLE_EDIT: 'user_management_role_edit',
  USER_MANAGEMENT_ROLE_DELETE: 'user_management_role_delete',
  USER_MANAGEMENT_BUSINESS_USERS_VIEW: 'user_management_business_users_view',
  USER_MANAGEMENT_BUSINESS_USERS_ADD: 'user_management_business_users_invite',
  USER_MANAGEMENT_BUSINESS_USERS_EDIT: 'user_management_business_user_edit',
  USER_MANAGEMENT_BUSINESS_USERS_DELETE: 'user_management_business_user_delete',

  // SCANNER VIEW

  SCANNER_VIEW: 'scanner_view',
  COMPLETE_SCANNED_TOKEN: 'scanner_complete_token',

  // ###------CUSTOMER PERMISSIONS ------####
  // BOOKING PERMISSIONS
  CUSTOMER_BOOKING_VIEW: 'customer_booking_view',
  CUSTOMER_BOOKING_ADD: 'customer_booking_add',
  CUSTOMER_BOOKING_EDIT: 'customer_booking_edit',
  CUSTOMER_BOOKING_DELETE: 'customer_booking_delete',
  // PROFILE
  CUSTOMER_PROFILE_VIEW: 'customer_profile_view',

  // BOOK FOR OTHERS
  BOOK_FOR_OTHERS: 'book_for_others',
}

export default PERMISSIONS
