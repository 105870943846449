import { IIcon } from './iconConstants'
const SlidersIcon = (props: IIcon) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox='0 0 21 16'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g fill={props.fill} fillRule='nonzero' opacity={props.opacity ? props.opacity : '.8'}>
        <path d='M9.936 4.802a3.74 3.74 0 0 0 7.216 0h2.2a1.002 1.002 0 0 0 0-2.004h-2.208a3.74 3.74 0 0 0-7.217 0H1.045a1.002 1.002 0 0 0 0 2.004h8.89zm4.857-2.236A1.754 1.754 0 1 1 12.3 5.033a1.754 1.754 0 0 1 2.494-2.467zM10.458 11.072a3.736 3.736 0 0 0-7.214 0h-2.2a1.002 1.002 0 0 0 0 2.003h2.208a3.737 3.737 0 0 0 7.214 0h8.886a1.002 1.002 0 0 0 0-2.003h-8.894zm-4.855 2.236A1.755 1.755 0 1 1 8.1 10.84a1.755 1.755 0 0 1-2.496 2.47z' />
      </g>
    </svg>
  )
}
export default SlidersIcon
