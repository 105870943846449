import { IIcon } from './iconConstants'
const LayersIcon = (props: IIcon) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox='0 0 20 22'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='m18.435 14.69 1.258.755a.524.524 0 0 1 0 .897l-9.18 5.509a1.047 1.047 0 0 1-1.078 0l-9.18-5.509a.523.523 0 0 1 0-.897l1.257-.755 8.462 5.076 8.46-5.076zm0-4.92 1.258.755a.524.524 0 0 1 0 .898l-9.72 5.831-9.719-5.831a.523.523 0 0 1 0-.898l1.258-.755 8.462 5.077 8.46-5.077zM10.512.15l9.181 5.508a.524.524 0 0 1 0 .897l-9.72 5.832L.255 6.555a.523.523 0 0 1 0-.897L9.435.149a1.047 1.047 0 0 1 1.077 0zm-.538 2.117-6.399 3.84 6.399 3.839 6.398-3.84-6.398-3.839z'
        fill={props.fill}
        fillRule='nonzero'
      />
    </svg>
  )
}
export default LayersIcon
