import { Box, Grid, useTheme } from '@mui/material'
import ContentLoader from 'react-content-loader'

interface ITokenBoardSkeleton {
  xs: number
  sm: number
  md: number
  lg: number
  xl: number
}
// eslint-disable-next-line react/prop-types
const TokenBoardSkeleton: React.FC<ITokenBoardSkeleton> = ({ xs, sm, md, lg, xl }) => {
  const theme = useTheme()
  return (
    <Box>
      <Grid container columnSpacing={{ xs: '1rem' }} rowSpacing={{ xs: '1rem', sm: '0' }}>
        {Array.from(Array(2)).map((_, index) => (
          <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl} mt={1} key={index}>
            <Box
              sx={{
                border: 'solid 0.063rem ',
                borderColor: 'border.light',
                borderRadius: '0.625rem',
                padding: '1.063rem',
                height: '10rem',
              }}
            >
              <ContentLoader
                speed={2}
                width={'100%'}
                height={'100%'}
                viewBox='0 0 100% 100%'
                backgroundColor={theme.palette.facilityList.colorTwo}
                foregroundColor={theme.palette.facilityList.colorThree}
              >
                <rect x='10' y='1' rx='3' ry='3' width='30%' height='14' />
                <rect x='10' y='28' rx='3' ry='3' width='95%' height='8' />
                <rect x='10' y='60' rx='3' ry='3' width='50%' height='14' />
                <rect x='10' y='100' rx='3' ry='3' width='50%' height='12' />
              </ContentLoader>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default TokenBoardSkeleton
