import { ExpandLess, ExpandMore } from '@mui/icons-material'
import MenuIcon from '@mui/icons-material/Menu'
import {
  Divider,
  List,
  ListItem,
  Stack,
  Tab,
  TabProps,
  Tabs,
  TabsProps,
  styled,
  useTheme,
} from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import { PopperPlacementType } from '@mui/material/Popper'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../../_helpers/hooks'
import CustomPopperWithModifers from '../../../components/@extended/customPopper/popperWithModifiers'
import Logo from '../../../images/logo.svg'

import { useEffect } from 'react'
import { ITab } from '../../../_models/tabsInterface'
import { SuitcaseIcon, UserIcon } from '../../../components/icons'
import {
  fadeInAnimation,
  fadeInWithDelayAnimation,
} from '../../../pages/common/landingPage/animations'
import { headerTabs } from '../items'
import { isBusinessURL } from '../../../_helpers/routes'
interface IHeader {
  children: React.ReactNode
  handleDrawerToggle: () => void
  handleNavigate: (redirectionPath: string, state: Record<string, unknown>) => void
}

interface IUserType {
  title: string
  subtitle: string
  handleSelectUserType?: () => void
  logo: string
  color: string
}

const CustomTabs = styled(Tabs)<TabsProps>(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  paddingLeft: 0,
  paddingRight: 0,
  '& .indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    height: '.1875rem',
    '& > span': {
      width: '80%',
      backgroundColor: theme.palette.primary.main,
      height: '.1875rem',
    },
  },
  '& .flexContainer': {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'fit-content',
  },
}))

const CustomTab = styled(Tab)<TabProps>(({ theme }) => ({
  borderRadius: '0.625rem',
  minHeight: 'initial',
  '&.Mui-selected': {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontWeight: 500,
  },
  ...fadeInAnimation,
}))

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}
const UserType: React.FC<IUserType> = ({ title, subtitle, handleSelectUserType, logo, color }) => (
  <ListItem button onClick={handleSelectUserType}>
    <Stack direction='row' spacing={1} ml={2} pb={1}>
      {logo == 'customerIcon' && (
        <Box
          sx={{
            pl: 1,
            paddingRight: '0.188rem',
            paddingTop: '0rem !important',
            marginTop: '5% !important',
          }}
        >
          <UserIcon fill={color} width='24' height='24' />
        </Box>
      )}
      {logo == 'businessUserIcon' && (
        <Box
          sx={{
            pl: 1,
            paddingRight: '0.188rem',
            paddingTop: '0rem !important',
            marginTop: '3% !important',
          }}
        >
          <SuitcaseIcon fill={color} width='24' height='24' />
        </Box>
      )}

      <Stack>
        <Typography
          variant='h5'
          pt={1}
          sx={{
            fontFamily: 'Lexend !important',
          }}
        >
          {title}
        </Typography>
        <Typography
          color='secondary.dark'
          variant='subtitle1'
          sx={{
            fontFamily: 'Lexend !important',
            fontWeight: 400,
          }}
        >
          {subtitle}
        </Typography>
      </Stack>
    </Stack>
  </ListItem>
)

const PublicHeader: React.FC<IHeader> = ({ handleNavigate, handleDrawerToggle, children }) => {
  const navigate = useNavigate()
  const translatedInput = useAppSelector((state) => state?.translation.translatedData)
  const theme = useTheme()
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const [open, setOpen] = React.useState(false)
  const [placement, setPlacement] = React.useState<PopperPlacementType>()
  const [value, setValue] = React.useState(-1)
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }
  const handleClickTab = (id: number) => {
    document.body.classList.remove('no-scroll')
    const isBusiness = isBusinessURL()
    const baseBusinessUrl = `${window.location.protocol}//${process.env.REACT_APP_BUSINESS_URL}`
    let path = ''
    switch (id) {
      case 1:
        path = 'coming-soon'
        break
      case 2:
        path = 'coming-soon'
        break
      case 3:
        path = 'contact-us'
        break
      default:
        setOpen(false)
        break
    }

    const url = isBusiness ? `${baseBusinessUrl}/${path}` : `/${path}`
    isBusiness ? window.location.replace(url) : navigate(url)
    setOpen(false)
  }
  const handleClick =
    (newPlacement: PopperPlacementType) => (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget)
      setOpen((prev) => placement !== newPlacement || !prev)
      setPlacement(newPlacement)
    }
  const checkPath = () => {
    switch (window.location.pathname) {
      case '/contact-us':
        setValue(2)
        break
      case '/':
        setValue(-1)
        break
      default:
        break
    }
  }

  useEffect(() => {
    checkPath()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname])
  return (
    <>
      <CustomPopperWithModifers
        open={open}
        anchorEl={anchorEl}
        placement={placement}
        sx={{
          mt: 3,
          display: { xs: 'none', sm: 'block' },
          boxShadow: theme.customShadows.z2,
          width: 373,
          [theme.breakpoints.down('md')]: {
            maxWidth: 250,
          },
        }}
      >
        <List component='nav' aria-label='mailbox folders' sx={{ paddingTop: 0, paddingBottom: 0 }}>
          <UserType
            title={translatedInput?.landingScreen?.personalBtn}
            subtitle={translatedInput?.landingScreen?.personalInfoText}
            handleSelectUserType={() =>
              handleNavigate('/sign-up', { state: { userType: 'CUSTOMER' } })
            }
            logo={'customerIcon'}
            color={theme.palette.toggleButtonGroup.contrastText}
          />
          <Divider />
          <UserType
            title={translatedInput?.landingScreen?.businessAdminBtn}
            subtitle={translatedInput?.landingScreen?.businessAdminInfoText}
            handleSelectUserType={() =>
              handleNavigate('/sign-up', { state: { userType: 'BUSINESS' } })
            }
            logo={'businessUserIcon'}
            color={theme.palette.toggleButtonGroup.contrastText}
          />
        </List>
      </CustomPopperWithModifers>
      <Box
        sx={{
          display: 'flex',
        }}
      >
        <AppBar
          component='nav'
          sx={{
            p: 1,

            margin: '0 auto',
            left: 0,
            right: 0,
          }}
        >
          <Toolbar>
            <Box
              component='div'
              sx={{
                flexGrow: { xs: 1, lg: 0 },
                maxWidth: '90rem',
                ml: { lg: 12 },
                mr: { lg: 8 },
                display: { sm: 'block' },
                textAlign: { xs: 'left', sm: 'left', md: 'left', lg: 'left' },
                cursor: 'pointer',
                ...fadeInWithDelayAnimation,
              }}
              onClick={() => navigate('/')}
            >
              <img src={Logo} alt='' />
            </Box>
            <Box
              sx={{
                display: { xs: 'none', lg: 'flex' },
                flexGrow: 1,
                justifyContent: 'space-between',
              }}
            >
              <CustomTabs
                value={value}
                onChange={handleChange}
                aria-label='profile settings tabs'
                indicatorColor='primary'
                classes={{ indicator: 'indicator' }}
                TabIndicatorProps={{ children: <span /> }}
                sx={{ px: { sm: 0, md: 0, lg: 1 }, py: 2 }}
              >
                {headerTabs.map((item: ITab, index: number) => {
                  return (
                    <CustomTab
                      sx={{
                        backgroundColor: 'common.white',
                        color: 'secondary.main',
                        fontWeight: 'normal',
                        fontSize: '1rem',
                        mx: { sm: 0, md: 2, lg: 2 },
                      }}
                      key={index}
                      label={item.label}
                      onClick={() => handleClickTab(item.id)}
                      {...a11yProps(index)}
                    />
                  )
                })}
              </CustomTabs>
              <Box display={'flex'} justifyContent={'end'}>
                <Button
                  variant='outlined'
                  sx={{
                    mx: 1,
                    mt: 1.7,
                    px: { sm: 2, md: 4 },
                    height: 44,
                    fontSize: '1rem',
                    ...fadeInAnimation,
                  }}
                  onClick={() => navigate('/login')}
                >
                  {translatedInput?.loginPage?.signInBtn}
                </Button>
                <Button
                  variant='contained'
                  onClick={handleClick('bottom-end')}
                  endIcon={open ? <ExpandLess /> : <ExpandMore />}
                  sx={{
                    px: { sm: 2, md: 4 },
                    mt: 1.7,
                    mx: { sm: 1, md: 2 },
                    height: 44,
                    fontSize: '1rem',
                    ...fadeInAnimation,
                  }}
                >
                  {translatedInput?.loginPage?.startFreeButton}
                </Button>
              </Box>
            </Box>
            <IconButton
              color='primary'
              aria-label='open drawer'
              edge='start'
              size='large'
              onClick={handleDrawerToggle}
              sx={{ display: { lg: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box mt={9.2}>{children}</Box>
      </Box>
    </>
  )
}
export default PublicHeader
