/* eslint-disable camelcase */
import { Box, Tab, Tabs, Typography, styled, useTheme } from '@mui/material'
import { useState } from 'react'
import { useAppSelector } from '../../../_helpers/hooks'
import { TabPanelProps } from '../../../components/common/tabPanel'
import BusinessCardImage from '../../../images/card.png'
import CreateImage from '../../../images/create.png'
import FacilityCardImage from '../../../images/dropdown.png'
import SettingsImage from '../../../images/icon1.svg'
import UserImage from '../../../images/icons2.svg'
import GlobeImage from '../../../images/icons3.svg'
import BulbImage from '../../../images/icons4.png'
import MailImage from '../../../images/icons5.png'
import TickImage from '../../../images/icons6.png'
import MaleImage from '../../../images/image-section.png'
import LadyImage from '../../../images/image-section1.png'
import QrImage from '../../../images/qr-code.png'
import { fadeInFromBottomToTop, fadeInFromRight, fadeInFromTop } from './animations'
const StyledTab = styled(Tabs)(({ theme }) => ({
  '& .MuiTab-root': {
    color: theme.palette.tab.light,
    fontWeight: 600,
    fontSize: '1rem',
  },
  '& .Mui-selected': {
    backgroundColor: `${theme.palette.common.white} !important`,
    color: `${theme.palette.publicPages.colorOne} !important`,
    fontWeight: 600,
    fontSize: '1rem',
    borderRadius: '0.5rem !important',
    boxShadow: '0rem 0.139rem 0.363rem 0rem #00000011',
  },
}))
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography component='div'>{children}</Typography>
        </Box>
      )}
    </div>
  )
}
function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  }
}
const IntroductionSection = () => {
  const theme = useTheme()
  const translatedInput = useAppSelector((state) => state?.translation.translatedData)
  const [value, setValue] = useState(0)
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }
  return (
    <Box
      mt={{ xs: 4, md: 15 }}
      mb={{ xs: 5, md: 7 }}
      sx={{
        margin: '0 auto',
        maxWidth: '100rem',
        [theme.breakpoints.up(1900)]: {
          marginTop: 13,
        },
      }}
    >
      <Typography
        variant='h2'
        sx={{ mb: 2, color: 'iconColors.main' }}
        fontSize={{ xs: '1.5rem', md: '2.5rem' }}
        lineHeight={{ xs: '2.5rem', md: '3.375rem' }}
        fontWeight={600}
      >
        {translatedInput?.landingScreen?.introducingSlashqText}: <br />
        {translatedInput?.landingScreen?.letterA}{' '}
        <span style={{ color: theme.palette.publicPages.contrastText }}>
          {translatedInput?.landingScreen?.digitalBookingText}
        </span>{' '}
        {translatedInput?.landingScreen?.systemText}
      </Typography>
      <Box display={'flex'} justifyContent={'center'}>
        <Typography
          variant='h5'
          sx={{
            color: 'publicPages.main',
            wordBreak: 'break-word',
            [theme.breakpoints.down(350)]: {
              maxWidth: 200,
            },
          }}
          lineHeight={'1.563rem'}
          fontWeight={400}
          maxWidth={{ xs: 300, sm: 600, md: 800 }}
        >
          {translatedInput?.landingScreen?.introductionSectionText1}
        </Typography>
      </Box>

      <Box display={'flex'} justifyContent={'center'} mt={6}>
        <StyledTab
          value={value}
          onChange={handleChange}
          aria-label='full width tabs example'
          sx={{
            backgroundColor: 'label.main',
            padding: '0.313rem',
            borderRadius: '0.75rem',
            fontWeight: 600,
            maxWidth: '18.938rem',
            [theme.breakpoints.down(300)]: {
              display: 'contents',
            },
          }}
        >
          <Tab label={'For Business'} {...a11yProps(0)} />
          <Tab label={'For Customers'} {...a11yProps(1)} />
        </StyledTab>
      </Box>
      <Box>
        <TabPanel value={value} index={0}>
          <Box
            mt={{ xs: 1, md: 4 }}
            display={{ xs: 'block', md: 'flex' }}
            justifyContent={'center'}
            columnGap={{ xs: 0, md: 2, lg: 12, xl: 18 }}
          >
            <Box display={'flex'} justifyContent={'center'}>
              <Box
                sx={{
                  textAlign: { md: 'left' },
                  [theme.breakpoints.down(350)]: {
                    maxWidth: 200,
                  },
                }}
                mt={4}
                mr={4}
                maxWidth={{ xs: 300, sm: 600, md: 400, lg: 500, xl: 600 }}
              >
                <Box mb={6}>
                  <Box component='img' src={SettingsImage} />
                  <Typography
                    variant='h3'
                    sx={{ mb: 1, pt: 1, color: 'publicPages.colorDivider' }}
                    lineHeight={'1.563rem'}
                    fontWeight={500}
                  >
                    {translatedInput?.landingScreen?.introductionSectionText2}
                  </Typography>
                  <Typography
                    variant='h5'
                    sx={{ color: 'publicPages.main' }}
                    lineHeight={'1.313rem'}
                    fontWeight={300}
                  >
                    {translatedInput?.landingScreen?.introductionSectionText3}
                  </Typography>
                </Box>

                <Box mb={6}>
                  <Box component='img' src={UserImage} />
                  <Typography
                    variant='h3'
                    sx={{ mb: 1, pt: 1, color: 'publicPages.colorDivider' }}
                    lineHeight={'1.563rem'}
                    fontWeight={500}
                  >
                    {translatedInput?.landingScreen?.introductionSectionText4}
                  </Typography>
                  <Typography
                    variant='h5'
                    sx={{ color: 'publicPages.main' }}
                    lineHeight={'1.313rem'}
                    fontWeight={300}
                  >
                    {translatedInput?.landingScreen?.introductionSectionText5}
                  </Typography>
                </Box>
                <Box mb={6}>
                  <Box component='img' src={GlobeImage} />
                  <Typography
                    variant='h3'
                    sx={{ mb: 1, pt: 1, color: 'publicPages.colorDivider' }}
                    lineHeight={'1.563rem'}
                    fontWeight={500}
                  >
                    {translatedInput?.landingScreen?.introductionSectionText6}
                  </Typography>
                  <Typography
                    variant='h5'
                    sx={{ color: 'publicPages.main' }}
                    lineHeight={'1.313rem'}
                    fontWeight={300}
                  >
                    {translatedInput?.landingScreen?.introductionSectionText7}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                zoom: { xs: '50%', sm: '80%', md: '82%', lg: '90%', xl: '100%' },
                [theme.breakpoints.down(400)]: {
                  zoom: '30%',
                },
              }}
            >
              <Box position='relative' display='inline-block'>
                <Box sx={{ ...fadeInFromRight }} component='img' src={LadyImage} />
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    transform: 'translate(-20%,15%)',
                    ...fadeInFromBottomToTop,
                  }}
                  component='img'
                  src={FacilityCardImage}
                />
                <Box
                  sx={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    transform: 'translate(25%, 20%)',
                    ...fadeInFromTop,
                  }}
                  component='img'
                  src={BusinessCardImage}
                />
              </Box>
            </Box>
          </Box>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Box
            mt={{ xs: 1, md: 4 }}
            display={{ xs: 'block', md: 'flex' }}
            justifyContent={'center'}
            columnGap={{ xs: 0, md: 2, lg: 12, xl: 18 }}
          >
            <Box display={'flex'} justifyContent={'center'}>
              <Box
                sx={{
                  textAlign: { md: 'left' },
                  [theme.breakpoints.down(350)]: {
                    maxWidth: 200,
                  },
                }}
                mt={4}
                mr={4}
                maxWidth={{ xs: 300, sm: 600, md: 400, lg: 500, xl: 600 }}
              >
                <Box mb={6}>
                  <Box component='img' src={BulbImage} />
                  <Typography
                    variant='h3'
                    sx={{ mb: 1, pt: 1, color: 'publicPages.colorDivider' }}
                    lineHeight={'1.563rem'}
                    fontWeight={500}
                  >
                    {translatedInput?.landingScreen?.introductionSectionText8}
                  </Typography>
                  <Typography
                    variant='h5'
                    sx={{ color: 'publicPages.main' }}
                    lineHeight={'1.313rem'}
                    fontWeight={300}
                  >
                    {translatedInput?.landingScreen?.introductionSectionText9}
                  </Typography>
                </Box>

                <Box mb={6}>
                  <Box component='img' src={MailImage} />
                  <Typography
                    variant='h3'
                    sx={{ mb: 1, pt: 1, color: 'publicPages.colorDivider' }}
                    lineHeight={'1.563rem'}
                    fontWeight={500}
                  >
                    {translatedInput?.landingScreen?.introductionSectionText10}
                  </Typography>
                  <Typography
                    variant='h5'
                    sx={{ color: 'publicPages.main' }}
                    lineHeight={'1.313rem'}
                    fontWeight={300}
                  >
                    {translatedInput?.landingScreen?.introductionSectionText11}
                  </Typography>
                </Box>
                <Box mb={6}>
                  <Box component='img' src={TickImage} />
                  <Typography
                    variant='h3'
                    sx={{ mb: 1, pt: 1, color: 'publicPages.colorDivider' }}
                    lineHeight={'1.563rem'}
                    fontWeight={500}
                  >
                    {translatedInput?.landingScreen?.introductionSectionText12}
                  </Typography>
                  <Typography
                    variant='h5'
                    sx={{ color: 'publicPages.main' }}
                    lineHeight={'1.313rem'}
                    fontWeight={300}
                  >
                    {translatedInput?.landingScreen?.introductionSectionText13}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                zoom: { xs: '50%', sm: '80%', md: '82%', lg: '90%', xl: '100%' },
                [theme.breakpoints.down(400)]: {
                  zoom: '30%',
                },
              }}
            >
              <Box position='relative' display='inline-block'>
                <Box sx={{ ...fadeInFromRight }} component='img' src={MaleImage} />
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    transform: 'translate(-20%,15%)',
                    ...fadeInFromBottomToTop,
                  }}
                  component='img'
                  src={CreateImage}
                />
                <Box
                  sx={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    transform: 'translate(40%, 25%)',
                    ...fadeInFromTop,
                  }}
                  component='img'
                  src={QrImage}
                />
              </Box>
            </Box>
          </Box>
        </TabPanel>
      </Box>
    </Box>
  )
}

export default IntroductionSection
