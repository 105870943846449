import { IIcon } from './iconConstants'
const FileUploadIcon = (props: IIcon) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox='0 0 30 27'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M3 24h24V13.5h3v12a1.5 1.5 0 0 1-1.5 1.5h-27A1.5 1.5 0 0 1 0 25.5v-12h3V24zM16.5 9v10.5h-3V9H6l9-9 9 9h-7.5z'
        fill={props.fill}
        fillRule='nonzero'
        opacity='.5'
      />
    </svg>
  )
}
export default FileUploadIcon
