/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import moment from 'moment'
import { getUserTypeFromToken } from '../_helpers/cookie'
import { handleMessages } from '../_helpers/utilities'
import dashboardService from '../_services/dashboardService'
import { RootState } from '../_store/store'
import { IFilterDashboard } from './../_models/commonInterface'
import {
  IAdvancedFacilityFilters,
  ICompleteBooking,
  IDashboardState,
} from './../_models/dashboardInterface'
import { startLoader, stopLoader } from './loaderSlice'
import { cancelBookingModal, closeDrawer } from './newBookingSlice'
import { errorToast, successToast } from './toastSlice'
const initialState: IDashboardState = {
  dashboardData: {
    todays_bookings_count: {
      total_bookings_count: 0,
      completed_bookings_count: 0,
      upcoming_bookings_count: 0,
      cancelled_bookings_count: 0,
      expired_bookings_count: 0,
      scanned_bookings_count: 0,
      pending_bookings_count: 0,
      blocked_bookings_count: 0,
    },
    check_in_status: {
      today: {
        total_bookings: 0,
        todays_completed_bookings_count: 0,
      },
      this_month: {
        total_bookings: 0,
        completed_bookings_count: 0,
      },
      this_week: {
        total_bookings: 0,
        completed_bookings_count: 0,
      },
    },
    customer_visits: {
      this_month: {
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
      },
      this_week: {
        Sun: 0,
        Mon: 0,
        Tue: 0,
        Wed: 0,
        Thu: 0,
        Fri: 0,
        Sat: 0,
      },
    },
  },

  dashboardBookingListData: {
    isLoadingTableData: false,
    filters: {
      per_page: 10,
      page: 1,
      status_completed: false,
      status_upcoming: true,
      status_cancelled: false,
      status_scanned: false,
      status_pending: false,
      status_blocked: false,
      status_expired: false,
      booking_id: '',
      email: '',
      phone: '',
      country_code: '',
      facility_id: '',
      start_date: moment().format('YYYY-MM-DD'),
      end_date: moment().format('YYYY-MM-DD'),
      start_time: '',
      end_time: '',
      search: '',
      facilityName: '',
      booked_slot_sort: 'asc',
    },
    bookingData: {
      all_tokens: {
        current_page: 1,
        data: [],
        last_page: 0,
        per_page: 10,
        total: 0,
      },
      tokens_count: {
        all: 0,
        blocked: 0,
        cancelled: 0,
        completed: 0,
        pending: 0,
        upcoming: 0,
        expired: 0,
        scanned: 0,
      },
    },
  },
  searchChipsStatus: false,
  filterApplied: false,
  facilityName: '',
  scannerModal: false,
  facilitiesAdvancedSearch: [],
  facilitiesAdvancedSearchLoading: false,
  updatedFacilityId: '',
  completeModal: false,
}
export const getDashboardStatistics = createAsyncThunk(
  '/getDashboardStatistics',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await dashboardService.getDashboardStatistics()
      return response
    } catch (err: any) {
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)

// ##Booking list

export const getBookingList = createAsyncThunk(
  '/getBookingList',
  async (filters: IFilterDashboard, { dispatch, rejectWithValue }) => {
    const loggedInUserType = getUserTypeFromToken()

    try {
      if (loggedInUserType && loggedInUserType === 'business') {
        const response = await dashboardService.getBookingList(filters)
        return response
      } else {
        const response = await dashboardService.getBookingListCustomer(filters)
        return response
      }
    } catch (err: any) {
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)

export const getFacilitiesAdvancedSearch = createAsyncThunk(
  '/getFacilitiesAdvancedSearch',
  async (advancedFacilityFilters: IAdvancedFacilityFilters, { dispatch, rejectWithValue }) => {
    try {
      const response = await dashboardService.getFacilitiesAdvancedSearch(advancedFacilityFilters)
      return response
    } catch (err: any) {
      dispatch(stopLoader())
      return rejectWithValue(err)
    }
  },
)
export const completeBooking = createAsyncThunk(
  '/completeBooking',

  async (
    actionParams: {
      data: ICompleteBooking
      isPermissionForKpi?: boolean
    },
    { dispatch, rejectWithValue, getState },
  ) => {
    dispatch(startLoader())
    try {
      const response = await dashboardService.completeBooking(actionParams.data)
      dispatch(successToast(handleMessages(response.message)))
      dispatch(closeCompleteModal())
      const { dashboard } = getState() as { dashboard: IDashboardState }
      dispatch(getBookingList(dashboard.dashboardBookingListData.filters))
      actionParams.isPermissionForKpi && dispatch(getDashboardStatistics())
      dispatch(closeDrawer())
      dispatch(stopLoader())
      return response
    } catch (err: any) {
      dispatch(stopLoader())
      dispatch(errorToast(handleMessages(err.message)))
      dispatch(closeCompleteModal())
      return rejectWithValue(err)
    }
  },
)

export const isActiveToken = createAsyncThunk(
  '/isActiveToken',
  async (token_id: number[], { dispatch, rejectWithValue }) => {
    dispatch(startLoader())
    try {
      const response = await dashboardService.isActiveToken({ token_ids: token_id })
      dispatch(stopLoader())
      if (response?.data?.is_active_token === 1) {
        dispatch(errorToast(handleMessages('Unable to cancel booking as the token is active.')))
      } else if (response?.data?.is_active_token === 0) {
        dispatch(cancelBookingModal())
      }
      return response
    } catch (err: any) {
      dispatch(stopLoader())
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)
export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    changeFilters: (state, action) => {
      state.dashboardBookingListData.filters = action.payload
    },
    resetFilters: (state) => {
      state.dashboardBookingListData.filters = initialState.dashboardBookingListData.filters
    },
    setFilters: (state, action) => {
      state.dashboardBookingListData.filters = {
        ...state.dashboardBookingListData.filters,
        ...action.payload,
      }
    },
    setFiltersWithInitialData: (state, action) => {
      state.dashboardBookingListData.filters = {
        ...initialState.dashboardBookingListData.filters,
        ...action.payload,
      }
    },
    showSearchChips: (state) => {
      state.searchChipsStatus = true
    },
    hideSearchChips: (state) => {
      state.searchChipsStatus = false
    },
    setFacilityName: (state, action) => {
      state.facilityName = action.payload
    },
    openScannerModal: (state) => {
      state.scannerModal = true
    },
    closeScannerModal: (state) => {
      state.scannerModal = false
    },

    filterAppliedStatus: (state, action) => {
      return {
        ...state,
        filterApplied: action.payload,
      }
    },
    openCompleteModal: (state) => {
      state.completeModal = true
    },
    closeCompleteModal: (state) => {
      state.completeModal = false
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDashboardStatistics.fulfilled, (state, action) => {
        state.dashboardData = action.payload.data
      })
      .addCase(getBookingList.pending, (state) => {
        state.dashboardBookingListData.isLoadingTableData = true
      })
      .addCase(getBookingList.fulfilled, (state, action) => {
        state.dashboardBookingListData.bookingData = action.payload.data
        state.dashboardBookingListData.isLoadingTableData = false
      })
      .addCase(getBookingList.rejected, (state) => {
        state.dashboardBookingListData.isLoadingTableData = false
      })
      .addCase(getFacilitiesAdvancedSearch.pending, (state) => {
        state.facilitiesAdvancedSearchLoading = true
      })
      .addCase(getFacilitiesAdvancedSearch.fulfilled, (state, action) => {
        state.facilitiesAdvancedSearch = action.payload.data.facilities
        state.updatedFacilityId = action.payload.data.facility_id
        state.facilitiesAdvancedSearchLoading = false
      })
      .addCase(getFacilitiesAdvancedSearch.rejected, (state) => {
        state.facilitiesAdvancedSearchLoading = false
      })
  },
})
export const {
  changeFilters,
  resetFilters,
  setFilters,
  setFiltersWithInitialData,
  showSearchChips,
  hideSearchChips,
  setFacilityName,
  openScannerModal,
  closeScannerModal,
  filterAppliedStatus,
  openCompleteModal,
  closeCompleteModal,
} = dashboardSlice.actions

export const dashboardState = (state: RootState) => state.dashboard
const dashboardReducer = dashboardSlice.reducer
export default dashboardReducer
