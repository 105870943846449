/* eslint-disable @typescript-eslint/no-explicit-any */
import { useTheme } from '@mui/material'
import ContentLoader from 'react-content-loader'

const DetailsDrawerLoader = (props: any) => {
  const theme = useTheme()
  return (
    <ContentLoader
      speed={2}
      width={'100%'}
      height={'100%'}
      viewBox='0 0 100% 100%'
      backgroundColor={theme.palette.facilityList.colorTwo}
      foregroundColor={theme.palette.facilityList.colorThree}
      {...props}
    >
      <rect x='30' y='30' rx='3' ry='3' width='12%' height='20' />
      <rect x='30' y='60' rx='3' ry='3' width='22%' height='40' />
      <rect x='0' y='115' rx='3' ry='3' width='100%' height='2' />
      <rect x='30' y='130' rx='3' ry='3' width='45%' height='21' />
      <rect x='0' y='165' rx='3' ry='3' width='100%' height='2' />
      <rect x='30' y='200' rx='3' ry='3' width='75%' height='9' />
      <rect x='30' y='245' rx='3' ry='3' width='75%' height='10' />
      <rect x='30' y='290' rx='3' ry='3' width='75%' height='10' />
    </ContentLoader>
  )
}

export default DetailsDrawerLoader
