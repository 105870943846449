import { IIcon } from './iconConstants'
const EditIcon = (props: IIcon) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox='0 0 16 16'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g fill={props.fill} fillRule='nonzero'>
        <path d='m6.202 8.232-.492 1.843c-.043.162.053.258.215.215l1.843-.492c.277-.074.668-.3.87-.502l5.435-5.435-1.935-1.934-5.435 5.435a2.283 2.283 0 0 0-.501.87zM15.599 2.334A1.367 1.367 0 1 0 13.665.4l-.92.92 1.934 1.934.92-.92z' />
        <path d='M15.059 5.255a.941.941 0 0 0-.941.941v7.828a.094.094 0 0 1-.094.094H1.977a.094.094 0 0 1-.095-.094V1.976c0-.052.043-.094.095-.094h7.827a.941.941 0 0 0 0-1.882H1.882A1.884 1.884 0 0 0 0 1.882v12.236C0 15.156.844 16 1.882 16h12.236A1.884 1.884 0 0 0 16 14.118V6.196a.941.941 0 0 0-.941-.941z' />
      </g>
    </svg>
  )
}
export default EditIcon
