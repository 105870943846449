import { IIcon } from './iconConstants'
const BuildingWithCloseIcon = (props: IIcon) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox='0 0 55 43'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g fill='none' fillRule='evenodd'>
        <path d='M-4-6h49.5v49.5H-4z' />
        <path
          d='M40.5 43C32.492 43 26 36.508 26 28.5S32.492 14 40.5 14 55 20.492 55 28.5 48.508 43 40.5 43zm0-2.9c6.407 0 11.6-5.193 11.6-11.6s-5.193-11.6-11.6-11.6-11.6 5.193-11.6 11.6 5.193 11.6 11.6 11.6zm0-13.65 4.1-4.102 2.052 2.051L42.55 28.5l4.102 4.1-2.051 2.052L40.5 30.55l-4.1 4.102-2.052-2.051L38.45 28.5l-4.102-4.1 2.051-2.052L40.5 26.45z'
          fill={props.backgroundColor}
          fillRule='nonzero'
        />
        <path
          d='M33.125.188c1.14 0 2.063.923 2.063 2.062v10.313h1.765a18.456 18.456 0 0 0-7.76 4.125h-6.38v16.5h.38a18.371 18.371 0 0 0 1.102 4.126l-24.17-.001v-4.125h2.063V2.25c0-1.14.923-2.062 2.062-2.062h28.875zm-2.062 4.125H6.313v28.875h12.375V12.563h12.375v-8.25zm-16.5 20.625v4.125h-4.125v-4.125h4.125zm0-8.25v4.125h-4.125v-4.125h4.125zm0-8.25v4.125h-4.125V8.438h4.125z'
          fill={props.fill}
          fillRule='nonzero'
        />
      </g>
    </svg>
  )
}
export default BuildingWithCloseIcon
