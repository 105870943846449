// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function Tabs(theme: any) {
  return {
    MuiTabs: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.common.white,
          paddingLeft: 0,
          paddingRight: 0,
          minHeight: 0,
        },
        indicator: {
          display: 'none',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          borderRadius: '0.625rem',
          minHeight: 'initial',
          '&.Mui-selected': {
            backgroundColor: theme.palette.tab.main,
            color: theme.palette.common.white,
            fontWeight: 'bold',
          },
        },
      },
    },
  }
}
