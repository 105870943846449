import { IIcon } from './iconConstants'
const PhoneIcon = (props: IIcon) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox='0 0 18 18'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g fill='none' fillRule='evenodd'>
        <path d='M-2-2h22v22H-2z' />
        <path
          d='M6.585 7.792a9.676 9.676 0 0 0 3.623 3.623l.81-1.135a.917.917 0 0 1 1.187-.272 10.47 10.47 0 0 0 4.2 1.25.917.917 0 0 1 .845.915v4.09c0 .47-.355.864-.823.912-.486.05-.976.075-1.469.075C7.112 17.25.75 10.888.75 3.042c0-.494.025-.983.075-1.469A.917.917 0 0 1 1.737.75h4.09c.479 0 .877.368.914.844a10.47 10.47 0 0 0 1.25 4.201.917.917 0 0 1-.27 1.187l-1.136.81zM4.274 7.19l1.741-1.244A12.293 12.293 0 0 1 5.01 2.583H2.593a12.7 12.7 0 0 0-.009.459c0 6.834 5.54 12.375 12.374 12.375.153 0 .307-.003.459-.01V12.99a12.292 12.292 0 0 1-3.363-1.005l-1.244 1.741c-.5-.194-.987-.424-1.455-.687l-.053-.03a11.51 11.51 0 0 1-4.31-4.31l-.03-.054a11.405 11.405 0 0 1-.688-1.455z'
          fill={props.fill}
          fillRule='nonzero'
        />
      </g>
    </svg>
  )
}
export default PhoneIcon
