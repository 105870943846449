import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { IconButton, InputBase, Paper, useTheme } from '@mui/material'
import { ReactNode, useCallback, useEffect } from 'react'
import { validateNumber } from '../../_helpers/validation'
import { SearchIcon } from '../icons'
interface ISearchProps {
  searchValue: string | number | undefined
  setSearchValue: (searchValue: string | number | undefined) => void
  debouncedValue: string | number | undefined
  handleSearch: (searchValue: string | number | undefined) => void
  searchPlaceholder: string
  numberValidate?: boolean
  width?: { xs?: number | string; sm?: number | string; md?: number | string; lg?: number | string }
  marginRight?: {
    xs?: number | string
    sm?: number | string
    md?: number | string
    lg?: number | string
  }
  maxHeight?: string | number
  marginTop?:
    | string
    | number
    | {
        xs?: number | string
        sm?: number | string
        md?: number | string
        lg?: number | string
      }
  children?: ReactNode
  padding?: string | number
  display?: string
  alignItems?: string
  boxShadow?: string
  border?: string
  borderColor?: string
  borderRadius?: string | number
}

export default function SearchWithClose(props: ISearchProps) {
  const {
    searchValue,
    setSearchValue,
    debouncedValue,
    handleSearch,
    searchPlaceholder,
    numberValidate,
    width,
    marginRight,
    maxHeight,
    marginTop,
    children,
    padding,
    display,
    alignItems,
    boxShadow,
    border,
    borderColor,
    borderRadius,
  } = props
  const theme = useTheme()

  const handleClearSearch = () => {
    setSearchValue('')
  }

  const OnHandleSearch = useCallback(() => {
    if (searchValue === debouncedValue) {
      handleSearch(searchValue)
    } else if (searchValue === '') {
      handleSearch('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, debouncedValue])

  useEffect(() => {
    debouncedValue !== '' && OnHandleSearch()
  }, [debouncedValue, OnHandleSearch])
  // Search after 500 milliseconds
  const onEnterKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      if (searchValue) {
        OnHandleSearch()
      }
    }
  }
  return (
    <>
      <Paper
        component='form'
        sx={{
          p: padding ? padding : '0.125rem 0.25rem',
          display: display ? display : 'flex',
          alignItems: alignItems ? alignItems : 'center',
          width: width ? width : { sm: 325 },
          boxShadow: boxShadow ? boxShadow : 'none',
          border: border ? border : 'solid 0.063rem',
          borderColor: borderColor ? borderColor : 'secondary.light',
          borderRadius: borderRadius ? borderRadius : '0.625rem',
          marginRight: marginRight,
          maxHeight: maxHeight ? maxHeight : 'inherit',
          marginTop: marginTop ? marginTop : 'inherit',
        }}
      >
        <IconButton sx={{ p: '0.625rem' }}>
          <SearchIcon fill={theme.palette.secondary.dark} width='16' height='20' />
        </IconButton>
        <InputBase
          sx={{ ml: 1, flex: 1, fontSize: '0.875rem', fontFamily: 'Lexend' }}
          placeholder={searchPlaceholder}
          inputProps={{ 'aria-label': 'Search Facility' }}
          value={searchValue}
          onChange={(event) => {
            numberValidate
              ? ((event?.target?.value && validateNumber(event.target.value)) ||
                  event?.target?.value == '') &&
                setSearchValue(event.target.value)
              : setSearchValue(event.target.value.trimStart())
          }}
          onKeyPress={onEnterKeyPress}
        />
        {searchValue && (
          <IconButton
            sx={{ p: '0.625rem' }}
            onClick={(e) => {
              e.stopPropagation()
              handleClearSearch()
            }}
          >
            <CloseRoundedIcon style={{ width: '1.063rem', height: '1.063rem' }} />
          </IconButton>
        )}
        {children}
      </Paper>
    </>
  )
}
