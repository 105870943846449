import { PERMISSIONS } from '../../../_constants'
import { ISideMenu } from '../../../_models/menuInterface'
import {
  ArtBoardLineIcon,
  BuildingIcon,
  BuildingWithGearIcon,
  FloorPlanIcon,
  ShieldCheckIcon,
  UserSettingsIcon,
} from '../../../components/icons'

const {
  FACILITY_VIEW,
  TOKEN_BOARD_VIEW,
  VIEW_BUSINESS_PROFILE,
  FLOOR_VIEW,
  USER_MANAGEMENT_BUSINESS_USERS_VIEW,
  USER_MANAGEMENT_ROLES_VIEW,
  VIEW_BUSINESS_SETTINGS,
  SUBSCRIPTION_VIEW,
} = PERMISSIONS
export const settingsMenu: ISideMenu[] = [
  {
    id: 1,
    label: 'businessSettingsText',
    icon: BuildingWithGearIcon({ fill: '#000', width: '22', height: '22' }),
    to: '/business-settings',
    permissions: [VIEW_BUSINESS_SETTINGS, SUBSCRIPTION_VIEW],
  },
  {
    id: 11,
    label: 'profileSettings',
    icon: UserSettingsIcon({ fill: '#000', width: '22', height: '22' }),
    to: '/profile-settings',
    permissions: [VIEW_BUSINESS_PROFILE],
  },

  {
    id: 2,
    label: 'organizationSettings',
    icon: '',
    to: '',
    permissions: [FACILITY_VIEW, FLOOR_VIEW, TOKEN_BOARD_VIEW],
  },
  {
    id: 3,
    label: 'facilities',
    icon: BuildingIcon({ fill: '#000', width: '22', height: '22', opacity: '1' }),
    to: '/facilities',
    permissions: [FACILITY_VIEW],
  },
  {
    id: 4,
    label: 'floorPlans',
    icon: FloorPlanIcon({ fill: '#000', width: '22', height: '22', opacity: '1' }),
    to: '/floor-plan',
    permissions: [FLOOR_VIEW],
  },

  {
    id: 6,
    label: 'tokenBoards',
    icon: ArtBoardLineIcon({ fill: '#000', width: '22', height: '22', opacity: '1' }),
    to: '/tokenboard',
    permissions: [TOKEN_BOARD_VIEW],
  },
  {
    id: 9,
    label: 'userManagement',
    icon: '',
    to: '',
    permissions: [USER_MANAGEMENT_BUSINESS_USERS_VIEW, USER_MANAGEMENT_ROLES_VIEW],
  },
  {
    id: 10,
    label: 'permissions',
    icon: ShieldCheckIcon({ fill: '#000', width: '22', height: '22', opacity: '1' }),
    to: '/permissions',
    permissions: [USER_MANAGEMENT_BUSINESS_USERS_VIEW, USER_MANAGEMENT_ROLES_VIEW],
  },
]
