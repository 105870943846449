import {
  IDeleteAccountFormData,
  IForgetPasswordFormData,
  INewPasswordData,
  ITokenExpiryCheck,
  IUpdateDetails,
} from '../_models/userInterface'
import { _delete, get, post, postFileData, put } from './apiServices'

const forgetPassword = async (userCredentials: IForgetPasswordFormData) => {
  const response = post('/forgot-password', userCredentials)
  return response
}

const getUserDetails = async () => {
  const response = post('get-user-details')
  return response
}

// admin profile information
const getProfileInformation = async () => {
  const response = get('business/personal-details')
  return response
}

const resetPassword = async (newPasswordData: INewPasswordData) => {
  const response = post('reset-password', newPasswordData)
  return response
}

const deleteAccount = async (userCredentials: IDeleteAccountFormData) => {
  const response = post('business/delete-account', userCredentials)
  return response
}

const updateProfileImage = async (image: File) => {
  const response = await postFileData('business/profile-image', { image: image })
  return response
}

const deleteProfileImage = async () => {
  const response = _delete('business/profile-image')
  return response
}

const updateDetails = async (userDetails: IUpdateDetails) => {
  const response = put('business/personal-details', userDetails)
  return response
}

const tokenExpiryCheck = async (tokenDetails: ITokenExpiryCheck) => {
  const response = await get('/reset-password/verify', tokenDetails)
  return response
}
const getUserPermissions = async () => {
  const response = get('business/permission-list')
  return response
}

const userService = {
  getUserDetails,
  forgetPassword,
  resetPassword,
  deleteAccount,
  updateProfileImage,
  deleteProfileImage,
  getProfileInformation,
  updateDetails,
  tokenExpiryCheck,
  getUserPermissions,
}

export default userService
