import { IIcon } from './iconConstants'
const MapIcon = (props: IIcon) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox='0 0 125 110'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <defs>
        <path
          d='M8 0h109a8 8 0 0 1 8 8v94a8 8 0 0 1-8 8H8a8 8 0 0 1-8-8V8a8 8 0 0 1 8-8z'
          id='efki9gd6ra'
        />
      </defs>
      <g fill='none' fillRule='evenodd'>
        <mask id='sibhyk76gb' fill='#fff'>
          <use xlinkHref='#efki9gd6ra' />
        </mask>
        <use fill='#DFDFDF' xlinkHref='#efki9gd6ra' />
        <g opacity='.5' mask='url(#sibhyk76gb)' fill={props.fill} fillRule='nonzero'>
          <path
            d='m88.027 74.142-5.95-21.32c1.913-2.905 3.806-6.34 3.806-8.54 0-4.524-3.68-8.202-8.202-8.202s-8.202 3.678-8.202 8.202c0 1.941 1.468 4.83 3.128 7.482H43.843a.617.617 0 0 0-.592.447l-6.12 21.931a.62.62 0 0 0 .104.534.607.607 0 0 0 .488.243h49.715a.61.61 0 0 0 .59-.777h-.001zM77.679 37.305a6.985 6.985 0 0 1 6.978 6.98c0 3.031-4.662 9.605-6.978 12.563-2.316-2.957-6.977-9.531-6.977-12.563.002-3.852 3.129-6.98 6.977-6.98zm-.474 20.909c.117.14.29.227.474.227s.36-.087.476-.227c.128-.16 1.483-1.847 3.023-4.06l1.46 5.24-6.62 5.866-4.293-.403-1.452-11.868h3.118c1.839 2.77 3.662 5.032 3.814 5.225zm-14.571 5.793a.612.612 0 0 0-.582.293l-5.554 9.394h-2.911l1.72-14.08 4.45.725c.22.04.43-.046.569-.21l5.988-7.144h2.727l1.436 11.752-7.843-.73zm7.995 1.977.942 7.712H57.923l4.986-8.432 7.72.72zm-15.175-7.583.66-5.414h8.602l-5.105 6.088-4.157-.674zm-11.149-5.414h10.577l-.637 5.219-5.062-.823a.6.6 0 0 0-.35.046l-6.592 2.965 2.064-7.407zm-2.493 8.942 7.355-3.308 4.928.8-1.74 14.273H38.53l3.282-11.765zm30.993 11.766-.927-7.598 4.296.403a.606.606 0 0 0 .461-.152l6.372-5.647 3.625 12.994H72.805z'
            stroke={props.stoke}
          />
          <path d='M81.79 44.542a4.115 4.115 0 0 0-4.11-4.11 4.115 4.115 0 0 0-4.112 4.11 4.115 4.115 0 0 0 4.111 4.11 4.115 4.115 0 0 0 4.111-4.11zm-6.995 0a2.89 2.89 0 0 1 2.886-2.886 2.89 2.89 0 0 1 2.887 2.886 2.892 2.892 0 0 1-2.887 2.887 2.892 2.892 0 0 1-2.886-2.887z' />
        </g>
      </g>
    </svg>
  )
}
export default MapIcon
