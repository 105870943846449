import { Typography } from '@mui/material'
import { useAppSelector } from '../../_helpers/hooks'

const CopyRight = (props: Record<string, unknown>) => {
  const translatedInput = useAppSelector((state) => state?.translation.translatedData)
  return (
    <Typography variant='subtitle2' color='secondary.main' align='center' {...props}>
      {'© '}
      {new Date().getFullYear()} {translatedInput?.landingScreen?.copyRightText}
      {'.'}
    </Typography>
  )
}

export default CopyRight
