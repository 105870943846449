import { isAuthenticated } from '../_helpers/cookie'
import { useAppSelector } from '../_helpers/hooks'
import { userState } from '../slices/userSlice'

function useUserPermissionAuthorization(): [
  // requiredPermissions: string[],
  // boolean,
  (permission: string[]) => boolean,
] {
  const userInformation = useAppSelector(userState)
  const { userData } = userInformation
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const isUserPermissionAuthorized =
  //   isAuthenticated() &&
  //   userData.activePermissions &&
  //   userData.activePermissions.some((userPermission) =>
  //     requiredPermissions.includes(userPermission),
  //   )
  //     ? true
  //     : false
  const isPermitted = (permissions: string[]) =>
    isAuthenticated() &&
    userData.activePermissions &&
    userData.activePermissions.some((userPermission) => permissions.includes(userPermission))
      ? true
      : false
  return [
    // isUserPermissionAuthorized,
    isPermitted,
  ] // return isUserPermissionAuthorized once the actual permissions are updated
}

export default useUserPermissionAuthorization
