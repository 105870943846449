import { IIcon } from './iconConstants'
const SearchIcon = (props: IIcon) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox='0 0 16 16'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='m13.3 15.537-1.95-1.95a7.325 7.325 0 0 1-3.97 1.175 7.381 7.381 0 1 1 7.382-7.381 7.33 7.33 0 0 1-1.175 3.97l1.95 1.949a1.581 1.581 0 1 1-2.237 2.237zM7.38 2.108a5.273 5.273 0 1 0 0 10.545 5.273 5.273 0 0 0 0-10.545z'
        fill={props.fill}
        fillRule='nonzero'
      />
    </svg>
  )
}
export default SearchIcon
