import { get } from './apiServices'

const getBusinessVoyagerConfiguration = async () => {
  const response = await get('/business/config')
  return response
}

const voyagerService = {
  getBusinessVoyagerConfiguration,
}

export default voyagerService
