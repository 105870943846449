import { ReactNode, useEffect } from 'react'
import { Navigate, useSearchParams } from 'react-router-dom'
import { getUserTypeFromToken, isAuthenticated } from '../../_helpers/cookie'
import { useAppDispatch } from '../../_helpers/hooks'
import { getDomainNames, isBusinessURL } from '../../_helpers/routes'
import { userDetails } from '../../slices/userSlice'

interface IRedirectIfLoggedIn {
  children: ReactNode
}

const RedirectIfLoggedIn = ({ children }: IRedirectIfLoggedIn) => {
  const dispatch = useAppDispatch()
  const loggedInUserType = getUserTypeFromToken()

  const [urlParams] = useSearchParams()
  const isRescheduleLink = urlParams.get('reschedule') ? true : false
  const isConfirmRescheduleLink = urlParams.get('confirm-reschedule') ? true : false

  // fetch user details - user type and user active permissions
  useEffect(() => {
    if (isAuthenticated()) {
      dispatch(userDetails())
    }
  }, [dispatch])

  const getRedirectContent = () => {
    const domainNames = getDomainNames()
    if (
      isAuthenticated() &&
      loggedInUserType &&
      loggedInUserType === 'business' &&
      !isRescheduleLink &&
      !isConfirmRescheduleLink
    ) {
      if (isBusinessURL()) {
        if (domainNames.length > 3) {
          window.location.replace(
            `${window.location.protocol}//${domainNames[1]}.slashq.${domainNames[3]}/admin/business/${domainNames[0]}`,
          )
        } else {
          window.location.replace(
            `${window.location.protocol}//slashq.io/admin/business/${domainNames[0]}`,
          )
        }
      } else {
        return <Navigate to='/admin/dashboard' />
      }
    } else if (
      isAuthenticated() &&
      loggedInUserType &&
      loggedInUserType === 'customer' &&
      !isRescheduleLink &&
      !isConfirmRescheduleLink
    ) {
      if (isBusinessURL()) {
        if (domainNames.length > 3) {
          window.location.replace(
            `${window.location.protocol}//${domainNames[1]}.slashq.${domainNames[3]}/customer/business/${domainNames[0]}`,
          )
        } else {
          window.location.replace(
            `${window.location.protocol}//slashq.io/customer/business/${domainNames[0]}`,
          )
        }
      } else {
        return <Navigate to='/customer/business' />
      }
    } else {
      return children
    }
  }

  return <>{getRedirectContent()}</>
}

export default RedirectIfLoggedIn
