import Skeleton from '@mui/material/Skeleton'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { StyledTableCell } from '../../../components/@extended/tableCell'

const TableSkeleton = () => {
  const rows = [1, 2] // set the number of rows you want to display
  const columns = [1, 2, 3, 4, 5, 6] // set the number of columns you want to display
  return (
    <>
      <TableContainer>
        <Table>
          <TableHead sx={{ height: '3.125rem' }}>
            <TableRow>
              {columns.map((column) => (
                <StyledTableCell key={column} />
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row}>
                {columns.map((column) => (
                  <TableCell key={column}>
                    <Skeleton />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default TableSkeleton
