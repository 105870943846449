import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { store } from './_store/store'
import AlertModal from './components/alertModal'
import { Loader } from './components/loader'
import NavigateSetter from './components/navigateSetter'
import ToastAlert from './components/toastAlert'
import PricingPage from './pages/admin/pricingPage'
import ThemeRoutes from './routes'
import { ThemeCustomization } from './themes'
function App() {
  return (
    <Provider store={store}>
      <ThemeCustomization>
        <BrowserRouter>
          <NavigateSetter />
          <AlertModal />
          <ToastAlert />
          <Loader />
          <PricingPage />
          <ThemeRoutes />
        </BrowserRouter>
      </ThemeCustomization>
    </Provider>
  )
}

export default App
