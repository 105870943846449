import {
  IAnnounceTokenParams,
  ICancelBookingParams,
  IChangeTokenStatusParams,
  ICheckFacilityStatusParams,
  IFacility,
  IGetFacilitiesFromActiveTokenParams,
  INextTokenParams,
  ITokenListingParams,
} from './../_models/bookingManagementInterface'
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import {
  newBookingAlertDisabledStatus,
  tokenMovedFromPendingAlertDisabledStatus,
} from '../_helpers/bookingManagement'
import { handleMessages } from '../_helpers/utilities'
import { IBookingManagementState, IStartFacility } from '../_models/bookingManagementInterface'
import bookingManagementService from '../_services/bookingManagementService'
import { RootState } from '../_store/store'
import { startLoader, stopLoader } from './loaderSlice'
import { getLocationsNewBooking, noActiveTokenStatus } from './newBookingSlice'
import { errorToast, successToast } from './toastSlice'

const initialState: IBookingManagementState = {
  bookingManagementFacility: {
    facilities: [],
  },
  facilityStatus: 'NOT STARTED',
  furtherBookingStatus: null,
  isLoadingBookingManagementTableList: false,
  totalPage: 1,
  tokenLists: [],
  tokenCount: {
    completed_token: 0,
    pending_token: 0,
    upcoming_token: 0,
    cancelled_token: 0,
    total_token: 0,
  },

  activeToken: {
    board_status: 0,
    business_unit_id: 0,
    email: '',
    facility_name: '',
    formatted_time: '',
    id: 0,
    no_of_seats: 0,
    phone: '',
    preferred_slot_from: '',
    preferred_slot_to: '',
    preferred_time_status: 0,
    scheduled_time: '',
    short_token: 0,
    status: 0,
    time_slot: '',
    token_id: '',
  },
  facilityIdState: 'placeholder',
  tokenListsFilter: {
    facility_id: 'placeholder',
    status: 'Upcoming',
    date: '',
    page: 1,
    search: '',
    per_page: 10,
  },
  facilitiesAndLocationsFromActiveToken: {
    facilities: [],
    locations: [],
  },
  isNewUpcomingBookingsAlert: false,
  checkNewUpcomingBookings: false,
  newToken: {
    id: 0,
    short_token: 0,
    token_id: '',
  },
  isTokenMovedFromPendingAlert: false,
  isFacilityResumeTokenAnnounceAlert: false,
}
const handleToastMessage = (facilityStatus: string, furtherBookingStatus: string) => {
  switch (facilityStatus) {
    case 'STARTED':
      return 'Facility already started.'
      break
    case 'RESUMED':
      return 'Facility already started.'
      break
    case 'STOPPED':
      return 'Facility is stopped for the day.'
      break
    case 'PAUSED':
      return furtherBookingStatus === 'STOP_FURTHER_BOOKINGS'
        ? 'Facility is paused and further bookings are stopped'
        : 'Facility is paused.'
      break
    default:
      return ''
      break
  }
}
export const getAllFacilitiesInBookingManagement = createAsyncThunk(
  '/getAllFacilitiesInBookingManagement',
  async (_, { dispatch, rejectWithValue, getState }) => {
    const { bookingManagement } = getState() as {
      bookingManagement: IBookingManagementState
    }
    try {
      const response = await bookingManagementService.getAllFacilitiesInBookingManagement()
      const facilities: IFacility[] | [] = response.data.facilities
      // Check facility inactive / deletion
      const isFacilityAvailableAndActive = facilities.some(
        (facility) => facility.id_hash === bookingManagement.tokenListsFilter.facility_id,
      )
      if (!isFacilityAvailableAndActive) {
        dispatch(resetBookingManagement())
      }
      return response
    } catch (err: any) {
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)
export const checkFacilityStatus = createAsyncThunk(
  '/checkFacilityStatus',
  async (
    checkFacilityStatusParams: ICheckFacilityStatusParams,
    { dispatch, rejectWithValue, getState },
  ) => {
    const { facilityId, showFacilityStatusToast } = checkFacilityStatusParams
    const { bookingManagement } = getState() as {
      bookingManagement: IBookingManagementState
    }
    dispatch(startLoader())
    try {
      const facilitiesResponse =
        await bookingManagementService.getAllFacilitiesInBookingManagement()
      const facilities: IFacility[] | [] = facilitiesResponse.data.facilities
      // Check facility inactive / deletion
      const isFacilityAvailableAndActive = facilities.some(
        (facility) => facility.id_hash === facilityId,
      )
      if (!isFacilityAvailableAndActive) {
        dispatch(resetBookingManagement())
      }

      if (isFacilityAvailableAndActive) {
        const response = await bookingManagementService.checkFacilityStatus(facilityId)
        dispatch(stopLoader())
        if (response && response.facility_status !== 'NOT STARTED') {
          if (showFacilityStatusToast) {
            dispatch(
              successToast(
                handleMessages(
                  handleToastMessage(response.facility_status, response.furthur_booking_status),
                ),
              ),
            )
          }
          if (response.facility_status === 'STOPPED') {
            dispatch(
              updateTokenListsFilter({
                ...bookingManagement.tokenListsFilter,
                facility_id: facilityId,
                search: '',
                status: 'Completed',
                page: 1,
                per_page: 10,
              }),
            )
          } else {
            dispatch(
              updateTokenListsFilter({
                ...bookingManagement.tokenListsFilter,
                facility_id: facilityId,
                search: '',
                status: 'Upcoming',
                page: 1,
                per_page: 10,
              }),
            )
          }
        }
        return response
      }
    } catch (err: any) {
      dispatch(stopLoader())
      return rejectWithValue(err)
    }
  },
)
export const startFacility = createAsyncThunk(
  '/startFacility',
  async (facilityId: IStartFacility, { dispatch, rejectWithValue, getState }) => {
    const { bookingManagement } = getState() as {
      bookingManagement: IBookingManagementState
    }
    dispatch(startLoader())
    try {
      const response = await bookingManagementService.startFacility(facilityId)
      dispatch(
        updateTokenListsFilter({
          ...bookingManagement.tokenListsFilter,
          facility_id: facilityId.facility_id,
          status: 'Upcoming',
          page: 1,
          search: '',
          per_page: 10,
        }),
      )

      dispatch(stopLoader())
      dispatch(successToast(handleMessages(response.message)))
      return response
    } catch (err: any) {
      dispatch(stopLoader())
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)
export const pauseFacility = createAsyncThunk(
  '/pauseFacility',
  async (facilityId: IStartFacility, { dispatch, rejectWithValue }) => {
    dispatch(startLoader())
    try {
      const response = await bookingManagementService.pauseFacility(facilityId)
      dispatch(stopLoader())
      dispatch(successToast(handleMessages(response.message)))
      return response
    } catch (err: any) {
      dispatch(stopLoader())
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)
export const resumeFacility = createAsyncThunk(
  '/resumeFacility',
  async (facilityId: IStartFacility, { dispatch, rejectWithValue, getState }) => {
    const { bookingManagement } = getState() as {
      bookingManagement: IBookingManagementState
    }
    dispatch(startLoader())
    try {
      const response = await bookingManagementService.resumeFacility(facilityId)

      const existingUpcomingToken = response.data.new_token
      if (existingUpcomingToken) {
        dispatch(updateNewUpcomingToken(existingUpcomingToken))
      }
      if (
        !bookingManagement.activeToken.token_id &&
        bookingManagement.tokenCount.upcoming_token > 0
      ) {
        dispatch(updateFacilityResumeTokenAnnounceAlertStatus(true))
      }

      dispatch(stopLoader())
      dispatch(successToast(handleMessages(response.message)))
      return response
    } catch (err: any) {
      dispatch(stopLoader())
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)

export const tokenListing = createAsyncThunk(
  '/tokenListing',
  async (tokenListingParams: ITokenListingParams, { dispatch, rejectWithValue, getState }) => {
    try {
      const response = await bookingManagementService.tokenListing(tokenListingParams)

      const { bookingManagement } = getState() as {
        bookingManagement: IBookingManagementState
      }

      const newToken = response.data.new_token
      const activeToken = response.data.active_token

      if (newToken) {
        dispatch(updateNewUpcomingToken(newToken))
      }

      if (
        bookingManagement.checkNewUpcomingBookings &&
        !newBookingAlertDisabledStatus.includes(bookingManagement.facilityStatus) &&
        activeToken === null &&
        bookingManagement.tokenCount.upcoming_token === 0 &&
        newToken &&
        bookingManagement.tokenListsFilter.facility_id !== 'placeholder' &&
        !bookingManagement.isTokenMovedFromPendingAlert
      ) {
        dispatch(updateNewUpcomingBookingsAlertStatus(true))
      }

      if (bookingManagement.tokenListsFilter.page > 1 && response.data.tokens.data.length === 0) {
        dispatch(
          updateTokenListsFilter({
            ...bookingManagement.tokenListsFilter,
            page: bookingManagement.tokenListsFilter.page - 1,
          }),
        )
      }

      dispatch(stopLoader())
      return response
    } catch (err: any) {
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)

export const changeTokenStatus = createAsyncThunk(
  '/changeTokenStatus',

  async (
    actionParams: {
      changeTokenStatusParams: IChangeTokenStatusParams
      isCallFromStopFacilityAction?: boolean
    },
    { dispatch, rejectWithValue, getState },
  ) => {
    const { bookingManagement } = getState() as {
      bookingManagement: IBookingManagementState
    }
    dispatch(startLoader())
    if (actionParams.isCallFromStopFacilityAction) {
      actionParams.changeTokenStatusParams.is_facility_stopped = true
    } else {
      actionParams.changeTokenStatusParams.is_facility_stopped = false
    }
    try {
      const response = await bookingManagementService.changeTokenStatus(
        actionParams.changeTokenStatusParams,
      )

      if (
        bookingManagement.checkNewUpcomingBookings &&
        !tokenMovedFromPendingAlertDisabledStatus.includes(bookingManagement.facilityStatus) &&
        !bookingManagement.activeToken.token_id &&
        bookingManagement.tokenCount.upcoming_token === 0 &&
        bookingManagement.tokenListsFilter.facility_id !== 'placeholder' &&
        actionParams.changeTokenStatusParams.status === 'Upcoming'
      ) {
        dispatch(updateTokenMovedFromPendingAlertStatus(true))
      }

      dispatch(stopLoader())
      if (actionParams.isCallFromStopFacilityAction)
        dispatch(stopFacility({ facility_id: bookingManagement.tokenListsFilter.facility_id }))
      else dispatch(successToast(handleMessages(response.message)))

      dispatch(tokenListing(bookingManagement.tokenListsFilter))
      return response
    } catch (err: any) {
      dispatch(stopLoader())
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)

export const changeActiveTokenStatusAndAnnounceNewToken = createAsyncThunk(
  '/changeActiveTokenStatusAndAnnounceNewToken',

  async (
    actionParams: {
      changeActiveTokenStatusParams: IChangeTokenStatusParams
      changeTokenStatusParams: IChangeTokenStatusParams
      announceTokenParams: IAnnounceTokenParams
    },
    { dispatch, rejectWithValue },
  ) => {
    dispatch(startLoader())
    try {
      const response = await bookingManagementService.changeTokenStatus(
        actionParams.changeActiveTokenStatusParams,
      )
      dispatch(stopLoader())
      dispatch(changeTokenStatusAndAnnounce(actionParams))

      return response
    } catch (err: any) {
      dispatch(stopLoader())
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)

export const changeTokenStatusAndAnnounce = createAsyncThunk(
  '/changeTokenStatusAndAnnounce',

  async (
    actionParams: {
      changeTokenStatusParams: IChangeTokenStatusParams
      announceTokenParams: IAnnounceTokenParams
    },
    { dispatch, rejectWithValue, getState },
  ) => {
    const { bookingManagement } = getState() as {
      bookingManagement: IBookingManagementState
    }
    dispatch(startLoader())
    try {
      const response = await bookingManagementService.changeTokenStatus(
        actionParams.changeTokenStatusParams,
      )
      dispatch(stopLoader())

      try {
        await dispatch(announceToken(actionParams.announceTokenParams))
        dispatch(tokenListing(bookingManagement.tokenListsFilter))
      } catch (err: any) {
        dispatch(stopLoader())
        dispatch(errorToast(handleMessages(err.message)))
        return rejectWithValue(err)
      }

      return response
    } catch (err: any) {
      dispatch(stopLoader())
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)

export const announceAndRefetchTokens = createAsyncThunk(
  '/announceAndRefetchTokens',
  async (announceTokenParams: IAnnounceTokenParams, { dispatch, rejectWithValue, getState }) => {
    const { bookingManagement } = getState() as {
      bookingManagement: IBookingManagementState
    }
    dispatch(startLoader())
    try {
      const response = await bookingManagementService.announceToken(announceTokenParams)
      dispatch(tokenListing(bookingManagement.tokenListsFilter))
      dispatch(stopLoader())
      dispatch(successToast(handleMessages(response.message)))
      return response
    } catch (err: any) {
      dispatch(stopLoader())
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)

export const announceToken = createAsyncThunk(
  '/announceToken',
  async (announceTokenParams: IAnnounceTokenParams, { dispatch, rejectWithValue }) => {
    dispatch(startLoader())
    try {
      const response = await bookingManagementService.announceToken(announceTokenParams)
      dispatch(stopLoader())
      dispatch(successToast(handleMessages(response.message)))
      return response
    } catch (err: any) {
      dispatch(stopLoader())
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)
export const stopFurtherBooking = createAsyncThunk(
  '/stopFurtherBooking',
  async (facilityId: IStartFacility, { dispatch, rejectWithValue }) => {
    dispatch(startLoader())
    try {
      const response = await bookingManagementService.stopFurtherBooking(facilityId)
      dispatch(stopLoader())
      dispatch(successToast(handleMessages(response.message)))
      return response
    } catch (err: any) {
      dispatch(stopLoader())
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)
export const resumeFurtherBooking = createAsyncThunk(
  '/resumeFurtherBooking',
  async (facilityId: IStartFacility, { dispatch, rejectWithValue }) => {
    dispatch(startLoader())
    try {
      const response = await bookingManagementService.resumeFurtherBooking(facilityId)
      dispatch(stopLoader())
      dispatch(successToast(handleMessages(response.message)))
      return response
    } catch (err: any) {
      dispatch(stopLoader())
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)
export const stopFacility = createAsyncThunk(
  '/stopFacility',
  async (facilityId: IStartFacility, { dispatch, rejectWithValue, getState }) => {
    const { bookingManagement } = getState() as { bookingManagement: IBookingManagementState }
    dispatch(startLoader())
    try {
      const response = await bookingManagementService.stopFacility(facilityId)
      dispatch(stopLoader())
      dispatch(successToast(handleMessages(response.message)))
      dispatch(
        updateTokenListsFilter({
          ...bookingManagement.tokenListsFilter,
          status: 'Completed',
        }),
      )

      return response
    } catch (err: any) {
      dispatch(stopLoader())
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)
export const cancelBooking = createAsyncThunk(
  '/cancelBooking',
  async (
    actionParams: {
      cancelTokenParams: ICancelBookingParams
      isCallFromStopFacilityAction?: boolean
    },

    { dispatch, rejectWithValue, getState },
  ) => {
    const { bookingManagement } = getState() as { bookingManagement: IBookingManagementState }
    dispatch(startLoader())
    if (actionParams.isCallFromStopFacilityAction) {
      actionParams.cancelTokenParams.is_facility_stopped = true
    } else {
      actionParams.cancelTokenParams.is_facility_stopped = false
    }
    try {
      const response = await bookingManagementService.cancelBooking(actionParams.cancelTokenParams)
      if (actionParams.isCallFromStopFacilityAction)
        dispatch(stopFacility({ facility_id: bookingManagement.tokenListsFilter.facility_id }))
      else dispatch(successToast(handleMessages(response.message)))
      dispatch(tokenListing(bookingManagement.tokenListsFilter))
      dispatch(stopLoader())
      return response
    } catch (err: any) {
      dispatch(stopLoader())
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)

export const nextToken = createAsyncThunk(
  '/nextToken',
  async (nextTokenParams: INextTokenParams, { dispatch, rejectWithValue, getState }) => {
    const { bookingManagement } = getState() as { bookingManagement: IBookingManagementState }
    dispatch(startLoader())
    try {
      const response = await bookingManagementService.nextToken(nextTokenParams)
      dispatch(stopLoader())
      dispatch(successToast(handleMessages(response.message)))
      dispatch(tokenListing(bookingManagement.tokenListsFilter))
      return response
    } catch (err: any) {
      dispatch(stopLoader())
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)

export const getFacilitiesFromActiveToken = createAsyncThunk(
  '/getFacilitiesFromActiveToken',
  async (facility_params: IGetFacilitiesFromActiveTokenParams, { dispatch, rejectWithValue }) => {
    dispatch(startLoader())
    try {
      const response = await bookingManagementService.getFacilitiesFromActiveToken(facility_params)
      dispatch(stopLoader())
      // dispatch(successToast(handleMessages(response.message)))
      dispatch(noActiveTokenStatus())
      dispatch(getLocationsNewBooking())
      return response
    } catch (err: any) {
      dispatch(stopLoader())
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)

export const bookingManagementSlice = createSlice({
  name: 'bookingManagement',
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.facilityStatus = initialState.facilityStatus
    },
    updateFacilityId: (state, data) => {
      state.facilityIdState = data.payload
    },
    updateTokenListsFilter: (state, data) => {
      state.tokenListsFilter = data.payload
    },
    resetTokenListFilter: (state) => {
      state.tokenListsFilter = initialState.tokenListsFilter
    },
    updateNewUpcomingBookingsAlertStatus: (state, data) => {
      state.isNewUpcomingBookingsAlert = data.payload
    },
    updateNewUpcomingToken: (state, data) => {
      state.newToken = data.payload
    },
    updateCheckNewUpcomingBookingsStatus: (state, data) => {
      state.checkNewUpcomingBookings = data.payload
    },
    resetBookingManagement: () => initialState,
    updateTokenMovedFromPendingAlertStatus: (state, data) => {
      state.isTokenMovedFromPendingAlert = data.payload
    },
    updateFacilityResumeTokenAnnounceAlertStatus: (state, data) => {
      state.isFacilityResumeTokenAnnounceAlert = data.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllFacilitiesInBookingManagement.fulfilled, (state, action) => {
      state.bookingManagementFacility = action.payload.data
    })
    builder.addCase(checkFacilityStatus.fulfilled, (state, action) => {
      if (action.payload && action.payload.facility_status) {
        state.facilityStatus = action.payload.facility_status
        state.furtherBookingStatus = action.payload.furthur_booking_status
      }
    })
    builder.addCase(startFacility.fulfilled, (state, action) => {
      state.facilityStatus = action.payload.data.facility_status
    })
    builder.addCase(pauseFacility.fulfilled, (state, action) => {
      state.facilityStatus = action.payload.data.facility_status
    })
    builder.addCase(resumeFacility.fulfilled, (state, action) => {
      state.facilityStatus = action.payload.data.facility_status
    })
    builder.addCase(tokenListing.pending, (state) => {
      state.isLoadingBookingManagementTableList = true
    })
    builder.addCase(tokenListing.fulfilled, (state, action) => {
      state.isLoadingBookingManagementTableList = false
      state.totalPage = action.payload.data.tokens.last_page
      state.tokenLists = action.payload.data.tokens.data
      state.tokenCount = action.payload.data.token
      state.activeToken = action.payload.data.active_token
        ? action.payload.data.active_token
        : initialState.activeToken
      if (state.tokenListsFilter.facility_id !== 'placeholder') {
        state.checkNewUpcomingBookings = true
      }
    })
    builder.addCase(tokenListing.rejected, (state) => {
      state.isLoadingBookingManagementTableList = false
    })
    builder.addCase(stopFurtherBooking.fulfilled, (state) => {
      state.furtherBookingStatus = 'STOP_FURTHER_BOOKINGS'
    })
    builder.addCase(resumeFurtherBooking.fulfilled, (state) => {
      state.furtherBookingStatus = 'RESUME_FURTHER_BOOKINGS'
    })
    builder.addCase(stopFacility.fulfilled, (state) => {
      state.facilityStatus = 'STOPPED'
    })
    builder.addCase(getFacilitiesFromActiveToken.fulfilled, (state, action) => {
      state.facilitiesAndLocationsFromActiveToken = action.payload.data
    })
  },
})
export const {
  resetStatus,
  updateFacilityId,
  updateTokenListsFilter,
  resetTokenListFilter,
  updateNewUpcomingBookingsAlertStatus,
  updateNewUpcomingToken,
  updateCheckNewUpcomingBookingsStatus,
  resetBookingManagement,
  updateTokenMovedFromPendingAlertStatus,
  updateFacilityResumeTokenAnnounceAlertStatus,
} = bookingManagementSlice.actions
export const bookingManagementState = (state: RootState) => state.bookingManagement
const bookingManagementReducer = bookingManagementSlice.reducer
export default bookingManagementReducer
