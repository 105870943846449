/* eslint-disable camelcase */
import { Box, Grid, Typography, useTheme } from '@mui/material'
import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../_helpers/hooks'
import BusinessListImage from '../../../images/Browser.png'
import BusinessCardImage from '../../../images/Card-5.png'
import BgImage from '../../../images/background.png'
import FacilityCardImage from '../../../images/facility-card-2.png'
import { getPricingPlans } from '../../../slices/publicPagesSlice'
import {
  fadeInFromBottom80Animation,
  fadeInFromBottomAnimation,
  fadeInFromLeftAnimation,
  fadeInFromRightAnimation,
} from './animations'
import FAQSection from './faq'
import GoMobileSection from './go-mobile'
import IntroductionSection from './introduction'
import OurFeatureSection from './ourFeature'
import CarouselSection from './perfectSolution'
import PricingSection from './pricing/pricing'
import EasyStepsSection from './setUpInEasy'

const HomePage = () => {
  const theme = useTheme()
  const translatedInput = useAppSelector((state) => state?.translation.translatedData)
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(getPricingPlans())
  }, [dispatch])
  return (
    <Grid container spacing={1} justifyContent={'center'} textAlign={'center'}>
      <Grid item xs={12} sm={12} md={12} mt={8}>
        <Box
          sx={{
            backgroundImage: `url(${BgImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'right',
            backgroundSize: 'cover',
          }}
        >
          <Box sx={{ margin: '0 auto', maxWidth: '100rem' }}>
            <Typography
              variant='h2'
              sx={{ mb: 2, color: 'iconColors.main', ...fadeInFromBottomAnimation }}
              fontSize={{ xs: '2.75rem', md: '3.75rem' }}
              lineHeight={{ xs: '3.25rem', md: '4.5rem' }}
            >
              {translatedInput?.landingScreen?.simplifyingText}{' '}
              <span style={{ color: theme.palette.primary.main }}>
                {translatedInput?.common?.businessTxt}
              </span>{' '}
              <br />
              {translatedInput?.landingScreen?.schedulingText}
            </Typography>

            <Typography
              variant='h2'
              sx={{ mb: 2, color: 'publicPages.main', ...fadeInFromBottom80Animation }}
              fontSize={'1.25rem'}
              lineHeight={'1.875rem'}
              fontWeight={300}
            >
              {translatedInput?.landingScreen?.flexibleBookingText}
            </Typography>
            <Box mt={{ xs: 4, lg: 12 }} position='relative' display='inline-block'>
              <Box
                sx={{ width: '100%', zIndex: 2, ...fadeInFromBottomAnimation }}
                component='img'
                src={BusinessListImage}
              />
              <Box
                sx={{
                  position: 'absolute',
                  zIndex: 2,
                  top: 100,
                  left: 220,
                  transform: 'translate(-50%, -50%)',
                  display: { xs: 'none', lg: 'block' },
                  ...fadeInFromRightAnimation,
                }}
                component='img'
                src={FacilityCardImage}
              />
              <Box
                sx={{
                  position: 'absolute',
                  top: 130,
                  right: 150,
                  transform: 'translate(50%, -50%)',
                  display: { xs: 'none', lg: 'block' },
                  zIndex: 2,
                  ...fadeInFromLeftAnimation,
                }}
                component='img'
                src={BusinessCardImage}
              />
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <IntroductionSection />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <OurFeatureSection />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <EasyStepsSection />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <GoMobileSection />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <CarouselSection />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <PricingSection />
      </Grid>

      <Grid item xs={12} sm={12} md={12}>
        <FAQSection />
      </Grid>
    </Grid>
  )
}

export default HomePage
