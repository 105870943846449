import { settingsMenu } from '../pages/admin/settingsPage/items'

export const getSelectedMenu = () => {
  let selectedMenuId = 0
  const location = window.location
  const currentPath: string = location.pathname

  const selectedMenuItem = settingsMenu.find((MenuItem) => {
    const sideMenuPath = MenuItem.to
    return currentPath && currentPath.includes(sideMenuPath) && sideMenuPath !== ''
  })

  if (selectedMenuItem) {
    selectedMenuId = selectedMenuItem.id
  }

  return selectedMenuId
}
