import { IIcon } from './iconConstants'
const BuildingCrossIcon = (props: IIcon) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox='0 0 42 38'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g fill='none' fillRule='evenodd' opacity='.5'>
        <path d='M-4-6h49.5v49.5H-4z' />
        <path
          d='M41.375 37.313H.125v-4.125h2.063V2.25c0-1.14.923-2.062 2.062-2.062h28.875c1.14 0 2.063.923 2.063 2.062v10.313h4.125v20.625h2.062v4.125zm-10.312-4.125h4.125v-16.5H22.813v16.5h4.125v-.375h4.125v.375zm0-20.625v-8.25H6.313v28.875h12.375V12.563h12.375zm-20.625 4.125h4.125v4.125h-4.125v-4.125zm0 8.25h4.125v4.125h-4.125v-4.125zm0-16.5h4.125v4.125h-4.125V8.438z'
          fill={props.backgroundColor}
          fillRule='nonzero'
        />
        <path
          d='m29.609 25 3.246-3.26a.42.42 0 0 0 0-.61.42.42 0 0 0-.609 0L29 24.391l-3.26-3.26a.42.42 0 0 0-.61 0 .42.42 0 0 0 0 .608L28.377 25l-3.247 3.26a.42.42 0 0 0 0 .61c.087.087.203.13.305.13a.442.442 0 0 0 .304-.13L29 25.609l3.26 3.26c.088.088.204.131.305.131a.442.442 0 0 0 .305-.13.42.42 0 0 0 0-.61L29.609 25z'
          stroke={props.stoke}
          strokeWidth='1.333'
          fill={props.fill}
          fillRule='nonzero'
        />
      </g>
    </svg>
  )
}
export default BuildingCrossIcon
