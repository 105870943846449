import { IIcon } from './iconConstants'
const ClockHour4Icon = (props: IIcon) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox='0 0 16 16'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g fill={props.fill} fillRule='nonzero'>
        <path d='M7.71.01a7.7 7.7 0 1 0 7.7 7.7 7.709 7.709 0 0 0-7.7-7.7zm0 14a6.3 6.3 0 1 1 6.3-6.3 6.308 6.308 0 0 1-6.3 6.3z' />
        <path d='M8.41 7.42V3.51a.7.7 0 1 0-1.4 0v4.2a.7.7 0 0 0 .205.495l2.1 2.1a.7.7 0 0 0 .99-.99L8.41 7.42z' />
      </g>
    </svg>
  )
}
export default ClockHour4Icon
