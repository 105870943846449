import { IIcon } from './iconConstants'
const ArrowUpDownSortIcon = ({ width, height, fill, stoke }: IIcon) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 20 20'
      fill={fill}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16.667 3.333v10h2.5L15.833 17.5 12.5 13.333H15v-10h1.667zM10 15v1.666H2.5V15H10zm1.667-5.834v1.667H2.5V9.166h9.167zm0-5.833V5H2.5V3.333h9.167z'
        fill={stoke}
      />
    </svg>
  )
}
export default ArrowUpDownSortIcon
