import CloseIcon from '@mui/icons-material/Close'
import { Box, Stack, Typography, useTheme } from '@mui/material'
import { useAppSelector } from '../../../_helpers/hooks'
import { ISlotInfo } from '../../../_models/newBookingInterface'
import { StyledChip } from '../../../components/@extended/customChip'
import { MoonIcon, SunIcon, SunRiseIcon, SunSetIcon } from '../../../components/icons'

interface IProps {
  slotInfo: ISlotInfo
  onSlotDeselect: () => void
}
const SelectedSlot = (props: IProps) => {
  const { slotInfo, onSlotDeselect } = props
  const { slotId, slotTime, slotInterval } = slotInfo
  const translatedInput = useAppSelector((state) => state?.translation.translatedData)
  const theme = useTheme()

  return (
    <div>
      <>
        <Typography mt={2} variant='subtitle1' color='table.light'>
          {translatedInput?.newBooking?.pickSlotTitle}
        </Typography>
        <Stack
          spacing={2}
          direction={'row'}
          sx={{
            [theme.breakpoints.down(430)]: {
              display: 'block',
            },
          }}
        >
          <Box sx={{ display: 'flex' }}>
            <Box mr={1} mt={1}>
              {slotInterval === 'morning' && (
                <SunRiseIcon
                  fill='none'
                  stoke={theme.palette.toggleButtonGroup.contrastText}
                  width='24'
                  height='24'
                />
              )}
              {slotInterval === 'afternoon' && (
                <SunIcon
                  fill='none'
                  stoke={theme.palette.toggleButtonGroup.contrastText}
                  width='24'
                  height='24'
                />
              )}
              {slotInterval === 'evening' && (
                <SunSetIcon
                  fill={'none'}
                  stoke={theme.palette.toggleButtonGroup.contrastText}
                  width='24'
                  height='24'
                />
              )}
              {slotInterval === 'night' && (
                <MoonIcon
                  fill={theme.palette.toggleButtonGroup.contrastText}
                  width='24'
                  height='24'
                />
              )}
            </Box>
            <Typography variant='subtitle2' color='toggleButtonGroup.contrastText' mt={1}>
              {slotInterval === 'morning' && translatedInput?.newBooking?.morningTitle}
              {slotInterval === 'afternoon' && translatedInput?.newBooking?.afterNoonTitle}
              {slotInterval === 'evening' && translatedInput?.newBooking?.eveningTitle}
              {slotInterval === 'night' && translatedInput?.newBooking?.nightTitle}
            </Typography>
          </Box>
          <StyledChip
            deleteIcon={<CloseIcon sx={{ color: `${theme.palette.common.white} !important` }} />}
            label={
              <Box display='flex'>
                <Typography variant='h5' fontSize={14}>
                  {slotTime}
                </Typography>
              </Box>
            }
            key={slotId}
            onDelete={() => onSlotDeselect()}
            sx={{
              [theme.breakpoints.down(430)]: {
                marginLeft: '0px !important',
              },
            }}
          />
        </Stack>
      </>
    </div>
  )
}

export default SelectedSlot
