import { IIcon } from './iconConstants'

const UserManagementIcon = (props: IIcon) => {
  const { height, width, stoke } = props

  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 30 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M26.35 10.725v8.55c0 1.4-.75 2.7-1.963 3.413l-7.425 4.287c-1.212.7-2.712.7-3.937 0L5.6 22.688c-1.212-.7-1.962-2-1.962-3.413v-8.55c0-1.4.75-2.7 1.962-3.413l7.425-4.287c1.213-.7 2.713-.7 3.937 0l7.425 4.287c1.213.713 1.963 2 1.963 3.413z'
        stroke={stoke}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15 13.75a2.912 2.912 0 1 0 0-5.825 2.912 2.912 0 0 0 0 5.825zM20 20.825c0-2.25-2.238-4.075-5-4.075s-5 1.825-5 4.075'
        stroke={stoke}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
export default UserManagementIcon
