import { IIcon } from './iconConstants'
const CSVIcon = (props: IIcon) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox='0 0 14 15'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='m7.65 7.5 2.1 3h-1.8l-1.2-1.714-1.2 1.714h-1.8l2.1-3-2.1-3h1.8l1.2 1.714L7.95 4.5H9v-3H1.5v12H12v-9H9.75l-2.1 3zM0 .744C0 .333.336 0 .749 0H9.75l3.75 3.75v10.494a.75.75 0 0 1-.745.756H.745A.75.75 0 0 1 0 14.256V.744z'
        fill={props.fill}
        fillRule='nonzero'
        opacity='.7'
      />
    </svg>
  )
}
export default CSVIcon
