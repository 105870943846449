/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { handleMessages } from '../_helpers/utilities'
import { IFilters } from '../_models/commonInterface'
import {
  IAddTokenBoard,
  IEditTokenBoard,
  ITokenBoardActivationCode,
  ITokenBoardFacilityFilters,
  ITokenBoardState,
} from '../_models/tokenBoardInterface'
import tokenBoardService from '../_services/tokenBoardService'
import { RootState } from '../_store/store'
import { startLoader, stopLoader } from './loaderSlice'
import { errorToast, successToast } from './toastSlice'
import { getPricingLimits } from './pricingSlice'

const initialState: ITokenBoardState = {
  token_boards: {
    current_page: 1,
    data: [],
    total: 20,
    per_page: 10,
    last_page: 0,
  },
  filters: {
    page: 1,
    per_page: 10,
    search: '',
  },
  tokenBoardMode: 'add',
  tokenBoardDrawer: false,
  deleteTokenBoardStatus: false,
  isLoadingTokenBoardList: false,
  isLoadingTokenBoardDetails: false,
  tokenBoardDetails: {
    name: '',
    url: '',
    code: '',
    slides: {
      '1': [],
    },
    id_hash: '',
    facility_count: 0,
    activationUrl: '',
  },
  tokenBoardFacilities: [],
  tokenBoardAddFacilityModal: false,
}

// ALL TOKEN BOARDS

export const getAllTokenBoards = createAsyncThunk(
  '/getAllTokenBoards',
  async (filters: IFilters, { dispatch, rejectWithValue }) => {
    try {
      const response = await tokenBoardService.getAllTokenBoards(filters)
      dispatch(getPricingLimits({}))
      return response
    } catch (err: any) {
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)

// TOKEN BOARD DETAILS

export const getTokenBoardDetails = createAsyncThunk(
  '/getTokenBoardDetails',
  async (tokenBoardId: string, { dispatch, rejectWithValue }) => {
    try {
      const response = await tokenBoardService.getTokenBoardDetails(tokenBoardId)
      return response
    } catch (err: any) {
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)

// DELETE TOKEN BOARD

export const deleteTokenBoard = createAsyncThunk(
  '/deleteTokenBoard',
  async (tokenBoardId: string, { dispatch, rejectWithValue, getState }) => {
    dispatch(startLoader())
    try {
      const response = await tokenBoardService.deleteTokenBoard(tokenBoardId)
      dispatch(successToast(handleMessages(response.message)))
      const { tokenBoard } = getState() as { tokenBoard: ITokenBoardState }
      dispatch(getAllTokenBoards(tokenBoard.filters))
      dispatch(closeDeleteTokenBoardModal())
      dispatch(updateTokenBoardDrawerStatus(false))
      dispatch(resetTokenBoardDetails())
      dispatch(stopLoader())
      return response
    } catch (err: any) {
      dispatch(stopLoader())
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)

// ADD TOKEN BOARD

export const addTokenBoard = createAsyncThunk(
  '/addTokenBoard',
  async (tokenBoardData: IAddTokenBoard, { dispatch, rejectWithValue }) => {
    dispatch(startLoader())
    try {
      const response = await tokenBoardService.addTokenBoard(tokenBoardData)
      dispatch(stopLoader())
      dispatch(successToast(handleMessages(response.message)))
      dispatch(getAllTokenBoards(initialState.filters))
      dispatch(updateTokenBoardDrawerStatus(false))
      dispatch(resetTokenBoardDetails())
      return response
    } catch (err: any) {
      dispatch(stopLoader())
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)

// EDIT TOKEN BOARD

export const editTokenBoard = createAsyncThunk(
  '/editTokenBoard',
  async (tokenBoardData: IEditTokenBoard, { dispatch, rejectWithValue, getState }) => {
    dispatch(startLoader())
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { tokenBoardId, ...tokenBoardDetails } = tokenBoardData
    try {
      const response = await tokenBoardService.editTokenBoard(
        tokenBoardData.tokenBoardId,
        tokenBoardDetails,
      )
      dispatch(stopLoader())
      dispatch(successToast(handleMessages(response.message)))
      const { tokenBoard } = getState() as { tokenBoard: ITokenBoardState }
      dispatch(getAllTokenBoards(tokenBoard.filters))
      dispatch(updateTokenBoardDrawerStatus(false))
      dispatch(resetTokenBoardDetails())
      return response
    } catch (err: any) {
      dispatch(stopLoader())
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)

// GET ALL FACILITIES

export const getTokenBoardFacilities = createAsyncThunk(
  '/getTokenBoardFacilities',
  async (facilityFilters: ITokenBoardFacilityFilters, { dispatch, rejectWithValue }) => {
    dispatch(startLoader())
    try {
      const response = await tokenBoardService.getTokenBoardFacilities(facilityFilters)
      dispatch(updateTokenBoardAddFacilityModalStatus(true))
      dispatch(stopLoader())
      return response
    } catch (err: any) {
      dispatch(stopLoader())
      return rejectWithValue(err)
    }
  },
)

// CHECK TOKEN BOARD ACTIVATION CODE

export const checkTokenBoardActivationCode = createAsyncThunk(
  '/checkTokenBoardActivationCode',
  async (activationCodeInfo: ITokenBoardActivationCode, { dispatch, rejectWithValue }) => {
    dispatch(startLoader())
    try {
      const response = await tokenBoardService.checkTokenBoardActivationCode(activationCodeInfo)
      dispatch(stopLoader())
      window.location.href = response.data.token_board_url
      return response
    } catch (err: any) {
      dispatch(stopLoader())
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)

export const tokenBoardSlice = createSlice({
  name: 'tokenBoard',
  initialState,
  reducers: {
    changeFilters: (state, action) => {
      return {
        ...state,
        filters: action.payload,
      }
    },
    resetFilters: (state) => {
      state.filters = initialState.filters
    },
    updateTokenBoardMode: (state, action) => {
      return {
        ...state,
        tokenBoardMode: action.payload,
      }
    },
    updateTokenBoardDrawerStatus: (state, action) => {
      return {
        ...state,
        tokenBoardDrawer: action.payload,
      }
    },
    deleteTokenBoardModal: (state) => {
      state.deleteTokenBoardStatus = true
    },
    closeDeleteTokenBoardModal: (state) => {
      state.deleteTokenBoardStatus = false
    },
    updateTokenBoardAddFacilityModalStatus: (state, action) => {
      return {
        ...state,
        tokenBoardAddFacilityModal: action.payload,
      }
    },
    resetTokenBoardDetails: (state) => {
      return {
        ...state,
        tokenBoardDetails: initialState.tokenBoardDetails,
        tokenBoardMode: initialState.tokenBoardMode,
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllTokenBoards.pending, (state) => {
        state.isLoadingTokenBoardList = true
      })
      .addCase(getAllTokenBoards.fulfilled, (state, action) => {
        state.token_boards = action.payload.data.token_boards
        state.isLoadingTokenBoardList = false
      })
      .addCase(getAllTokenBoards.rejected, (state) => {
        state.isLoadingTokenBoardList = false
      })
      .addCase(getTokenBoardDetails.pending, (state) => {
        state.isLoadingTokenBoardDetails = true
      })
      .addCase(getTokenBoardDetails.fulfilled, (state, action) => {
        if (
          action.payload.data &&
          action.payload.data.token_board &&
          Object.keys(action.payload.data.token_board.slides).length === 0
        ) {
          const tokenBoardDetails = {
            ...action.payload.data.token_board,
            slides: initialState.tokenBoardDetails.slides,
          }
          state.tokenBoardDetails = tokenBoardDetails
        } else {
          state.tokenBoardDetails = action.payload.data.token_board
        }
        state.isLoadingTokenBoardDetails = false
      })
      .addCase(getTokenBoardDetails.rejected, (state) => {
        state.isLoadingTokenBoardDetails = false
      })
      .addCase(getTokenBoardFacilities.fulfilled, (state, action) => {
        state.tokenBoardFacilities = action.payload.data.facilities
      })
  },
})

export const {
  changeFilters,
  updateTokenBoardMode,
  updateTokenBoardDrawerStatus,
  deleteTokenBoardModal,
  closeDeleteTokenBoardModal,
  updateTokenBoardAddFacilityModalStatus,
  resetTokenBoardDetails,
  resetFilters,
} = tokenBoardSlice.actions

export const tokenBoardData = (state: RootState) => state.tokenBoard
export default tokenBoardSlice.reducer
