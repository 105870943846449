import { IIcon } from './iconConstants'
const ArrowLeftIcon = (props: IIcon) => {
  return (
    <svg
      xmlnsXlink='http://www.w3.org/1999/xlink'
      viewBox='0 0 16 16'
      xmlns='http://www.w3.org/2000/svg'
      height={props.height}
      width={props.width}
    >
      <g fill={props.fill}>
        <path d='M6.293 8l6-6 .707.707L7.707 8 13 13.293l-.707.707z' />
        <path d='M2.293 8l6-6L9 2.707 3.707 8 9 13.293 8.293 14z' />
      </g>
    </svg>
  )
}
export default ArrowLeftIcon
