import { IIcon } from './iconConstants'
const BuildingLineIcon = (props: IIcon) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox='0 0 20 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M17.5 16.333h1.667V18H.833v-1.667H2.5v-12.5c0-.46.373-.833.833-.833h8.334c.46 0 .833.373.833.833v12.5h3.333V9.667h-1.666V8h2.5c.46 0 .833.373.833.833v7.5zM4.167 4.667v11.666h6.666V4.667H4.167zm1.666 5h3.334v1.666H5.833V9.667zm0-3.334h3.334V8H5.833V6.333z'
        fill={props.fill}
      />
    </svg>
  )
}
export default BuildingLineIcon
