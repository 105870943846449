import { IIcon } from './iconConstants'
const LocationLargeIcon = (props: IIcon) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox='0 0 18 22'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g fill='none' fillRule='evenodd'>
        <path d='M-3-2h24v24H-3z' />
        <path
          d='m9 18.9 4.95-4.95a7 7 0 1 0-9.9 0L9 18.9zm0 2.828-6.364-6.364a9 9 0 1 1 12.728 0L9 21.728zM9 11a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm0 2a4 4 0 1 1 0-8 4 4 0 0 1 0 8z'
          fill={props.fill}
          fillRule='nonzero'
        />
      </g>
    </svg>
  )
}
export default LocationLargeIcon
