import PublicLayout from '../layout/publicLayout'
import PrivacyPolicyAndTermsOfService from '../pages/common/PrivacyPolicyAndTermsOfService'
import RedirectIfLoggedIn from './utils/redirectIfLoggedIn'
// ==============================|| PUBLIC PAGES ROUTING WITH NAVIGATION HEADER ||============================== //
import HomePage from '../pages/common/landingPage'
import ContactUs from '../pages/common/contactUs'
import FAQSection from '../pages/common/landingPage/faq'
import ComingSoonPage from '../pages/common/comingSoon'
const PublicRoutes = {
  path: '/',
  element: (
    <RedirectIfLoggedIn>
      <PublicLayout />
    </RedirectIfLoggedIn>
  ),
  children: [
    {
      path: '/',
      element: <HomePage />,
    },
    {
      path: 'home',
      element: <HomePage />,
    },
    {
      path: 'contact-us',
      element: <ContactUs />,
    },
    {
      path: 'faq',
      element: <FAQSection />,
    },
    {
      path: 'privacy-policy',
      element: <PrivacyPolicyAndTermsOfService />,
    },
    {
      path: 'termsofservice',
      element: <PrivacyPolicyAndTermsOfService />,
    },
    {
      path: 'coming-soon',
      element: <ComingSoonPage />,
    },
  ],
}

export default PublicRoutes
