// ==============================|| OVERRIDES - BUTTON ||============================== //

export default function Button(theme: any) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 500,
        },
        contained: {
          borderRadius: '0.625rem',
          fontWeight: 600,
          boxShadow: `0 0.313rem 0.625rem 0 ${theme.palette.shadow.main}`,
        },
        outlined: {
          borderRadius: '0.625rem',
          fontWeight: 600,
        },
      },
    },
  }
}
