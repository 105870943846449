import { IIcon } from './iconConstants'
const BuildingWithCloseIcon2 = (props: IIcon) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox='0 0 59 50'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g fill='none' fillRule='evenodd'>
        <g opacity='.5'>
          <path d='M0 0h49.5v49.5H0z' />
          <path
            d='M37.125 6.188c1.14 0 2.063.923 2.063 2.062v10.313h3.378a14.063 14.063 0 0 0-4.833 4.125h-10.92v16.5h4.125V28.813h4.125l-.001.865a14.174 14.174 0 0 0 0 2.644v6.866h2.58a14.067 14.067 0 0 0 4.69 4.125H4.125v-4.125h2.063V8.25c0-1.14.923-2.062 2.062-2.062h28.875zm-2.062 4.125h-24.75v28.875h12.375V18.563h12.375v-8.25zm-16.5 20.625v4.125h-4.125v-4.125h4.125zm0-8.25v4.125h-4.125v-4.125h4.125zm0-8.25v4.125h-4.125v-4.125h4.125z'
            fill={props.backgroundColor}
            fillRule='nonzero'
          />
        </g>
        <path
          d='M48 20.7c2.844 0 5.42 1.153 7.283 3.017A10.268 10.268 0 0 1 58.3 31c0 2.844-1.153 5.42-3.017 7.283A10.268 10.268 0 0 1 48 41.3c-2.844 0-5.42-1.153-7.283-3.017A10.268 10.268 0 0 1 37.7 31c0-2.844 1.153-5.42 3.017-7.283A10.268 10.268 0 0 1 48 20.7zm0 2.6a7.676 7.676 0 0 0-5.445 2.255A7.676 7.676 0 0 0 40.3 31c0 2.126.862 4.051 2.255 5.445A7.676 7.676 0 0 0 48 38.7a7.676 7.676 0 0 0 5.445-2.255A7.676 7.676 0 0 0 55.7 31a7.676 7.676 0 0 0-2.255-5.445A7.676 7.676 0 0 0 48 23.3zm2.828 3.033 1.84 1.839L49.837 31l2.83 2.828-1.84 1.84L48 32.837l-2.828 2.83-1.84-1.84L46.163 31l-2.83-2.828 1.84-1.84L48 29.163l2.828-2.83z'
          stroke={props.fill}
          strokeWidth='.6'
          fill={props.fill}
          fillRule='nonzero'
        />
      </g>
    </svg>
  )
}
export default BuildingWithCloseIcon2
