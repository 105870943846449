import { get } from './apiServices'

const translated = async (language: string) => {
  const response = await get(`/translations?lang=${language}`)
  return response
}

const getLanguages = async () => {
  const response = await get('/get-languages')
  return response
}

const translationService = {
  translated,
  getLanguages,
}

export default translationService
