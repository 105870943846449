/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable camelcase */
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  ClickAwayListener,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  InputBase,
  Link,
  Paper,
  styled,
  Tab,
  Tabs,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import * as React from 'react'

import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CloseIcon from '@mui/icons-material/Close'
import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../_helpers/hooks'
import { handleMessages } from '../../../_helpers/utilities'
import { IFloorDetails, IFloorsNewBooking, ISeats } from '../../../_models/newBookingInterface'
import { ITab } from '../../../_models/tabsInterface'
import { StyledChip } from '../../../components/@extended/customChip'
import { StyledPopper } from '../../../components/@extended/customPopper'
import { CustomScrollbarBox } from '../../../components/@extended/customScrollbar'
import { PlaceholderImageContainer, SearchContainer } from '../../../components/common'
import { ArrowLeftIcon, ArrowRightIcon, LayersIcon, SearchIcon } from '../../../components/icons'
import GridSkeleton from '../../../components/loader/contentLoader/gridSkeleton'
import LightTooltip from '../../../components/toolTip'
import { setSearchSeatFilter } from '../../../slices/floorSlice'
import { newBookingData, updateSelectedSeats } from '../../../slices/newBookingSlice'
import { errorToast } from '../../../slices/toastSlice'
import CSVSeatSelection from './csvSeatSelection'
import CSVSeatSelectionBookForOthers from './csvSeatSelectionBookForOthers'
import SVGSeatSelection from './svgSeatSelection'
interface ISeatSelectionArea {
  noOfSeat: number
  updateDrawerWidth: (value: boolean) => void
  selectedFloor: IFloorsNewBooking | null
  handleFloorSelectionChange: (value: IFloorsNewBooking | null) => void
  svgSeatSelectionRef: React.RefObject<HTMLElement>
  isBookForOthers: boolean
  userEmails: string[] | []
}
const icon = <CheckBoxOutlineBlankIcon fontSize='small' />
const checkedIcon = <CheckBoxIcon fontSize='small' />
export interface IAvailabilityOptions {
  value: number
  title: string
}

interface IFilterOption {
  value: number
  title: string
}

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography component='div'>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}
const StyledTab = styled(Tabs)(({ theme }) => ({
  '& .MuiTab-root': {
    color: theme.palette.card.contrastText,
    backgroundColor: theme.palette.label.main,
  },
  '& .Mui-selected': {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
}))
const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  '& .MuiTypography-root': {
    fontSize: '.875rem',
    color: theme.palette.table.dark,
  },
  '& .MuiCheckbox-root': {
    color: theme.palette.secondary.light,
  },
  '& .Mui-checked': {
    color: theme.palette.primary.main,
  },
}))
const StyledFormControlLabelForSearch = styled(FormControlLabel)(({ theme }) => ({
  '& .MuiTypography-root': {
    fontSize: '.75rem',
    color: theme.palette.tab.dark,
    fontWeight: 'bold',
  },
  '& .MuiCheckbox-root': {
    color: theme.palette.secondary.light,
  },
  '& .Mui-checked': {
    color: theme.palette.primary.main,
  },
  '&.Mui-disabled': {
    cursor: 'not-allowed',
  },
}))

const SeatSelectionArea: React.FC<ISeatSelectionArea> = ({
  noOfSeat,
  updateDrawerWidth,
  selectedFloor,
  handleFloorSelectionChange,
  svgSeatSelectionRef,
  isBookForOthers,
  userEmails,
}) => {
  const dispatch = useAppDispatch()
  const newBookingState = useAppSelector(newBookingData)
  const { floors, isLoadingFloors, isLoadingSeats, floorDetails, selectedSeats, bookingDetails } =
    newBookingState
  const { colorCodes, seats, availableSeats } = floorDetails

  const theme = useTheme()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover-advanced-search' : undefined
  const mapFormat = selectedFloor && selectedFloor.floor_type === 2 ? 'csv' : 'svg'
  const translatedInput = useAppSelector((state) => state?.translation.translatedData)
  const [expand, setExpand] = useState(false)
  const [filterTabValue, setFilterTabValue] = useState(0)
  const [anchorElSearchSvg, setAnchorElSearchSvg] = React.useState<null | HTMLElement>(null)
  const openSearchSvg = Boolean(anchorElSearchSvg)
  const idSearchSvg = openSearchSvg ? 'simple-popover-SearchSvg' : undefined
  const [svgSeatSearchText, setSvgSeatSearchText] = useState('')

  const [unselectedSeatId, setUnselectedSeatId] = useState<number | null>(null)

  // svg seat selection
  const [isAllSVGSeatsSelected, setIsAllSVGSeatsSelected] = useState<boolean>(false)
  const svgSeatSelectionContainerRef = React.useRef<HTMLElement | null>(null)
  const availabilityOptions = [
    { title: translatedInput?.mapView?.availableTxtMap, value: 1 },
    { title: translatedInput?.bookingDetails?.selectedText, value: 2 },
    { title: translatedInput?.mapView?.unavailableTxtMap, value: 0 },
  ]
  const autoCompleteSeatsData = svgSeatSearchText
    ? seats.filter((seat: ISeats) =>
        seat.seat_name.toLowerCase().includes(svgSeatSearchText.toLowerCase()),
      )
    : seats

  const [seatResults, setSeatResults] = useState<IFloorDetails>(floorDetails)
  const [searchSeatDetails, setSearchSeatDetails] = useState<ISeats[]>([])
  const [filteredSeatDetails, setFilteredSeatDetails] = useState<ISeats[]>([])
  const [selectedStatus, setSelectedStatus] = useState<string[]>([])
  const [selectedSections, setSelectedSections] = useState<string[]>([])
  const [chipFiltered, setChipFiltered] = useState<string[]>([])
  const [searchChipValue, setSearchChipValue] = useState('')
  const [filteredStatus, setFilteredStatus] = useState<string[]>(selectedStatus)
  const [filteredSections, setFilteredSections] = useState<string[]>(selectedSections)
  const filterTab: ITab[] = [
    {
      id: 0,
      label: `Availability${selectedStatus.length > 0 ? ` (${selectedStatus.length})` : ''}`,
      filterValue: 'availability',
    },
    {
      id: 1,
      label: `Section${selectedSections.length > 0 ? ` (${selectedSections.length})` : ''}`,
      filterValue: 'section',
    },
  ]

  useEffect(() => {
    const tempSeats = filteredSeatDetails?.filter((seat) =>
      searchSeatDetails?.some((sectionSeat) => seat.seat_id === sectionSeat.seat_id),
    )
    const result = {
      seats: tempSeats,
      availableSeats: floorDetails.availableSeats,
      colorCodes: floorDetails.colorCodes,
    }
    setSeatResults(result)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredSeatDetails, searchSeatDetails])

  useEffect(() => {
    setSeatResults(floorDetails)
    setFilteredSeatDetails(floorDetails?.seats)
    setSearchSeatDetails(floorDetails?.seats)
  }, [floorDetails])
  useEffect(() => {
    if (chipFiltered.length) handleFilterApply()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSeats, seats])

  const getUniqueSeatSection = (floorDetails: IFloorDetails) => {
    const seatGroupNamesSet: any = new Set(floorDetails.seats.map((seat) => seat.seat_group_name))
    const uniqueSeatGroupNames: string[] = Array.from(seatGroupNamesSet)
    return uniqueSeatGroupNames
  }

  const uniqueSeatGroupNames = getUniqueSeatSection(floorDetails)
  const sectionOptions = uniqueSeatGroupNames.map((name, index) => ({
    title: name,
    value: index + 1,
  }))

  // csv search
  const closePopper = () => {
    setAnchorEl(null)
  }
  const handleCancelButton = () => {
    closePopper()
    setSelectedSections(filteredSections)
    setSelectedStatus(filteredStatus)
  }
  const handleClickAway = () => {
    handleCancelButton()
  }
  const handleOpenPopper = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleFilterTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setFilterTabValue(newValue)
  }
  // csv search end

  const handleExpand = (value: boolean) => {
    setExpand(value)
    updateDrawerWidth(value)
  }

  // svg search
  const closePopperSearchSvg = () => {
    setAnchorElSearchSvg(null)
  }
  const handleCancelButtonSearchSvg = () => {
    closePopperSearchSvg()
  }
  const handleClickAwaySearchSvg = () => {
    setIsAllSVGSeatsSelected(false)
    handleCancelButtonSearchSvg()
  }
  const handleSearchSeatClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElSearchSvg(event.currentTarget)
  }
  const handleClearSelectionClick = () => {
    setSvgSeatSearchText('')
    if (svgSeatSelectionRef?.current) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const currentRef = svgSeatSelectionRef.current as any
      currentRef.handleClose()
    }
  }
  const handleSeatCodeClick = (name: string, id: number, closeSeatSearch: boolean) => {
    if (svgSeatSelectionRef?.current && name) {
      const currentRef = svgSeatSelectionRef.current as any
      currentRef.findPathAndNavigateWithSeatName(name, isBookForOthers ? false : true)
    }
    if (closeSeatSearch) {
      setSvgSeatSearchText(name)
      setAnchorElSearchSvg(null)
    }
    if (id && svgSeatSelectionContainerRef?.current) {
      svgSeatSelectionContainerRef?.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }
  }
  const handleSvgSeatSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value.slice(0, 25)
    setSvgSeatSearchText(inputValue)
  }
  // svg search end
  const handleFloorChange = (event: React.ChangeEvent<object>, value: IFloorsNewBooking | null) => {
    dispatch(updateSelectedSeats([]))
    handleFloorSelectionChange(value)
    setChipFiltered([])
    setSearchChipValue('')
    setFilteredStatus([])
    setFilteredSections([])
    setSelectedSections([])
    setSelectedStatus([])
    setSvgSeatSearchText('')
  }
  const handleSeatSelectionChange = (data: ISeats[]) => {
    dispatch(updateSelectedSeats(data))
    setUnselectedSeatId(null)
  }

  const handleSelectedSeatClearClick = (data: number) => {
    if (selectedFloor?.floor_type === 1) {
      const unSelectedSeat = seats.find((seat: ISeats) => seat.seat_id === data)
      if (unSelectedSeat) {
        // seat un selection from parent component
        handleSVGSeatSelectionChange(unSelectedSeat, 'unselect')
      }
    } else {
      setUnselectedSeatId(data)
    }
  }

  const handleSVGSeatSelectionChange = (seatDetails: ISeats, seatAction: string) => {
    if (seatAction === 'select') {
      handleSeatSelectionChange([...selectedSeats, seatDetails])
    } else {
      const updatedSeats = selectedSeats.filter(
        (seat: ISeats) => seat.seat_id !== seatDetails.seat_id,
      )
      handleSeatSelectionChange(updatedSeats)
      setIsAllSVGSeatsSelected(false)
    }
  }

  const handleSimpleSearch = (searchValue: string | number) => {
    dispatch(setSearchSeatFilter({ search: searchValue }))
    const matchingSeats = floorDetails.seats.filter((seat) =>
      seat.seat_name.toLowerCase().includes((searchValue + '').toLowerCase()),
    )
    setSearchSeatDetails(matchingSeats)
    setSearchChipValue(searchValue + '')
  }
  const handleStatusSelection = (option: IFilterOption) => {
    const isSelected = selectedStatus.includes(option?.title)
    if (!isSelected) {
      setSelectedStatus([...selectedStatus, option?.title])
    } else {
      setSelectedStatus(selectedStatus.filter((value) => value !== option?.title))
    }
  }

  const handleSectionSelection = (option: IFilterOption) => {
    const isSelected = selectedSections.includes(option.title)
    if (!isSelected) {
      setSelectedSections([...selectedSections, option.title])
    } else {
      setSelectedSections(selectedSections.filter((value) => value !== option.title))
    }
  }

  const getSeatStatusName = (statusNumber: number) => {
    if (statusNumber !== 1) return 'Unavailable'
    else if (statusNumber === 1) return 'Available'
    // else if (statusNumber == 2) return 'Selected'
    else return ''
  }
  const [isFilterDeleted, setIsFilterDeleted] = useState(false)
  const handleFilterApply = () => {
    let filteredSeats = floorDetails.seats.filter((seat) => {
      let availabilityMatch, sectionMatch
      if (selectedStatus.length == 0) availabilityMatch = floorDetails.seats
      else availabilityMatch = selectedStatus.includes(getSeatStatusName(seat.status as number))

      if (selectedSections.length == 0) sectionMatch = floorDetails.seats
      else sectionMatch = selectedSections.includes(seat.seat_group_name as string)

      return availabilityMatch && sectionMatch
    })
    if (selectedStatus.includes('Selected')) {
      const uniqueSelectedSeats = selectedSeats.filter((selectedSeat) => {
        return !filteredSeats.some((filteredSeat) => filteredSeat.seat_id === selectedSeat.seat_id)
      })
      filteredSeats = [...filteredSeats, ...uniqueSelectedSeats]
    }

    setFilteredSeatDetails(filteredSeats)
    handleChips()
    closePopper()
    setFilteredStatus(selectedStatus)
    setFilteredSections(selectedSections)
  }

  const handleChips = () => {
    setChipFiltered(selectedStatus.concat(selectedSections))
  }
  const handleChipDelete = (chip: string) => {
    setIsFilterDeleted(true)
    if (selectedStatus.includes(chip)) {
      setSelectedStatus((prevOptions) => prevOptions.filter((value) => value !== chip))
    }

    if (selectedSections.includes(chip)) {
      setSelectedSections((prevSections) => prevSections.filter((value) => value !== chip))
    }
    handleFilterApply()
  }
  useEffect(() => {
    if (isFilterDeleted) {
      handleFilterApply()
      setIsFilterDeleted(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFilterDeleted])

  const onEnterKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault()
    }
  }
  const getSeatProps = () => {
    return (
      <>
        <Box display='flex' flexDirection='column'>
          {selectedSeats.map((data, index) => (
            <Box
              key={data.seat_id}
              display='flex'
              alignItems='center'
              pt={1}
              pb={index === selectedSeats.length - 1 ? 0 : 1}
              sx={{
                borderBottom:
                  index === selectedSeats.length - 1
                    ? 'none'
                    : `0.063rem solid ${theme.palette.publicPages.dark}`,
              }}
            >
              <Typography
                variant='subtitle2'
                color='common.black'
                sx={{ minWidth: '6.25rem', maxWidth: '6.25rem' }}
              >
                {data.seat_name}
              </Typography>
              <Typography
                variant='subtitle2'
                color='secondary.dark'
                ml={2}
                sx={{ wordBreak: 'break-all', minWidth: { md: '10.25rem' } }}
              >
                {data.user_email}
              </Typography>
            </Box>
          ))}
        </Box>
      </>
    )
  }
  return (
    <>
      <Box>
        <Grid
          container
          columnSpacing={'1.25rem'}
          width={expand ? { xs: '100%', md: '50%' } : '100%'}
        >
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6} mt={2}>
            <Autocomplete
              id='combo-box-demo'
              blurOnSelect={true}
              onChange={(event, value) => handleFloorChange(event, value)}
              value={selectedFloor}
              isOptionEqualToValue={(option, value) => option?.floor_id === value?.floor_id}
              getOptionLabel={(option) => option.floor_name}
              options={floors}
              clearOnBlur
              loading={isLoadingFloors}
              onKeyPress={onEnterKeyPress}
              renderOption={(props, option) => {
                let statusColor = ''

                if (option.fully_booked === 0) {
                  statusColor = '#289149'
                } else {
                  statusColor = '#787878'
                }

                return (
                  <li {...props} style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span>{option.floor_name}</span>
                    <span style={{ color: statusColor }}>
                      {option.fully_booked === 0
                        ? translatedInput?.mapView?.availableTxtMap
                        : translatedInput?.floorPlan?.floorNotAvailableText}
                    </span>
                  </li>
                )
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={`${translatedInput?.floorPlan?.floorNameFieldText}${'*'}`}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position='start'>
                        <LayersIcon
                          fill={theme.palette.toggleButtonGroup.contrastText}
                          width='17'
                          height='17'
                        />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <>
                        {isLoadingFloors ? <CircularProgress color='inherit' size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          {selectedFloor !== null && !isLoadingSeats && (
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} mt={2}>
              {mapFormat === 'csv' ? (
                <SearchContainer
                  searchDefaultValue={''}
                  needAdvanceFilter={true}
                  handleOpenAdvanceFilter={handleOpenPopper}
                  handleSimpleSearch={handleSimpleSearch}
                  placeholder={translatedInput?.newBookingScreenText?.searchSeatsText}
                  searchChipValue={searchChipValue}
                />
              ) : (
                <Paper
                  component='form'
                  sx={{
                    p: '0.282rem 0.25rem',
                    display: 'flex',
                    alignItems: 'center',
                    width: { sm: '100%' },

                    boxShadow: 'none',
                    border: 'solid 0.063rem',
                    borderColor: 'secondary.light',
                    borderRadius: '0.625rem',
                  }}
                  onClick={handleSearchSeatClick}
                >
                  <IconButton sx={{ p: '0.625rem' }}>
                    <SearchIcon fill={theme.palette.secondary.dark} width='16' height='16' />
                  </IconButton>
                  <InputBase
                    sx={{ ml: 1, flex: 1, fontSize: '0.875rem', fontFamily: 'Lexend' }}
                    placeholder={translatedInput?.mapView?.searchSeats}
                    inputProps={{ 'aria-label': 'Search Seat' }}
                    value={svgSeatSearchText}
                    onChange={handleSvgSeatSearchInputChange}
                    onKeyPress={onEnterKeyPress}
                  />
                  {svgSeatSearchText != '' && (
                    <IconButton
                      sx={{ p: '0.625rem' }}
                      onClick={(e) => {
                        e.stopPropagation() // Prevent the click event from bubbling up to the Paper component
                        handleClearSelectionClick() // Call your custom handler
                      }}
                    >
                      <CloseRoundedIcon style={{ width: '1.063rem', height: '1.063rem' }} />
                    </IconButton>
                  )}
                </Paper>
              )}
            </Grid>
          )}
        </Grid>
        <Box display='flex' mt={1}>
          <Box
            display='flex'
            flexWrap='wrap'
            justifyContent='flex-start'
            alignItems='flex-start'
            gap={2}
            mt={1}
          >
            {' '}
            {searchChipValue && (
              <StyledChip
                deleteIcon={
                  <CloseIcon style={{ fill: theme.palette.common.white, width: 20, height: 20 }} />
                }
                key={'search'}
                label={
                  <Box display='flex'>
                    <Typography variant='subtitle2'>{searchChipValue}</Typography>
                  </Box>
                }
                onDelete={() => handleSimpleSearch('')}
              />
            )}
            {chipFiltered.map((chip) => (
              <StyledChip
                deleteIcon={
                  <CloseIcon style={{ fill: theme.palette.common.white, width: 20, height: 20 }} />
                }
                key={chip}
                label={
                  <Box display='flex'>
                    <Typography variant='subtitle2'>{chip}</Typography>
                  </Box>
                }
                onDelete={() => handleChipDelete(chip)}
              />
            ))}{' '}
          </Box>
        </Box>
        {selectedFloor != null && !isLoadingSeats && (
          <>
            <Box display={{ md: 'flex' }} justifyContent={'space-between'} mt={4}>
              <Typography variant='subtitle1' color='description.main'>
                {translatedInput?.mapView?.availableSeatsMap}:{' '}
                <Typography
                  variant='subtitle1'
                  color='description.main'
                  display='inline'
                  fontWeight={'bold'}
                >
                  {availableSeats}
                </Typography>
              </Typography>
              <Box display={'flex'}>
                <Box display={'flex'} mr={3}>
                  <Box
                    sx={{
                      width: 17,
                      height: 17,
                      border: '0.063rem solid',
                      borderColor: colorCodes.available_seat_border_color,
                    }}
                    mr={1}
                    mt={0.5}
                  />
                  <Typography variant='subtitle2' color='floor.main' fontWeight={'normal'}>
                    {translatedInput?.mapView?.availableTxtMap}
                  </Typography>
                </Box>
                <Box display={'flex'} mr={3}>
                  <Box
                    sx={{
                      width: 17,
                      height: 17,
                      backgroundColor: colorCodes.selected_seat_color,
                      border: `0.063rem solid ${colorCodes.selected_seat_border_color}`,
                    }}
                    mr={1}
                    mt={0.5}
                  />
                  <Typography variant='subtitle2' color='floor.main' fontWeight={'normal'}>
                    {translatedInput?.common?.selectedText}
                  </Typography>
                </Box>
                <Box display={'flex'}>
                  <Box
                    sx={{
                      width: 17,
                      height: 17,
                      border: `0.063rem solid ${colorCodes.unavailable_seat_border_color}`,
                      backgroundColor: colorCodes.unavailable_seat_color,
                    }}
                    mr={1}
                    mt={0.5}
                  />
                  <Typography variant='subtitle2' color='floor.main' fontWeight={'normal'}>
                    {translatedInput?.mapView?.unavailableTxtMap}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Grid container columnSpacing={'1.25rem'}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={2}>
                {mapFormat === 'svg' && (
                  <Box display={'flex'}>
                    <Box
                      ml={-3}
                      height={expand ? '70vh' : '30.25rem'}
                      display={'flex'}
                      justifyContent={'center'}
                      alignItems={'center'}
                      sx={{
                        '&:hover': {
                          backgroundColor: 'csv.light',
                          cursor: 'pointer',
                        },
                      }}
                      onClick={() => handleExpand(!expand)}
                      title={expand ? 'Collapse' : 'Expand'}
                    >
                      <IconButton
                        sx={{
                          '&:hover': {
                            backgroundColor: 'transparent',
                          },
                        }}
                      >
                        {expand ? (
                          <ArrowRightIcon fill={theme.palette.error.main} width='25' height='25' />
                        ) : (
                          <ArrowLeftIcon fill={theme.palette.error.main} width='18' height='18' />
                        )}
                      </IconButton>
                    </Box>

                    <Box
                      height={expand ? '70vh' : '500px'}
                      width={'100%'}
                      sx={{
                        borderRadius: 5,
                      }}
                      ref={svgSeatSelectionContainerRef}
                    >
                      <SVGSeatSelection
                        noOfSeat={noOfSeat}
                        handleSVGSeatSelectionChange={handleSVGSeatSelectionChange}
                        selectedSeats={selectedSeats}
                        seatSelectionRef={svgSeatSelectionRef}
                        isBookForOthers={isBookForOthers}
                        userEmails={userEmails}
                      />
                    </Box>
                  </Box>
                )}
                {mapFormat === 'csv' && (
                  <Box>
                    <Divider />
                    <CustomScrollbarBox
                      pt={1}
                      pb={3}
                      pr={2}
                      maxHeight={'40rem'}
                      sx={{ overflowY: 'auto' }}
                    >
                      {isBookForOthers ? (
                        <CSVSeatSelectionBookForOthers
                          noOfSeat={noOfSeat}
                          handleSeatSelectionChange={setUnselectedSeatId}
                          unselectedSeatId={unselectedSeatId}
                          seatResults={seatResults}
                          userEmails={userEmails}
                        />
                      ) : (
                        <CSVSeatSelection
                          noOfSeat={noOfSeat}
                          handleSeatSelectionChange={setUnselectedSeatId}
                          unselectedSeatId={unselectedSeatId}
                          seatResults={seatResults}
                        />
                      )}
                    </CustomScrollbarBox>
                  </Box>
                )}
              </Grid>
            </Grid>
            {selectedSeats.length > 0 && (
              <Box mt={2}>
                <Divider sx={{ mb: 3 }} />
                <Box display='flex'>
                  <Typography variant='h5' color='table.light'>
                    {translatedInput?.newBookingScreenText?.selectedSeatsText}
                  </Typography>
                  {isBookForOthers && (
                    <Box ml={3}>
                      <LightTooltip title={getSeatProps()} enterTouchDelay={0} needAutoClose={true}>
                        <Typography variant='subtitle2' color='error.main'>
                          {translatedInput?.bookingConfirmation?.viewDetails}
                        </Typography>
                      </LightTooltip>
                    </Box>
                  )}
                </Box>

                <Box display='flex' mt={1}>
                  <Box
                    display='flex'
                    flexWrap='wrap'
                    justifyContent='flex-start'
                    alignItems='flex-start'
                    gap={2}
                    mt={1}
                  >
                    {' '}
                    {selectedSeats.map((data) => (
                      <StyledChip
                        deleteIcon={
                          <CloseIcon
                            style={{ fill: theme.palette.common.white, width: 20, height: 20 }}
                          />
                        }
                        key={data.seat_id}
                        label={
                          <Box display='flex'>
                            <Typography variant='subtitle2'>{data.seat_name}</Typography>
                          </Box>
                        }
                        onDelete={() => handleSelectedSeatClearClick(data.seat_id)}
                      />
                    ))}{' '}
                  </Box>

                  {/* <Link variant='body2' color='primary' sx={{ ml: 2, py: 1 }}>
                clearAll
              </Link> */}
                </Box>
              </Box>
            )}
          </>
        )}
        {selectedFloor === null && isLoadingSeats === false && (
          <Box
            mt={4}
            minHeight={{
              xs: '12.5rem',
              sm: '18.75rem',
              md: '12.5rem',
              lg: '12.5rem',
              xl: '12.5rem',
            }}
          >
            <PlaceholderImageContainer
              text={translatedInput?.newBookingScreenText?.noFloorSelectedText}
              bordercolor={'transparent'}
              iconComponent={
                <LayersIcon fill={theme.palette.toggleButtonGroup.light} width='39' height='39' />
              }
            />
          </Box>
        )}
        {isLoadingSeats && (
          <GridSkeleton
            width='100%'
            height={'2.75rem'}
            count={8}
            xs={6}
            sm={3}
            md={3}
            lg={3}
            xl={3}
          />
        )}
      </Box>
      {/* csv search */}
      <StyledPopper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement='bottom-end'
        disablePortal={false}
        sx={{
          boxShadow: '0 0.188rem 1.375rem 0 rgba(178, 178, 178, 0.43)',
          borderRadius: '20px !important',
          width: { xs: '95%', md: '50%', lg: '40.5%', xl: '23.5%' },
          marginTop: '1.25rem !important',
          marginBottom: '1.25rem !important',
          zIndex: '1000',
        }}
      >
        <span className='arrow' />
        <ClickAwayListener onClickAway={handleClickAway}>
          <Box sx={{ backgroundColor: 'white', borderRadius: '0.5rem !important' }}>
            <Box p={3}>
              <StyledTab
                variant='scrollable'
                scrollButtons={false}
                value={filterTabValue}
                onChange={handleFilterTabChange}
                aria-label='tabs'
                TabIndicatorProps={{
                  style: {
                    display: 'none',
                  },
                }}
              >
                {filterTab.map((item: ITab, index: number) => {
                  return (
                    <Tab
                      sx={{
                        backgroundColor: {
                          xs: 'label.main',
                          md: 'common.white',
                          lg: 'common.white',
                          xl: 'common.white',
                        },
                        marginRight: '0.625rem',
                        width: '45%',
                      }}
                      key={item.id}
                      value={item.id}
                      label={item.label}
                      {...a11yProps(index)}
                    />
                  )
                })}
              </StyledTab>
              <Box minHeight={{ md: '10vh', xl: '15vh' }}>
                <TabPanel value={filterTabValue} index={0}>
                  <CustomScrollbarBox
                    pr={3}
                    pt={1}
                    pb={1}
                    maxHeight={'21.875rem'}
                    sx={{ overflowY: 'auto' }}
                  >
                    <FormGroup>
                      {availabilityOptions.map((option) => (
                        <StyledFormControlLabel
                          key={option.value}
                          control={
                            <Checkbox
                              checked={selectedStatus.includes(option?.title)}
                              onChange={() => handleStatusSelection(option)}
                            />
                          }
                          label={option.title}
                        />
                      ))}
                    </FormGroup>
                  </CustomScrollbarBox>
                </TabPanel>
                <TabPanel value={filterTabValue} index={1}>
                  <CustomScrollbarBox
                    pt={1}
                    pr={3}
                    pb={1}
                    maxHeight={'21.875rem'}
                    sx={{ overflowY: 'auto' }}
                  >
                    <FormGroup>
                      {sectionOptions.map((option) => (
                        <StyledFormControlLabel
                          key={option.value}
                          control={
                            <Checkbox
                              checked={selectedSections.includes(option?.title)}
                              onChange={() => handleSectionSelection(option)}
                            />
                          }
                          label={option.title}
                        />
                      ))}
                    </FormGroup>
                  </CustomScrollbarBox>
                </TabPanel>
              </Box>
            </Box>
            <Divider />
            <Box p={3}>
              <Grid container columnSpacing={'1.25rem'}>
                <Grid item xs={6} sm={6} md={6}>
                  <Button
                    sx={{ width: '100%' }}
                    variant='contained'
                    size='large'
                    type='submit'
                    onClick={handleFilterApply}
                  >
                    {translatedInput?.newBookingScreenText?.applyButtonText}
                  </Button>
                </Grid>

                <Grid item xs={6} sm={6} md={6}>
                  <Button
                    sx={{ width: '100%' }}
                    variant='outlined'
                    size='large'
                    onClick={handleCancelButton}
                  >
                    {translatedInput?.common?.cancelBtn}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </ClickAwayListener>
      </StyledPopper>
      {/* svg search */}
      <StyledPopper
        id={idSearchSvg}
        open={openSearchSvg}
        anchorEl={anchorElSearchSvg}
        placement='bottom-start'
        disablePortal={true}
        sx={{
          boxShadow: ' 0 0.188rem 1.375rem 0 rgba(178, 178, 178, 0.43)',
          borderRadius: '20px !important',
          minWidth: {
            xs: '85%',
            sm: '90%',
            md: expand ? '18.75rem' : '45%',
            lg: expand ? '19rem' : '45%',
            xl: expand ? '19.75rem' : '45%',
          },
          marginTop: '0.55rem !important',
          marginBottom: '1.25rem !important',
          zIndex: '1000',
        }}
      >
        <ClickAwayListener onClickAway={handleClickAwaySearchSvg}>
          <Box sx={{ backgroundColor: 'white', borderRadius: '0.5rem !important' }}>
            <Box pl={isBookForOthers ? 1.5 : 0}>
              <CustomScrollbarBox maxHeight={'21.875rem'} sx={{ overflowY: 'auto' }} p={3}>
                <FormGroup>
                  {autoCompleteSeatsData.length > 0 ? (
                    <Box display={'flex'}>
                      {!isBookForOthers && (
                        <>
                          <StyledFormControlLabelForSearch
                            control={
                              <Checkbox
                                checked={isAllSVGSeatsSelected}
                                onChange={(event) => {
                                  const allAvailableNotSelectedSeats = autoCompleteSeatsData.filter(
                                    (seat: ISeats) =>
                                      seat.status === 1 &&
                                      !selectedSeats.some(
                                        (selectedSeat: ISeats) =>
                                          selectedSeat.seat_id === seat.seat_id,
                                      ),
                                  )
                                  if (event.target.checked) {
                                    if (
                                      allAvailableNotSelectedSeats.length <= noOfSeat &&
                                      selectedSeats.length + allAvailableNotSelectedSeats.length <=
                                        noOfSeat
                                    ) {
                                      setIsAllSVGSeatsSelected(event.target.checked)
                                      dispatch(
                                        updateSelectedSeats([
                                          ...selectedSeats,
                                          ...allAvailableNotSelectedSeats,
                                        ]),
                                      )
                                    } else {
                                      dispatch(
                                        errorToast(
                                          handleMessages(
                                            translatedInput?.newBookingScreenText
                                              ?.seatSelectionLimitedToSeatsText,
                                          ),
                                        ),
                                      )
                                    }
                                  } else {
                                    setIsAllSVGSeatsSelected(event.target.checked)
                                    const updatedSeats = selectedSeats.filter(
                                      (selectedSeat: ISeats) =>
                                        !autoCompleteSeatsData.some(
                                          (seat: ISeats) => seat.seat_id === selectedSeat.seat_id,
                                        ),
                                    )
                                    dispatch(updateSelectedSeats(updatedSeats))
                                  }
                                }}
                              />
                            }
                            label={translatedInput?.newBookingScreenText?.selectAllText}
                          />
                          <Link
                            variant='subtitle2'
                            color='error.main'
                            sx={{ ml: 0.5, py: 1 }}
                            fontWeight={'normal'}
                            onClick={() => {
                              const updatedSeats = selectedSeats.filter(
                                (selectedSeat: ISeats) =>
                                  !autoCompleteSeatsData.some(
                                    (seat: ISeats) => seat.seat_id === selectedSeat.seat_id,
                                  ),
                              )
                              dispatch(updateSelectedSeats(updatedSeats))
                              setIsAllSVGSeatsSelected(false)
                            }}
                          >
                            {translatedInput?.newBookingScreenText?.clearButtonText}
                          </Link>
                        </>
                      )}
                    </Box>
                  ) : (
                    <Typography variant='subtitle1' color='seat.light' fontSize={13}>
                      {translatedInput?.newBookingScreenText?.noSeatsFoundText}
                    </Typography>
                  )}

                  {autoCompleteSeatsData.map((item: ISeats, index: number) => (
                    <Box display={'flex'} key={index} justifyContent={'space-between'}>
                      <Box display={'flex'}>
                        {!isBookForOthers && (
                          <StyledFormControlLabelForSearch
                            control={
                              <Checkbox
                                onChange={(event) => {
                                  if (event.target.checked) {
                                    // allow selection only if seat status is available
                                    if (item.status === 1 && selectedSeats.length < noOfSeat) {
                                      handleSVGSeatSelectionChange(item, 'select')
                                      handleSeatCodeClick(item.seat_name, item.seat_id, false)
                                    } else {
                                      dispatch(
                                        errorToast(
                                          handleMessages(
                                            translatedInput?.newBookingScreenText
                                              ?.chosenMaxSeatsToast,
                                          ),
                                        ),
                                      )
                                    }
                                  } else {
                                    handleSVGSeatSelectionChange(item, 'unSelect')
                                    setIsAllSVGSeatsSelected(false)
                                  }
                                }}
                                checked={selectedSeats.some(
                                  (seat: ISeats) => seat.seat_id === item.seat_id,
                                )}
                                disabled={item?.status !== 1 ? true : false} // enable checkbox only for available seats
                              />
                            }
                            label={''}
                            key={item.seat_id}
                          />
                        )}

                        <Link
                          variant='subtitle2'
                          color='csv.dark'
                          sx={{ ml: -2, py: 1 }}
                          fontWeight={'bold'}
                          onClick={() => handleSeatCodeClick(item.seat_name, item.seat_id, true)}
                        >
                          {item.seat_name}
                        </Link>
                      </Box>

                      {item?.status === 1 && (
                        <Typography variant='subtitle1' color='seat.light' mt={1.5} fontSize={11}>
                          {selectedSeats.some((seat: ISeats) => seat.seat_id === item.seat_id) ? (
                            <Typography variant='subtitle1' color='floor.light' fontSize={11}>
                              {translatedInput?.common?.selectedText}
                            </Typography>
                          ) : (
                            <Typography variant='subtitle1' color='seat.light' fontSize={11}>
                              {translatedInput?.mapView?.availableTxtMap}
                            </Typography>
                          )}
                        </Typography>
                      )}
                      {item?.status !== 1 && (
                        <Typography variant='subtitle1' color='seat.main' mt={1.5} fontSize={11}>
                          {translatedInput?.mapView?.unavailableTxtMap}
                        </Typography>
                      )}
                    </Box>
                  ))}
                </FormGroup>
              </CustomScrollbarBox>
            </Box>
          </Box>
        </ClickAwayListener>
      </StyledPopper>
    </>
  )
}

export default SeatSelectionArea
