import { Box, Grid, useTheme } from '@mui/material'
import ContentLoader from 'react-content-loader'
interface ISkeleton {
  xs?: number
  sm?: number
  md?: number
  lg?: number
  xl?: number
  cardNo?: number
  height?: string
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const FacilityListSkeleton2 = ({ xl, xs, sm, md, lg, cardNo, height }: ISkeleton) => {
  const theme = useTheme()
  return (
    <Box height='100%'>
      <Grid container columnSpacing={{ xs: '1rem' }} rowSpacing={{ xs: '1rem', sm: '0' }}>
        {Array.from(Array(cardNo ? cardNo : 2)).map((_, index) => (
          <Grid
            item
            xs={xs ? xs : 12}
            sm={sm ? sm : 6}
            md={md ? md : 6}
            lg={lg ? lg : 6}
            xl={xl ? xl : 6}
            mt={2}
            key={index}
          >
            <Box
              sx={{
                border: 'solid 0.063rem ',
                borderRadius: 2,
                borderColor: 'divider',
                height: height ? height : '300px',
                paddingRight: '1.45rem',
              }}
            >
              <ContentLoader
                speed={2}
                width={'100%'}
                height={'100%'}
                viewBox='0 0 100% 100%'
                backgroundColor={theme.palette.facilityList.colorTwo}
                foregroundColor={theme.palette.facilityList.colorThree}
              >
                <rect x='20' y='20' rx='0' ry='0' width='150' height='150' />
                <rect x='187' y='20' rx='0' ry='0' width='500' height='20' />
                <rect x='229' y='73' rx='0' ry='0' width='26' height='0' />
                <rect x='186' y='55' rx='0' ry='0' width='500' height='20' />
                <rect x='188' y='95' rx='0' ry='0' width='500' height='45' />
                <rect x='186' y='155' rx='0' ry='0' width='500' height='12' />
                <rect x='800' y='20' rx='0' ry='0' width='49' height='42' />
                <rect x='750' y='138' rx='0' ry='0' width='100' height='28' />
              </ContentLoader>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default FacilityListSkeleton2
