import { IIcon } from './iconConstants'
const Twitter2Icon = ({ width, height, fill, backgroundColor }: IIcon) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 41 41'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g filter='url(#vqisl4mv0a)'>
        <g filter='url(#6g9mfk959b)'>
          <rect x='4.857' y='-.006' width='32' height='32.184' rx='7.111' fill={fill} />
        </g>
        <path
          d='m21.907 14.208 4.857-5.677h-1.15l-4.218 4.929-3.104-4.542a.889.889 0 0 0-.733-.387h-3.416l5.093 7.454-5.093 5.953h1.15l4.454-5.206 3.293 4.819c.165.242.44.387.734.387h3.415l-5.282-7.73zm-1.576 1.842-.516-.742-4.107-5.906h1.768l3.314 4.766.516.743 4.308 6.195h-1.768l-3.515-5.056z'
          fill={backgroundColor}
        />
        <path
          d='m20.33 16.05-.515-.742-4.107-5.906h1.768l3.314 4.766.516.743 4.308 6.195h-1.768l-3.515-5.056z'
          fill={backgroundColor}
        />
      </g>
      <defs>
        <filter
          id='vqisl4mv0a'
          x='.857'
          y='-.006'
          width='40'
          height='40.184'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='4' />
          <feGaussianBlur stdDeviation='2' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
          <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_621_6860' />
          <feBlend in='SourceGraphic' in2='effect1_dropShadow_621_6860' result='shape' />
        </filter>
        <filter
          id='6g9mfk959b'
          x='-2.392'
          y='-7.255'
          width='46.498'
          height='46.682'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImageFix' stdDeviation='3.624' />
          <feComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_621_6860' />
          <feBlend in='SourceGraphic' in2='effect1_backgroundBlur_621_6860' result='shape' />
        </filter>
      </defs>
    </svg>
  )
}
export default Twitter2Icon
