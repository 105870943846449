import { IIcon } from './iconConstants'
const Facebook2Icon = (props: IIcon) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox='0 0 41 41'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g filter='url(#tjyy40bswa)'>
        <g filter='url(#f1r7vqrerb)'>
          <rect x='4.714' y='-.006' width='32' height='32.184' rx='7.111' fill={props.fill} />
        </g>
        <path
          d='m25.041 16.35.494-3.235h-3.089v-2.099c0-.885.431-1.748 1.814-1.748h1.403V6.514a17.023 17.023 0 0 0-2.489-.218c-2.542 0-4.204 1.55-4.204 4.354v2.465h-2.826v3.235h2.826v7.822h3.478V16.35h2.593z'
          fill={props.backgroundColor}
        />
      </g>
      <defs>
        <filter
          id='tjyy40bswa'
          x='.714'
          y='-.006'
          width='40'
          height='40.184'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='4' />
          <feGaussianBlur stdDeviation='2' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
          <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_621_6854' />
          <feBlend in='SourceGraphic' in2='effect1_dropShadow_621_6854' result='shape' />
        </filter>
        <filter
          id='f1r7vqrerb'
          x='-2.534'
          y='-7.255'
          width='46.498'
          height='46.682'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImageFix' stdDeviation='3.624' />
          <feComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_621_6854' />
          <feBlend in='SourceGraphic' in2='effect1_backgroundBlur_621_6854' result='shape' />
        </filter>
      </defs>
    </svg>
  )
}
export default Facebook2Icon
