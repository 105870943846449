import { IIcon } from './iconConstants'
const Building2Icon = (props: IIcon) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox='0 0 117 117'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g fill='none' fillRule='evenodd'>
        <path
          d='M10 0h97c5.523 0 10 4.477 10 10v97c0 5.523-4.477 10-10 10H10c-5.523 0-10-4.477-10-10V10C0 4.477 4.477 0 10 0z'
          fill='#F4F4F4'
        />
        <path d='M44 44h28.8v28.8H44z' />
        <path
          d='M69.2 66.8h2.4v2.4H45.2v-2.4h2.4v-18a1.2 1.2 0 0 1 1.2-1.2h12a1.2 1.2 0 0 1 1.2 1.2v18h4.8v-9.6h-2.4v-2.4H68a1.2 1.2 0 0 1 1.2 1.2v10.8zM50 50v16.8h9.6V50H50zm2.4 7.2h4.8v2.4h-4.8v-2.4zm0-4.8h4.8v2.4h-4.8v-2.4z'
          fill={props.fill}
          fillRule='nonzero'
        />
      </g>
    </svg>
  )
}
export default Building2Icon
