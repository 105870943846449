import { IDeleteIds, IInviteUserParams, IUserLIstFilters } from '../_models/inviteUserInterface'
import { get, post, put } from './apiServices'

const getUsersList = async (filters: IUserLIstFilters) => {
  const response = await get('/business/invite-user/list', filters)

  return response
}
const getFacilityList = async () => {
  // providing a greater per page value to get all facilities (per_page = 1000)
  const response = await get('/business/invite-user/facilities?per_page=1000')
  return response
}
const inviteUser = async (params: IInviteUserParams) => {
  const response = await post('/business/invite-user', params)
  return response
}
const reinviteUser = async (id: number) => {
  const response = await put(`/business/re-invite-user/${id}`)
  return response
}

const deleteUser = async (ids: IDeleteIds) => {
  const response = await post('/business/delete-user-invite', ids)

  return response
}
const getUserDetails = async (id: number) => {
  const response = await get(`/business/user-details/${id}`)
  return response
}
const editInvitedUser = async (id: number, params: IInviteUserParams) => {
  const response = await put(`/business/edit-user/${id}`, params)
  return response
}
const checkIfUserIsInvited = async (params: { email: string }) => {
  const response = await get('/business/invite-user/details', params)
  return response
}
const allowFacilitySelection = async (id: number) => {
  const response = await get(`/business/invite-user/role/${id}`)
  return response
}
const businessUserService = {
  getUsersList,
  getFacilityList,
  inviteUser,
  reinviteUser,
  deleteUser,
  getUserDetails,
  editInvitedUser,
  checkIfUserIsInvited,
  allowFacilitySelection,
}

export default businessUserService
