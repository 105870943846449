import { useState } from 'react'

function useQueue<T>(
  initialQueue: T[] = [],
): [
  T[],
  (element: T) => void,
  () => T | null,
  () => T | null,
  () => T | null,
  () => boolean,
  () => number,
  () => void,
] {
  const [queue, setQueue] = useState(initialQueue)

  // Adds an element to the queue
  function enqueue(element: T): void {
    setQueue((prevQueue) => [...prevQueue, element])
  }

  // Removes an element from the queue and returns it
  function dequeue(): T | null {
    if (isEmpty()) {
      return null
    }
    const [first, ...rest] = queue
    setQueue(rest)
    return first
  }

  // Returns the front element of the queue without removing it
  function front(): T | null {
    if (isEmpty()) {
      return null
    }
    return queue[0]
  }

  // Returns the rear element of the queue without removing it
  function rear(): T | null {
    if (isEmpty()) {
      return null
    }
    return queue[queue.length - 1]
  }

  // Returns true if the queue is empty, false otherwise
  function isEmpty(): boolean {
    return queue.length === 0
  }

  // Returns the size of the queue
  function size(): number {
    return queue.length
  }

  // Clears all elements from the queue
  function clear(): void {
    setQueue([])
  }

  return [queue, enqueue, dequeue, front, rear, isEmpty, size, clear]
}

export default useQueue
