import { IIcon } from './iconConstants'
const MenuIcon = (props: IIcon) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox='0 0 4 18'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g fill='none' fillRule='evenodd' opacity={props.opacity ? props.opacity : '.6'}>
        <path d='M-9-2h22v22H-9z' />
        <path
          d='M2 .75A1.839 1.839 0 0 0 .167 2.583C.167 3.592.992 4.417 2 4.417a1.839 1.839 0 0 0 1.833-1.834A1.839 1.839 0 0 0 2 .75zm0 12.833a1.839 1.839 0 0 0-1.833 1.834c0 1.008.825 1.833 1.833 1.833a1.839 1.839 0 0 0 1.833-1.833A1.839 1.839 0 0 0 2 13.583zm0-6.416A1.839 1.839 0 0 0 .167 9c0 1.008.825 1.833 1.833 1.833A1.839 1.839 0 0 0 3.833 9 1.839 1.839 0 0 0 2 7.167z'
          fill={props.fill}
          fillRule='nonzero'
        />
      </g>
    </svg>
  )
}
export default MenuIcon
