import { IIcon } from './iconConstants'
const TickIcon = (props: IIcon) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox='0 0 18 14'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M17.054.969A1.789 1.789 0 0 0 15.756.4c-.468 0-.933.19-1.286.565L6.76 8.947 3.557 5.631a1.81 1.81 0 0 0-1.306-.56 1.81 1.81 0 0 0-1.305.56A1.968 1.968 0 0 0 .4 7c0 .497.181.994.546 1.372l4.503 4.661c.36.372.829.566 1.3.566.47 0 .94-.194 1.298-.566l9.007-9.323c.365-.378.546-.875.546-1.371 0-.497-.181-.994-.546-1.371z'
        fill={props.fill}
        fillRule='nonzero'
        stroke={props.stoke}
        strokeWidth='.8'
      />
    </svg>
  )
}
export default TickIcon
