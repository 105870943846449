import { useEffect, useState } from 'react'

interface Voice {
  name: string
  lang: string
  default: boolean
}

interface SpeechSynthesisHook {
  supported: boolean
  voices: Voice[]
  speaking: boolean
  speak: (text: string, voiceName?: string, pitch?: number, rate?: number) => void
  stopSpeaking: () => void
}

const useSpeechSynthesis = (): SpeechSynthesisHook => {
  const [voices, setVoices] = useState<Voice[]>([])
  const [supported, setSupported] = useState<boolean>(true)
  const [speaking, setSpeaking] = useState(false)

  useEffect(() => {
    const synth = window.speechSynthesis
    // Check if speechSynthesis is supported
    if (!synth) {
      console.error('Text-to-speech is not supported on this device or browser.')
      setSupported(false)
      return
    }

    setSupported(!!synth)

    const onVoicesChanged = () => {
      if (synth.getVoices()) {
        setVoices(synth.getVoices())
      }
    }
    // Check if synth has addEventListener method before using it
    if (synth.addEventListener) {
      synth.addEventListener('voiceschanged', onVoicesChanged)
    } else {
      setSupported(false)
      console.error(
        'Event listener is not supported for speechSynthesis on this device or browser.',
      )
    }
    if (synth.getVoices()) {
      setVoices(synth.getVoices())
    }

    return () => {
      // Check if synth has removeEventListener method before using it
      if (synth.removeEventListener) {
        synth.removeEventListener('voiceschanged', onVoicesChanged)
      }
    }
  }, [])

  const speak = (text: string, voiceName?: string | null, pitch?: number, rate?: number) => {
    if (!speaking) {
      const utterance = new SpeechSynthesisUtterance(text)
      if (voiceName) {
        const synthVoice = voices.find((v) => v.name === voiceName) || null
        utterance.voice = synthVoice as SpeechSynthesisVoice | null
      }

      utterance.pitch = pitch || 1
      utterance.rate = rate || 1

      utterance.addEventListener('start', () => setSpeaking(true))
      utterance.addEventListener('end', () => setSpeaking(false))

      window.speechSynthesis.speak(utterance)
    }
  }

  const stopSpeaking = () => {
    window.speechSynthesis.cancel()
    setSpeaking(false)
  }

  return {
    supported,
    voices,
    speaking,
    speak,
    stopSpeaking,
  }
}

export default useSpeechSynthesis
