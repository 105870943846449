import { Box, Modal, useTheme } from '@mui/material'
import { FC, ReactNode } from 'react'

interface ICustomModal {
  children: ReactNode
  isOpen: boolean
  onClose?: () => void
  paddingRightRequired?: boolean
}

const CustomModal: FC<ICustomModal> = ({ children, isOpen, onClose, paddingRightRequired }) => {
  const theme = useTheme()
  const style = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  }

  const innerStyle = {
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.customShadows.z2,
    borderRadius: '0.625rem',
    pt: { xs: 5, sm: 5 },
    pb: { xs: 5, sm: 5 },
    pl: { xs: 5, sm: 5 },
    pr: { xs: 5, sm: 5 },
    maxWidth: '90vw',
    maxHeight: '90vh',
    outline: 0,
    overflow: 'auto',
    ...(paddingRightRequired ? { pr: 1 } : {}),
  }

  return (
    <Modal
      BackdropProps={{ sx: { backgroundColor: 'backdrop.main' }, transitionDuration: 0 }}
      open={isOpen}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      onClose={onClose}
    >
      <Box sx={style}>
        <Box sx={innerStyle}>{children}</Box>
      </Box>
    </Modal>
  )
}

export default CustomModal
