/* eslint-disable camelcase */

import {
  IBlockSeats,
  ICheckForFloors,
  IGetFloors,
  IGuestUserDetails,
  IMailLinkDetails,
  INewBookingForm,
  ISeatInFloors,
  ITokenIds,
  IUserEmails,
} from '../_models/newBookingInterface'
import { ISlotsFormData } from './../_models/commonInterface'
import { get, post, put } from './apiServices'

const getLocationsNewBooking = async (params: { is_booking: number }) => {
  const response = await get('business/locations', params)
  return response
}

const getPhoneNumber = async (params: { email: string }) => {
  const response = await get('business/phone-number', params)
  return response
}

const getLocationFacilities = async (params: { location: number }) => {
  const response = await get('facilities', params)
  return response
}
const getSlots = async (filters: ISlotsFormData) => {
  const response = await get('/facilities/slots', filters)
  return response
}
const newBooking = async (newBookingFormData: INewBookingForm) => {
  const response = post('/booking', newBookingFormData)
  return response
}
const bookingDetails = async (bookingId: number | string) => {
  const response = await get(`/booking/${bookingId}`)
  return response
}
const updateBooking = async (editFormData: INewBookingForm) => {
  const response = await put(`/booking/${editFormData.token_id}`, editFormData)
  return response
}
const cancelBooking = async (tokenIds: ITokenIds) => {
  const response = await post('/booking/cancel', tokenIds)
  return response
}
const getFloors = async (params: IGetFloors) => {
  const response = await get('/booking/floor/list', params)
  return response
}
const checkForFloors = async (params: ICheckForFloors) => {
  const response = await post('/booking/floor/check-seat-booking', params)
  return response
}
const getCSVSeatsInFloors = async (params: ISeatInFloors) => {
  const response = await post('/booking/floor-plan/csv', params)
  return response
}
const blockSeats = async (params: IBlockSeats) => {
  const response = await post('/booking/floor-plan/block-seats', params)
  return response
}
const getSVGSeatsInFloors = async (params: ISeatInFloors) => {
  const response = await post('/booking/floor-plan/svg', params)
  return response
}
const previousBooking = async (facilityId: string) => {
  const response = await get(`/previous-booking?facility_id=${facilityId}`)
  return response
}

const checkCancelBookingMailLink = async (params: IMailLinkDetails) => {
  const response = await post('/guest/cancel-booking/verify', params)
  return response
}

const cancelBookingFromMailLink = async (params: IMailLinkDetails) => {
  const response = await post('/mail/cancel-booking', params)
  return response
}

const checkRescheduleBookingMailLink = async (params: IMailLinkDetails) => {
  const response = await get(
    `/mail/reschedule-booking?token=${params.token}&expires=${params.expires}&reschedule=true`,
  )
  return response
}

const checkConfirmRescheduleBookingMailLink = async (params: IMailLinkDetails) => {
  const response = await post('/guest/confirm-booking/verify', params)
  return response
}

const confirmRescheduleBooking = async (params: IMailLinkDetails) => {
  const response = await post('/mail/confirm-booking', params)
  return response
}

const validateEmails = async (emails: IUserEmails) => {
  const response = await post('booking/email-validate', emails)
  return response
}
const getSlotsForGuestUser = async (filters: ISlotsFormData) => {
  const response = await get('/guest/facilities/slots', filters)
  return response
}
const checkForFloorsForGuestUser = async (params: ICheckForFloors) => {
  const response = await post('/guest/floor/check-seat-booking', params)
  return response
}
const getFloorsForGuestUser = async (params: IGetFloors) => {
  const response = await get('/guest/floor/list', params)
  return response
}
const getCSVSeatsInFloorsForGuestUser = async (params: ISeatInFloors) => {
  const response = await post('/guest/floor-plan/csv', params)
  return response
}
const getSVGSeatsInFloorsForGuestUser = async (params: ISeatInFloors) => {
  const response = await post('/guest/floor-plan/svg', params)
  return response
}
const blockSeatsForGuestUser = async (params: IBlockSeats) => {
  const response = await post('/guest/floor-plan/block-seats', params)
  return response
}
const newBookingForGuestUser = async (newBookingFormData: INewBookingForm) => {
  const response = post('/guest/booking', newBookingFormData)
  return response
}
const validatePhoneNumberForGuestUser = async (guestUserDetails: IGuestUserDetails) => {
  const response = post('/check/phone-number', guestUserDetails)
  return response
}
const editBookingForGuestUser = async (editFormData: INewBookingForm) => {
  const response = await post('/mail/confirm-reschedule', editFormData)
  return response
}
const newBookingService = {
  getLocationsNewBooking,
  getPhoneNumber,
  getLocationFacilities,
  getSlots,
  newBooking,
  bookingDetails,
  updateBooking,
  cancelBooking,
  getFloors,
  checkForFloors,
  getCSVSeatsInFloors,
  blockSeats,
  getSVGSeatsInFloors,
  previousBooking,
  checkCancelBookingMailLink,
  cancelBookingFromMailLink,
  checkRescheduleBookingMailLink,
  confirmRescheduleBooking,
  validateEmails,
  getSlotsForGuestUser,
  checkForFloorsForGuestUser,
  getFloorsForGuestUser,
  getCSVSeatsInFloorsForGuestUser,
  getSVGSeatsInFloorsForGuestUser,
  blockSeatsForGuestUser,
  newBookingForGuestUser,
  checkConfirmRescheduleBookingMailLink,
  validatePhoneNumberForGuestUser,
  editBookingForGuestUser,
}
export default newBookingService
