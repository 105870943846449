/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import _isEqual from 'lodash/isEqual'
import { decodeTokenType, setTokenInCookie } from '../_helpers/cookie'
import { History } from '../_helpers/history'
import { handleMessages } from '../_helpers/utilities'
import { IFilters } from '../_models/commonInterface'
import { IDashboardState } from '../_models/dashboardInterface'
import businessService from '../_services/businessService'
import {
  IBusinessDetails,
  IBusinessInformation,
  IProfileData,
} from './../_models/businessInterface'
import { RootState } from './../_store/store'
import { getBookingList, getDashboardStatistics } from './dashboardSlice'
import { startLoader, stopLoader } from './loaderSlice'
import { errorToast, successToast } from './toastSlice'
import { userDetails } from './userSlice'
const initialState: IBusinessDetails = {
  businessInformation: {},
  businessList: {
    current_page: 1,
    data: [],
    total: 20,
    per_page: 10,
    last_page: 0,
  },
  filters: {
    page: 1,
    per_page: 10,
    search: '',
  },
  isLoadingBusinessList: false,
  isLoadingRecentBusinessList: false,
  recentBookings: [],
  allocatedBusinessList: [],
  completeBusinessList: [],
}

export const getBusinessDetails = createAsyncThunk(
  '/businessDetails',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(startLoader())
    try {
      const response = await businessService.getBusinessDetails()
      dispatch(stopLoader())
      return response
    } catch (err: any) {
      dispatch(stopLoader())
      return rejectWithValue(err)
    }
  },
)

export const switchBusinessList = createAsyncThunk(
  '/switchBusinessList',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(startLoader())
    try {
      const response = await businessService.switchBusinessList()
      dispatch(stopLoader())
      return response
    } catch (err: any) {
      dispatch(stopLoader())
      return rejectWithValue(err)
    }
  },
)

export const switchProfile = createAsyncThunk(
  '/switchProfile',
  async (profileData: IProfileData, { dispatch, rejectWithValue, getState }) => {
    dispatch(startLoader())
    try {
      const response = await businessService.switchProfile(profileData)
      setTokenInCookie(response.data.token)
      const userDetailsResponse = await dispatch(userDetails())
      if (userDetailsResponse) {
        if (decodeTokenType(response.data.token) === 'business') {
          History.navigate('/admin/dashboard')
          const { dashboard } = getState() as { dashboard: IDashboardState }
          const permissions = response.data?.activePermissions
          dispatch(getBookingList(dashboard.dashboardBookingListData.filters))
          permissions.includes('dashboard_kpis_view') && dispatch(getDashboardStatistics())
        } else {
          History.navigate('/customer/business')
        }
      }

      dispatch(stopLoader())
      return response
    } catch (err: any) {
      dispatch(stopLoader())
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)

export const switchProfileFromMail = createAsyncThunk(
  '/switchProfileFromMail',
  async (profileData: IProfileData, { dispatch, rejectWithValue }) => {
    dispatch(startLoader())
    try {
      const response = await businessService.switchProfile(profileData)
      setTokenInCookie(response?.data?.token)
      dispatch(stopLoader())
      return response
    } catch (err: any) {
      dispatch(stopLoader())
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)
export const updateBusinessCoverImage = createAsyncThunk(
  '/updateBusinessCoverImage',
  async (image: File, { dispatch, rejectWithValue }) => {
    dispatch(startLoader())
    try {
      const response = await businessService.updateBusinessCoverImage(image)
      // dispatch(successToast(handleMessages(response.message)))
      dispatch(getBusinessDetails())
      return response
    } catch (err: any) {
      dispatch(stopLoader())
      dispatch(errorToast(handleMessages(err.message)))
      dispatch(getBusinessDetails())

      return rejectWithValue(err)
    }
  },
)

export const postBusinessDetails = createAsyncThunk(
  '/updateBusinessDetails',
  async (updatedBusinessDetails: IBusinessInformation, { dispatch, rejectWithValue }) => {
    dispatch(startLoader())
    try {
      const response = await businessService.postBusinessDetails(updatedBusinessDetails)
      dispatch(successToast(handleMessages(response.message)))
      dispatch(getBusinessDetails())
      dispatch(userDetails())
      dispatch(stopLoader())
      dispatch(switchBusinessList())
      return response
    } catch (err: any) {
      dispatch(errorToast(handleMessages(err.message)))
      dispatch(stopLoader())
      return rejectWithValue(err)
    }
  },
)
export const deleteCoverImage = createAsyncThunk(
  '/deleteCoverImage',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(startLoader())
    try {
      const response = await businessService.deleteCoverImage()
      dispatch(stopLoader())
      dispatch(successToast(handleMessages(response.message)))
      dispatch(getBusinessDetails())
      return response
    } catch (err: any) {
      dispatch(stopLoader())
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)
// customer business list
export const getAllBusinessList = createAsyncThunk(
  '/getAllBusinessList',
  async (filters: IFilters, { dispatch, rejectWithValue }) => {
    try {
      const response = await businessService.getBusinessList(filters)
      return response
    } catch (err: any) {
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)
export const getRecentBusiness = createAsyncThunk(
  '/getRecentBusiness',
  async (_, { rejectWithValue }) => {
    try {
      const response = await businessService.getRecentBusiness()
      return response
    } catch (err: any) {
      return rejectWithValue(err)
    }
  },
)

export const businessSlice = createSlice({
  name: 'business',
  initialState,
  reducers: {
    // customer business list filter
    changeFilters: (state, action) => {
      return {
        ...state,
        filters: action.payload,
      }
    },
    resetBusinessListData: (state) => {
      state.completeBusinessList = initialState.completeBusinessList
    },
  },
  extraReducers: (builder) => {
    builder
      // Business Details
      .addCase(getBusinessDetails.fulfilled, (state, action) => {
        state.businessInformation = action.payload.data
      })
      // Customer business listing
      .addCase(getAllBusinessList.pending, (state) => {
        state.isLoadingBusinessList = true
      })
      .addCase(getAllBusinessList.fulfilled, (state, action) => {
        state.businessList = action.payload?.data
        if (
          action.payload.data?.data &&
          !_isEqual(state.completeBusinessList, action.payload.data?.data)
        ) {
          // Append action.payload.data to existing completeBusinessList
          state.completeBusinessList = [
            ...state.completeBusinessList,
            ...(action.payload.data?.data || []),
          ]
        }

        state.isLoadingBusinessList = false
      })
      .addCase(getAllBusinessList.rejected, (state) => {
        state.isLoadingBusinessList = false
      })
      .addCase(getRecentBusiness.pending, (state) => {
        state.isLoadingRecentBusinessList = true
      })
      .addCase(getRecentBusiness.fulfilled, (state, action) => {
        state.recentBookings = action.payload?.data
        state.isLoadingRecentBusinessList = false
      })
      .addCase(getRecentBusiness.rejected, (state) => {
        state.isLoadingRecentBusinessList = false
      })
      .addCase(switchBusinessList.fulfilled, (state, action) => {
        state.allocatedBusinessList = action.payload.data
      })
  },
})
export const { changeFilters, resetBusinessListData } = businessSlice.actions

export const businessData = (state: RootState) => state.business
const businessReducer = businessSlice.reducer
export default businessReducer
