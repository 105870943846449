import { IIcon } from './iconConstants'
const SunSetIcon = (props: IIcon) => {
  return (
    <svg
      width={props?.width}
      height={props?.height}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#hr1wt7k5za)'>
        <path fill='#fff' d='M0 0h24v24H0z' />
        <path
          d='M11 1h2v3h-2V1zM3.515 4.929l1.414-1.414L7.05 5.636 5.636 7.05 3.515 4.93zM19.07 3.515l1.414 1.414-2.121 2.121-1.414-1.414 2.121-2.121zM23 10v2h-3v-2h3zM4 10v2H1v-2h3z'
          fill='#7F7F7F'
        />
        <path d='M17 12a5 5 0 0 0-10 0' stroke={props?.stoke} strokeWidth='2' />
        <path d='M9 14H1v2h8v-2zM23 14h-8v2h8v-2z' fill='#7F7F7F' />
        <path
          d='M11.93 14.915v7'
          stroke={props?.stoke}
          strokeWidth='2'
          strokeLinecap='square'
          strokeLinejoin='round'
        />
        <path
          d='m7.93 18.916 4 4 4-4'
          stroke={props?.stoke}
          strokeWidth='2'
          strokeLinecap='square'
        />
      </g>
      <defs>
        <clipPath id='hr1wt7k5za'>
          <path fill='#fff' d='M0 0h24v24H0z' />
        </clipPath>
      </defs>
    </svg>
  )
}
export default SunSetIcon
