import { IIcon } from './iconConstants'
const Tick2Icon = (props: IIcon) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox='0 0 10 8'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M3.969 4.231 2.602 2.82a1.355 1.355 0 0 0-.977-.408c-.356 0-.713.135-.977.408A1.462 1.462 0 0 0 .25 3.84c0 .37.131.743.398 1.019l2.375 2.45c.263.272.63.441.946.441.37 0 .683-.169.945-.44l4.438-4.58c.267-.276.398-.648.398-1.02A1.46 1.46 0 0 0 9.352.691a1.415 1.415 0 0 0-.97-.44 1.22 1.22 0 0 0-.921.376L3.969 4.23z'
        fill={props.fill}
        fillRule='nonzero'
        stroke={props.stoke}
        strokeWidth='.5'
      />
    </svg>
  )
}
export default Tick2Icon
