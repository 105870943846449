import { lazy } from 'react'
import { Navigate } from 'react-router-dom'
import CustomerLayout from '../layout/customerLayout'

import { PERMISSIONS } from '../_constants'
import LazyLoad from './utils/LazyLoad'
import CheckEmailLinkValidity from './utils/checkEmailLinkValidity'
import RequireUserPermissionsAuth from './utils/requireUserPermissionsAuth'
import RequireUserTypeAuth from './utils/requireUserTypeAuth'
const BusinessListPage = LazyLoad(lazy(() => import('../pages/customer/businessList')))
const BusinessDetailsPage = LazyLoad(lazy(() => import('../pages/customer/businessDetailsPage')))
const ProfileSettingsPage = LazyLoad(lazy(() => import('../pages/customer/profileSettings')))
const BookingsList = LazyLoad(lazy(() => import('../pages/customer/bookingList')))

const { CUSTOMER_BOOKING_VIEW, CUSTOMER_PROFILE_VIEW } = PERMISSIONS

const CustomerRoutes = {
  path: '/customer',
  element: (
    <RequireUserTypeAuth authorizedUserType={'customer'}>
      <CustomerLayout />
    </RequireUserTypeAuth>
  ),
  children: [
    {
      path: '',
      element: <Navigate to='business' />,
    },
    {
      path: 'business',
      element: (
        // <RequireUserPermissionsAuth authorizedUserPermissions={[]}>
        <BusinessListPage />
        // </RequireUserPermissionsAuth>
      ),
    },
    {
      path: 'my-bookings',
      element: (
        <RequireUserPermissionsAuth authorizedUserPermissions={[CUSTOMER_BOOKING_VIEW]}>
          <BookingsList />
        </RequireUserPermissionsAuth>
      ),
    },
    {
      path: 'business/:url',
      element: (
        <CheckEmailLinkValidity>
          <BusinessDetailsPage />
        </CheckEmailLinkValidity>
      ),
    },
    {
      path: 'profile-settings',
      element: (
        <RequireUserPermissionsAuth authorizedUserPermissions={[CUSTOMER_PROFILE_VIEW]}>
          <ProfileSettingsPage />
        </RequireUserPermissionsAuth>
      ),
    },
  ],
}
export default CustomerRoutes
