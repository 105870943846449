import { IIcon } from './iconConstants'
const SeatIcon = (props: IIcon) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox='0 0 20 22'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g fill='none' fillRule='evenodd'>
        <path d='M-1 0h22v22H-1z' />
        <path
          d='M3.846 1c1.39 0 2.578 1.028 2.806 2.43l1.138 6.982h8.334c1.588 0 2.876 1.317 2.876 2.94 0 1.625-1.288 2.942-2.876 2.942v2.941a.582.582 0 0 1-.576.588h-4.602v.589a.582.582 0 0 1-.575.588H9.22a.582.582 0 0 1-.576-.588v-.589H4.043a.582.582 0 0 1-.576-.588v-2.941a.58.58 0 0 1-.567-.491L1.04 4.387C.75 2.614 2.087 1 3.845 1zm11.509 15H5v2.353h10.355V16zM3.695 2c-1.047 0-1.844.961-1.672 2.018L3.804 14.94h12.169c.953 0 1.726-.79 1.726-1.764 0-.975-.773-1.765-1.726-1.765H7.152a.58.58 0 0 1-.568-.492L5.366 3.448A1.706 1.706 0 0 0 3.695 2z'
          stroke={props.stoke}
          strokeWidth='.5'
          fill={props.fill}
          fillRule='nonzero'
        />
      </g>
    </svg>
  )
}
export default SeatIcon
