// ==============================|| DEFAULT THEME - TYPOGRAPHY  ||============================== //

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Typography = (fontFamily: string, theme: any) => ({
  htmlFontSize: 16,
  fontFamily,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 600,
  // These variants currently used in slashQ,
  h1: {
    fontWeight: 500,
    fontSize: '1.875rem', // 30px
    lineHeight: 1.8,
  },
  h2: {
    fontWeight: 600,
    fontSize: '2.125rem', // 32px
    // lineHeight: 0.5,
  },
  h3: {
    fontWeight: 600,
    fontSize: '1.125rem', // 18px
    lineHeight: 0.5,
  },
  h4: {
    fontWeight: 500,
    fontSize: '1rem', // 16px
    lineHeight: 1.5,
  },
  h5: {
    fontWeight: 600,
    fontSize: '1rem', // 16px
    lineHeight: 1.5,
  },
  subtitle1: {
    fontSize: '.875rem', // 14px
    fontWeight: 500,
    lineHeight: 1.71,
  },

  subtitle2: {
    fontSize: '0.85rem', // 12px
    fontWeight: 400,
    lineHeight: 1.71,
  },
  body2: {
    fontSize: '0.875rem',
    lineHeight: 1.66,
    fontWeight: 400,
  },
  button: {
    textTransform: 'capitalize',
  },
  caption: {
    fontWeight: 400,
    fontSize: '0.75rem', // 12px
    lineHeight: 1.66,
  },
  h6: {
    fontSize: '1.625rem', // 26px
    lineHeight: 1.6,
    fontWeight: 500,
  },

  // drawer specific text variants
  drawerTitle: {
    fontWeight: 500,
    fontSize: '1.5rem', // 24px
    lineHeight: 0.67,
    color: theme.palette.common.black,
  },
  drawerSubtitle1: {
    fontWeight: 500,
    fontSize: '1rem', // 16px
    lineHeight: 1,
    color: theme.palette.common.black,
  },
  drawerSubtitle2: {
    fontWeight: 500,
    fontSize: '0.875rem', // 14px
    lineHeight: 1.14,
    color: theme.palette.common.black,
  },
  drawerSubtitle3: {
    fontWeight: 400,
    fontSize: '0.8125rem', // 13px
    lineHeight: 1.23,
    color: theme.palette.common.black,
  },
  drawerText: {
    fontWeight: 400,
    fontSize: '0.75rem', // 12px
    lineHeight: 0.83,
    color: theme.palette.common.black,
  },

  // #########  remaining variants available to use

  // h1: {
  //   fontWeight: 600,
  //   fontSize: '2.375rem',
  //   lineHeight: 1.21,
  // },
  // h4: {
  //   fontWeight: 600,
  //   fontSize: '1.25rem',
  //   lineHeight: 1.4,
  // },
  // h6: {
  //   fontWeight: 400,
  //   fontSize: '0.875rem',
  //   lineHeight: 1.57,
  // },

  // body1: {
  //   fontSize: '0.875rem',
  //   lineHeight: 1.57,
  // },

  // overline: {
  //   lineHeight: 1.66,
  // },
})

export default Typography
