import { IIcon } from './iconConstants'
const MailEnvelopeWithInfoIcon = (props: IIcon) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox='0 0 54 54'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g fill='none' fillRule='evenodd'>
        <g stroke={props.stoke} strokeLinecap='round' strokeLinejoin='round' strokeWidth='2.4'>
          <path d='m2 19.308 25 15.384 25-15.384' />
          <path d='M9.692 24.115V5.846A3.857 3.857 0 0 1 13.538 2h26.924a3.857 3.857 0 0 1 3.846 3.846v18.27' />
          <path d='M44.308 9.692c4.23 0 7.692 3.462 7.692 7.693v26.923C52 48.538 48.538 52 44.308 52H9.692C5.462 52 2 48.538 2 44.308V17.385c0-4.231 3.462-7.693 7.692-7.693' />
        </g>
        <path d='M18 8h18v18H18z' />
        <path
          d='M27 24.5a7.5 7.5 0 1 1 0-15 7.5 7.5 0 0 1 0 15zm-.75-5.25v1.5h1.5v-1.5h-1.5zm0-6v4.5h1.5v-4.5h-1.5z'
          fill={props.fill}
          fillRule='nonzero'
        />
      </g>
    </svg>
  )
}
export default MailEnvelopeWithInfoIcon
