import { IIcon } from './iconConstants'
const CopyIcon = (props: IIcon) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox='0 0 18 20'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g fill='none' fillRule='evenodd'>
        <path d='M-3-2h24v24H-3z' />
        <path
          d='M4 4V1a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1h-3v3c0 .552-.45 1-1.007 1H1.007A1.001 1.001 0 0 1 0 19L.003 5c0-.552.45-1 1.007-1H4zM2.003 6 2 18h10V6H2.003zM6 4h8v10h2V2H6v2z'
          fill={props.fill}
          fillRule='nonzero'
        />
      </g>
    </svg>
  )
}
export default CopyIcon
