import { IIcon } from './iconConstants'
const LogoutBoxRLineIcon = (props: IIcon) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox='0 0 17 18'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g fill='none' fillRule='evenodd'>
        <path d='M-3-1h20v20H-3z' />
        <path
          d='M1.167 17.333a.833.833 0 0 1-.834-.833v-15c0-.46.373-.833.834-.833h11.666c.46 0 .834.373.834.833V4H12V2.333H2v13.334h10V14h1.667v2.5c0 .46-.373.833-.834.833H1.167zm10.833-5v-2.5H6.167V8.167H12v-2.5L16.167 9 12 12.333z'
          fill={props.fill}
          fillRule='nonzero'
        />
      </g>
    </svg>
  )
}
export default LogoutBoxRLineIcon
