import { IIcon } from './iconConstants'
const RefreshIcon = (props: IIcon) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox='0 0 16 16'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <title>Group</title>
      <desc>Created with Sketch.</desc>
      <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g
          id='20_Settings_Profile_Business'
          transform='translate(-508.000000, -862.000000)'
          fill={props.fill}
          fillRule='nonzero'
        >
          <g id='Group-5' transform='translate(456.000000, 717.000000)'>
            <g id='Group-4' transform='translate(17.000000, 13.000000)'>
              <g id='Group' transform='translate(35.000000, 132.000000)'>
                <path
                  d='M2.7704,1.94641171 C4.22204728,0.688542464 6.07918928,-0.00266416928 8,7.71729259e-06 C12.4184,7.71729259e-06 16,3.58160855 16,8.00000772 C16,9.70879669 15.464,11.2927936 14.552,12.5919911 L12,8.00000772 L14.4,8.00000772 C14.4002106,5.43515262 12.8691575,3.11790895 10.5097437,2.11211058 C8.15032995,1.10631221 5.41833033,1.60625211 3.568,3.38240893 L2.7704,1.94641171 Z M13.2296,14.0535883 C11.7779527,15.3114575 9.92081072,16.0026642 8,15.9999923 C3.5816,15.9999923 0,12.4183915 0,8 C0,6.29120331 0.536,4.70720637 1.448,3.40800889 L4,8 L1.6,8 C1.59978935,10.5648474 3.13084252,12.882091 5.49025629,13.8878894 C7.84967005,14.8936878 10.5816697,14.3937479 12.432,12.6175911 L13.2296,14.0535883 L13.2296,14.0535883 Z'
                  id='Shape'
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
export default RefreshIcon
