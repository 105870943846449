import { PERMISSIONS } from '../../_constants'
import { ITab } from '../../_models/tabsInterface'

export const headerTabs: ITab[] = [
  {
    id: 1,
    label: 'business',
    path: '/customer/business',
  },
  {
    id: 2,
    label: 'booking',
    path: '/customer/my-bookings',
    permissions: [PERMISSIONS.CUSTOMER_BOOKING_VIEW],
  },
]
