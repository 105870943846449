import { IIcon } from './iconConstants'
const UnderConstructionIcon = ({ width, height }: IIcon) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 434 239'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        opacity='.5'
        d='M125.503 129.93H70.905v93.734h133.711V59.469h-79.113v70.461zM294.616 135.668V0h-79.113v224.664h137.195v-88.996h-58.082z'
        fill='#D0D0D0'
        fillOpacity='.4'
      />
      <path d='M433.001 221.664H1a1 1 0 0 0 0 2h432.001a1 1 0 0 0 0-2z' fill='#D0D0D0' />
      <path
        d='M273.814 82.664H107.091c-6.627 0-12 5.373-12 12v125.891c0 6.627 5.373 12 12 12h166.723c6.627 0 12-5.373 12-12V94.665c0-6.628-5.373-12-12-12z'
        fill='#FEE'
      />
      <path d='M285.814 99.643H95.091v132.912h190.723V99.643z' fill='#fff' />
      <path
        d='M110.815 196.657v-37.295h66.845v37.295h-66.845zM110.815 149.657v-37.295h66.845v37.295h-66.845z'
        stroke='#FF5958'
      />
      <path
        d='M177.571 206.831h-66.666v3h66.666v-3zM177.571 216.831h-66.666v3h66.666v-3zM268.283 206.831h-66.666v3h66.666v-3zM268.283 216.831h-66.666v3h66.666v-3z'
        fill='#FF5958'
      />
      <path
        d='M286.814 222.556c0 6.075-4.925 11-11 11H105.091c-6.075 0-11-4.925-11-11V92.664c0-6.075 4.925-11 11-11h170.723c6.075 0 11 4.925 11 11v129.892zm-190.723-2c0 6.075 4.925 11 11 11h166.723c6.075 0 11-4.925 11-11V94.664c0-6.075-4.925-11-11-11H107.091c-6.075 0-11 4.925-11 11v125.892z'
        fill='#F8D9D9'
      />
      <path
        d='M332.508 210.998h-19.566V46.332h19.566v164.666zm-17.566-2h15.566V48.332h-15.566v160.666z'
        fill='#D0D0D0'
      />
      <path d='M335.187 201.472h-24.923v17.859h24.923v-17.859z' fill='#9D9D9D' />
      <path
        d='M331.615 191.997h-17.333a1.001 1.001 0 0 1-.734-1.68l16.666-17.999 1.468 1.358-15.112 16.32h15.044v2.001h.001z'
        fill='#D0D0D0'
      />
      <path
        d='M331.615 173.963h-17.333a1.001 1.001 0 0 1-.734-1.68l16.666-18 1.468 1.358-15.112 16.321h15.044v2.001h.001z'
        fill='#D0D0D0'
      />
      <path
        d='M331.615 155.929h-17.333a1.001 1.001 0 0 1-.734-1.68l16.666-18 1.468 1.358-15.112 16.321h15.044v2.001h.001z'
        fill='#D0D0D0'
      />
      <path
        d='M331.615 137.895h-17.333a1.001 1.001 0 0 1-.734-1.68l16.666-18 1.468 1.358-15.112 16.321h15.044v2.001h.001z'
        fill='#D0D0D0'
      />
      <path
        d='M331.615 119.861h-17.333a1.001 1.001 0 0 1-.734-1.68l16.666-18 1.468 1.358-15.112 16.321h15.044v2.001h.001z'
        fill='#D0D0D0'
      />
      <path
        d='M331.615 101.827h-17.333a1.001 1.001 0 0 1-.734-1.68l16.666-18 1.468 1.358-15.112 16.321h15.044v2.001h.001z'
        fill='#D0D0D0'
      />
      <path
        d='M331.615 83.793h-17.333a1.001 1.001 0 0 1-.734-1.68l16.666-18 1.468 1.358-15.112 16.32h15.044v2.002h.001z'
        fill='#D0D0D0'
      />
      <path
        d='M331.615 65.759h-17.333a1.001 1.001 0 0 1-.734-1.68l16.666-18 1.468 1.358-15.112 16.32h15.044v2.002h.001z'
        fill='#D0D0D0'
      />
      <path
        d='M332.475 39.378h-31.719v26.74h31.719v-26.74zM380.141 39.378h-31.719v15.359h31.719v-15.36z'
        fill='#FF5958'
      />
      <path
        d='M379.615 41.33H222.948a1 1 0 0 1 0-2h156.667a1 1 0 0 1 0 2zM231.226 44.998h-1v65h1v-65z'
        fill='#D0D0D0'
      />
      <path d='M343.687 216.139h-41.924v7.859h41.924v-7.859z' fill='#7E7E7E' />
      <path
        d='M316.732 41.069a.498.498 0 0 1-.353-.146l-8.354-8.354h-76.586l-7.854 7.854a.5.5 0 0 1-.707-.707l8.146-8.146h77.414l8.646 8.646a.5.5 0 0 1-.352.853zM380.282 41.069a.5.5 0 0 1-.443-.268l-8.36-15.97h-20.274l-7.861 15.465a.5.5 0 0 1-.891-.453l8.139-16.012h21.493l8.64 16.506a.5.5 0 0 1-.443.732z'
        fill='#D0D0D0'
      />
      <path
        d='M234.393 44.997a3.667 3.667 0 1 1-7.335-.001 3.667 3.667 0 0 1 7.335 0z'
        fill='#FF5958'
      />
      <path
        d='m190.554 159.039-3.894-42.155 82.738-7.644 3.894 42.155-82.738 7.644z'
        stroke='#FF5958'
      />
      <path
        d='M252.405 32.562a.5.5 0 0 1-.146-.978L326.292 9.01a.497.497 0 0 1 .34.018l35.089 14.809a.5.5 0 1 1-.389.921l-34.921-14.739-73.86 22.521a.493.493 0 0 1-.146.022z'
        fill='#D0D0D0'
      />
      <path
        d='M314.791 41.136a1 1 0 0 1-.934-1.356l11.657-30.674a.999.999 0 0 1 1.915.158l6.026 29.916a1.001 1.001 0 0 1-.783 1.178 1.005 1.005 0 0 1-1.178-.783l-5.342-26.52-10.427 27.436a1 1 0 0 1-.934.645z'
        fill='#D0D0D0'
      />
      <path d='M328.463 9.46a2.015 2.015 0 1 1-4.03.001 2.015 2.015 0 0 1 4.03 0z' fill='#FF5958' />
      <path
        d='M241.864 41.164a.498.498 0 0 1-.314-.111l-9.819-7.937v7.421a.5.5 0 0 1-1 0v-8.468a.5.5 0 0 1 .815-.388l10.634 8.595a.5.5 0 0 1-.316.888z'
        fill='#D0D0D0'
      />
      <path
        d='M251.36 41.164a.498.498 0 0 1-.314-.111l-9.82-7.937v7.421a.5.5 0 0 1-1 0v-8.468a.5.5 0 0 1 .815-.388l10.634 8.595a.5.5 0 0 1-.315.888z'
        fill='#D0D0D0'
      />
      <path
        d='M260.855 41.164a.498.498 0 0 1-.314-.111l-9.82-7.937v7.421a.5.5 0 0 1-1 0v-8.468a.5.5 0 0 1 .815-.388l10.634 8.595a.5.5 0 0 1-.315.888z'
        fill='#D0D0D0'
      />
      <path
        d='M270.351 41.164a.498.498 0 0 1-.314-.111l-9.819-7.937v7.421a.5.5 0 0 1-1 0v-8.468a.5.5 0 0 1 .815-.388l10.634 8.595a.5.5 0 0 1-.316.888z'
        fill='#D0D0D0'
      />
      <path
        d='M279.846 41.164a.498.498 0 0 1-.314-.111l-9.82-7.937v7.421a.5.5 0 0 1-1 0v-8.468a.5.5 0 0 1 .815-.388l10.634 8.595a.5.5 0 0 1-.315.888z'
        fill='#D0D0D0'
      />
      <path
        d='M289.342 41.164a.498.498 0 0 1-.314-.111l-9.82-7.937v7.421a.5.5 0 0 1-1 0v-8.468a.5.5 0 0 1 .815-.388l10.634 8.595a.5.5 0 0 1-.315.888z'
        fill='#D0D0D0'
      />
      <path
        d='M298.837 41.164a.498.498 0 0 1-.314-.111l-9.82-7.937v7.421a.5.5 0 0 1-1 0v-8.468a.5.5 0 0 1 .815-.388l10.634 8.595a.5.5 0 0 1-.315.888z'
        fill='#D0D0D0'
      />
      <path
        d='M308.333 41.164a.498.498 0 0 1-.314-.111l-9.82-7.937v7.421a.5.5 0 0 1-1 0v-8.468a.5.5 0 0 1 .815-.388l10.634 8.595a.5.5 0 0 1-.315.888zM364.467 41.164a.499.499 0 0 1-.382-.177l-12.753-15.051v14.6a.5.5 0 0 1-1 0V24.574a.499.499 0 0 1 .882-.323l13.635 16.09a.5.5 0 0 1-.382.824z'
        fill='#D0D0D0'
      />
      <path
        d='M374.276 41.164a.503.503 0 0 1-.418-.224l-9.717-14.704v14.3a.5.5 0 0 1-1 0V24.574a.5.5 0 0 1 .917-.276l10.634 16.09a.5.5 0 0 1-.416.777z'
        fill='#D0D0D0'
      />
      <path
        d='M233.875 111.331a3.148 3.148 0 1 1-6.296 0 3.148 3.148 0 0 1 6.296 0z'
        fill='#FF5958'
      />
      <path
        d='M62.115 174.165h-2v47.999h2v-47.999zM24.115 174.165h-2v47.999h2v-47.999z'
        fill='#D0D0D0'
      />
      <path d='M69.615 208.165h-55v2h55v-2z' fill='#D0D0D0' />
      <path d='M67.334 178.664H17.115v18.5h50.219v-18.5z' fill='#FEE' />
      <path
        d='M67.334 197.164v-17.5l-9.234 17.5h9.234zM35.6 197.164h10.741l9.761-18.5H45.361l-9.761 18.5zM22.861 178.664l-5.746 10.89v7.61h6.726l9.761-18.5H22.861z'
        fill='#FF5958'
      />
      <path
        d='M410.115 174.165h-2v47.999h2v-47.999zM372.115 174.165h-2v47.999h2v-47.999z'
        fill='#D0D0D0'
      />
      <path d='M417.615 208.165h-55v2h55v-2z' fill='#D0D0D0' />
      <path d='M415.334 178.664h-50.219v18.5h50.219v-18.5z' fill='#FEE' />
      <path
        d='M415.334 197.164v-17.5l-9.234 17.5h9.234zM383.6 197.164h10.741l9.761-18.5h-10.741l-9.761 18.5zM370.861 178.664l-5.746 10.89v7.61h6.726l9.761-18.5h-10.741zM91.497 235.992H68.716a1.358 1.358 0 0 0 0 2.717h22.781a1.358 1.358 0 0 0 0-2.717z'
        fill='#FF5958'
      />
      <path
        d='M81.956 216.625a.742.742 0 0 0-.697-.488h-2.611a.741.741 0 0 0-.7.497l-7.351 21.03h19.018l-7.659-21.039z'
        fill='#FF5958'
      />
      <path
        d='m76.588 220.527-1.161 3.321h9.159l-1.21-3.321h-6.788zM73.629 228.991l-1.161 3.322h15.2l-1.21-3.322H73.629z'
        fill='#FEE'
      />
      <path
        opacity='.5'
        d='M88.093 58.944c-1.668 0-3.21.53-4.477 1.424v-2.205c0-6.35-5.149-11.5-11.5-11.5s-11.5 5.15-11.5 11.5v4.346a5.783 5.783 0 0 0-3.994-1.598 5.81 5.81 0 0 0-5.81 5.81h45.057a7.776 7.776 0 0 0-7.776-7.777zM180.954 27.605a5.629 5.629 0 0 0-3.259 1.037v-1.606a8.372 8.372 0 0 0-16.744 0V30.2a4.211 4.211 0 0 0-2.908-1.163 4.23 4.23 0 0 0-4.23 4.23h32.803a5.662 5.662 0 0 0-5.662-5.662z'
        fill='#D0D0D0'
      />
    </svg>
  )
}
export default UnderConstructionIcon
