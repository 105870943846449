import { IIcon } from './iconConstants'
const BuildingWithCircleSlashIcon = (props: IIcon) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox='0 0 54 42'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g fill='none' fillRule='evenodd'>
        <g opacity='.5'>
          <path d='M-4-6h49.5v49.5H-4z' />
          <path
            d='M33.125.188c1.14 0 2.063.923 2.063 2.062v10.313h1.765a18.456 18.456 0 0 0-7.76 4.125h-6.38v16.5h.38a18.371 18.371 0 0 0 1.102 4.126l-24.17-.001v-4.125h2.063V2.25c0-1.14.923-2.062 2.062-2.062h28.875zm-2.062 4.125H6.313v28.875h12.375V12.563h12.375v-8.25zm-16.5 20.625v4.125h-4.125v-4.125h4.125zm0-8.25v4.125h-4.125v-4.125h4.125zm0-8.25v4.125h-4.125V8.438h4.125z'
            fill={props.fill}
            fillRule='nonzero'
          />
        </g>
        <path d='M23 13h35.486v35.486H23z' />
        <path
          d='M40.5 42C33.044 42 27 35.956 27 28.5S33.044 15 40.5 15 54 21.044 54 28.5 47.956 42 40.5 42zm0-2.7c5.965 0 10.8-4.835 10.8-10.8 0-5.965-4.835-10.8-10.8-10.8-5.965 0-10.8 4.835-10.8 10.8 0 5.965 4.835 10.8 10.8 10.8zM33 19l2-1 14.5 19.5-3 1.5L33 19z'
          fill={props.stoke}
          fillRule='nonzero'
          opacity='.5'
        />
      </g>
    </svg>
  )
}
export default BuildingWithCircleSlashIcon
