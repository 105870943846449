/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import _isEqual from 'lodash/isEqual'
import { getUserTypeFromToken, isAuthenticated } from '../_helpers/cookie'
import { generateOperatingWeekDays, generateSlots, getTimeInHours } from '../_helpers/facility'
import { History } from '../_helpers/history'
import { handleMessages } from '../_helpers/utilities'
import { INewBookingDetails } from '../_models/newBookingInterface'
import facilityService from '../_services/facilityServices'
import { RootState } from '../_store/store'
import { IFilters } from './../_models/commonInterface'
import {
  IAddLocation,
  ICheckDayFacilityConfigEvents,
  ICheckFacilityConfigEvents,
  ICreateFacilityBasicInfoDetails,
  ICreateFacilityConfiguration,
  IDayDetails,
  IFacilityState,
  IFacilityStatusDetails,
  IGetBusinessDetails,
  IGetFacilityBookingCount,
  IUpdateFacilityBasicInfoDetails,
  IUpdateFacilityConfiguration,
  IUpdateFacilityDaySettings,
} from './../_models/facilityInterface'
import { startLoader, stopLoader } from './loaderSlice'
import {
  rescheduleBookingFromMail,
  setFacilityLoadingStatusFalse,
  setFacilityLoadingStatusTrue,
} from './newBookingSlice'
import { getPricingLimits } from './pricingSlice'
import { errorToast, successToast } from './toastSlice'
const initialState: IFacilityState = {
  facilities: {
    current_page: 1,
    data: [],
    total: 20,
    per_page: 10,
    last_page: 0,
  },
  bookingFacilities: {
    current_page: 1,
    data: [],
    total: 20,
    per_page: 10,
    last_page: 0,
  },

  filters: {
    page: 1,
    per_page: 10,
    // inactive not required for other slices.
    inactive: 1,
    search: '',
  },
  facilityStatus: {
    facilityId: '',
    status: false,
  },
  facilityBasicInfo: {
    facility_name: '',
    address: '',
    latitude: '',
    longitude: '',
    location: '',
    image: null,
    description: '',
  },
  facilityLocation: {
    location_id: '',
    locationOptions: [],
    loading: false,
    initialLocationId: '',
  },
  facilityConfiguration: {
    facility_id: '',
    allow_cancelled_slot_booking: true,
    preferred_time_toggle: false,
    max_active_booking: '',
    max_bulk_booking: '',
    max_recurring_booking: '',
    open_time: '',
    close_time: '',
    slot_interval: '',
    pre_buffer: '00:30',
    buffer: '00:30',
    slots: [],
    week_days: [],
    availability: '',
    recurring: false,
    requestsPerSlot: '10',
    isFacilityConfigurationCompleted: false,
  },
  facilitySingleDayConfiguration: {
    unit: '',
    date: '',
    holiday: false,
    open_time: '',
    close_time: '',
    slot_interval: '00:30',
    slots: [],
    requestsPerSlot: '10',
  },
  facilityEditedEvents: [],
  facilityDrawer: false,
  facilityCurrentStep: 0,
  facilitySingleDayEditModal: false,
  isLoadingFacilityList: false,
  isFacilityBasicInfoCreated: false,
  mode: 'add',
  isLoadingBusinessFacilityList: false,
  isLoadingBusinessDetails: false,
  isInvalidBusiness: false,
  businessUrl: '',
  businessDetailsFilter: {
    page: 1,
    search: '',
  },
  businessDetails: {
    business: {
      id_hash: '',
      name: null,
      description: null,
      address: null,
      url: null,
      phone: '',
      cover: null,
      logo: null,
      sms_enabled: 0,
      date_format: null,
      created_at: null,
      updated_at: null,
      deleted_at: null,
      user_id: 0,
      latitude: null,
      longitude: null,
      purchase_status: 0,
      restrictions_status: 0,
      profile_status: 0,
      location: null,
      country_code: '',
    },
    businessUnits: {
      current_page: 0,
      data: [],
      first_page_url: null,
      from: 0,
      last_page: 0,
      last_page_url: null,
      next_page_url: null,
      path: null,
      per_page: 0,
      prev_page_url: null,
      to: 0,
      total: 0,
    },
    unitsCount: 0,
  },
  completeFacilityList: [],
}

export const createFacilityBasicInfo = createAsyncThunk(
  '/createFacilityBasicInfo',
  async (
    facilityBasicInfoDetails: ICreateFacilityBasicInfoDetails,
    { dispatch, rejectWithValue },
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { updateStep, ...facilityBasicInfoData } = facilityBasicInfoDetails
    dispatch(startLoader())
    try {
      const response = await facilityService.createFacilityBasicInfo(facilityBasicInfoData)
      dispatch(stopLoader())
      dispatch(successToast(handleMessages(response.message)))
      dispatch(getAllFacilities(initialState.filters))
      dispatch(getAllLocations())
      facilityBasicInfoDetails.updateStep()
      return response
    } catch (err: any) {
      dispatch(stopLoader())
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)

export const getAllLocations = createAsyncThunk(
  '/getAllLocations',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(updateLocationLoader(true))
    try {
      const response = await facilityService.getAllLocations()
      dispatch(updateLocationLoader(false))
      return response
    } catch (err: any) {
      dispatch(stopLoader())
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)

export const addLocation = createAsyncThunk(
  '/addLocation',
  async (locationData: IAddLocation, { dispatch, rejectWithValue }) => {
    dispatch(startLoader())
    try {
      const response = await facilityService.addLocation(locationData)
      dispatch(stopLoader())
      return response
    } catch (err: any) {
      dispatch(stopLoader())
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)

export const createFacilityConfiguration = createAsyncThunk(
  '/createFacilityConfiguration',
  async (
    facilityConfigurationDetails: ICreateFacilityConfiguration,
    { dispatch, rejectWithValue },
  ) => {
    dispatch(startLoader())
    try {
      const response = await facilityService.createFacilityConfiguration(
        facilityConfigurationDetails,
      )
      dispatch(stopLoader())
      dispatch(successToast(handleMessages(response.message)))
      dispatch(getAllFacilities(initialState.filters))
      dispatch(updateFacilityDrawerStatus(false))
      dispatch(updateFacilityCurrentStep(0))
      dispatch(resetFacilityBasicInfo())
      dispatch(resetFacilityConfiguration())
      dispatch(updateFacilityBasicInfoCreationStatus(false))
      return response
    } catch (err: any) {
      dispatch(stopLoader())
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)

export const updateFacilityConfiguration = createAsyncThunk(
  '/updateFacilityConfiguration',
  async (
    facilityConfigurationDetails: IUpdateFacilityConfiguration,
    { dispatch, rejectWithValue, getState },
  ) => {
    dispatch(startLoader())
    try {
      const response = await facilityService.updateFacilityConfiguration(
        facilityConfigurationDetails,
      )
      dispatch(stopLoader())
      dispatch(successToast(handleMessages(response.message)))
      const { facility } = getState() as { facility: IFacilityState }
      dispatch(getAllFacilities(facility.filters))
      dispatch(updateFacilityDrawerStatus(false))
      dispatch(updateFacilityCurrentStep(0))
      dispatch(resetFacilityBasicInfo())
      dispatch(resetFacilityConfiguration())
      dispatch(updateFacilityBasicInfoCreationStatus(false))
      return response
    } catch (err: any) {
      dispatch(stopLoader())
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)

export const updateFacilityDaySettings = createAsyncThunk(
  '/updateFacilityDaySettings',
  async (facilityDaySettingsDetails: IUpdateFacilityDaySettings, { dispatch, rejectWithValue }) => {
    dispatch(startLoader())
    try {
      const response = await facilityService.updateFacilityDaySettings(facilityDaySettingsDetails)
      dispatch(stopLoader())
      dispatch(successToast(handleMessages(response.message)))
      dispatch(getFacilityDetails(facilityDaySettingsDetails.unit))
      return response
    } catch (err: any) {
      dispatch(stopLoader())
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)

export const getAllFacilities = createAsyncThunk(
  '/getAllFacilities',
  async (filters: IFilters, { dispatch, rejectWithValue }) => {
    try {
      const response = await facilityService.getAllFacilities(filters)
      dispatch(getPricingLimits({}))
      return response
    } catch (err: any) {
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)

export const getLocationFacilities = createAsyncThunk(
  '/getLocationFacilities',
  async (location: number, { dispatch, rejectWithValue }) => {
    dispatch(setFacilityLoadingStatusTrue())

    try {
      const params = {
        location: location,
        inactive: 0,
      }
      const response = await facilityService.getLocationFacilities(params)
      dispatch(setFacilityLoadingStatusFalse())

      return response
    } catch (err: any) {
      dispatch(setFacilityLoadingStatusFalse())

      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)

export const getFacilityDetails = createAsyncThunk(
  '/getFacilityDetails',
  async (facilityId: string, { dispatch, rejectWithValue }) => {
    dispatch(startLoader())
    try {
      const response = await facilityService.getFacilityDetails(facilityId)
      dispatch(getAllLocations())
      dispatch(stopLoader())
      dispatch(updateFacilityDrawerStatus(true))
      return response
    } catch (err: any) {
      dispatch(stopLoader())
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)

export const getFacilityBookingCount = createAsyncThunk(
  '/getFacilityBookingCount',
  async (facilityDetails: IGetFacilityBookingCount, { dispatch, rejectWithValue }) => {
    dispatch(startLoader())
    try {
      const response = await facilityService.getFacilityBookingCount(facilityDetails)
      dispatch(stopLoader())
      return response
    } catch (err: any) {
      dispatch(stopLoader())
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)

export const updateFacilityBasicInfo = createAsyncThunk(
  '/updateFacilityBasicInfo',
  async (
    facilityBasicInfoDetails: IUpdateFacilityBasicInfoDetails,
    { dispatch, rejectWithValue, getState },
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { updateStep, ...facilityBasicInfoData } = facilityBasicInfoDetails
    dispatch(startLoader())
    try {
      const response = await facilityService.updateFacilityBasicInfo(facilityBasicInfoData)
      dispatch(stopLoader())
      dispatch(successToast(handleMessages(response.message)))
      dispatch(getFacilityDetails(facilityBasicInfoDetails.facility_id))
      const { facility } = getState() as { facility: IFacilityState }
      dispatch(getAllFacilities(facility.filters))
      dispatch(getAllLocations())
      facilityBasicInfoDetails.updateStep()
      return response
    } catch (err: any) {
      dispatch(stopLoader())
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)

export const getFacilityDayConfiguration = createAsyncThunk(
  '/getFacilityDayConfiguration',
  async (dayDetails: IDayDetails, { dispatch, rejectWithValue }) => {
    dispatch(startLoader())
    try {
      const response = await facilityService.getFacilityDayConfiguration(dayDetails)
      dispatch(stopLoader())
      dispatch(updateFacilitySingleDayEditModalStatus(true))
      return response
    } catch (err: any) {
      dispatch(stopLoader())
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)

export const checkFacilityConfigurationEvents = createAsyncThunk(
  '/checkFacilityConfigEvents',
  async (
    facilityConfigurationDetails: ICheckFacilityConfigEvents | ICheckDayFacilityConfigEvents,
    { dispatch, rejectWithValue },
  ) => {
    dispatch(startLoader())
    try {
      const response = await facilityService.checkFacilityConfigurationEvents(
        facilityConfigurationDetails,
      )
      dispatch(stopLoader())
      return response
    } catch (err: any) {
      dispatch(stopLoader())
      return rejectWithValue(err)
    }
  },
)

export const deleteFacility = createAsyncThunk(
  '/deleteFacility',
  async (facilityId: string, { dispatch, rejectWithValue, getState }) => {
    dispatch(startLoader())
    try {
      const response = await facilityService.deleteFacility(facilityId)
      dispatch(stopLoader())
      dispatch(successToast(handleMessages(response.message)))
      const { facility } = getState() as { facility: IFacilityState }
      dispatch(getAllFacilities(facility.filters))
      return response
    } catch (err: any) {
      dispatch(stopLoader())
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)

export const updateFacilityStatus = createAsyncThunk(
  '/updateStatus',
  async (facilityStatus: IFacilityStatusDetails, { dispatch, rejectWithValue, getState }) => {
    dispatch(startLoader())
    try {
      const response = await facilityService.updateFacilityStatus(facilityStatus)
      dispatch(successToast(handleMessages(response.message)))
      dispatch(stopLoader())
      return response
    } catch (err: any) {
      dispatch(stopLoader())
      const { facility } = getState() as { facility: IFacilityState }
      dispatch(getAllFacilities(facility.filters))
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)

// ### customer service ###

export const getFacilityListByBusinessUrl = createAsyncThunk(
  '/getFacilityListByBusinessUrl',
  async (filter: IGetBusinessDetails, { dispatch, rejectWithValue, getState }) => {
    try {
      const response = await facilityService.getFacilityListByBusinessUrl(filter)

      // For handling reschedule from mail flow
      const searchParams = new URLSearchParams(window.location.search)
      const isRescheduleLink = searchParams.get('reschedule') ? true : false
      const bookingToken = searchParams.get('token')
      const linkExpiryTime = searchParams.get('expires')
      const loggedInUserType = getUserTypeFromToken()
      const { newBooking } = getState() as {
        newBooking: INewBookingDetails
      }

      if (
        response &&
        response.data.business.restrictions_status === 0 &&
        bookingToken &&
        linkExpiryTime
      ) {
        if (isAuthenticated() && loggedInUserType && loggedInUserType === 'customer') {
          History.navigate(`/customer/my-bookings${window.location.search}`)
        } else {
          if (isRescheduleLink) {
            dispatch(
              rescheduleBookingFromMail({
                token: bookingToken,
                expires: linkExpiryTime,
                bookingDetails: newBooking.rescheduleBookingDetails,
              }),
            )
          }
          // handle guest rescheduling flow here
        }
      }

      return response
    } catch (err: any) {
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)
export const facilitySlice = createSlice({
  name: 'facility',
  initialState,
  reducers: {
    changeFilters: (state, action) => {
      return {
        ...state,
        filters: action.payload,
      }
    },
    resetFilters: (state) => {
      state.filters = initialState.filters
    },
    // To reset the values in Filter
    updateLocationLoader: (state, action) => {
      return {
        ...state,
        facilityLocation: {
          ...state.facilityLocation,
          loading: action.payload,
        },
      }
    },
    updateLocationId: (state, action) => {
      return {
        ...state,
        facilityLocation: {
          ...state.facilityLocation,
          location_id: action.payload,
        },
      }
    },
    updateFacilityId: (state, action) => {
      return {
        ...state,
        facilityConfiguration: {
          ...state.facilityConfiguration,
          facility_id: action.payload,
        },
      }
    },
    updateFacilityMode: (state, action) => {
      return {
        ...state,
        mode: action.payload,
      }
    },
    updateFacilityDrawerStatus: (state, action) => {
      return {
        ...state,
        facilityDrawer: action.payload,
      }
    },
    updateFacilitySingleDayEditModalStatus: (state, action) => {
      return {
        ...state,
        facilitySingleDayEditModal: action.payload,
      }
    },
    updateFacilityCurrentStep: (state, action) => {
      return {
        ...state,
        facilityCurrentStep: action.payload,
      }
    },
    resetFacilityBasicInfo: (state) => {
      return {
        ...state,
        facilityBasicInfo: initialState.facilityBasicInfo,
        facilityLocation: {
          ...state.facilityLocation,
          location_id: initialState.facilityLocation.location_id,
          loading: initialState.facilityLocation.loading,
        },
        mode: initialState.mode,
      }
    },
    resetFacilityConfiguration: (state) => {
      return {
        ...state,
        facilityConfiguration: initialState.facilityConfiguration,
        mode: initialState.mode,
      }
    },
    resetFacilityDropdown: (state) => {
      state.bookingFacilities.data = []
    },
    updateFacilityBasicInfoCreationStatus: (state, action) => {
      return {
        ...state,
        isFacilityBasicInfoCreated: action.payload,
      }
    },

    // ### customer related reducer ###
    changeBusinessUrl: (state, action) => {
      return {
        ...state,
        businessUrl: action.payload,
      }
    },
    changeBusinessDetailsFilter: (state, action) => {
      return {
        ...state,
        businessDetailsFilter: action.payload,
      }
    },
    resetBusinessDetailsFilter: (state) => {
      state.businessDetailsFilter = initialState.businessDetailsFilter
    },
    resetFacilityListData: (state) => {
      state.completeFacilityList = initialState.completeFacilityList
    },
    resetBusinessDetailsData: (state) => {
      state.businessDetails = initialState.businessDetails
      state.businessDetailsFilter = initialState.businessDetailsFilter
      state.isLoadingBusinessFacilityList = initialState.isLoadingBusinessFacilityList
      state.completeFacilityList = initialState.completeFacilityList
      state.businessUrl = initialState.businessUrl
    },
  },
  extraReducers: (builder) => {
    builder
      // Facility Details
      .addCase(getAllFacilities.pending, (state) => {
        state.isLoadingFacilityList = true
      })
      .addCase(getAllFacilities.fulfilled, (state, action) => {
        state.facilities = action.payload.data.facilities
        state.isLoadingFacilityList = false
      })
      .addCase(getAllFacilities.rejected, (state) => {
        state.isLoadingFacilityList = false
      })
      .addCase(createFacilityBasicInfo.fulfilled, (state, action) => {
        state.facilityBasicInfo.facility_name = action.payload.data.facility.name
        state.facilityBasicInfo.address = action.payload.data.facility.address
        state.facilityBasicInfo.latitude = action.payload.data.facility.latitude
        state.facilityBasicInfo.longitude = action.payload.data.facility.longitude
        state.facilityBasicInfo.location = action.payload.data.facility.map_location
        state.facilityBasicInfo.image = action.payload.data.facility.image
        state.facilityBasicInfo.description = action.payload.data.facility.description
          ? action.payload.data.facility.description
          : ''

        state.facilityLocation.initialLocationId =
          action.payload.data.facility.location_id.toString()
        state.facilityLocation.location_id = action.payload.data.facility.location_id

        state.facilityConfiguration.facility_id = action.payload.data.facility.id_hash
      })
      .addCase(getAllLocations.fulfilled, (state, action) => {
        state.facilityLocation.locationOptions = action.payload.data.locations
      })
      .addCase(addLocation.fulfilled, (state, action) => {
        state.facilityLocation.location_id = action.payload.data.location.id
      })
      .addCase(getLocationFacilities.fulfilled, (state, action) => {
        state.bookingFacilities = action.payload.data.facilities
      })
      .addCase(getFacilityDetails.fulfilled, (state, action) => {
        state.facilityBasicInfo.facility_name = action.payload.data.facility.name
        state.facilityBasicInfo.address = action.payload.data.facility.address
        state.facilityBasicInfo.latitude = action.payload.data.facility.latitude
        state.facilityBasicInfo.longitude = action.payload.data.facility.longitude
        state.facilityBasicInfo.location = action.payload.data.facility.map_location
        state.facilityBasicInfo.image = action.payload.data.facility.image
        state.facilityBasicInfo.description = action.payload.data.facility.description
          ? action.payload.data.facility.description
          : ''

        state.facilityLocation.initialLocationId =
          action.payload.data.facility.location_id.toString()
        state.facilityLocation.location_id = action.payload.data.facility.location_id.toString()

        state.facilityConfiguration.allow_cancelled_slot_booking =
          action.payload.data.facility.allow_cancelled_slot_boooking
        state.facilityConfiguration.preferred_time_toggle =
          action.payload.data.facility.preferred_time_toggle
        state.facilityConfiguration.max_active_booking = action.payload.data.facility
          .max_active_booking
          ? action.payload.data.facility.max_active_booking
          : ''
        state.facilityConfiguration.max_bulk_booking = action.payload.data.facility.max_bulk_booking
          ? action.payload.data.facility.max_bulk_booking
          : ''
        state.facilityConfiguration.max_recurring_booking =
          action.payload.data.facility.max_recurring_booking
        state.facilityConfiguration.open_time = action.payload.data.facility.open_time
        state.facilityConfiguration.close_time = action.payload.data.facility.close_time
        state.facilityConfiguration.close_time = action.payload.data.facility.close_time
        state.facilityConfiguration.slot_interval = getTimeInHours(action.payload.data.interval)
        state.facilityConfiguration.pre_buffer = getTimeInHours(action.payload.data.pre_buffer)
        state.facilityConfiguration.buffer = getTimeInHours(action.payload.data.buffer)
        ;(state.facilityConfiguration.slots = action.payload.data.base_slots
          ? generateSlots(
              action.payload.data.facility.open_time,
              action.payload.data.interval,
              'minutes',
              action.payload.data.facility.close_time,
              '10',
              action.payload.data.base_slots.slot_seats,
            )
          : []),
          (state.facilityConfiguration.week_days = generateOperatingWeekDays(
            action.payload.data.facility.open_time,
            action.payload.data.facility.close_time,
            action.payload.data.days,
          ))

        state.facilityConfiguration.availability = action.payload.data.availability
        state.facilityConfiguration.recurring = action.payload.data.facility.recurring
        state.facilityEditedEvents = action.payload.data.events_edited
        state.facilityConfiguration.isFacilityConfigurationCompleted = action.payload.data
          .base_slots
          ? true
          : false
      })
      .addCase(getFacilityDayConfiguration.fulfilled, (state, action) => {
        state.facilitySingleDayConfiguration.unit = action.payload.data.unit
        state.facilitySingleDayConfiguration.date = action.payload.data.start
        state.facilitySingleDayConfiguration.open_time = action.payload.data.start_time
        state.facilitySingleDayConfiguration.close_time = action.payload.data.end_time
        state.facilitySingleDayConfiguration.holiday =
          action.payload.data.holiday === 'Yes' ? true : false
        state.facilitySingleDayConfiguration.slot_interval = getTimeInHours(
          action.payload.data.interval_mins,
        )

        state.facilitySingleDayConfiguration.slots = generateSlots(
          action.payload.data.start_time,
          action.payload.data.interval_mins,
          'minutes',
          action.payload.data.end_time,
          '10',
          action.payload.data.slot_seats ? action.payload.data.slot_seats : '',
        )
      })
      .addCase(getFacilityListByBusinessUrl.pending, (state) => {
        state.isLoadingBusinessFacilityList = true
        state.isInvalidBusiness = false
      })
      .addCase(getFacilityListByBusinessUrl.fulfilled, (state, action) => {
        state.businessDetails = action.payload.data
        if (
          action.payload.data?.businessUnits?.data &&
          !_isEqual(state.completeFacilityList, action.payload.data?.businessUnits?.data)
        ) {
          // Append action.payload.data to existing completeFacilityList
          state.completeFacilityList = [
            ...state.completeFacilityList,
            ...(action.payload.data?.businessUnits?.data || []),
          ]
        }
        state.isLoadingBusinessFacilityList = false
        state.isInvalidBusiness = false
      })
      .addCase(getFacilityListByBusinessUrl.rejected, (state) => {
        state.isLoadingBusinessFacilityList = false
        state.isInvalidBusiness = true
      })
  },
})

export const {
  changeFilters,
  updateLocationId,
  updateLocationLoader,
  updateFacilityId,
  updateFacilityMode,
  resetFacilityBasicInfo,
  updateFacilityDrawerStatus,
  updateFacilitySingleDayEditModalStatus,
  updateFacilityCurrentStep,
  resetFacilityConfiguration,
  resetFacilityDropdown,
  updateFacilityBasicInfoCreationStatus,
  resetFilters,
  changeBusinessDetailsFilter,
  resetBusinessDetailsFilter,
  changeBusinessUrl,
  resetFacilityListData,
  resetBusinessDetailsData,
} = facilitySlice.actions
export const facilityState = (state: RootState) => state.facility
export default facilitySlice.reducer
