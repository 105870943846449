import { IIcon } from './iconConstants'
const TickInsideCircleIcon = (props: IIcon) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox='0 0 45 45'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g fillRule='nonzero' fill='none'>
        <path
          d='M22.5 1.5c-5.792 0-11.042 2.354-14.844 6.156C3.854 11.458 1.5 16.708 1.5 22.5s2.354 11.042 6.156 14.844C11.458 41.146 16.708 43.5 22.5 43.5s11.042-2.354 14.844-6.156C41.146 33.542 43.5 28.292 43.5 22.5s-2.354-11.042-6.156-14.844C33.542 3.854 28.292 1.5 22.5 1.5z'
          stroke={props.fill}
          strokeWidth='3'
        />
        <path
          d='M31.353 16.369a1.965 1.965 0 0 0-1.422-.619c-.512 0-1.021.206-1.408.615l-8.487 8.73-3.53-3.631a1.988 1.988 0 0 0-1.43-.61c-.518 0-1.035.204-1.43.61a2.13 2.13 0 0 0-.596 1.488c0 .539.198 1.078.597 1.489l4.953 5.094c.394.405.908.615 1.423.615s1.03-.21 1.423-.615l9.907-10.19c.399-.41.597-.95.597-1.488 0-.539-.198-1.078-.597-1.488z'
          stroke={props.stoke}
          strokeWidth='.9'
          fill={props.fill}
        />
      </g>
    </svg>
  )
}
export default TickInsideCircleIcon
