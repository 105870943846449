import { ReactNode, useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { getUserTypeFromToken, isAuthenticated } from '../../_helpers/cookie'
import { useAppDispatch, useAppSelector } from '../../_helpers/hooks'
import useUserTypeAuthorization from '../../hooks/useUserTypeAuthorization'
import { translation, translationData } from '../../slices/translationSlice'
import { userDetails } from '../../slices/userSlice'

interface IRequireUserTypeAuth {
  children: ReactNode
  authorizedUserType: string
}

const RequireUserTypeAuth = ({ children, authorizedUserType }: IRequireUserTypeAuth) => {
  const dispatch = useAppDispatch()
  const [isUserTypeAuthorized] = useUserTypeAuthorization(authorizedUserType)
  const loggedInUserType = getUserTypeFromToken()
  const translationState = useAppSelector(translationData)
  const { selectedLanguage } = translationState

  // fetch user details - user type and user active permissions
  useEffect(() => {
    if (isAuthenticated()) {
      dispatch(translation(selectedLanguage))
      dispatch(userDetails())
    }
  }, [dispatch, selectedLanguage])

  const getAuthContent = () => {
    if (!isAuthenticated()) {
      return <Navigate to='/login' />
    } else if (isAuthenticated() && loggedInUserType && !isUserTypeAuthorized) {
      return <Navigate to='/access-denied' />
    } else if (isAuthenticated() && loggedInUserType && isUserTypeAuthorized) {
      return children
    } else {
      return <></>
    }
  }

  return <>{getAuthContent()}</>
}

export default RequireUserTypeAuth
