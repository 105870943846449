import * as React from 'react'
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress'
import { FC } from 'react'

interface IProgressBar {
  width?: string
  height: string
  value: number
  borderRadius?: string
  customColor: string
}

const CustomProgressBar: FC<IProgressBar & LinearProgressProps> = ({
  width,
  height,
  value,
  borderRadius,
  customColor,
  ...rest
}) => {
  return (
    <LinearProgress
      variant='determinate'
      value={value}
      sx={{
        height: height,
        width: width ? width : '100%',
        borderRadius: borderRadius ? borderRadius : 5,
        backgroundColor: (theme) =>
          theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
        '& .MuiLinearProgress-bar': {
          borderRadius: borderRadius ? borderRadius : 5,
          backgroundColor: customColor,
        },
      }}
      {...rest}
    />
  )
}

export default CustomProgressBar
