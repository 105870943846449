import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Collapse,
  LinearProgress,
  Typography,
  linearProgressClasses,
  styled,
  useTheme,
} from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
// import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../../_helpers/hooks'
import orangeBg from '../../../images/background-circle.png'
import CheckIn from '../../../images/imageComponents/checkIn'
import CreateOnline from '../../../images/imageComponents/createOnline'
import SeatBooking from '../../../images/imageComponents/seatBooking'
import SimplifyFloorMap from '../../../images/imageComponents/simplifyFloorMap'
import TokenManagement from '../../../images/imageComponents/tokenManagement'
import WorkspaceConfig from '../../../images/imageComponents/workspaceConfig'

const images = [
  CreateOnline,
  WorkspaceConfig,
  SeatBooking,
  TokenManagement,
  CheckIn,
  SimplifyFloorMap,
]

const progressValues = [20, 40, 60, 80, 90, 100]

const OurFeatureSection = () => {
  const theme = useTheme()
  // const navigate = useNavigate()
  const [expanded, setExpanded] = useState<string | false>(false)
  const [imageIndex, setImageIndex] = useState(0)
  const [progress, setProgress] = useState(0)
  const [animationKey, setAnimationKey] = useState(0)
  const intervalRef = useRef<NodeJS.Timeout | null>(null)
  const translatedInput = useAppSelector((state) => state?.translation.translatedData)
  const panels = ['panel1', 'panel2', 'panel3', 'panel4', 'panel5', 'panel6']

  const startRotation = (startIndex: number) => {
    let index = startIndex
    intervalRef.current = setInterval(() => {
      setExpanded(panels[index])
      setImageIndex(index)
      setProgress(progressValues[index])
      setAnimationKey((prev) => prev + 1)
      index = (index + 1) % panels.length
    }, 3000)
  }

  const stopRotation = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current)
      intervalRef.current = null
    }
  }

  const handleChange =
    (panel: string, index: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      stopRotation()
      setExpanded(isExpanded ? panel : false)
      setImageIndex(isExpanded ? index : 0)
      setProgress(isExpanded ? progressValues[index] : 0)
      setAnimationKey(isExpanded ? (prev) => prev + 1 : animationKey)
      if (isExpanded) {
        startRotation(index)
      }
    }

  const accordionStyle = {
    mb: 3,
    boxShadow: '0rem 0.5rem 1rem rgba(0, 0, 0, 0.08), 0rem 1rem 2rem rgba(0, 0, 0, 0.08)',
    borderRadius: '0.875rem',
    minHeight: '4.375rem',

    [theme.breakpoints.down(400)]: {
      minHeight: '5rem',
    },
    maxWidth: '42rem',
    textAlign: 'left',
    backgroundColor: theme.palette.common.white,
    '&:before': {
      display: 'none',
    },
    '&:first-of-type': {
      borderRadius: '1rem',
    },
    '&:last-of-type': {
      borderRadius: '1rem',
    },
  }

  const accordionSummaryStyle = {
    '& .MuiAccordionSummary-root': {
      minHeight: '4.375rem',
    },
    '& .MuiAccordionSummary-content': {
      margin: '1.25rem 0 0 0',
    },
  }

  const accordionDetailsStyle = {
    paddingLeft: 2,
    paddingRight: 2,
    paddingTop: 0,
    textAlign: 'left',
  }

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: '0.25rem',
    borderRadius: 5,
    marginLeft: 1,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      background: `linear-gradient(to right, ${theme.palette.publicPages.colorSix}, ${theme.palette.primary.main})`,
    },
  }))

  useEffect(() => {
    startRotation(0)
    return () => stopRotation()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box
      sx={{
        backgroundImage: `url(${orangeBg})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right',
      }}
      p={5}
    >
      <Box sx={{ margin: '0 auto', maxWidth: '100rem' }}>
        <Typography
          variant='h2'
          sx={{ mb: 2, color: 'iconColors.main' }}
          fontSize={{ xs: '1.75rem', md: '2.5rem' }}
          lineHeight={{ xs: '2.5rem', md: '3.375rem' }}
          fontWeight={600}
        >
          {translatedInput?.landingScreen?.ourText}
          <span style={{ color: theme.palette.primary.main }}> Features</span>
        </Typography>
        <Typography
          variant='h5'
          sx={{ color: 'publicPages.colorTwo' }}
          lineHeight={'1.563rem'}
          fontWeight={400}
        >
          {translatedInput?.landingScreen?.ourFeatureText}
        </Typography>
        <Box
          display={'flex'}
          justifyContent={'center'}
          columnGap={10}
          mt={8}
          minHeight={{ md: '43.75rem', xl: '40.625rem' }}
        >
          <Box
            sx={{
              zoom: { xs: '50%', md: '90%', lg: '100%' },
              display: { xs: 'none', md: 'block' },
            }}
          >
            {React.createElement(images[imageIndex])}
          </Box>
          <Box>
            {['panel1', 'panel2', 'panel3', 'panel4', 'panel5', 'panel6'].map((panel, index) => (
              <Accordion
                key={panel}
                expanded={expanded === panel}
                onChange={handleChange(panel, index)}
                sx={accordionStyle}
              >
                <AccordionSummary
                  aria-controls={`${panel}-content`}
                  id={`${panel}-header`}
                  sx={accordionSummaryStyle}
                >
                  <Typography
                    fontWeight={600}
                    fontSize={{ xs: '1.125rem', md: '1.25rem' }}
                    lineHeight={'1.563rem'}
                    sx={{
                      color: expanded === panel ? 'primary.main' : 'publicPages.colorDivider',
                      [theme.breakpoints.down(428)]: {
                        paddingBottom: 2,
                      },
                    }}
                  >
                    {index === 0
                      ? translatedInput?.landingScreen?.ourFeatureHeader1
                      : index === 1
                      ? translatedInput?.landingScreen?.ourFeatureHeader2
                      : index === 2
                      ? translatedInput?.landingScreen?.ourFeatureHeader3
                      : index === 3
                      ? translatedInput?.landingScreen?.ourFeatureHeader4
                      : index === 4
                      ? translatedInput?.landingScreen?.ourFeatureHeader5
                      : translatedInput?.landingScreen?.ourFeatureHeader6}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={accordionDetailsStyle}>
                  <Typography
                    fontSize={'1rem'}
                    lineHeight={'1.563rem'}
                    fontWeight={300}
                    color={'publicPages.main'}
                  >
                    {index === 0 && <>{translatedInput?.landingScreen?.ourFeatureText1}</>}
                    {index === 1 && <>{translatedInput?.landingScreen?.ourFeatureText2}</>}
                    {index === 2 && <>{translatedInput?.landingScreen?.ourFeatureText3}</>}
                    {index === 3 && <>{translatedInput?.landingScreen?.ourFeatureText4}</>}
                    {index === 4 && <>{translatedInput?.landingScreen?.ourFeatureText5}</>}
                    {index === 5 && <>{translatedInput?.landingScreen?.ourFeatureText6}</>}
                  </Typography>
                </AccordionDetails>
                <Collapse in={expanded === panel} sx={{ width: '98%', marginLeft: '0.25rem' }}>
                  <BorderLinearProgress
                    variant='determinate'
                    value={progress}
                    sx={{ borderRadius: 5 }}
                  />
                </Collapse>
              </Accordion>
            ))}
          </Box>
        </Box>
        {/* <Box mt={{ xs: 5, md: 1 }}>
          <Button size='large' variant='contained' onClick={() => navigate('coming-soon')}>
            {translatedInput?.landingScreen?.viewFeaturesText}
          </Button>
        </Box> */}
      </Box>
    </Box>
  )
}

export default OurFeatureSection
