import { IIcon } from './iconConstants'
const ClockIcon = (props: IIcon) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox='0 0 16 16'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8 16A8 8 0 1 1 8 0a8 8 0 0 1 0 16zm0-1.6A6.4 6.4 0 1 0 8 1.6a6.4 6.4 0 0 0 0 12.8zM8.8 8H12v1.6H7.2V4h1.6v4z'
        fill={props.fill}
        fillRule='nonzero'
      />
    </svg>
  )
}
export default ClockIcon
