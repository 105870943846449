import { IScannerBookingData } from '../_models/pusherInterface'
import { get, post } from './apiServices'

const getPusherConfigDetails = async () => {
  const response = await get('/pusher/config')
  return response
}

const getAllScannerFacilities = async () => {
  const response = await get('/scanner/facilities')
  return response
}
const completeBooking = async (BookingData: IScannerBookingData) => {
  const response = await post('/scanner/complete', BookingData)
  return response
}
const scannerService = {
  getPusherConfigDetails,
  getAllScannerFacilities,
  completeBooking,
}
export default scannerService
