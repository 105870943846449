/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */

import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
  useTheme,
} from '@mui/material'
import Box from '@mui/material/Box'
import { useEffect, useRef, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import 'react-phone-input-2/lib/material.css'

import { useAppDispatch, useAppSelector } from '../../_helpers/hooks'
import { ISlotsFormData } from '../../_models/commonInterface'
import { IFacility } from '../../_models/facilityInterface'
import {
  IBlockSeats,
  IFloorsNewBooking,
  INewBookingLocations,
  IPhoneDetails,
  ISeatInFloors,
  ISeats,
  ISlotInfo,
} from '../../_models/newBookingInterface'

import { facilityState, resetFacilityDropdown } from '../../slices/facilitySlice'
import {
  blockSeats,
  changeValue,
  checkFloorsAndUpdateSeatDetails,
  checkForFloors,
  closeDrawer,
  getCSVSeatsInFloors,
  getFloors,
  getLocationFacilities,
  getSVGSeatsInFloors,
  getSlots,
  newBooking,
  newBookingData,
  resetBookingDetails,
  resetFloorDetails,
  resetSeatAvailabilityStates,
  unBlockSeats,
  updateBooking,
  updateBookingConfirmationModalStatus,
  updateFloorDetails,
  updateIsBookForOthers,
  updateNotBlockedSeats,
  updatePreviouslyBlockedSeats,
  updateSelectedSeats,
  validateEmails,
} from '../../slices/newBookingSlice'

import { InfoOutlined } from '@mui/icons-material'
import { PERMISSIONS } from '../../_constants'
import { dateToYearMonthDay, stampToTime } from '../../_helpers/dateTime'
import { handleMessages } from '../../_helpers/utilities'
import { ILocationsFromActiveToken } from '../../_models/bookingManagementInterface'
import CustomDrawer from '../../components/@extended/customDrawer'
import {
  PageDrawerContainer,
  PageDrawerFooterButtonsContainer,
  PageDrawerHeaderContainer,
  PageDrawerSectionContainer,
} from '../../components/containers/pageDrawerContainers'
import { WarningIcon } from '../../components/icons'
import { GridSkeleton } from '../../components/loader'
import '../../components/phoneInput/phoneInput.scss'
import LightTooltip from '../../components/toolTip'
import { useUserPermissionAuthorization } from '../../hooks'
import { bookingManagementState } from '../../slices/bookingManagementSlice'
import { errorToast } from '../../slices/toastSlice'
import { userState } from '../../slices/userSlice'
import { voyagerState } from '../../slices/voyagerSlice'
import ActionAlertModal from '../actionAlertModal'
import BookingConfirmationModal from '../bookingConfirmationModal'
import BookingSuccessModal from '../bookingSuccessModal'
import BookForOthersFormFields from './bookForOthersEmail'
import SelectedSlot from './bookingSlots/selectedSlot'
import Slots from './bookingSlots/slots'
import Counters from './counters'
import DateSelection from './dateSelection'
import FormFields from './formFields'
import SeatSelectionArea from './seatSelectionArea'
interface IDrawer {
  drawerStatus: boolean
  updateDrawer: () => void
}

const NewBookingDrawer = (props: IDrawer) => {
  type FormInputs = {
    email: string | null
    name: string | null
    phone: string | null
    location: INewBookingLocations | null
    facility: IFacility | null
  }
  type FormFields = {
    email: string
    name: string
    phone: string
    location: INewBookingLocations | null
    facility: IFacility | null
  }

  const formFields = useForm<FormFields>({
    mode: 'onChange',
  })
  const {
    formState: { isSubmitted },
    handleSubmit,
  } = formFields

  const translatedInput = useAppSelector((state) => state?.translation.translatedData)
  const dispatch = useAppDispatch()

  const [showErrorData, setShowErrorData] = useState<string>('')
  const [showDiscardAlert, setShowDiscardAlert] = useState(false)
  const [bookForOthersUnChecked, setBookForOthersUnChecked] = useState(false)
  const { businessConfig } = useAppSelector(voyagerState)
  const newBookingState = useAppSelector(newBookingData)
  const facilityData = useAppSelector(facilityState)
  const bookingManagementData = useAppSelector(bookingManagementState)
  const { userData } = useAppSelector(userState)
  const { facilitiesAndLocationsFromActiveToken } = bookingManagementData
  const { bookingFacilities } = facilityData
  const {
    newBookingLocationInformation,
    phoneNumberInformation,
    slotInformation,
    slotInformationMessage,
    bookingDetails,
    drawerType,
    bookingSuccessModalStatus,
    bookingConfirmationModalStatus,
    isLoadingSlots,
    noActiveToken,
    seatAvailable,
    isExactTiming,
    seatBookingNotAvailableReason,
    floorDetails,
    selectedSeats,
    previouslyBlockedSeats,
    notBlockedSeats,
    floors,
    floorNotAvailable,
    isBookForOthers,
  } = newBookingState
  const theme = useTheme()
  const slotInfoMorning = slotInformation?.slots_morning
  const slotInfoAfternoon = slotInformation?.slots_afternoon
  const slotInfoEvening = slotInformation?.slots_evening
  const slotInfoNight = slotInformation?.slots_night
  const [state, setState] = useState<boolean>()
  const facilityId = formFields?.getValues('facility')?.id_hash
  const [date, setDate] = useState<string>('')
  const [phoneDetails, setPhoneDetails] = useState<IPhoneDetails>()
  const [numberUpdated, setNumberUpdated] = useState<boolean>(false)
  const [seatCount, setSeatCount] = useState(1)
  const [recurringCount, setRecurringCount] = useState(1)
  const [slotInfo, setSlotInfo] = useState<ISlotInfo>({})
  const { slotId, slotTime } = slotInfo
  const slotAvailabilityError =
    !slotInfoMorning && !slotInfoAfternoon && !slotInfoEvening && !slotInfoNight
  const [counterError, setCounterError] = useState(false)
  const [needSeatSelection, setNeedSeatSelection] = useState('No')
  const [slotAvailability, setSlotAvailability] = useState(0)
  const [floor, setFloor] = useState<IFloorsNewBooking | null>(null)
  const [drawerWidth, setDrawerWidth] = useState({
    xl: '50%',
    md: '59.938rem',
    breakpoint: '55%',
  })
  const [isExpanded, setIsExpanded] = useState(false)
  const [isEditFloorInfoLoaded, setIsEditFloorInfoLoaded] = useState<boolean>(false)
  const seatSelectionContainerRef = useRef<HTMLDivElement | null>(null)
  const svgSeatSelectionRef = useRef(null)
  const [displaySlots, setDisplaySlots] = useState(false)
  const [userEmails, setUserEmails] = useState<string[]>([])
  const [isPermitted] = useUserPermissionAuthorization()
  const isPermissionForKpi = isPermitted([PERMISSIONS.DASHBOARD_KPI_VIEW])

  const handleCloseSvgBookingPopup = () => {
    if (svgSeatSelectionRef?.current) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const currentRef = svgSeatSelectionRef.current as any
      currentRef.handleClose()
    }
  }
  useEffect(() => {
    setState(props.drawerStatus)
    dispatch(updateIsBookForOthers(false))
  }, [props.drawerStatus])
  useEffect(() => {
    const handleBackButton = () => {
      dispatch(closeDrawer())
    }
    window.addEventListener('popstate', handleBackButton)
    return () => {
      window.removeEventListener('popstate', handleBackButton)
    }
  }, [])

  useEffect(() => {
    if (bookingDetails && drawerType === 'edit') {
      handleEditBooking()
    } else if (drawerType === 'create') {
      handleCreateBooking()
    }
  }, [bookingDetails, drawerType])
  useEffect(() => {
    // set location value in edit flow
    if (bookingDetails && drawerType === 'edit') {
      const locationVal = newBookingLocationInformation?.find(
        (location: INewBookingLocations) => location.id === bookingDetails?.location_id,
      )
      if (locationVal) formFields.setValue('location', locationVal)
    }
  }, [newBookingLocationInformation, bookingDetails])

  useEffect(() => {
    // set facility value in edit flow
    if (bookingDetails && drawerType === 'edit') {
      const facilityVal = bookingFacilities?.data?.find(
        (facility: IFacility) => facility?.id_hash == bookingDetails?.facility_id,
      )
      if (facilityVal) formFields?.setValue('facility', facilityVal)
    }
  }, [bookingFacilities?.data])
  useEffect(() => {
    if (date) callSlotsApi()
  }, [date])
  useEffect(() => {
    // populate location and facility in booking form from booking management
    if (noActiveToken) {
      const activeTokenLocations = facilitiesAndLocationsFromActiveToken?.locations
      if (activeTokenLocations) {
        const locationVal = activeTokenLocations?.find(
          (location: ILocationsFromActiveToken) => location?.is_selected == 1,
        )
        if (locationVal) {
          formFields?.setValue('location', locationVal)
          dispatch(getLocationFacilities(locationVal?.id))
        }
      }
      const activeTokenFacilities = facilitiesAndLocationsFromActiveToken?.facilities
      if (activeTokenFacilities) {
        const facilityVal = activeTokenFacilities?.find(
          (facility: IFacility) => facility.is_selected == 1,
        )
        if (facilityVal) formFields?.setValue('facility', facilityVal)
      }
    }
  }, [facilitiesAndLocationsFromActiveToken])

  const handleEditBooking = () => {
    if (newBookingLocationInformation) {
      dispatch(getLocationFacilities(bookingDetails?.location_id))
      const phoneData = bookingDetails?.user_country_code + bookingDetails?.user_phone
      formFields?.setValue('email', bookingDetails?.user_email)
      formFields?.setValue('name', bookingDetails?.user_name)
      formFields?.setValue('phone', phoneData)
      updatePhoneDetails(
        bookingDetails?.user_country_code,
        bookingDetails?.user_phone,
        bookingDetails?.user_iso_code,
      )
      setSeatCount(bookingDetails?.no_of_seats)
      setDate(bookingDetails?.date)
      setDisplaySlots(true)
      setRecurringCount(1)
      setSlotAvailability(bookingDetails?.number_of_available_slots)
      setSlotInfo((prevState) => ({
        ...prevState,
        slotId: bookingDetails?.master_slot_id,
        slotTime: `${stampToTime(bookingDetails?.preferred_slot_from)} - ${stampToTime(
          bookingDetails?.preferred_slot_to,
        )}`,
      }))
      // get floor and seat details
      if (bookingDetails?.master_slot_id && bookingDetails?.facility_id && bookingDetails?.date) {
        dispatch(checkFloorsAndUpdateSeatDetails(bookingDetails))
      }
      if (bookingDetails?.map_details && drawerType === 'edit') {
        setNeedSeatSelection('Yes')
      } else {
        setNeedSeatSelection('No')
      }
    }
  }
  const handleCreateBooking = () => {
    if (noActiveToken) {
      // handle date as today's date in booking management
      setDate(dateToYearMonthDay(new Date().toString()))
      formFields?.setValue('email', '')
    } else {
      resetAllValues()
      resetFormValues()
      dispatch(resetBookingDetails())
    }
    dispatch(resetFacilityDropdown())
  }

  // set floor and need seat selection local states based on booking details
  useEffect(() => {
    if (floors.length > 0 && drawerType === 'edit') {
      // seats were already chosen in floor with seats and seatsAvailable
      if (
        bookingDetails?.floor &&
        seatAvailable &&
        isEditFloorInfoLoaded === false &&
        bookingDetails?.map_details
      ) {
        setNeedSeatSelection('Yes')

        const bookedFloor = floors.find(
          (floor: IFloorsNewBooking) => floor.floor_name === bookingDetails?.floor,
        )
        // set booked floor details
        if (bookedFloor && bookingDetails?.facility_id && bookingDetails?.master_slot_id) {
          setFloor(bookedFloor)
        }
        setIsEditFloorInfoLoaded(true)
      }

      if (!seatAvailable || !bookingDetails?.floor || !bookingDetails?.map_details) {
        setNeedSeatSelection('No')
      }
    }
  }, [floors])

  const closeDiscardAlertModal = () => {
    setShowDiscardAlert(false)
  }

  const toggleDrawer = () => () => {
    if (userEmails?.length > 0) {
      setShowDiscardAlert(true)
    } else {
      closeBookingDrawer()
      setShowDiscardAlert(false)
    }
  }

  const updatePhoneDetails = (
    countryCodeData: string,
    phoneNumberData: string,
    isoCode: string,
  ) => {
    const phoneDetails: IPhoneDetails = {
      countryCode: countryCodeData,
      phoneNumber: phoneNumberData,
      isoCode: isoCode,
    }
    if (phoneDetails) {
      setPhoneDetails(phoneDetails)
    }
    setNumberUpdated(true)
  }
  const onLocationChange = (locationId: number) => {
    resetDateSlotsCounterSeatValues()
    dispatch(getLocationFacilities(locationId))
  }
  const onFacilityChange = () => {
    resetDateSlotsCounterSeatValues()
  }
  const onDateChange = () => {
    dispatch(resetSeatAvailabilityStates())
    setNeedSeatSelection('No')
    resetSelectedSeatsAndFloors()
    setSlotInfo((prevState) => ({
      ...prevState,
      slotId: undefined,
    }))
  }
  const onCountersChange = () => {
    setNeedSeatSelection('No')
    // reset only selected seats and selected floor details
    dispatch(resetFloorDetails())
    dispatch(updateSelectedSeats([]))
    setFloor(null)
  }
  const callSlotsApi = () => {
    const slotsFormData: ISlotsFormData = {
      business_unit_id: facilityId,
      schedule_date: date,
      token: bookingDetails?.token_id ? bookingDetails?.token_id : null,
      no_of_seats: seatCount,
    }
    if (slotsFormData.business_unit_id && date) {
      dispatch(getSlots(slotsFormData))
      setDisplaySlots(true)
    }
  }
  const onSlotSelection = (slot: number | undefined) => {
    resetSelectedSeatsAndFloors()
    dispatch(resetSeatAvailabilityStates())

    if (facilityId && slot && date) {
      const getFloorsParams = {
        facility_id: facilityId,
        master_slot_id: slot,
        date: date,
      }
      dispatch(
        checkForFloors(
          drawerType === 'edit'
            ? { ...getFloorsParams, token_id: bookingDetails?.token_id }
            : getFloorsParams,
        ),
      )
    }
  }
  const onSlotDeselect = () => {
    setSlotInfo((prevState) => ({
      ...prevState,
      slotId: undefined,
    }))
    setNeedSeatSelection('No')
    resetSelectedSeatsAndFloors()
    resetCounter()
    callSlotsApi()
  }

  const closeBookingDrawer = (): void => {
    dispatch(updateIsBookForOthers(false))
    unBlockedSeats()
    resetAllValues()
    setShowDiscardAlert(false)
    props.updateDrawer()
  }
  const resetAllValues = () => {
    dispatch(changeValue({ isDuplicatePhoneNumber: false }))
    setShowErrorData('')

    formFields.reset()
    resetDateSlotsCounterSeatValues()
    setIsEditFloorInfoLoaded(false)
    dispatch(resetFacilityDropdown())
    setUserEmails([])
  }

  const resetFormValues = () => {
    formFields?.setValue('email', '')
    formFields?.setValue('phone', '1')
    formFields?.setValue('name', '')
    formFields?.setValue('location', null)
    formFields?.setValue('facility', null)
  }
  const resetDateSlotsCounterSeatValues = () => {
    resetSelectedSeatsAndFloors()
    setDate('')
    setSlotInfo((prevState) => ({
      ...prevState,
      slotId: undefined,
    }))
    resetCounter()
    dispatch(updateNotBlockedSeats([]))
    dispatch(updatePreviouslyBlockedSeats([]))
    setDisplaySlots(false)
    setNeedSeatSelection('No')
    dispatch(resetSeatAvailabilityStates())
  }
  const resetCounter = () => {
    setSeatCount(1)
    setRecurringCount(1)
    setSlotAvailability(0)
  }
  const isValidData = () => {
    return date && slotId && !counterError && !showErrorData
  }
  const handleBookingConfirmationStatus = (bookingConfirmationStatus: string) => {
    const formData = {
      email: formFields?.getValues().email,
      name: formFields?.getValues().name,
      country_code: numberUpdated
        ? phoneDetails?.countryCode
        : phoneNumberInformation?.country_code,
      phone: numberUpdated ? phoneDetails?.phoneNumber : phoneNumberInformation?.phone,
      iso_code: numberUpdated ? phoneDetails?.isoCode : phoneNumberInformation?.iso_code,
      facility_id: formFields?.getValues().facility?.id_hash,
      no_of_seats: seatCount,
      recurringBooking: recurringCount,
      date: date,
      master_slot_id: slotId,
      book_for_others: isBookForOthers,
      customer_info:
        selectedSeats?.length > 0 && isBookForOthers
          ? userEmails?.map((email) => ({
              seat_id: selectedSeats?.find((seat) => seat?.user_email === email)?.seat_id || null,
              user_email: email,
            }))
          : selectedSeats?.length > 0
          ? selectedSeats?.map((seat) => ({
              seat_id: seat?.seat_id,
              user_email: seat?.user_email,
            }))
          : isBookForOthers
          ? userEmails?.map((email) => ({
              seat_id: null,
              user_email: email,
            }))
          : [{}],
      floor_id: selectedSeats?.length > 0 ? floor?.floor_id : null,
    }

    if (isBookForOthers && drawerType === 'create') {
      // added for supporting backend validations
      formData.email = userData.email ? userData.email : ''
      formData.phone = userData.phone
      formData.country_code = userData.country_code
    }
    switch (bookingConfirmationStatus) {
      case 'Confirm':
        dispatch(updateBookingConfirmationModalStatus(false))
        if (drawerType === 'create' && isValidData()) {
          if (phoneNumberInformation?.user_is_random_phone) {
            delete formData?.country_code
            delete formData?.phone
          }

          dispatch(newBooking(formData))
        }
        if (drawerType === 'edit' && isValidData()) {
          const editFormData = {
            ...formData,
            token_id: bookingDetails?.token_id,
            seat_list: selectedSeats.map((seat: ISeats) => seat.seat_id),
          }
          if (bookingDetails?.is_random_phone) {
            delete editFormData?.country_code
            delete editFormData?.phone
            delete editFormData?.iso_code
          }

          dispatch(
            updateBooking({ formData: editFormData, isPermissionForKpi: isPermissionForKpi }),
          )
        }
        break

      case 'Cancel':
        dispatch(updateBookingConfirmationModalStatus(false))
        break

      case 'Discard':
        dispatch(updateBookingConfirmationModalStatus(false))
        closeBookingDrawer()
        break

      default:
        break
    }
  }

  const onSubmit: SubmitHandler<FormInputs> = () => {
    formFields?.trigger()
    // if (drawerType === 'edit' && bookingDetails) {
    //   const formData = {
    //     token_id: bookingDetails?.token_id,
    //     user_email: formFields?.getValues().email,
    //     user_name: formFields?.getValues().name,
    //     user_country_code: numberUpdated
    //       ? phoneDetails?.countryCode
    //       : phoneNumberInformation?.country_code,
    //     user_phone: numberUpdated ? phoneDetails?.phoneNumber : phoneNumberInformation?.phone,
    //     facility_id: formFields?.getValues().facility?.id_hash,
    //     no_of_seats: seatCount,
    //     // recurringBooking: recurringCount,
    //     date: date,
    //     master_slot_id: slotId,
    //     floor_id: selectedSeats.length > 0 ? floor?.floor_id : null,
    //     seat_list: selectedSeats.map((seat: ISeats) => seat.seat_id),
    //   }
    //   const formattedDetails = {
    //     ...bookingDetails,
    //     seat_list: bookingDetails?.seat_list?.map((seat: ISeats) => seat.seat_id),
    //     floor_id: bookingDetails?.seat_list?.floor_id,
    //   }
    //   const test = checkIfDetailsUpdated(formData, formattedDetails)
    // }
    if ((drawerType === 'edit' || drawerType === 'create') && isValidData()) {
      if (drawerType === 'create' && isBookForOthers && userEmails?.length <= 0) {
        dispatch(
          errorToast(
            handleMessages(translatedInput?.newBookingScreenText?.addAtleastOneVisitorEmailToast),
          ),
        )
      } else {
        if (selectedSeats.length > 0 || previouslyBlockedSeats.length > 0) {
          const blockSeatPrams: IBlockSeats = {
            floor_id: floor?.floor_id,
            facility_id: facilityId,
            master_slot_id: slotId,
            date: date,
            no_of_seats: seatCount,
            customer_info: [],
          }
          if (selectedSeats.length > 0) {
            blockSeatPrams.customer_info = selectedSeats.map((seat: ISeats) => ({
              seat_id: seat.seat_id,
              user_email: seat.user_email ? seat.user_email : '',
            }))
          }
          if (previouslyBlockedSeats.length > 0) {
            blockSeatPrams.previously_blocked_seats = previouslyBlockedSeats
          }
          dispatch(updateNotBlockedSeats([]))
          if (drawerType === 'create') {
            if (isBookForOthers) {
              blockSeatPrams.isBookForOthers = true
              blockSeatPrams.userEmails = userEmails
            }
            dispatch(blockSeats(blockSeatPrams))
          } else {
            dispatch(blockSeats({ ...blockSeatPrams, token_id: bookingDetails?.token_id }))
          }
        } else {
          if (isBookForOthers) {
            // if isBookForOthers true then we need to show a info text in the confirmation modal
            // so check whether there is any unregistered emails in the userEmails
            dispatch(validateEmails({ user_emails: userEmails }))
          } else {
            dispatch(updateBookingConfirmationModalStatus(true))
          }
        }
      }
    }
  }
  const handleUpdateDrawerWidth = (expand: boolean) => {
    setIsExpanded(expand)
    if (expand) {
      setDrawerWidth({
        xl: '90%',
        md: '90%',
        breakpoint: '90%',
      })
    } else {
      setDrawerWidth({
        xl: '50%',
        md: '59.938rem',
        breakpoint: '55%',
      })
    }
  }
  useEffect(() => {
    if (notBlockedSeats.length > 0 && floor !== null) {
      dispatch(resetFloorDetails)
      const getSeatParam: ISeatInFloors = {
        floor_id: floor.floor_id,
        facility_id: facilityId,
        master_slot_id: slotId,
        date: date,
        no_of_seats: seatCount,
      }
      if (floor.floor_type === 1) {
        dispatch(getSVGSeatsInFloors(getSeatParam))
      } else {
        dispatch(getCSVSeatsInFloors(getSeatParam))
      }
    }
  }, [notBlockedSeats])

  useEffect(() => {
    if (notBlockedSeats.length > 0) {
      const updatedSelectedSeats = selectedSeats.filter(
        (seat) =>
          !notBlockedSeats.some((notBlockedSeat) => notBlockedSeat.seat_id === seat.seat_id),
      )

      if (updatedSelectedSeats.length > 0) {
        // Check if there is a match between floorDetails.seats and selectedSeats with a different status
        const hasMatch = floorDetails.seats.some((seat) =>
          updatedSelectedSeats.some(
            (selectedSeat) =>
              selectedSeat.seat_id === seat.seat_id && selectedSeat.status !== seat.status,
          ),
        )
        if (hasMatch) {
          const updatedFloorDetails = {
            ...floorDetails,
            seats: floorDetails.seats.map((seat: ISeats) => {
              // Check if the seat_id exists in selectedSeats
              if (
                updatedSelectedSeats.some((selectedSeat) => selectedSeat.seat_id === seat.seat_id)
              ) {
                // If the seat_id exists in selectedSeats, update the status to 1
                return { ...seat, status: 1 }
              } else {
                // If not found in selectedSeats, return the original seat object
                return seat
              }
            }),
            availableSeats: floorDetails.availableSeats + updatedSelectedSeats.length,
          }

          // Dispatch the updatedFloorDetails
          dispatch(updateFloorDetails(updatedFloorDetails))
          dispatch(updateNotBlockedSeats([]))
        }
      }
      dispatch(updateSelectedSeats(updatedSelectedSeats))
    }
  }, [floorDetails])

  useEffect(() => {
    if (needSeatSelection === 'Yes' && isExpanded) handleUpdateDrawerWidth(true)
    else handleUpdateDrawerWidth(false)
  }, [needSeatSelection])
  useEffect(() => {
    if ((isExpanded || floorDetails.seats.length > 0) && seatSelectionContainerRef.current) {
      seatSelectionContainerRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [isExpanded, floorDetails])

  const handleFloorSelectionChange = (floor: IFloorsNewBooking | null) => {
    unBlockedSeats()
    dispatch(resetFloorDetails())
    setFloor(floor)
    dispatch(updateSelectedSeats([]))
    dispatch(updateNotBlockedSeats([]))
    dispatch(updatePreviouslyBlockedSeats([]))

    if (floor) {
      const getSeatParam: ISeatInFloors = {
        floor_id: floor.floor_id,
        facility_id: facilityId,
        master_slot_id: slotId,
        date: date,
        no_of_seats: seatCount,
      }
      if (floor.floor_type === 1) {
        dispatch(getSVGSeatsInFloors(getSeatParam))
      } else {
        dispatch(getCSVSeatsInFloors(getSeatParam))
      }
    }
  }

  // reset selected seats, selected floor details and floors list
  const resetSelectedSeatsAndFloors = () => {
    unBlockedSeats()
    dispatch(resetFloorDetails())
    dispatch(updateSelectedSeats([]))
    setFloor(null)
    dispatch(changeValue({ floors: [] }))
    setUserEmails([])
  }
  useEffect(() => {
    if (floorNotAvailable) {
      resetSelectedSeatsAndFloors()
      const getFloorsParams = {
        facility_id: facilityId as string,
        master_slot_id: slotId as number,
        date: date as string,
      }
      dispatch(getFloors(getFloorsParams))
    }
  }, [floorNotAvailable])
  useEffect(() => {
    if (!props.drawerStatus) {
      resetAllValues()
    }
  }, [props.drawerStatus])
  const unBlockedSeats = () => {
    if (date && !counterError && slotId && floor && previouslyBlockedSeats.length > 0) {
      const blockSeatPrams: IBlockSeats = {
        floor_id: floor?.floor_id,
        facility_id: facilityId,
        master_slot_id: slotId,
        date: date,
        no_of_seats: seatCount,
        customer_info: [],
        previously_blocked_seats: previouslyBlockedSeats,
      }
      dispatch(unBlockSeats(blockSeatPrams))
    }
  }

  // book for others
  const handleBookForOthers = (event: { target: { checked: boolean } }) => {
    if (event.target.checked) {
      dispatch(updateIsBookForOthers(true))
    } else {
      setBookForOthersUnChecked(true)

      if (userEmails?.length > 0) {
        setShowDiscardAlert(true)
      } else {
        dispatch(updateIsBookForOthers(false))
        resetAllValues()
      }
    }
  }
  const alertConfirm = () => {
    if (isBookForOthers && !bookForOthersUnChecked) {
      closeBookingDrawer()
    } else if (isBookForOthers && bookForOthersUnChecked) {
      setShowDiscardAlert(false)
      dispatch(updateIsBookForOthers(false))
      resetAllValues()
    }
  }
  const handleAddUserEmailButton = (emailValue: string) => {
    if (Math.min(slotAvailability, businessConfig.max_bulk_bookings) > userEmails?.length) {
      if (!userEmails.includes(emailValue)) {
        setUserEmails((prevUserEmails) => [...prevUserEmails, emailValue])
      } else {
        dispatch(errorToast(handleMessages(translatedInput?.newBooking?.emailAlreadyAddedError)))
      }
    } else {
      dispatch(
        errorToast(
          handleMessages(
            `${translatedInput?.newBooking?.slotLimitReachedError}${translatedInput?.newBooking?.cannotAddVisitorsError}`,
          ),
        ),
      )
    }
  }
  const handleEmailClearClick = (emailToRemove: string) => {
    // Check if the userEmails array contains the email to remove
    if (userEmails.includes(emailToRemove)) {
      if (selectedSeats.length > 0) {
        // Filter out the seat with the specified emailToRemove
        const updatedSelectedSeats = selectedSeats.filter(
          (seat) => seat.user_email !== emailToRemove,
        )
        // Update the selectedSeats state with the filtered array
        dispatch(updateSelectedSeats(updatedSelectedSeats))
      }
      // Filter out the email to remove from the userEmails array
      const updatedEmails = userEmails.filter((email) => email !== emailToRemove)

      // Update the userEmails state with the filtered array
      setUserEmails(updatedEmails)
    }
  }
  useEffect(() => {
    if (isBookForOthers) setSeatCount(userEmails?.length > 0 ? userEmails?.length : 1)
  }, [userEmails])

  return (
    <>
      <CustomDrawer
        drawerStatus={Boolean(state)}
        handleClose={toggleDrawer()}
        handleScrollCapture={handleCloseSvgBookingPopup}
        sx={{
          zIndex: 1000,
          '& .MuiDrawer-paper': {
            width: { xs: '100%', sm: '100%', md: drawerWidth.md, xl: drawerWidth.xl },
            pt: '4.75rem',
            [theme.breakpoints.up(1536)]: {
              width: drawerWidth.breakpoint,
            },
            [theme.breakpoints.up(1670)]: {
              width: drawerWidth.xl,
            },
          },
        }}
      >
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <PageDrawerContainer>
            <PageDrawerSectionContainer>
              <PageDrawerHeaderContainer>
                <Box sx={{ flexGrow: 1 }}>
                  <Typography variant='h4' sx={{ fontSize: '1.5rem' }} color='common.black'>
                    {drawerType === 'edit'
                      ? translatedInput?.newBooking?.editAppointmentTitle
                      : translatedInput?.newBooking?.bookAnAppointmentTitle}
                  </Typography>
                </Box>
                <IconButton onClick={toggleDrawer()} sx={{ p: 0 }}>
                  <CloseRoundedIcon />
                </IconButton>
              </PageDrawerHeaderContainer>
              <Box
                sx={(theme) => ({
                  minHeight: {
                    xs: 0,
                    sm: 600,
                    md: '68vh',
                  },
                  [theme.breakpoints.up(1568)]: {
                    minHeight: '71vh',
                  },
                })}
              >
                {drawerType !== 'edit' && isPermitted([PERMISSIONS.BOOK_FOR_OTHERS]) && (
                  <Box display={'flex'} mt={1}>
                    <Checkbox
                      checked={isBookForOthers}
                      color='primary'
                      onChange={handleBookForOthers}
                    />
                    <Typography variant='subtitle2' color='common.black' ml={0.1} mt={1}>
                      {translatedInput?.newBooking?.bookMultipleVisitorTitle}
                    </Typography>
                    <LightTooltip
                      title={translatedInput?.newBookingScreenText?.multipleVisitorInfo}
                      enterTouchDelay={0}
                    >
                      <IconButton aria-label='' sx={{ ml: '0 !important' }}>
                        <InfoOutlined color='primary' fontSize='small' />
                      </IconButton>
                    </LightTooltip>
                  </Box>
                )}

                <FormFields
                  setShowErrorData={setShowErrorData}
                  showErrorData={showErrorData}
                  form={formFields}
                  isSubmitted={isSubmitted}
                  onLocationChange={onLocationChange}
                  onFacilityChange={onFacilityChange}
                  isExpanded={isExpanded}
                  updatePhoneDetails={updatePhoneDetails}
                  setNumberUpdated={setNumberUpdated}
                  isBookForOthers={isBookForOthers}
                  phoneDetails={phoneDetails}
                  setPhoneDetails={setPhoneDetails}
                />

                <DateSelection
                  date={date}
                  setDate={setDate}
                  isSubmitted={isSubmitted}
                  onDateChange={onDateChange}
                />

                {slotId ? (
                  <SelectedSlot slotInfo={slotInfo} onSlotDeselect={onSlotDeselect} />
                ) : (
                  <Box>
                    {isLoadingSlots ? (
                      <GridSkeleton
                        width={isExpanded ? '50%' : '100%'}
                        height={'2.75rem'}
                        count={8}
                        xs={6}
                        sm={3}
                        md={3}
                        lg={3}
                        xl={3}
                      />
                    ) : (
                      <Box
                        className='SlotsBox'
                        m={0}
                        p={0}
                        display={needSeatSelection == 'Yes' ? 'none' : 'contents'}
                      >
                        {slotInformation && displaySlots && (
                          <Slots
                            onSlotSelection={onSlotSelection}
                            slotInfo={slotInfo}
                            setSlotInfo={setSlotInfo}
                            isDrawerExpanded={isExpanded}
                            setSlotAvailability={setSlotAvailability}
                          />
                        )}
                        {isSubmitted &&
                          date &&
                          !slotId &&
                          (slotInfoMorning?.length ||
                            slotInfoAfternoon?.length ||
                            slotInfoEvening?.length ||
                            slotInfoNight?.length) && (
                            <Typography variant='subtitle2' fontSize={14} ml={1.25} color='error'>
                              {translatedInput?.newBooking?.newBookingSlot}
                            </Typography>
                          )}

                        {slotAvailabilityError && date && (
                          <Typography variant='subtitle2' fontSize={14} ml={1.25} color='error'>
                            {slotInformationMessage}
                          </Typography>
                        )}
                      </Box>
                    )}
                  </Box>
                )}
                <Counters
                  seatCount={seatCount}
                  recurringCount={recurringCount}
                  setSeatCount={setSeatCount}
                  setRecurringCount={setRecurringCount}
                  onCountersChange={onCountersChange}
                  drawerType={drawerType}
                  setCounterError={setCounterError}
                  slotAvailability={slotAvailability}
                  isBookForOthers={isBookForOthers}
                />
                {facilityId && date && slotId && isBookForOthers && (
                  <BookForOthersFormFields
                    isExpanded={isExpanded}
                    userEmails={userEmails}
                    handleAddUserEmailButton={handleAddUserEmailButton}
                    handleEmailClearClick={handleEmailClearClick}
                  />
                )}

                {facilityId &&
                  date &&
                  slotId &&
                  isExactTiming == 0 &&
                  seatAvailable !== null &&
                  (!isBookForOthers || (isBookForOthers && userEmails?.length > 0)) && (
                    <>
                      {recurringCount > 1 || seatAvailable == 0 ? (
                        <Box display={'flex'} mt={4}>
                          <Box mt={0.1}>
                            <WarningIcon
                              fill={theme.palette.iconColors.contrastText}
                              width='20'
                              height='20'
                            />
                          </Box>

                          <Typography
                            variant='subtitle1'
                            fontWeight={'normal'}
                            color='floor.contrastText'
                            ml={1}
                          >
                            {seatAvailable == 0
                              ? seatBookingNotAvailableReason
                              : translatedInput?.newBookingScreenText?.seatSelectionNotAvailable}
                          </Typography>
                        </Box>
                      ) : (
                        <>
                          <Box
                            display={'flex'}
                            mt={2}
                            sx={{
                              [theme.breakpoints.down(430)]: {
                                display: 'block',
                              },
                            }}
                          >
                            <Typography variant='subtitle1' color='table.light' mt={1.3} mr={4}>
                              {translatedInput?.newBookingScreenText?.selectSeatText}
                            </Typography>
                            <FormControl>
                              <RadioGroup
                                row
                                aria-labelledby='demo-form-control-label-placement'
                                name='needSeatSelection'
                                value={needSeatSelection}
                                onChange={(event) => {
                                  // reset only selected seats and selected floor details
                                  unBlockedSeats()
                                  dispatch(resetFloorDetails())
                                  dispatch(updateSelectedSeats([]))
                                  setFloor(null)
                                  setNeedSeatSelection(event.target.value)
                                }}
                              >
                                <FormControlLabel
                                  value='Yes'
                                  control={<Radio />}
                                  label={translatedInput?.common?.yesBtn}
                                />
                                <FormControlLabel
                                  value='No'
                                  control={<Radio />}
                                  label={translatedInput?.common?.noButton}
                                />
                              </RadioGroup>
                            </FormControl>
                          </Box>
                          <Box ref={seatSelectionContainerRef}>
                            {needSeatSelection === 'Yes' && (
                              <SeatSelectionArea
                                svgSeatSelectionRef={svgSeatSelectionRef}
                                noOfSeat={seatCount}
                                handleFloorSelectionChange={handleFloorSelectionChange}
                                updateDrawerWidth={handleUpdateDrawerWidth}
                                selectedFloor={floor}
                                isBookForOthers={isBookForOthers}
                                userEmails={userEmails}
                              />
                            )}
                          </Box>
                        </>
                      )}
                    </>
                  )}
              </Box>
            </PageDrawerSectionContainer>
            <Divider />
            <PageDrawerFooterButtonsContainer>
              <Grid container columnSpacing={'1.25rem'} rowGap={2}>
                <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                  <Button
                    type='submit'
                    sx={{ width: '100%' }}
                    variant='contained'
                    size='large'
                    // onClick={openBookingSuccessModal}
                  >
                    {drawerType === 'edit'
                      ? translatedInput?.common?.updateBtn
                      : translatedInput?.common?.submitText}
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                  <Button
                    sx={{ width: '100%' }}
                    variant='outlined'
                    size='large'
                    onClick={toggleDrawer()}
                  >
                    {translatedInput?.common?.cancelBtn}
                  </Button>
                </Grid>
              </Grid>
            </PageDrawerFooterButtonsContainer>
          </PageDrawerContainer>
        </form>
        <ActionAlertModal
          showAlert={showDiscardAlert}
          title={translatedInput?.common?.discardChangesTitle}
          isDefaultButtonsVisible={true}
          cancelButtonText={translatedInput?.common?.noButton}
          confirmButtonText={translatedInput?.common?.yesBtn}
          onAlertClose={closeDiscardAlertModal}
          onAlertConfirm={alertConfirm}
          alertIcon={
            <WarningIcon
              fill={theme.palette.iconColors.contrastText}
              width='46'
              height='46'
              stoke={theme.palette.common.white}
            />
          }
        >
          <Box p={'0.625rem'} minWidth={{ md: '30rem', xs: 0 }}>
            <Typography variant='subtitle1' color='secondary.main' textAlign='center'>
              {translatedInput?.settings?.settingsDiscardChangesMsg}
            </Typography>

            <Typography variant='subtitle1' color='table.dark' mb={'1.375rem'} textAlign='center'>
              {''}
            </Typography>
          </Box>
        </ActionAlertModal>
      </CustomDrawer>
      <BookingSuccessModal showModal={bookingSuccessModalStatus} userType='admin' />
      {bookingConfirmationModalStatus && date && slotId && (
        <BookingConfirmationModal
          showModal={bookingConfirmationModalStatus}
          bookingInfo={{
            date: date,
            timeSlot: slotTime || '',
            noOfSeats: seatCount,
            noOfDays: recurringCount,
            floorName: floor ? floor.floor_name : '',
            selectedSeats: selectedSeats,
            facilityName: formFields?.getValues().facility?.name || '',
            selectedEmails: isBookForOthers
              ? userEmails
              : [formFields?.getValues().email as string],
          }}
          updateBookingConfirmationStatus={handleBookingConfirmationStatus}
        />
      )}
    </>
  )
}

export default NewBookingDrawer
