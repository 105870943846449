import { IIcon } from './iconConstants'
const WarningIcon = (props: IIcon) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox='0 0 47 47'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M23.6 46.6c-12.703 0-23-10.297-23-23s10.297-23 23-23 23 10.297 23 23-10.297 23-23 23zm0-4.6C33.762 42 42 33.762 42 23.6c0-10.162-8.238-18.4-18.4-18.4-10.162 0-18.4 8.238-18.4 18.4C5.2 33.762 13.438 42 23.6 42zm-2.3-11.5h4.6v4.6h-4.6v-4.6zm0-18.4h4.6v13.8h-4.6V12.1z'
        fill={props.fill}
        fillRule='nonzero'
        stroke={props.stoke}
        strokeWidth='.4'
      />
    </svg>
  )
}
export default WarningIcon
