export const handleMessages = (messageRecord: Record<string, never> | string) => {
  const messages = []
  if (typeof messageRecord !== 'string') {
    for (const i in messageRecord) {
      messages.push([...messageRecord[i]])
    }
    return messages.flat(Infinity)
  } else {
    messages.push(messageRecord)
    return messages
  }
}
