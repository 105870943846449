import { IIcon } from './iconConstants'
const MicIcon = (props: IIcon) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox='0 0 21 26'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.5 2.364c-1.944 0-3.521 1.587-3.521 3.545v4.727c0 1.958 1.577 3.546 3.522 3.546 1.945 0 3.521-1.588 3.521-3.546V5.91c0-1.958-1.576-3.545-3.521-3.545zM10.5 0a5.85 5.85 0 0 1 4.151 1.73 5.93 5.93 0 0 1 1.72 4.18v4.726c0 3.264-2.629 5.91-5.87 5.91-3.242 0-5.87-2.646-5.87-5.91V5.91C4.631 2.646 7.26 0 10.501 0zM0 11.818h2.365c.588 4.067 4.052 7.083 8.134 7.083 4.083 0 7.546-3.016 8.134-7.083H21c-.55 4.938-4.42 8.836-9.326 9.39V26H9.327v-4.792C4.42 20.654.55 16.756 0 11.818z'
        fill={props.fill}
        fillRule='nonzero'
      />
    </svg>
  )
}
export default MicIcon
