import { IIcon } from './iconConstants'
const FacebookIcon = ({ width, height, fill, backgroundColor }: IIcon) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 28 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g filter='url(#erigplqkja)'>
        <rect width={width} height={height} rx='6.222' fill={backgroundColor} />
      </g>
      <path
        d='m17.786 14.23.432-2.814h-2.703V9.589c0-.77.377-1.52 1.587-1.52h1.228V5.673c-.72-.116-1.448-.18-2.178-.19-2.225 0-3.678 1.348-3.678 3.788v2.145H10v2.814h2.473v6.805h3.043V14.23h2.269z'
        fill={fill}
      />
      <defs>
        <filter
          id='erigplqkja'
          x='-6.343'
          y='-6.343'
          width='40.685'
          height='40.685'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImageFix' stdDeviation='3.171' />
          <feComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_297_1163' />
          <feBlend in='SourceGraphic' in2='effect1_backgroundBlur_297_1163' result='shape' />
        </filter>
      </defs>
    </svg>
  )
}
export default FacebookIcon
