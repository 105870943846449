import { IIcon } from './iconConstants'
const ClockWithHourGlassIcon = ({ width, height, fill, stoke }: IIcon) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 17 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9 14a6 6 0 1 0-5.667-7.977'
        stroke={stoke}
        strokeWidth='1.2'
        strokeLinecap='round'
      />
      <path d='M9 4.5v4.167l1.5 1.833' stroke={stoke} strokeWidth='1.2' strokeLinecap='round' />
      <path d='M1.5 8H7M1.5 15.333H7' stroke={fill} strokeWidth='1.2' strokeLinecap='round' />
      <path
        d='M2.222 15.333s-.203-1.018.204-2.037c.407-1.018 1.833-1.63 1.833-1.63s1.426-.61 1.834-1.629C6.5 9.019 6.296 8 6.296 8'
        stroke={fill}
        strokeWidth='1.2'
      />
      <path
        d='M6.296 15.333s.203-1.018-.204-2.037c-.407-1.018-1.833-1.63-1.833-1.63s-1.426-.61-1.834-1.629C2.018 9.019 2.222 8 2.222 8'
        stroke={fill}
        strokeWidth='1.2'
      />
    </svg>
  )
}
export default ClockWithHourGlassIcon
