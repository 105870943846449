import { Box, Button, ClickAwayListener, Grid, Stack, Typography, useTheme } from '@mui/material'
import { ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../_helpers/hooks'
import CustomModal from '../@extended/modal'
import { WarningIcon } from '../icons'
interface IPricingAlertModal {
  showAlert: boolean
  content: string
  onAlertConfirm?: () => void
  onAlertClose?: () => void
  children?: ReactNode
  modalContainerMaxWidth?: string
}

const PlanLimitExceededAlert = (props: IPricingAlertModal) => {
  const { modalContainerMaxWidth, onAlertClose, showAlert, content } = props
  const theme = useTheme()
  const navigate = useNavigate()
  const translatedInput = useAppSelector((state) => state?.translation.translatedData)
  const handleContactUsClick = () => {
    navigate('/admin/contact-us')
    closeAlertModal()
  }
  const closeAlertModal = (): void => {
    onAlertClose?.()
  }

  return (
    <CustomModal isOpen={showAlert}>
      <ClickAwayListener onClickAway={closeAlertModal}>
        <Grid
          container
          sx={{
            maxWidth: {
              sm: modalContainerMaxWidth ? modalContainerMaxWidth : '25rem',
              md: modalContainerMaxWidth ? modalContainerMaxWidth : '25rem',
              lg: modalContainerMaxWidth ? modalContainerMaxWidth : '25rem',
            },
            minWidth: { sm: '25rem', md: '25rem', lg: '25rem' },
          }}
        >
          <Grid item xs={12}>
            <Stack justifyContent='center' alignItems='center'>
              <Box
                sx={{
                  height: '2.875rem',
                  width: '2.875rem',
                  mb: '1.5rem',
                }}
              >
                <WarningIcon
                  fill={theme.palette.iconColors.contrastText}
                  stoke={theme.palette.common.white}
                />
              </Box>
              <Stack direction={'row'} spacing={1} justifyContent='center' alignItems='center'>
                <Typography
                  sx={{
                    fontSize: '1.375rem',
                    lineHeight: { xs: '1.3', sm: '0.73' },
                  }}
                  variant='h4'
                  textAlign='center'
                  color='common.black'
                >
                  {translatedInput?.pricingPage?.limitReached}
                </Typography>
              </Stack>
              <Box py={3} maxWidth={'70%'}>
                <Typography variant='subtitle1' color='secondary.main' textAlign='center'>
                  {content}
                </Typography>
              </Box>

              <Box p={'0.625rem'} width={'100%'} mt={2}>
                <Grid container spacing={'1.25rem'}>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Button
                      sx={{ width: '100%' }}
                      variant='outlined'
                      size='large'
                      onClick={closeAlertModal}
                    >
                      {translatedInput?.common?.cancelBtn}
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Button
                      sx={{ width: '100%' }}
                      variant='contained'
                      size='large'
                      type='submit'
                      onClick={handleContactUsClick}
                    >
                      {translatedInput?.generalText?.contactUsTxt}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </ClickAwayListener>
    </CustomModal>
  )
}

export default PlanLimitExceededAlert
