import { IIcon } from './iconConstants'
const UserUploadIcon = (props: IIcon) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox='0 0 40 40'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Group-6'>
          <circle id='Oval' fill='#FFFFFF' cx='19.9999985' cy='19.9999985' r='19.9999985' />
          <g id='Group' transform='translate(10.000000, 10.000000)'>
            <polygon id='Path' points='0 0 20 0 20 20 0 20' />
            <path
              d='M11.6666667,11.8766667 L11.6666667,13.6183333 C10.1361646,13.0772191 8.43822297,13.3128074 7.11285881,14.2501712 C5.78749465,15.187535 4.99969,16.7099908 5,18.3333333 L3.33333312,18.3325 C3.33280918,16.2702846 4.28668322,14.3239126 5.9168472,13.0608616 C7.54701118,11.7978105 9.66993516,11.3602794 11.6666667,11.8758333 L11.6666667,11.8766667 Z M10,10.8333333 C7.2375,10.8333333 5,8.59583333 5,5.83333333 C5,3.07083333 7.2375,0.833333333 10,0.833333333 C12.7625,0.833333333 15,3.07083333 15,5.83333333 C15,8.59583333 12.7625,10.8333333 10,10.8333333 Z M10,9.16666667 C11.8416667,9.16666667 13.3333333,7.675 13.3333333,5.83333333 C13.3333333,3.99166667 11.8416667,2.5 10,2.5 C8.15833333,2.5 6.66666667,3.99166667 6.66666667,5.83333333 C6.66666667,7.675 8.15833333,9.16666667 10,9.16666667 Z M14.7558333,15.1008333 L13.2325,16.625 L12.0533333,15.4458333 L15.5891667,11.9108333 L19.125,15.4458333 L17.9458333,16.625 L16.4225,15.1008333 L16.4225,18.0891667 L14.7558333,18.0891667 L14.7558333,15.1008333 Z'
              id='Shape'
              fill={props.fill}
              fillRule='nonzero'
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
export default UserUploadIcon
