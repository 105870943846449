export default function ListItem(theme: any) {
  return {
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 'auto',
          marginRight: '.5rem',
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: theme.palette.label.main,
            '&:hover': {
              backgroundColor: theme.palette.label.main,
            },
          },
          '&:hover': {
            backgroundColor: theme.palette.label.main,
          },
        },
      },
    },
  }
}
