import { merge } from 'lodash'
import AppBar from './AppBar'
import Backdrop from './Backdrop'
import Button from './Button'
import InputLabel from './InputLabel'
import Link from './Link'
import ListItem from './ListItem'
import OutlinedInput from './OutlinedInput'
import Tabs from './Tabs'

import Autocomplete from './Autocomplete'
import DatePicker from './DatePicker'
import FormHelperText from './FormHelperText'
import MenuItem from './MenuItem'
import Pagination from './Pagination'
import Select from './Select'
import Stepper from './Stepper'
import Switch from './Switch'
import TextField from './TextField'
import Toast from './Toast'
import Typography from './Typography'
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function ComponentsOverrides(theme: any) {
  return merge(
    OutlinedInput(theme),
    InputLabel(theme),
    Button(theme),
    AppBar(theme),
    Link(theme),
    Backdrop(theme),
    ListItem(theme),
    Tabs(theme),

    Switch(theme),
    Pagination(theme),
    MenuItem(theme),
    Stepper(theme),
    Toast(theme),
    FormHelperText(theme),
    TextField(theme),
    Select(theme),
    Autocomplete(theme),
    DatePicker(theme),
    Typography(),
  )
}
