import { IIcon } from './iconConstants'
const DownloadIcon = (props: IIcon) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox='0 0 20 18'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11 7h5l-6 6-6-6h5V0h2v7zm-9 9h16V9h2v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V9h2v7z'
        fill={props.fill}
        fillRule='nonzero'
      />
    </svg>
  )
}
export default DownloadIcon
