import { Chip } from '@mui/material'
import { styled } from '@mui/material/styles'

export const StyledChip = styled(Chip)(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.common.black,
  borderRadius: '0.5rem',
  padding: '1.2rem 1rem 1.2rem 1rem',
  fontSize: '0.875rem',
  fontFamily: 'Lexend',

  '.MuiChip-deleteIcon	': {
    marginLeft: '.75rem',
  },
}))
