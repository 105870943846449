// ==============================|| AUTH ROUTING ||============================== //

import AuthLayout from '../layout/authLayout'
import LoginPage from '../pages/common/loginPage'
import SignUpPage from '../pages/common/signUpPage'
import RedirectIfLoggedIn from './utils/redirectIfLoggedIn'

// const LoginPage = LazyLoad(lazy(() => import('../pages/loginPage')))
// const SignUpPage = LazyLoad(lazy(() => import('../pages/signUpPage')))

// ==============================|| PUBLIC PAGE WITH CAROUSEL LAYOUT ||============================== //

const AuthRoutes = {
  path: '/',
  element: (
    <RedirectIfLoggedIn>
      <AuthLayout />
    </RedirectIfLoggedIn>
  ),
  children: [
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: 'sign-up',
      element: <SignUpPage />,
    },
  ],
}

export default AuthRoutes
