/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { handleMessages } from '../_helpers/utilities'

import { RootState } from '../_store/store'
import { startLoader, stopLoader } from './loaderSlice'
import { errorToast } from './toastSlice'
import { IBoardRegistration, IDigitalBoardState } from '../_models/digitalBoardInterface'
import digitalBoardService from '../_services/digitalBoardService'

const initialState: IDigitalBoardState = {
  boardDetails: {
    code: '',
    created_at: '',
    deleted_at: null,
    facility_list: '',
    id_hash: '',
    name: '',
    updated_at: '',
    url: '',
    voice: null,
    voice_pitch: null,
    voice_rate: null,
  },
  facilityList: [],
  token_repeat_count: 0,
  firebaseToken: '',
  isBoardInitiated: false,
}

export const getTokenBoard = createAsyncThunk(
  '/getTokenBoard',
  async (boardId: string, { dispatch, rejectWithValue }) => {
    dispatch(startLoader())
    try {
      const response = await digitalBoardService.getTokenBoard(boardId)
      dispatch(stopLoader())
      return response
    } catch (err: any) {
      dispatch(stopLoader())
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)
export const addFirebaseToken = createAsyncThunk(
  '/addFirebaseToken',
  async (boardDetails: IBoardRegistration, { dispatch, rejectWithValue }) => {
    dispatch(startLoader())
    try {
      const response = await digitalBoardService.addFirebaseToken(boardDetails)
      dispatch(stopLoader())
      return response
    } catch (err: any) {
      dispatch(stopLoader())
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)
export const digitalBoardSlice = createSlice({
  name: 'digitalBoard',
  initialState,
  reducers: {
    resetFacilityList: (state) => {
      state.facilityList = initialState.facilityList
    },
    updateFacilityList: (state, data) => {
      state.facilityList = data.payload
    },
    updateFirebaseToken: (state, data) => {
      state.firebaseToken = data.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTokenBoard.fulfilled, (state, action) => {
      state.boardDetails = action.payload.data.board
      const newFacilityList = action.payload.data.facilities
      if (JSON.stringify(newFacilityList) !== JSON.stringify(state.facilityList)) {
        state.facilityList = newFacilityList
      }
    })
    builder.addCase(addFirebaseToken.fulfilled, (state, action) => {
      if (action.payload.update_status === 1) {
        state.isBoardInitiated = true
      } else {
        state.isBoardInitiated = false
      }
    })
  },
})
export const { resetFacilityList, updateFirebaseToken, updateFacilityList } =
  digitalBoardSlice.actions
export const digitalBoardState = (state: RootState) => state.digitalBoard
const digitalBoardReducer = digitalBoardSlice.reducer
export default digitalBoardReducer
