import { Box, ToggleButton, Typography, useTheme } from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { formatMomentToDate, stampToDate } from '../../_helpers/dateTime'
import { useAppSelector } from '../../_helpers/hooks'
import { StyledToggleButtonGroup } from '../../components/@extended/customToggleGroup'
import { CalenderIcon } from '../../components/icons'

interface IDatesNewBooking {
  date: string
  setDate: React.Dispatch<React.SetStateAction<string>>
  isSubmitted: boolean
  onDateChange: () => void
}
const DateSelection = (props: IDatesNewBooking) => {
  const translatedInput = useAppSelector((state) => state?.translation.translatedData)
  const { setDate, date, isSubmitted, onDateChange } = props
  const [dateStatus, setDateStatus] = React.useState<string>('')
  const dayOne = moment()
  const dayTwo = moment(dayOne).add(1, 'days')
  const dayThree = moment(dayOne).add(2, 'days')
  const daysArray = [
    { status: '1', day: dayOne },
    { status: '2', day: dayTwo },
    { status: '3', day: dayThree },
  ]
  const [isCalendarOpen, setCalendarOpen] = useState(false)
  const theme = useTheme()
  useEffect(() => {
    switch (date) {
      case formatMomentToDate(dayOne):
        setDateStatus('1')
        break
      case formatMomentToDate(dayTwo):
        setDateStatus('2')
        break
      case formatMomentToDate(dayThree):
        setDateStatus('3')
        break
      default:
        setDateStatus('chooseDate')
        break
    }
    if (!date) setDateStatus('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date])

  const handleToggleCalendar = (isOpen: boolean) => {
    setCalendarOpen(isOpen)
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSelectDate = (date: any, dateStatus?: any) => {
    setDateStatus(dateStatus)
    setDate(formatMomentToDate(date))
    onDateChange()
  }
  const isChooseDatePlaceholder = () => {
    return !date || daysArray.some((day) => day.status === dateStatus)
  }
  return (
    <>
      <Box mt={4}>
        <Typography variant='subtitle1' color='table.light'>
          {translatedInput?.newBooking?.newBookingSelectDateLabel}
        </Typography>
        <Box sx={{ display: { md: 'flex' }, overflowX: 'auto' }}>
          <StyledToggleButtonGroup value={dateStatus} exclusive aria-label='status'>
            {daysArray?.map((days) => (
              <ToggleButton
                value={days.status}
                aria-label='Upcoming'
                key={days.status}
                sx={{
                  minWidth: '7.688rem',
                  padding: '0.313rem 1.25rem 0.563rem 0.625rem !important',
                }}
                onClick={() => {
                  handleSelectDate(days.day, days.status)
                }}
                selected={dateStatus === days.status}
              >
                <Box
                  component={'span'}
                  sx={{
                    fontSize: ' 1.125rem',
                    fontWeight: 'bold',
                  }}
                >
                  {days.day.format('DD')}
                </Box>{' '}
                {days.day.format('dddd')}
              </ToggleButton>
            ))}

            <ToggleButton
              sx={{ padding: '0.438rem 1.125rem !important' }}
              value='chooseDate'
              aria-label='chooseDate'
              disableFocusRipple
              onClick={() => {
                if (!isCalendarOpen) {
                  handleToggleCalendar(true)
                }
              }}
              selected={dateStatus === 'chooseDate'}
            >
              {' '}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={date}
                  open={isCalendarOpen}
                  onOpen={() => {
                    setCalendarOpen(true)
                  }}
                  onClose={() => {
                    setCalendarOpen(false)
                  }}
                  onChange={(newValue) => {
                    handleSelectDate(newValue, 'chooseDate')
                  }}
                  label={translatedInput?.newBooking?.chooseDate}
                  components={{
                    OpenPickerIcon: () =>
                      dateStatus === 'chooseDate' ? (
                        <CalenderIcon fill={theme.palette.common.white} width='16' height='16' />
                      ) : (
                        <CalenderIcon fill={theme.palette.iconColors.main} width='16' height='16' />
                      ),
                  }}
                  disablePast={true}
                  renderInput={({ inputRef, inputProps, InputProps }) => (
                    <Box sx={{ display: 'flex', alignItems: 'center', margin: '0' }}>
                      <input
                        ref={inputRef}
                        {...inputProps}
                        className='no-border'
                        readOnly={true}
                        value={isChooseDatePlaceholder() ? 'Choose Date' : stampToDate(date)}
                        placeholder={translatedInput?.newBooking?.chooseDate}
                        style={{ padding: 0, cursor: 'pointer' }}
                      />
                      {InputProps?.endAdornment}
                    </Box>
                  )}
                />
              </LocalizationProvider>
            </ToggleButton>
          </StyledToggleButtonGroup>
        </Box>
      </Box>
      <Box>
        {isSubmitted && !date && (
          <Typography variant='caption' ml={1.25} color='error'>
            {translatedInput?.newBooking?.newBookingDateRequired}
          </Typography>
        )}
      </Box>
    </>
  )
}

export default DateSelection
