import { IIcon } from './iconConstants'

const BookingManagementIcon = (props: IIcon) => {
  const { stoke, width, height } = props
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 30 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10 2.5v3.75M20 2.5v3.75M4.375 11.363h21.25M20.55 23.75l1.238 1.238 2.662-2.463'
        stroke={stoke}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M26.25 10.625v9.825a4.98 4.98 0 0 0-3.75-1.7c-2.762 0-5 2.238-5 5 0 .938.262 1.825.725 2.575.262.45.6.85.987 1.175H10c-4.375 0-6.25-2.5-6.25-6.25V10.625c0-3.75 1.875-6.25 6.25-6.25h10c4.375 0 6.25 2.5 6.25 6.25z'
        stroke={stoke}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M27.5 23.75c0 .938-.262 1.825-.725 2.575A4.962 4.962 0 0 1 22.5 28.75a4.881 4.881 0 0 1-3.288-1.25 4.492 4.492 0 0 1-.987-1.175 4.901 4.901 0 0 1-.725-2.575c0-2.762 2.238-5 5-5 1.5 0 2.837.663 3.75 1.7a5 5 0 0 1 1.25 3.3z'
        stroke={stoke}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.994 17.125h.012M10.368 17.125h.011M10.368 20.875h.011'
        stroke={stoke}
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
export default BookingManagementIcon
