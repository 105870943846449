import { Box, Typography } from '@mui/material'
import Stack from '@mui/material/Stack'
import { FC, ReactNode } from 'react'

interface INoData {
  bgcolor?: string
  children?: ReactNode
  sx?: Record<string, unknown>
  bordercolor?: string
  text?: string
  iconComponent?: React.ReactNode
  theme?: string
  minHeight?: string | number
  color?: string
  fontSize?: string | number
}

const PlaceholderImageContainer: FC<INoData> = (props: INoData) => {
  const { children, text, iconComponent, color, bordercolor } = props
  return (
    <>
      <Box
        sx={{
          width: '100%',
          height: '100%',

          minHeight: 'inherit',

          borderRadius: '0.625rem',
          border: 'solid 0.063rem',
          borderColor: bordercolor ? bordercolor : 'info.contrastText',
        }}
        justifyContent='center'
        display='flex'
        alignItems='center'
      >
        <Stack direction='column' alignItems='center' spacing={1} justifyContent='center'>
          <Box>{iconComponent}</Box>
          <Typography
            fontSize='1.188rem'
            color={color ? color : 'toggleButtonGroup.light'}
            fontWeight='500'
            textAlign={'center'}
          >
            {text}
          </Typography>
          {children}
        </Stack>
      </Box>
    </>
  )
}

export default PlaceholderImageContainer
