import { IIcon } from './iconConstants'
const CalenderWithTickIcon = (props: IIcon) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox='0 0 50 51'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g fillRule='nonzero' fill='none'>
        <path
          d='M10.176 20.13h-2.73a.594.594 0 0 0-.59.589v2.516c0 .321.268.59.59.59h2.73a.594.594 0 0 0 .589-.59V20.72a.594.594 0 0 0-.59-.589zM16.226 20.13h-2.73a.594.594 0 0 0-.59.589v2.516c0 .321.268.59.59.59h2.73a.594.594 0 0 0 .589-.59V20.72a.594.594 0 0 0-.59-.589zM22.275 20.13h-2.73a.594.594 0 0 0-.589.589v2.516c0 .321.268.59.589.59h2.73a.594.594 0 0 0 .59-.59V20.72a.594.594 0 0 0-.59-.589zM28.325 20.13h-2.73a.594.594 0 0 0-.59.589v2.516c0 .321.269.59.59.59h2.73a.594.594 0 0 0 .589-.59V20.72a.594.594 0 0 0-.589-.589zM31.644 23.878h2.73a.594.594 0 0 0 .59-.59v-2.516a.594.594 0 0 0-.59-.588h-2.73a.594.594 0 0 0-.589.588v2.517c0 .32.268.589.59.589zM10.176 26.233h-2.73a.594.594 0 0 0-.59.59v2.515c0 .322.268.59.59.59h2.73a.594.594 0 0 0 .589-.59v-2.516a.594.594 0 0 0-.59-.589zM16.226 26.233h-2.73a.594.594 0 0 0-.59.59v2.515c0 .322.268.59.59.59h2.73a.594.594 0 0 0 .589-.59v-2.516a.594.594 0 0 0-.59-.589zM22.275 26.233h-2.73a.594.594 0 0 0-.589.59v2.515c0 .322.268.59.589.59h2.73a.594.594 0 0 0 .59-.59v-2.516a.594.594 0 0 0-.59-.589zM28.325 26.233h-2.73a.594.594 0 0 0-.59.59v2.515c0 .322.269.59.59.59h2.73a.594.594 0 0 0 .589-.59v-2.516a.594.594 0 0 0-.589-.589zM31.644 29.927h2.73a.594.594 0 0 0 .59-.589v-2.516a.594.594 0 0 0-.59-.589h-2.73a.594.594 0 0 0-.589.59v2.515c0 .322.268.59.59.59zM10.176 32.337h-2.73a.594.594 0 0 0-.59.588v2.517c0 .32.268.589.59.589h2.73a.594.594 0 0 0 .589-.59v-2.516a.594.594 0 0 0-.59-.588zM16.226 32.337h-2.73a.594.594 0 0 0-.59.588v2.517c0 .32.268.589.59.589h2.73a.594.594 0 0 0 .589-.59v-2.516a.594.594 0 0 0-.59-.588zM22.275 32.337h-2.73a.594.594 0 0 0-.589.588v2.517c0 .32.268.589.589.589h2.73a.594.594 0 0 0 .59-.59v-2.516a.594.594 0 0 0-.59-.588zM28.325 32.337h-2.73a.594.594 0 0 0-.59.588v2.517c0 .32.269.589.59.589h2.73a.594.594 0 0 0 .589-.59v-2.516a.594.594 0 0 0-.589-.588z'
          fill={props.backgroundColor}
        />
        <path
          d='M41.87 6.37a2.147 2.147 0 0 0-2.141-2.14h-5.354v-.536c0-2.034-1.66-3.64-3.64-3.64h-2.73a3.644 3.644 0 0 0-3.641 3.64v.536H17.51v-.536c0-2.035-1.66-3.64-3.64-3.64h-2.73a3.644 3.644 0 0 0-3.64 3.64v.535H2.145A2.147 2.147 0 0 0 .004 6.371v34.37c0 1.178.964 2.142 2.142 2.142h27.84c.963 4.55 5.032 7.977 9.85 7.977 5.568 0 10.118-4.55 10.118-10.119 0-4.871-3.48-8.94-8.084-9.904V6.371zM27.468 3.64a.48.48 0 0 1 .482-.481h2.73a.48.48 0 0 1 .482.482v3.854a.48.48 0 0 1-.481.482h-2.73a.48.48 0 0 1-.483-.482V3.641zm-16.81 0a.48.48 0 0 1 .481-.481h2.73a.48.48 0 0 1 .483.482v3.854a.48.48 0 0 1-.482.482h-2.73a.48.48 0 0 1-.482-.482V3.641zM7.499 7.014v.536c0 2.035 1.66 3.64 3.64 3.64h2.73c2.035 0 3.641-1.66 3.641-3.64v-.536h6.746v.536c0 2.035 1.66 3.64 3.64 3.64h2.73c2.035 0 3.64-1.66 3.64-3.64v-.536h4.712v6.372H2.734V7.013H7.5zM2.734 16.17h36.299V30.73c-4.98.32-8.941 4.336-9.263 9.369H2.734V16.169z'
          fill={props.backgroundColor}
        />
        <path
          d='M29.836 40.742c0-5.474 4.452-10 10-10 5.547 0 10 4.453 10 10 0 5.548-4.526 10-10 10-5.475 0-10-4.452-10-10z'
          fill={props.fill}
        />
        <path
          d='m42.281 37.453-3.312 3.419-1.188-1.226a1.623 1.623 0 0 0-2.312 0c-.625.645-.625 1.742 0 2.387l2.375 2.45c.313.324.75.517 1.125.517.437 0 .812-.193 1.124-.516l4.438-4.58c.625-.645.625-1.742 0-2.387s-1.625-.71-2.25-.064z'
          fill={props.stoke}
        />
      </g>
    </svg>
  )
}
export default CalenderWithTickIcon
