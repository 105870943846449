import { IIcon } from './iconConstants'
const SunsetIcon = (props: IIcon) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox='0 0 24 17'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g
        stroke={props.stoke}
        strokeWidth='2'
        fill='none'
        fillRule='evenodd'
        strokeLinecap='round'
        strokeLinejoin='round'
      >
        <path d='M17 12a5 5 0 0 0-10 0M12 3V1M4.22 4.22l1.42 1.42M1 12h2M21 12h2M18.36 5.64l1.42-1.42M23 16H1' />
      </g>
    </svg>
  )
}
export default SunsetIcon
