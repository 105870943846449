import { IIcon } from './iconConstants'
const CloseIcon = (props: IIcon) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox='0 0 14 14'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='m7.913 7 4.87-4.891a.63.63 0 0 0 0-.913.63.63 0 0 0-.914 0L7 6.087 2.11 1.196a.63.63 0 0 0-.913 0 .63.63 0 0 0 0 .913L6.066 7l-4.87 4.891a.63.63 0 0 0 0 .913c.13.13.304.196.456.196a.663.663 0 0 0 .457-.196L7 7.913l4.891 4.891c.13.13.305.196.457.196a.663.663 0 0 0 .456-.196.63.63 0 0 0 0-.913L7.913 7z'
        fill={props.fill}
        fillRule='nonzero'
        stroke={props.fill}
        strokeWidth='1.2'
      />
    </svg>
  )
}
export default CloseIcon
