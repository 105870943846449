import { Box, Container, Tab, Tabs, Typography, styled } from '@mui/material'
import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../../_helpers/hooks'
import { a11yProps } from '../../../_helpers/tabs'
import { TabPanelProps } from '../../../components/common/tabPanel'
import PrivacyPolicy from './privacyPolicy'
import TermsOfService from './termsOfService'

const PrivacyPolicyAndTermsOfService = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [TabValue, setTabValue] = React.useState(0)
  const translatedInput = useAppSelector((state) => state?.translation.translatedData)
  const handleTabSwitch = (event: React.SyntheticEvent, value: number) => {
    setTabValue(value)
    if (value === 0) {
      navigate('/privacy-policy')
    } else if (value === 1) {
      navigate('/termsofservice')
    }
  }

  const StyledTab = styled(Tabs)(({ theme }) => ({
    '& .MuiTab-root': {
      color: theme.palette.common.black,
      fontWeight: 600,
      fontSize: '0.938rem',
      backgroundColor: 'transparent',
    },
    '& .MuiTab-root.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      fontWeight: 500,
      fontSize: '0.938rem',
      borderRadius: '0.5rem ',
    },
  }))

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props

    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            <Typography component='div'>{children}</Typography>
          </Box>
        )}
      </div>
    )
  }

  useEffect(() => {
    if (location.pathname === '/privacy-policy') {
      setTabValue(0)
    } else if (location.pathname === '/termsofservice') {
      setTabValue(1)
    }
  }, [location.pathname])
  return (
    <Container
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        padding: { sm: '3.188rem 1rem 5.563rem', md: '0 1rem 5.563rem', lg: '0 0 5.563rem' },
        maxWidth: '100rem',
        margin: '0 auto',
        fontFamily: 'Lexend',
      }}
    >
      <Box sx={{ margin: '2.188rem 0 0.938rem' }}>
        <StyledTab
          value={TabValue}
          onChange={handleTabSwitch}
          aria-label='full width tabs example'
          variant='scrollable'
          sx={{
            borderRadius: '0.75rem',
            minWidth: '6.938rem',
            ml: { xs: '0rem', sm: '0.25rem', lg: '0' },
            mt: { sm: '0', lg: '1.844rem' },
            padding: {
              xs: '0 1rem',
              sm: '0 1rem',
              lg: '0.313rem 0',
            },
            fontSize: { xs: '0.7rem', sm: '0.7rem', lg: '0.938rem' },
            lineHeight: 1,
          }}
        >
          <Tab label={translatedInput?.privacyPolicyPage?.privacyPolicyTitle} {...a11yProps(0)} />

          <Tab label={translatedInput?.landingScreen?.termsOfServiceText} {...a11yProps(1)} />
        </StyledTab>
        <TabPanel value={TabValue} index={0}>
          <PrivacyPolicy />
        </TabPanel>
        <TabPanel value={TabValue} index={1}>
          <TermsOfService />
        </TabPanel>
      </Box>
    </Container>
  )
}

export default PrivacyPolicyAndTermsOfService
