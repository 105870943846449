import { PaletteMode } from '@mui/material'
import { createTheme } from '@mui/material/styles'

export const palette = (mode: PaletteMode) =>
  createTheme({
    palette: {
      mode,
      primary: {
        main: '#ff5958',
        light: '#ffe4e4',
      },
      secondary: {
        main: '#787878',
        light: '#aeaeae',
        dark: '#818181',
        contrastText: '#bababa',
      },
      error: {
        main: '#ff5d42',
        dark: '#c0392b',
        light: '#bbb',
        contrastText: '#535353',
      },
      success: {
        main: '#34c270',
        light: '#13974c',
      },
      info: {
        main: '#fff',
        light: '#ffc7c7',
        dark: '#f1f1f1',
        contrastText: '#e2e2e2',
      },
      common: {
        black: '#0c0c0c',
        white: '#fff',
      },
      label: {
        main: '#f4f4f4',
        light: '#606060',
        dark: '#858585',
        contrastText: '#626262',
      },
      description: {
        main: '#414141',
        light: '#4e4e4e',
        dark: '#202020',
        contrastText: '#e5e5e5',
      },
      tab: {
        main: '#201c1c',
        light: '#6f6f6f',
        dark: '#696969',
        contrastText: '#e4e4e4',
      },
      backdrop: {
        main: 'rgba(255,255,255, 0.7)',
        light: '#ffa4a4',
        dark: '#7b7b7b',
        contrastText: '#e9e9e9',
      },
      border: {
        main: '#c5c5c5',
        light: '#d4d4d4',
        dark: '#e3e3e3',
        contrastText: '#d6d6d6',
      },
      textGrey: {
        main: '#4b4b4b',
        light: '#515151',
        dark: '#fff1f1',
        contrastText: '#5a5a5a',
      },
      alertBackdrop: {
        main: '#f7f7f7',
        light: '#e4aa00',
        dark: '#f1f8ff',
        contrastText: '#1b7aea',
      },
      shadow: {
        main: '#e9cece',
        light: 'rgb(0 35 11 / 20%)',
        dark: 'rgba(255,255,255,.35)',
        contrastText: 'rgba(0,0,0,.25)',
      },
      toggleButtonGroup: {
        main: '#4f4f4f',
        light: '#b4b4b4',
        dark: '#f0eafd',
        contrastText: '#7f7f7f',
      },
      pagination: {
        main: '#707070',
        light: '#fdf0ef',
        dark: '#595959',
        contrastText: '#ffefef',
      },
      card: {
        main: '#e9fafc',
        light: '#cef3c5',
        dark: '#bee7ff',
        contrastText: '#838383',
      },
      status: {
        main: '#e6e6e6',
        light: '#ffe4b0',
        dark: '#bac4ff',
        contrastText: '#ffbaba',
      },
      table: {
        main: '#f6f6f6',
        light: '#313131',
        dark: '#3c3c3c',
        contrastText: '#282828',
      },
      chart: {
        main: '#ff9a48',
        light: '#f0e2e1',
        dark: '#FF0000',
        contrastText: '#408aff',
      },
      toast: {
        main: '#caffe1',
        light: '#fffbf7',
        dark: '#ff1914',
        contrastText: '#282828',
      },
      scroll: {
        main: '#c1c1c1',
        light: '#888',
        dark: '#454ade',
        contrastText: '#ececec',
      },
      rgba: {
        main: 'rgba(0, 0, 0, 0.12)',
        light: 'rgba(190, 100, 100, 0.16)',
        dark: 'rgba(151, 151, 151, 0.59)',
        contrastText: '#9E9E9E',
      },
      iconColors: {
        main: '#000',
        light: '#8C8C8C',
        dark: '#3CA4FF',
        contrastText: '#ECB728',
      },
      icons: {
        main: '#A0A2AB',
        light: '#FF4D4D',
        contrastText: '#ffd254',
        dark: '#C4C4C4',
      },
      selection: {
        main: '#989898',
        light: '#f9f9f9',
        contrastText: '#289149',
        dark: '#8F8F8F',
      },
      floor: {
        main: '#545454',
        light: '#1bb562',
        dark: '#d8d8d8',
        contrastText: '#6b6b6b',
      },
      csv: {
        main: '#646464',
        light: '#0000000a',
        dark: '#5e5e5e',
        contrastText: '#8a8a8a',
      },
      seat: {
        main: '#9f9f9f',
        light: '#4a4a4a',
        dark: '#ff6d6d',
        contrastText: '#ff6259',
      },
      profileIcon: {
        main: '#ffeeed',
        light: '#ffedca',
        dark: '#ffa800',
        contrastText: '#def6d8',
        contrastTextHover: '#5ad93a',
        colorOne: '#455ad7',
        colorOneHover: '#0a97e9',
        colorTwo: '#eb5959',
        colorThree: '#B0B0B0',
        colorFour: '#D0D0D0',
        colorFive: '#4a1d1c',
        colorSix: '#290000',
        colorDivider: '#bebebe',
        colorSeven: '#6A6A6A',
      },
      facilityList: {
        main: '#1eb61b',
        light: '#ddd',
        dark: '#fb2f2e',
        contrastText: '#a1a1a1',
        colorOne: '#f0f0f0',
        colorTwo: '#f3f3f3',
        colorThree: '#ecebeb',
        colorFour: '#FF5958',
        colorFive: '#00AC4F',
        colorSix: '#FF6F6F',
        colorDivider: '#2E1D1D',
        colorOneHover: '#808080',
      },
      publicPages: {
        main: '#666',
        light: '#060606',
        dark: '#0000001f',
        contrastText: '#405EDC',
        colorDivider: '#141414',
        colorOne: '#222222',
        colorTwo: '#555555',
        colorThree: '#645F5D',
        colorFour: '#FF6259',
        colorFive: '#dadada',

        colorSix: '#ffb682',
        colorOneHover: '#FF5859',
        contrastTextHover: '#99090933',
      },
      pricingAlert: {
        main: '#FF5E57',
        colorOne: '#fbfbfb',
        colorTwo: '#fbeceb',
        colorThree: '#b8f8ff',
        colorFour: '#2285BF',
        colorFive: '#7F61BF',
      },
    },
  })
