/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../_store/store'
import { startLoader, stopLoader } from './loaderSlice'
import { errorToast, successToast } from './toastSlice'
import { handleMessages } from '../_helpers/utilities'
import publicPagesService from '../_services/publicPagesService'
import { IContactUs } from '../_models/commonInterface'
const initialState: any = {
  isContactFormSubmitted: false,
  pricingData: {
    monthly_plans: [],
    yearly_plans: [],
  },
}
export const contactUs = createAsyncThunk(
  '/contactUs',
  async (data: IContactUs, { dispatch, rejectWithValue }) => {
    dispatch(startLoader())
    try {
      const response = await publicPagesService.contactUs(data)
      dispatch(successToast(handleMessages(response.message)))
      dispatch(stopLoader())
      return response
    } catch (err: any) {
      dispatch(stopLoader())
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)
export const getPricingPlans = createAsyncThunk(
  '/getPricingPlans',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(startLoader())
    try {
      const response = await publicPagesService.getPricingPlans()
      dispatch(stopLoader())
      return response
    } catch (err: any) {
      dispatch(stopLoader())
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)
export const publicPagesSlice = createSlice({
  name: 'publicPages',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(contactUs.pending, (state) => {
      state.isContactFormSubmitted = false
    })
    builder.addCase(contactUs.fulfilled, (state) => {
      state.isContactFormSubmitted = true
    })
    builder.addCase(getPricingPlans.fulfilled, (state, action) => {
      state.pricingData = action.payload.data
    })
  },
})
export const publicPagesState = (state: RootState) => state.publicPages
const publicPagesReducer = publicPagesSlice.reducer
export default publicPagesReducer
