import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../_store/store'
const initialState = {
  showAlert: false,
  alertType: '',
  alertMessage: [],
}

export const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    successAlert: (state, action) => {
      ;(state.showAlert = true),
        (state.alertMessage = action.payload),
        (state.alertType = 'success')
    },
    errorAlert: (state, action) => {
      ;(state.showAlert = true), (state.alertMessage = action.payload), (state.alertType = 'error')
    },
    warningAlert: (state, action) => {
      ;(state.showAlert = true),
        (state.alertMessage = action.payload),
        (state.alertType = 'warning')
    },
    hideAlert: (state) => {
      state.showAlert = false
    },
  },
})
export const { successAlert, errorAlert, warningAlert, hideAlert } = alertSlice.actions
export const alertData = (state: RootState) => state.alert

export default alertSlice.reducer
