import { combineReducers } from '@reduxjs/toolkit'
import alertReducer from '../slices/alertSlice'
import authenticationReducer from '../slices/authenticationSlice'
import bookingManagementReducer from '../slices/bookingManagementSlice'
import criteriaReducer from '../slices/businessCriteriaSlice'
import businessReducer from '../slices/businessSlice'
import dashboardReducer from '../slices/dashboardSlice'
import digitalBoardReducer from '../slices/digitalBoardSlice'
import facilityReducer from '../slices/facilitySlice'
import floorReducer from '../slices/floorSlice'
import inviteUserReducer from '../slices/inviteUserSlice'
import loaderReducer from '../slices/loaderSlice'
import longPressReducer from '../slices/longPressSlice'
import newBookingReducer from '../slices/newBookingSlice'
import pricingReducer from '../slices/pricingSlice'
import publicPagesReducer from '../slices/publicPagesSlice'
import rolesReducer from '../slices/roleSlice'
import scannerReducer from '../slices/scannerSlice'
import toastReducer from '../slices/toastSlice'
import tokenBoardReducer from '../slices/tokenBoardSlice'
import translationReducer from '../slices/translationSlice'
import userReducer from '../slices/userSlice'
import voyagerReducer from '../slices/voyagerSlice'
import { RESET_STATE } from './actions'
const appReducer = combineReducers({
  authentication: authenticationReducer,
  loader: loaderReducer,
  alert: alertReducer,
  toast: toastReducer,
  pricing: pricingReducer,
  user: userReducer,
  criteria: criteriaReducer,
  business: businessReducer,
  facility: facilityReducer,
  dashboard: dashboardReducer,
  newBooking: newBookingReducer,
  translation: translationReducer,
  scanner: scannerReducer,
  tokenBoard: tokenBoardReducer,
  floor: floorReducer,
  voyager: voyagerReducer,
  bookingManagement: bookingManagementReducer,
  longPress: longPressReducer,
  digitalBoard: digitalBoardReducer,
  roles: rolesReducer,
  inviteUser: inviteUserReducer,
  publicPages: publicPagesReducer,
})

const rootReducer = (
  state: ReturnType<typeof appReducer> | undefined,
  action: { type: string },
) => {
  if (action.type === RESET_STATE) {
    const { toast } = state || { toast: undefined }
    state = appReducer(undefined, action)
    state = { ...state, toast: toast ?? toastReducer(undefined, { type: undefined }) }
  }
  return appReducer(state, action)
}

export default rootReducer
