import { IRestrictionData } from './../_models/criteriaInterface'

import { get, post } from './apiServices'

const getCriteriaDetails = async () => {
  const response = await get('business/criteria')
  return response
}

const updateCriteria = async (newRestrictionData: IRestrictionData) => {
  const response = await post('business/update-criteria', newRestrictionData)
  return response
}
const criteriaService = {
  getCriteriaDetails,
  updateCriteria,
}

export default criteriaService
