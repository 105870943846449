import { Typography } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'
import Grid from '@mui/material/Grid'
import { useEffect } from 'react'
import Carousel from 'react-material-ui-carousel'
import { Outlet } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../_helpers/hooks'
import orangeBg from '../../images/orange_bg.png'
import { translation, translationData } from '../../slices/translationSlice'
import { items } from './items'
interface ICarousalItem {
  key: number
  item: {
    image: string
    alt: string
    content: string
    maxWidth: string
  }
}

function CarouselItem({ item }: ICarousalItem) {
  return (
    <Grid
      container
      component='main'
      sx={{
        height: '100%',
        px: 4,
        py: 8,
      }}
      alignItems='center'
      justifyContent='center'
    >
      <Grid
        item
        component={'img'}
        alt={item.alt}
        src={item.image}
        sx={{ textAlign: 'center', maxWidth: '100%', height: '100%' }}
        justifyContent='center'
        alignItems='stretch'
      />
      <Grid item mt={-8} sx={{ color: 'common.white', textAlign: 'center' }}>
        <Typography variant='h2' maxWidth={item.maxWidth}>
          {item.content}
        </Typography>
      </Grid>
    </Grid>
  )
}

const AuthLayout = () => {
  const translationState = useAppSelector(translationData)
  const { selectedLanguage } = translationState
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(translation(selectedLanguage))
  }, [dispatch, selectedLanguage])
  return (
    <Grid container component='main' sx={{ minHeight: '100vh' }} alignItems='stretch'>
      <CssBaseline />
      <Outlet />
      <Grid
        item
        xs={false}
        sm={6}
        md={7}
        display={{ xs: 'none', sm: 'block' }}
        sx={{
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundImage: `url(${orangeBg})`,
        }}
      >
        <Grid
          container
          direction='column'
          justifyContent='center'
          alignItems='stretch'
          height='100%'
        >
          <Grid item height='100%'>
            <Carousel
              navButtonsAlwaysInvisible={true}
              height={'calc(100% - 45px)'}
              sx={{
                height: 'calc(100% - 45px)',
              }}
              indicatorIconButtonProps={{
                style: {
                  padding: '2px', // 1
                  color: 'white', // 3
                  opacity: 0.5,
                },
              }}
              activeIndicatorIconButtonProps={{
                style: {
                  color: 'white',
                  opacity: 1, // 2
                },
              }}
            >
              {items.map((item, i) => (
                <CarouselItem key={i} item={item} />
              ))}
            </Carousel>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default AuthLayout
