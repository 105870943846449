import { Box, Button, Typography, useTheme } from '@mui/material'
import { useAppSelector } from '../../../_helpers/hooks'
import MobileImage from '../../../images/MockUp.png'
import AppleIcon from '../../../images/app-store-download.png'
import BgImage from '../../../images/background-shape.png'
import TabletImage from '../../../images/ipad-pro.png'
import AndroidIcon from '../../../images/play-store-download.png'

const GoMobileSection = () => {
  const theme = useTheme()
  const translatedInput = useAppSelector((state) => state?.translation.translatedData)
  const handleNavigation = (url: string) => {
    window.open(url, '_blank')
  }
  return (
    <Box
      position='relative'
      textAlign='left'
      mb={{ xs: 5, md: 10 }}
      mt={{ xs: 4, md: 5 }}
      sx={{
        minHeight: { sm: '35rem' },
        [theme.breakpoints.up(2000)]: {
          minHeight: '43.75rem',
        },
        [theme.breakpoints.up(2500)]: {
          minHeight: '48rem',
        },
        [theme.breakpoints.up(5000)]: {
          maxWidth: '62.5rem',
          display: 'inline-flex',
        },
      }}
    >
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: { xs: 'auto', md: '32.5rem' },
          width: { xs: '100%', md: '100%' },
        }}
      >
        <Box
          sx={{
            backgroundImage: `url(${BgImage})`,
            backgroundRepeat: 'no-repeat',
            borderRadius: 2,
            p: { xs: 3, md: 8 },
            color: 'white',
            height: { xs: 'auto', md: '32.5rem' },
            width: { xs: '90vw', lg: '70vw' },
            textAlign: 'left',
            zIndex: 1,
            mt: { md: 25 },
            [theme.breakpoints.between(1266, 1524)]: {
              width: '90vw',
            },
            [theme.breakpoints.up(2200)]: {
              mt: 45,
              width: '50vw',
            },

            [theme.breakpoints.up(2800)]: {
              width: '20vw',
            },
          }}
        >
          <Typography
            variant='h4'
            fontWeight={700}
            fontSize={{ xs: '1.5rem', md: '2.5rem' }}
            sx={{
              [theme.breakpoints.down(1100)]: {
                fontSize: '1.5rem',
              },
            }}
          >
            {`${translatedInput?.landingScreen?.goMobileSectionText1}${', '}${
              translatedInput?.landingScreen?.goMobileSectionText2
            }`}
          </Typography>
          <Typography
            variant='h5'
            fontWeight={300}
            maxWidth={{ xs: '100%', md: '25rem', lg: '29rem', xl: '31.25rem' }}
            sx={{
              wordBreak: 'break-word',
              [theme.breakpoints.between(1266, 1370)]: {
                width: '26rem',
              },
            }}
          >
            {translatedInput?.landingScreen?.goMobileSectionText3}
          </Typography>
          <Typography variant='h5' fontWeight={300} component='ul' sx={{ pl: 2, mt: 3 }}>
            <li style={{ marginTop: 2 }}>{translatedInput?.landingScreen?.goMobileSectionText4}</li>
            <li style={{ marginTop: 16 }}>
              {translatedInput?.landingScreen?.goMobileSectionText5}
            </li>
            <li style={{ marginTop: 16 }}>
              {translatedInput?.landingScreen?.goMobileSectionText6}
            </li>
          </Typography>
          <Box mt={{ xs: 5, lg: 10 }}>
            <Typography variant='body2' fontSize={'0.938rem'}>
              {translatedInput?.landingScreen?.downloadNowButton}
            </Typography>
            <Box display={{ sm: 'flex' }} gap={2} mt={{ xs: 5, sm: 2 }}>
              <Button
                variant='text'
                sx={{
                  p: 0,
                  mr: { xs: 4, sm: 0 },
                }}
                onClick={() =>
                  handleNavigation('https://apps.apple.com/in/app/slashq/id1519794406')
                }
              >
                <Box component='img' src={AppleIcon} />
              </Button>
              <Button
                variant='text'
                sx={{
                  p: 0,

                  [theme.breakpoints.down(477)]: {
                    mt: 2,
                  },
                }}
                onClick={() =>
                  handleNavigation(
                    ' https://play.google.com/store/apps/details?id=com.qburst.slashq',
                  )
                }
              >
                <Box component='img' src={AndroidIcon} />
              </Button>
            </Box>
          </Box>
        </Box>
        <Box
          display={{ xs: 'none', md: 'block' }}
          sx={{
            [theme.breakpoints.up(2800)]: {
              display: 'none',
            },
          }}
        >
          <Box
            component='img'
            src={TabletImage}
            alt='Tablet App'
            sx={{
              zIndex: 1,
              width: { md: '50vw', lg: '60vw', xl: '49vw' },
              position: 'absolute',
              right: 0,
              bottom: 0,
              top: 0,
              [theme.breakpoints.between(1524, 1660)]: {
                width: '55vw',
              },
              [theme.breakpoints.between(1266, 1524)]: {
                width: '65vw',
              },
              [theme.breakpoints.down(1266)]: {
                top: '21%',
                right: '5%',
              },
            }}
          />
          <Box
            component='img'
            src={MobileImage}
            alt='Mobile App'
            sx={{
              position: 'absolute',
              top: '13%',
              left: { md: '55%', lg: '48%', xl: '52%' },
              zIndex: 2,
              width: { md: '23vw', lg: '29vw', xl: '23.5vw' },
              [theme.breakpoints.between(1524, 1660)]: {
                width: '26.5vw',
                left: '45%',
              },
              [theme.breakpoints.between(1266, 1524)]: {
                width: '31vw',
                left: '35%',
              },
              [theme.breakpoints.down(1266)]: {
                top: '33%',
                left: '48%',
              },
              [theme.breakpoints.up(2500)]: {
                top: '19%',
                left: '52%',
              },
            }}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default GoMobileSection
