import { IIcon } from './iconConstants'
const FileOutlineIcon = (props: IIcon) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox='0 0 17 19'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g fill='none' fillRule='evenodd'>
        <path d='M-3-2h22v22H-3z' />
        <path
          d='M16.5 5.5v11.91a.917.917 0 0 1-.91.923H.91a.91.91 0 0 1-.91-.909V.909A.92.92 0 0 1 .918 0h10.08L16.5 5.5zm-1.833.917h-4.584V1.833h-8.25V16.5h12.834V6.417z'
          fill={props.fill}
          fillRule='nonzero'
        />
      </g>
    </svg>
  )
}
export default FileOutlineIcon
