import { IIcon } from './iconConstants'
const EyeIcon = (props: IIcon) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox='0 0 18 14'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g fill='none' fillRule='evenodd'>
        <path d='M0-2h18v18H0z' />
        <path
          d='M9 .25A8.253 8.253 0 0 1 17.114 7 8.253 8.253 0 0 1 .886 7 8.253 8.253 0 0 1 9 .25zm0 12A6.754 6.754 0 0 0 15.583 7 6.754 6.754 0 0 0 2.417 7 6.754 6.754 0 0 0 9 12.25zm0-1.875a3.375 3.375 0 1 1 0-6.75 3.375 3.375 0 0 1 0 6.75zm0-1.5a1.875 1.875 0 1 0 0-3.75 1.875 1.875 0 0 0 0 3.75z'
          fill={props.fill}
          fillRule='nonzero'
        />
      </g>
    </svg>
  )
}
export default EyeIcon
