import { Box, Button, Grid, Typography, useTheme } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../../_helpers/hooks'
import BrushImage from '../../../images/brush.png'
import Back1 from '../../../images/card-flip1.png'
import Back2 from '../../../images/card-flip2.png'
import Back3 from '../../../images/card-flip3.png'
import GridImage from '../../../images/element-4.png'
import PublishImage from '../../../images/publish.png'
import orangeBg1 from '../../../images/step-card-1.png'
import orangeBg2 from '../../../images/step-card-2.png'
import orangeBg3 from '../../../images/step-card-3.png'

const EasyStepsSection = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const translatedInput = useAppSelector((state) => state?.translation.translatedData)
  const cards = [
    {
      id: 'box1',
      frontImage: GridImage,
      backImage: Back1,
      background: orangeBg1,
      title: translatedInput?.permissionJS?.createOption,
      description: translatedInput?.landingScreen?.easyStepSectionDesc1,
    },
    {
      id: 'box2',
      frontImage: BrushImage,
      backImage: Back2,
      background: orangeBg2,
      title: translatedInput?.landingScreen?.customizeText,
      description: translatedInput?.landingScreen?.easyStepSectionDesc2,
    },
    {
      id: 'box3',
      frontImage: PublishImage,
      backImage: Back3,
      background: orangeBg3,
      title: 'Publish',
      description: translatedInput?.landingScreen?.easyStepSectionDesc3,
    },
  ]

  return (
    <Box
      mb={10}
      sx={{
        margin: '0 auto',
        maxWidth: '87.5rem',
      }}
    >
      <Box pl={{ xs: 4, sm: 0 }} pr={{ xs: 4, sm: 0 }} mt={10}>
        <Typography
          variant='h2'
          sx={{ mb: 1, color: 'iconColors.main' }}
          fontSize={{ xs: '1.75rem', md: '2.5rem' }}
          lineHeight={{ xs: '2.25rem', md: '3.375rem' }}
          fontWeight={600}
        >
          {translatedInput?.landingScreen?.easyStepSectionText1}
          <span style={{ color: theme.palette.publicPages.contrastText }}> 3 Steps</span>
        </Typography>
        <Typography
          variant='h5'
          sx={{ color: 'publicPages.colorTwo' }}
          lineHeight={'1.563rem'}
          fontWeight={400}
          fontSize={{ xs: '.8rem', md: '1rem' }}
        >
          {translatedInput?.landingScreen?.easyStepSectionText2}
        </Typography>
      </Box>

      <Grid
        container
        spacing={{ xs: 3, lg: 2, xl: 4 }}
        mt={{ xs: 0, md: 5, lg: 10 }}
        justifyContent='center'
        display={{ xl: 'inline-flex' }}
      >
        {cards.map(({ id, frontImage, backImage, background, title, description }) => (
          <Grid item xs={12} md={6} lg={4} xl={4} key={id} display='flex' justifyContent='center'>
            <Box
              sx={{
                width: { xs: 387, md: 387, lg: 387, xl: 387 },
                minHeight: { xs: 360 },
                borderRadius: 5,
                textAlign: 'left',
                perspective: 1000,
                cursor: 'pointer',
                [theme.breakpoints.down(400)]: {
                  width: 250,
                  minHeight: 500,
                },
                position: 'relative',
                '&:hover .front': {
                  opacity: 0,
                  transform: 'rotateY(180deg)',
                },
                '&:hover .back': {
                  opacity: 1,
                  transform: 'rotateY(0deg)',
                },
                '& .front, & .back': {
                  position: 'absolute',
                  transition: 'transform 0.6s, opacity 0.6s',
                  backfaceVisibility: 'hidden',
                  top: 0,
                  left: 0,
                },
                '& .back': {
                  transform: 'rotateY(-180deg)',
                  opacity: 0,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                },
              }}
              padding={4}
              id={id}
              mt={{ xs: 4, lg: 0 }}
            >
              <Box
                className='front'
                padding={4}
                sx={{
                  backgroundImage: `url(${background})`,
                  width: { xs: '100%' },
                  height: '100%',
                  [theme.breakpoints.down(400)]: {
                    backgroundImage: 'none',
                    backgroundColor: '#D2DBFF',
                    borderRadius: 5,
                    position: 'relative',
                  },
                }}
              >
                <Box component='img' src={frontImage} mt={2} />
                <Typography
                  variant='h2'
                  sx={{ mt: { xs: 5, md: 1, lg: 5 }, color: 'publicPages.colorOne' }}
                  fontSize={{ xs: '1.75rem', md: '1rem', lg: '1.75rem' }}
                  lineHeight={'1.875rem'}
                  fontWeight={600}
                >
                  {title}
                </Typography>
                <Typography
                  variant='h3'
                  sx={{ mt: 3, color: 'publicPages.colorThree', wordBreak: 'break-word' }}
                  lineHeight={'1.875rem'}
                  fontWeight={300}
                >
                  {description}
                </Typography>
              </Box>
              <Box
                className='back'
                sx={{
                  zoom: { xs: '100%', md: '100%', lg: '100%' },
                  width: { xs: '100%' },
                  height: '100%',
                  [theme.breakpoints.down(400)]: {
                    zoom: '70%',
                  },
                }}
              >
                <Box component='img' src={backImage} />
              </Box>
            </Box>
          </Grid>
        ))}
        <Grid item xs={0} md={1} lg={0} xl={0} display={{ lg: 'none' }} />
      </Grid>
      <Box mt={{ xs: 2, md: 8 }}>
        <Button size='large' variant='contained' onClick={() => navigate('/login')}>
          {translatedInput?.landingScreen?.easyStepSectionText3}
        </Button>
      </Box>
    </Box>
  )
}

export default EasyStepsSection
