import { IRestrictionData } from './../_models/criteriaInterface'
/* eslint-disable camelcase */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../_store/store'
import { startLoader, stopLoader } from './loaderSlice'
import { IBusinessCriteria } from '../_models/criteriaInterface'
import criteriaService from '../_services/criteriaService'
import { successAlert } from './alertSlice'
import { successToast } from './toastSlice'
import { handleMessages } from '../_helpers/utilities'
const initialState: IBusinessCriteria = {
  restrictions: [],
  restriction_criteria: [],
  restriction_toggle: 0,
}

// CRITERIA DETAILS ## data to display in criteria tab

export const getCriteriaDetails = createAsyncThunk(
  '/get-criteria-details',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(startLoader())
    try {
      const response = await criteriaService.getCriteriaDetails()
      dispatch(stopLoader())
      return response
    } catch (err: any) {
      dispatch(stopLoader())
      return rejectWithValue(err)
    }
  },
)

// CRITERIA UPDATE
export const updateCriteria = createAsyncThunk(
  '/updateCriteria',
  async (newRestrictionData: IRestrictionData, { dispatch, rejectWithValue }) => {
    dispatch(startLoader())
    try {
      const response = await criteriaService.updateCriteria(newRestrictionData)
      dispatch(successToast(handleMessages(response.message)))
      dispatch(getCriteriaDetails())

      return response
    } catch (err: any) {
      dispatch(stopLoader())
      return rejectWithValue(err)
    }
  },
)

export const criteriaSlice = createSlice({
  name: 'criteria',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCriteriaDetails.fulfilled, (state, action) => {
      ;(state.restrictions = action.payload.data.restrictions),
        (state.restriction_toggle = action.payload.data.restriction_toggle),
        (state.restriction_criteria = action.payload.data.restriction_criteria)
    })
  },
})

export const criteriaState = (state: RootState) => state.criteria
export default criteriaSlice.reducer
