import { Box, Grid, useTheme } from '@mui/material'
import ContentLoader from 'react-content-loader'
interface ISkeleton {
  xs?: number
  sm?: number
  md?: number
  lg?: number
  xl?: number
  cardNo?: number
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const FacilityListSkeleton = ({ xl, xs, sm, md, lg, cardNo }: ISkeleton) => {
  const theme = useTheme()
  return (
    <Box height='100%'>
      <Grid container columnSpacing={{ xs: '1rem' }} rowSpacing={{ xs: '1rem', sm: '0' }}>
        {Array.from(Array(cardNo ? cardNo : 2)).map((_, index) => (
          <Grid
            item
            xs={xs ? xs : 12}
            sm={sm ? sm : 6}
            md={md ? md : 6}
            lg={lg ? lg : 6}
            xl={xl ? xl : 6}
            mt={1}
            key={index}
          >
            <Box
              sx={{
                border: 'solid 0.063rem ',
                borderRadius: 2,
                borderColor: 'divider',
                height: '18.75rem',
                paddingRight: '1.45rem',
              }}
            >
              <ContentLoader
                speed={2}
                width={'100%'}
                height={'100%'}
                viewBox='0 0 100% 100%'
                backgroundColor={theme.palette.facilityList.colorTwo}
                foregroundColor={theme.palette.facilityList.colorThree}
              >
                <rect x='20' y='20' rx='3' ry='3' width='100%' height='140' />
                <rect x='20' y='180' rx='3' ry='3' width='40%' height='10' />
                <rect x='20' y='205' rx='3' ry='3' width='40%' height='10' />
                <rect x='20' y='250' rx='3' ry='3' width='100%' height='15' />
              </ContentLoader>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}
export default FacilityListSkeleton
