// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function Switch(theme: any) {
  return {
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 46,
          height: 22,
          padding: 0,
          display: 'flex',
        },
        thumb: {
          boxShadow: `0 2px 4px 0 ${theme.palette.shadow.light}`,
          width: 17,
          height: 17,
          borderRadius: 12,
          transition: theme.transitions.create(['width'], {
            duration: 200,
          }),
        },
        switchBase: {
          padding: 2,
          '&.Mui-checked': {
            transform: 'translateX(24px)',
            color: theme.palette.common.white,
            '& + .MuiSwitch-track': {
              opacity: 1,
              backgroundColor: 'primary',
            },
          },
        },
        track: {
          borderRadius: 30 / 2,
          opacity: 1,
          backgroundColor:
            theme.palette.mode === 'dark'
              ? theme.palette.shadow.dark
              : theme.palette.shadow.contrastText,
          boxSizing: 'border-box',
        },
      },
    },
  }
}
