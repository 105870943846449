/* eslint-disable camelcase */

import {
  ICheckEditFloorActiveBookings,
  ICreateCSVFloorData,
  ICreateSVGFloorData,
  IEditCSVFloorData,
  IEditSVGFloorData,
} from '../_models/floorEditorInterface'

import { IFilters } from './../_models/commonInterface'
import {
  ICheckFacilityParams,
  IConnectFacilityParams,
  IDetectSeats,
  IFloorMapFacility,
  IFloorPlanFacilityParams,
  IGetSlotsMap,
  ISeatDetailsMap,
} from './../_models/floorInterface'
import { _delete, get, post, put } from './apiServices'

const getAllFloors = async (filters: IFilters) => {
  const response = await get('/floors', filters)
  return response
}
const checkActiveBookingsDelete = async (floorId: string | number) => {
  const response = await post(`floors/delete/check-bookings?floorId=${floorId}`)
  return response
}
const deleteFloor = async (floorId: string | number) => {
  const response = await _delete(`/floors/delete?floorId=${floorId}`)
  return response
}

const checkDuplicateFloorName = async (filters: IFilters) => {
  const response = await get('/floors/config/exists', filters)
  return response
}

const createCSVFloor = async (floorData: ICreateCSVFloorData) => {
  const response = await post('/floors/config/csv', floorData)
  return response
}

const createSVGFloor = async (floorData: ICreateSVGFloorData) => {
  const response = await post('/floors/config/svg', floorData)
  return response
}

const getFloorDetails = async (floorIdData: { floor_id: number }) => {
  const response = await get('/floors/config/seats', floorIdData)
  return response
}
const editSVGFloor = async (floorData: IEditSVGFloorData) => {
  const response = await put('/floors/config/svg', floorData)
  return response
}
const editCSVFloor = async (floorData: IEditCSVFloorData) => {
  const response = await put('/floors/config/csv', floorData)
  return response
}
const checkEditFloorActiveBookingsSVG = async (floorData: ICheckEditFloorActiveBookings) => {
  const response = await post('/floors/config/check-svg-seat', floorData)
  return response
}

const checkEditFloorActiveBookingsCSV = async (floorData: ICheckEditFloorActiveBookings) => {
  const response = await post('/floors/config/check-csv-seat', floorData)

  return response
}
const getFacilitiesFloor = async (params: IFloorPlanFacilityParams) => {
  const response = await get('/floors/facilities', params)
  return response
}

const checkFacilityBookingStatus = async (params: ICheckFacilityParams) => {
  const response = await post('/floors/check-bookings', params)
  return response
}

const connectFacilityToFloors = async (params: IConnectFacilityParams) => {
  const response = await post('/floors/connect-facilities', params)
  return response
}

// Get all facilities in map view
const getAllFacilitiesMap = async (onlyMap: boolean) => {
  const response = await get('/floors/map-view/facilities', { onlyMap })
  return response
}

// Get floors linked in the selected facility
const getFloorsMap = async (facilityIdData: IFloorMapFacility) => {
  const response = await get('/floors/map-view/floors', facilityIdData)
  return response
}

// get slots in map view
const getSlotsMap = async (getSlotsData: IGetSlotsMap) => {
  const response = await get('/floors/map-view/slots', getSlotsData)
  return response
}

// get seat details in floor map
const getSeatDetailsMap = async (getSeatsData: ISeatDetailsMap) => {
  const response = await post('/floors/map-view', getSeatsData)
  return response
}
const detectSeats = async (params: IDetectSeats) => {
  const response = await post('/detect-seats-image-data', params)
  return response
}

const getEditorHelpers = async () => {
  const response = await get('/floors/config/attributes', {})
  return response
}

const floorServices = {
  getAllFloors,
  checkActiveBookingsDelete,
  deleteFloor,
  checkDuplicateFloorName,
  createCSVFloor,
  createSVGFloor,
  getFloorDetails,
  editCSVFloor,
  detectSeats,
  editSVGFloor,
  checkEditFloorActiveBookingsCSV,
  checkEditFloorActiveBookingsSVG,
  getFacilitiesFloor,
  checkFacilityBookingStatus,
  connectFacilityToFloors,
  getAllFacilitiesMap,
  getFloorsMap,
  getSlotsMap,
  getSeatDetailsMap,
  getEditorHelpers,
}

export default floorServices
