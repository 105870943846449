import { Box, Button, ClickAwayListener, Grid, Typography, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import { PERMISSIONS } from '../../_constants'
import { stampToDate } from '../../_helpers/dateTime'
import { useAppDispatch, useAppSelector } from '../../_helpers/hooks'
import { IRecurringBooking } from '../../_models/newBookingInterface'
import SpanwithColor from '../../components/chip/colorSpan'
import ShowMoreContainer from '../../components/common/showMoreItem'
import { CalenderWithTickIcon, Tick2Icon } from '../../components/icons'
import { useUserPermissionAuthorization } from '../../hooks'
import {
  changeFilters,
  dashboardState,
  getBookingList,
  getDashboardStatistics,
} from '../../slices/dashboardSlice'
import { closeBookingSuccessModal, newBookingData } from '../../slices/newBookingSlice'
import { getPricingLimits } from '../../slices/pricingSlice'
import ActionAlertModal from '../actionAlertModal'

interface IBookingSuccessModal {
  showModal: boolean
  userType: string
  updateDrawer?: () => void
}
const BookingSuccessModal = (props: IBookingSuccessModal) => {
  const { userType } = props
  const dispatch = useAppDispatch()
  const translatedInput = useAppSelector((state) => state?.translation.translatedData)
  const newBookingState = useAppSelector(newBookingData)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { bookingSuccessInformation, bookingSuccessHeaderMessage }: any = newBookingState
  const { dashboardBookingListData } = useAppSelector(dashboardState)
  const { filters } = dashboardBookingListData
  const [AlertModal, setAlertModal] = useState<boolean>(false)
  const [isPermitted] = useUserPermissionAuthorization()
  useEffect(() => {
    setAlertModal(props.showModal)
  }, [props])

  const closeAlertModal = (): void => {
    setAlertModal(false)
    dispatch(closeBookingSuccessModal())
    if (userType !== 'customer') {
      dispatch(changeFilters({ ...filters, search: '', page: 1 }))
      dispatch(getBookingList(filters))
      isPermitted([PERMISSIONS.DASHBOARD_KPI_VIEW]) && dispatch(getDashboardStatistics())
      dispatch(getPricingLimits({}))
    }
  }
  const [successfulBookings, setSuccessfulBookings] = useState<string[]>([])
  const [failedBookings, setFailedBookings] = useState<string[]>([])
  const [bookingStatus, setBookingStatus] = useState<string>(
    translatedInput?.bookingSuccess?.bookingSuccessStatus,
  )
  const theme = useTheme()
  useEffect(() => {
    setSuccessfulBookings([])
    if (bookingSuccessInformation.is_recurring_booking) {
      bookingSuccessInformation.recurring_booking_data?.map(
        (individualBooking: IRecurringBooking) => {
          if (individualBooking.bookingStatus === 'success') {
            setSuccessfulBookings((successfulBookings) => [
              ...successfulBookings,
              stampToDate(individualBooking.date),
            ])
          } else if (individualBooking.bookingStatus === 'failed')
            setFailedBookings([...failedBookings, stampToDate(individualBooking.date)])
        },
      )

      if (failedBookings?.length == 0) {
        setBookingStatus(translatedInput?.bookingSuccess?.bookingSuccessStatus)
      } else if (successfulBookings?.length == 0) {
        setBookingStatus(translatedInput?.bookingSuccess?.bookingFailedStatus)
      } else if (successfulBookings?.length !== 0 && failedBookings?.length !== 0) {
        setBookingStatus(translatedInput?.bookingSuccess?.bookingPartialStatus)
      }
    }
  }, [
    bookingSuccessInformation,
    failedBookings,
    successfulBookings?.length,
    translatedInput?.bookingSuccess?.bookingFailedStatus,
    translatedInput?.bookingSuccess?.bookingPartialStatus,
    translatedInput?.bookingSuccess?.bookingSuccessStatus,
  ])

  return (
    <ActionAlertModal
      showAlert={AlertModal}
      title={
        bookingSuccessInformation?.is_recurring_booking
          ? bookingStatus
          : bookingSuccessHeaderMessage
      }
      alertIcon={
        <CalenderWithTickIcon
          fill={theme.palette.success.light}
          stoke={theme.palette.common.white}
          width='50'
          height='51'
          backgroundColor={theme.palette.rgba.contrastText}
        />
      }
      modalContainerMaxWidth='32rem'
    >
      <ClickAwayListener onClickAway={closeAlertModal}>
        <Box mt={2} sx={{ width: '100%' }}>
          {!bookingSuccessInformation?.is_recurring_booking && (
            <>
              {!bookingSuccessInformation?.book_for_others && (
                <Box sx={{ display: { md: 'flex' } }} justifyContent={'center'}>
                  <Box mr={{ xs: 0, md: 3 }} mt={1} ml={{ xs: 0, md: 2 }} mb={1}>
                    <SpanwithColor
                      bgcolor='pagination.contrastText'
                      borderCol='solid 0.063rem'
                      brdrCol='info.light'
                    >
                      <Typography
                        color='toggleButtonGroup.main'
                        variant='subtitle1'
                        pl={{ xs: 0, md: 1 }}
                      >
                        {translatedInput?.bookingManagement?.token}:
                      </Typography>
                      <Typography
                        color='common.black'
                        variant='subtitle1'
                        pr={{ xs: 0, md: 3 }}
                        pl={{ xs: 0, sm: 1, md: 1 }}
                      >
                        {bookingSuccessInformation?.short_token}
                      </Typography>
                    </SpanwithColor>
                  </Box>
                  <Box mt={1} mb={1}>
                    <SpanwithColor
                      bgcolor='pagination.contrastText'
                      borderCol='solid 0.063rem '
                      brdrCol='info.light'
                    >
                      <Typography
                        color='toggleButtonGroup.main'
                        variant='subtitle1'
                        pl={{ xs: 0, md: 1 }}
                      >
                        {translatedInput?.common?.numberOfSeatsText}:
                      </Typography>
                      <Typography
                        color='common.black'
                        variant='subtitle1'
                        pr={{ xs: 0, md: 3 }}
                        pl={{ xs: 0, sm: 1, md: 1 }}
                      >
                        {bookingSuccessInformation?.no_of_seats}
                      </Typography>
                    </SpanwithColor>
                  </Box>
                </Box>
              )}

              {bookingSuccessInformation?.seats && bookingSuccessInformation?.seats?.length > 0 && (
                <Box
                  mt={2}
                  sx={{
                    padding: '.85rem 1.313rem .85rem 1.188rem',
                    borderRadius: '0.625rem',
                    backgroundColor: 'table.main',
                  }}
                >
                  <Box sx={{ display: { md: 'flex' } }}>
                    <Typography
                      color='toggleButtonGroup.main'
                      variant='subtitle1'
                      pr={{ xs: 0, md: 1.5 }}
                    >
                      {translatedInput?.newBookingScreenText?.selectedSeatsText}
                      {':'}
                    </Typography>
                    <Box flex={4} display={'flex'}>
                      <ShowMoreContainer
                        colorOfItem={'iconColors.main'}
                        colorOfOption={'error.main'}
                        fontSizeOfItem={'0.875rem'}
                        fontWeightOfOption='normal'
                        itemsToShowInitially={4}
                        isItemLink={false}
                        mtRemove={true}
                        data={bookingSuccessInformation.seats.split(', ').map(
                          // eslint-disable-next-line @typescript-eslint/no-explicit-any
                          (seat: string) => seat.trim(),
                        )}
                      />
                    </Box>
                  </Box>
                </Box>
              )}

              <Box
                mt={2}
                sx={{
                  padding: '1.063rem 1.313rem 1.063rem 1.188rem',
                  borderRadius: '0.625rem',
                  backgroundColor: 'table.main',
                }}
              >
                {bookingSuccessInformation?.tokens?.map(
                  (item: { token_id: string; customer_email: string }) => (
                    <Box
                      mt={1}
                      sx={{ display: { md: 'flex' } }}
                      mb={1}
                      alignItems={'end'}
                      key={item.token_id}
                    >
                      <Box sx={{ display: { xs: 'flex' } }}>
                        <Box pr={1}>
                          <Tick2Icon
                            fill={theme.palette.error.contrastText}
                            width='10'
                            height='8'
                            stoke={theme.palette.table.main}
                          />
                        </Box>
                        <Typography
                          fontSize={'1rem'}
                          color='common.black'
                          variant='subtitle1'
                          pr={{ xs: 0, md: 1.5 }}
                        >
                          {translatedInput?.common?.bookingIdLabel}: {item.token_id}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          color='toggleButtonGroup.main'
                          variant='subtitle1'
                          pr={{ xs: 0, md: 1 }}
                          pl={{ xs: 2.2, md: 0 }}
                        >
                          {stampToDate(bookingSuccessInformation?.selected_date)} :
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          color='tab.dark'
                          variant='subtitle1'
                          pr={0}
                          pl={{ xs: 2.2, md: 0 }}
                        >
                          {bookingSuccessInformation?.selectedSlot}
                        </Typography>
                      </Box>
                    </Box>
                  ),
                )}
              </Box>
            </>
          )}

          {bookingSuccessInformation?.is_recurring_booking && (
            <>
              <Box
                mt={1}
                sx={{
                  padding: '1.063rem 1.313rem 1.063rem 1.188rem',
                  borderRadius: '0.625rem',
                  backgroundColor: 'table.main',
                  width: '100%',
                }}
              >
                <Box display='flex'>
                  <Typography color='tab.dark' variant='subtitle1' pr={{ xs: 0, md: 1.5 }} flex={2}>
                    {translatedInput?.bookingSuccess?.selectedSlot}
                  </Typography>
                  <Typography color='tab.dark' variant='subtitle1' flex={0.5}>
                    :
                  </Typography>
                  <Typography color='toggleButtonGroup.main' variant='subtitle1' flex={4}>
                    {bookingSuccessInformation?.recurring_booking_data[0]?.selectedSlot}
                  </Typography>
                </Box>
                <Box display='flex'>
                  <Typography flex={2} color='tab.dark' variant='subtitle1' pr={{ xs: 0, md: 1.5 }}>
                    {translatedInput?.bookingSuccess?.noOfSeats}
                  </Typography>
                  <Typography color='tab.dark' variant='subtitle1' flex={0.5}>
                    :
                  </Typography>
                  <Typography color='toggleButtonGroup.main' variant='subtitle1' flex={4}>
                    {bookingSuccessInformation?.recurring_booking_data[0]?.no_of_seats}
                  </Typography>
                </Box>
              </Box>

              <Box mt={3}>
                {successfulBookings?.length > 0 && (
                  <Box textAlign={'center'}>
                    <Typography variant='subtitle1' color='tab.dark'>
                      {translatedInput?.bookingSuccess?.bookingSuccessfulForText}
                    </Typography>
                    {failedBookings?.length == 0 ? (
                      <Typography variant='subtitle1' color='toggleButtonGroup.main'>
                        {successfulBookings[0]} to{' '}
                        {successfulBookings[successfulBookings?.length - 1]}
                      </Typography>
                    ) : (
                      <Typography variant='subtitle1' color='toggleButtonGroup.main'>
                        {successfulBookings.join(', ')}
                      </Typography>
                    )}
                  </Box>
                )}
                {failedBookings?.length > 0 && (
                  <Box mt={2} textAlign={'center'}>
                    <Typography variant='subtitle1' color='tab.dark'>
                      {translatedInput?.bookingSuccess?.bookingFailedForText}
                    </Typography>
                    {successfulBookings?.length == 0 ? (
                      <Typography variant='subtitle1' color='toggleButtonGroup.main'>
                        {failedBookings[0]} to {failedBookings[failedBookings?.length - 1]}
                      </Typography>
                    ) : (
                      <Typography variant='subtitle1' color='toggleButtonGroup.main'>
                        {failedBookings.join(', ')}
                      </Typography>
                    )}
                  </Box>
                )}
              </Box>
            </>
          )}
          <Box>
            <Grid container columnSpacing={'1.25rem'}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={4}>
                <Button
                  type='submit'
                  sx={{ width: '100%' }}
                  variant='contained'
                  size='large'
                  onClick={closeAlertModal}
                >
                  {translatedInput?.common?.okayBtn}
                </Button>
              </Grid>
            </Grid>
          </Box>
          {bookingSuccessInformation.is_recurring_booking && (
            <Typography textAlign='center' variant='subtitle2' color='secondary.dark' mt={4} px={5}>
              {translatedInput?.bookingSuccess?.bookingSuccessInfoText1}&nbsp;
              {translatedInput?.bookingSuccess?.bookingSuccessInfoText3}
              &nbsp;{`${translatedInput?.bookingSuccess?.bookingSuccessInfoText2}.`}
            </Typography>
          )}
        </Box>
      </ClickAwayListener>
    </ActionAlertModal>
  )
}

export default BookingSuccessModal
