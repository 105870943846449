import { Box } from '@mui/material'
import { ReactNode } from 'react'

interface IDrawerContainer {
  children: ReactNode
  sx?: Record<string, unknown>
}

export const PageDrawerContainer = (props: IDrawerContainer) => {
  const { children, sx } = props

  return (
    <Box width={'100%'} height={'100%'} display={'flex'} flexDirection={'column'} sx={{ ...sx }}>
      {children}
    </Box>
  )
}

export const PageDrawerHeaderContainer = (props: IDrawerContainer) => {
  const { children, sx } = props

  return (
    <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} sx={{ ...sx }}>
      {children}
    </Box>
  )
}

export const PageDrawerSectionContainer = (props: IDrawerContainer) => {
  const { children, sx } = props

  return (
    <Box p={'1.625rem'} sx={{ ...sx }}>
      {children}
    </Box>
  )
}

export const PageDrawerFooterButtonsContainer = (props: IDrawerContainer) => {
  const { children, sx } = props

  return (
    <Box p={'1.625rem'} sx={{ ...sx }}>
      {children}
    </Box>
  )
}

export const PageDrawerStepperContainer = (props: IDrawerContainer) => {
  const { children, sx } = props

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        maxHeight: 100,
        ...sx,
      }}
      mt={'2rem'}
      mb={'1rem'}
    >
      {children}
    </Box>
  )
}
