import dayjs, { Dayjs } from 'dayjs'
import moment from 'moment'
//
export const stampToDate = (timeStamp: string) => {
  const formattedDate = moment(timeStamp).format('MM-DD-YYYY')
  return formattedDate
}

export const stampToTime = (timeStamp: string) => {
  const formattedDate = moment(timeStamp, ['HH:mm:ss']).format('hh:mm A')
  return formattedDate
}

export const dateToMonthDay = (timeStamp: string) => {
  const formattedDate = moment(timeStamp).format('MMMM DD, YYYY')
  return formattedDate
}

export const dateToDayMonthYear = (timeStamp: string) => {
  const formattedDate = moment(timeStamp).format('DD-MM-YYYY')
  return formattedDate
}
export const dateToYearMonthDay = (timeStamp: string) => {
  const formattedDate = moment(timeStamp).format('YYYY-MM-DD')
  return formattedDate
}
export const dayjsToYearMonthDay = (timeStamp: Dayjs) => {
  const formattedDate = dayjs(timeStamp).format('YYYY-MM-DD')
  return formattedDate
}
export const formatMomentToDate = (date: moment.Moment) => date.format('YYYY-MM-DD')

export const getDateRange = (selected: string) => {
  const Today = moment()
  const Tomorrow = moment().add(1, 'days')
  const Yesterday = moment().subtract(1, 'days')
  const currentYear = moment().format('YYYY')
  const currentMonth = moment().month()
  const startOfMonth = moment().month(currentMonth).startOf('month')
  const endOfMonth = moment().month(currentMonth).endOf('month')
  const startOfNextWeek = Today.clone().day(7)
  const lastWeekStart = Today.clone().subtract(1, 'weeks').startOf('week')
  const lastWeekEnd = Today.clone().subtract(1, 'weeks').endOf('week')
  let startingDate = ''
  let endingDate = ''

  switch (selected) {
    case 'Today':
      startingDate = Today.format('YYYY-MM-DD')
      endingDate = Today.format('YYYY-MM-DD')
      break
    case 'Tomorrow':
      startingDate = Tomorrow.format('YYYY-MM-DD')
      endingDate = Tomorrow.format('YYYY-MM-DD')
      break
    case 'Yesterday':
      startingDate = Yesterday.format('YYYY-MM-DD')
      endingDate = Yesterday.format('YYYY-MM-DD')
      break
    case 'ThisWeek':
      startingDate = Today.clone().subtract(Today.day(), 'days').format('YYYY-MM-DD')
      endingDate = Today.clone()
        .add(6 - Today.day(), 'days')
        .format('YYYY-MM-DD')
      break
    case 'ThisMonth':
      startingDate = startOfMonth.format('YYYY-MM-DD')
      endingDate = endOfMonth.format('YYYY-MM-DD')
      break
    case 'Last3months':
      startingDate = Today.clone().subtract(2, 'months').startOf('month').format('YYYY-MM-DD')
      endingDate = Today.clone().endOf('month').format('YYYY-MM-DD')
      break
    case 'Last6months':
      startingDate = Today.clone().subtract(5, 'months').startOf('month').format('YYYY-MM-DD')
      endingDate = Today.clone().endOf('month').format('YYYY-MM-DD')
      break
    case 'NextWeek':
      startingDate = startOfNextWeek.format('YYYY-MM-DD')
      endingDate = startOfNextWeek.clone().add(6, 'days').format('YYYY-MM-DD')
      break
    case 'NextMonth':
      startingDate = moment().add(1, 'months').startOf('month').format('YYYY-MM-DD')
      endingDate = moment(startingDate, 'YYYY-MM-DD').endOf('month').format('YYYY-MM-DD')
      break
    case 'LastMonth':
      startingDate = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD')
      endingDate = moment(startingDate, 'YYYY-MM-DD').endOf('month').format('YYYY-MM-DD')
      break
    case 'LastWeek':
      startingDate = lastWeekStart.format('YYYY-MM-DD')
      endingDate = lastWeekEnd.format('YYYY-MM-DD')
      break
    case 'ThisYear':
      startingDate = moment(currentYear).startOf('year').format('YYYY-MM-DD')
      endingDate = moment(currentYear).endOf('year').format('YYYY-MM-DD')
      break
    default:
      startingDate = Today.format('YYYY-MM-DD')
      endingDate = Today.format('YYYY-MM-DD')
      break
  }
  return {
    startingDate,
    endingDate,
  }
}
export const convertTo24HourFormat = (timeStr: string) => {
  const formattedTime = moment(timeStr, ['h:mm A']).format('HH:mm:ss')
  return formattedTime
}

export const timeStringToSeconds = (timeStr: string) => {
  if (timeStr) {
    const [hours, minutes, seconds] = timeStr.split(':').map(Number)
    return hours * 3600 + minutes * 60 + (seconds || 0)
  } else {
    return 0
  }
}

export const getCurrentTimeInSeconds = () => {
  const currentTimeString = dayjs().format('HH:mm:ss')
  return timeStringToSeconds(currentTimeString)
}

const isSameDay = (date: string) => {
  const currentDateTime = dayjs()
  const currentDate = currentDateTime.startOf('day')
  const scheduledDate = dayjs(date, 'MM-DD-YYYY').startOf('day')
  return currentDate.isSame(scheduledDate, 'day')
}

const getTimeDifference = (slotEndTime: string, bufferTime: number) => {
  const slotEndTimeInSeconds = timeStringToSeconds(slotEndTime)
  const currentTimeInSeconds = getCurrentTimeInSeconds()
  const bufferTimeInSeconds = bufferTime * 60
  const timeDifference = currentTimeInSeconds - slotEndTimeInSeconds

  return { timeDifference, bufferTimeInSeconds }
}

// To check if a booking is in post-buffer time
export const postBufferTimeCheck = (slotEndTime: string, bufferTime: number, date: string) => {
  const { timeDifference, bufferTimeInSeconds } = getTimeDifference(slotEndTime, bufferTime)
  return timeDifference > 0 && timeDifference < bufferTimeInSeconds && isSameDay(date)
}

// To check if a booking is past the post-buffer-time
export const pastBufferTimeCheck = (slotEndTime: string, bufferTime: number, date: string) => {
  const { timeDifference, bufferTimeInSeconds } = getTimeDifference(slotEndTime, bufferTime)
  return timeDifference > 0 && timeDifference > bufferTimeInSeconds && isSameDay(date)
}

export const checkStatusAndPostBufferTime = (
  slotEndingTime: string,
  bufferTime: number,
  date: string,
  statusNo: number,
) => {
  if (
    (postBufferTimeCheck(slotEndingTime, bufferTime, date) &&
      // Upcoming and pending  bookings
      (statusNo === 1 || statusNo === 6)) ||
    // Scanned booking
    statusNo === 8
  ) {
    return true
  } else {
    return false
  }
}

export const checkStatusAndPastBufferTime = (
  slotEndingTime: string,
  bufferTime: number,
  date: string,
  statusNo: number,
) => {
  if (
    pastBufferTimeCheck(slotEndingTime, bufferTime, date) &&
    // Upcoming , pending and scanned bookings
    (statusNo === 1 || statusNo === 6 || statusNo === 8)
  ) {
    return true
  } else {
    return false
  }
}

export const dateToDateInWords = (timeStamp: string) => {
  const date = new Date(timeStamp)

  const formattedDate = date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  })
  return formattedDate
}
