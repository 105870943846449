import { IIcon } from './iconConstants'
const CalenderIcon = (props: IIcon) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox='0 0 16 16'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5.6 0v1.6h4.8V0H12v1.6h3.2a.8.8 0 0 1 .8.8v12.8a.8.8 0 0 1-.8.8H.8a.8.8 0 0 1-.8-.8V2.4a.8.8 0 0 1 .8-.8H4V0h1.6zm8.8 8H1.6v6.4h12.8V8zM4 3.2H1.6v3.2h12.8V3.2H12v1.6h-1.6V3.2H5.6v1.6H4V3.2z'
        fill={props.fill}
        fillRule='nonzero'
      />
    </svg>
  )
}
export default CalenderIcon
