import { ReactNode, useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import { isAuthenticated } from '../../_helpers/cookie'
import { useAppSelector } from '../../_helpers/hooks'
import useUserPermissionAuthorization from '../../hooks/useUserPermissionAuthorization'
import { userState } from '../../slices/userSlice'

interface IRequireUserPermissionsAuth {
  children: ReactNode
  authorizedUserPermissions: string[]
}

const RequireUserPermissionsAuth = ({
  children,
  authorizedUserPermissions,
}: IRequireUserPermissionsAuth) => {
  const [isPermitted] = useUserPermissionAuthorization()
  const userInformation = useAppSelector(userState)
  const { userData } = userInformation
  const [activePermissionState, setActivePermissionState] = useState<string[] | undefined>([])

  useEffect(() => {
    setActivePermissionState(userData.activePermissions)
  }, [userData])

  const getAuthContent = () => {
    if (!isPermitted(authorizedUserPermissions)) {
      return <Navigate to='/access-denied' />
    } else if (isPermitted(authorizedUserPermissions)) {
      return <>{children}</>
    }
  }

  return (
    <>
      {isAuthenticated() ? (
        activePermissionState && activePermissionState.length > 0 && getAuthContent()
      ) : (
        <Navigate to='/login' />
      )}
    </>
  )
}

export default RequireUserPermissionsAuth
