import { IIcon } from './iconConstants'
const ErrorIcon = (props: IIcon) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox='0 0 48 48'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g fill='none' fillRule='evenodd'>
        <path d='M-4-4h56v56H-4z' />
        <path
          d='M24 47.333C11.113 47.333.667 36.887.667 24 .667 11.113 11.113.667 24 .667 36.887.667 47.333 11.113 47.333 24c0 12.887-10.446 23.333-23.333 23.333zm0-4.666c10.31 0 18.667-8.358 18.667-18.667 0-10.31-8.358-18.667-18.667-18.667C13.69 5.333 5.333 13.691 5.333 24c0 10.31 8.358 18.667 18.667 18.667zM24 20.7l6.599-6.601L33.9 17.4 27.3 24l6.6 6.599L30.6 33.9 24 27.3l-6.599 6.6L14.1 30.6 20.7 24l-6.6-6.599L17.4 14.1 24 20.7z'
          stroke={props.stoke}
          strokeWidth='.667'
          fill={props.fill}
          fillRule='nonzero'
        />
      </g>
    </svg>
  )
}
export default ErrorIcon
