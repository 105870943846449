export default function TextField(theme: any) {
  return {
    MuiTextField: {
      styleOverrides: {
        root: {
          input: {
            paddingBottom: '13px',
            paddingTop: '12px',
          },
          'input[type=number]': {
            '&::-webkit-outer-spin-button': {
              WebkitAppearance: 'none',
              display: 'none',
            },
            '&::-webkit-inner-spin-button': {
              WebkitAppearance: 'none',
              display: 'none',
            },
            MozAppearance: 'textfield',
          },
        },
      },
    },
  }
}
