import { Box } from '@mui/material'
import { FC, ReactNode } from 'react'
interface ISpanwithColor {
  bgcolor?: string
  children?: ReactNode
  borderCol?: string
  sx?: Record<string, unknown>
  jstify?: string
  brdrCol?: string
  minWidth?: string
}
const SpanwithColor: FC<ISpanwithColor> = ({
  bgcolor,
  children,
  borderCol,
  jstify,
  brdrCol,
  minWidth,
}) => {
  return (
    <>
      <Box
        sx={{
          backgroundColor: bgcolor,
          padding: '.5rem',
          borderRadius: '0.5rem',
          textAlign: 'center',
          display: { xs: 'block', sm: 'flex' },
          border: borderCol,
          borderColor: brdrCol,
          minWidth: minWidth ? minWidth : { md: '9.375rem' },
        }}
        justifyContent={jstify ? jstify : 'center'}
      >
        {children}
      </Box>
    </>
  )
}

export default SpanwithColor
