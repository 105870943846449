import { IIcon } from './iconConstants'

const ProIcon = (props: IIcon) => {
  const { height, stoke, width } = props
  return (
    <svg width={width} height={height} viewBox='0 0 50 50' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M34.792 39.542H15.208c-.875 0-1.854-.688-2.146-1.521L4.438 13.896c-1.23-3.459.208-4.521 3.166-2.396l8.125 5.813c1.354.937 2.896.458 3.48-1.063l3.666-9.77c1.167-3.126 3.104-3.126 4.27 0l3.668 9.77c.583 1.52 2.125 2 3.458 1.063l7.625-5.438c3.25-2.333 4.812-1.146 3.479 2.625l-8.417 23.563c-.312.791-1.291 1.479-2.166 1.479z'
        fill={'none'}
        stroke={stoke}
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.542 45.833h22.916'
        fill='none'
        stroke={stoke}
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M19.792 29.167h10.416'
        fill='none'
        stroke={stoke}
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default ProIcon
