// Define fadeIn animation
export const fadeInAnimation = {
  animation: 'fadeIn 1s ease-out forwards',
  '@keyframes fadeIn': {
    from: {
      opacity: 0,
      transform: 'translateY(-60px)',
    },
    to: {
      opacity: 1,
      transform: 'translateY(0)',
    },
  },
}
export const fadeInWithDelayAnimation = {
  animation: 'fadeIn2 1.8s ease-out forwards',
  '@keyframes fadeIn2': {
    from: {
      opacity: 0,
      transform: 'translateY(-50px)',
    },
    to: {
      opacity: 1,
      transform: 'translateY(0)',
    },
  },
}
export const fadeInFromBottomAnimation = {
  animation: 'fadeInBottom 1s ease-out forwards',
  '@keyframes fadeInBottom': {
    from: {
      opacity: 0,
      transform: 'translateY(60px)',
    },
    to: {
      opacity: 1,
      transform: 'translateY(0)',
    },
  },
}
export const fadeInFromBottom80Animation = {
  animation: 'fadeInBottom1 2s ease-out forwards',
  '@keyframes fadeInBottom1': {
    from: {
      opacity: 0.5,
      transform: 'translateY(90%)',
    },
    to: {
      opacity: 1,
      transform: 'translateY(0)',
    },
  },
}
export const fadeInFromRightAnimation = {
  animation: 'fadeInRight 1.5s ease-out forwards',
  '@keyframes fadeInRight': {
    from: {
      opacity: 0,
      transform: 'translate(-30%, -50%)',
    },
    to: {
      opacity: 1,
      transform: 'translate(-50%, -50%)',
    },
  },
}
export const fadeInFromLeftAnimation = {
  animation: 'fadeInLeft 1.5s ease-out forwards',
  '@keyframes fadeInLeft': {
    from: {
      opacity: 0,
      transform: 'translate(40%, -50%)', // Starting further to the left
    },
    to: {
      opacity: 1,
      transform: 'translate(50%, -50%)',
    },
  },
}
export const fadeInFromRight = {
  animation: 'fadeInRight1 .5s ease-out forwards',
  '@keyframes fadeInRight1': {
    from: {
      opacity: 0,
      transform: 'translateX(20px)',
    },
    to: {
      opacity: 1,
      transform: 'translateX(0)',
    },
  },
}

// Keyframe animation for top-to-bottom movement

export const fadeInFromTop = {
  animation: 'fadeInTop .5s ease-out forwards',
  '@keyframes fadeInTop': {
    from: {
      opacity: 0,
      transform: 'translate(25%, -5%)',
    },
    to: {
      opacity: 1,
      transform: 'translate(25%, 20%)',
    },
  },
}
export const fadeInFromBottomToTop = {
  animation: 'fadeInBottomToTop  .5s ease-out forwards',
  '@keyframes fadeInBottomToTop ': {
    from: {
      opacity: 0,
      transform: 'translate(-20%,25%)',
    },
    to: {
      opacity: 1,
      transform: 'translate(-20%,15%)',
    },
  },
}
