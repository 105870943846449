import { ReactNode, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../_helpers/hooks'
import {
  checkConfirmRescheduleBookingMailLink,
  checkRescheduleBookingMailLink,
  newBookingData,
} from '../../slices/newBookingSlice'

interface RedirectIfInvalidLink {
  children: ReactNode
}

const CheckEmailLinkValidity = ({ children }: RedirectIfInvalidLink) => {
  const dispatch = useAppDispatch()

  const [urlParams] = useSearchParams()
  const isRescheduleLink = urlParams.get('reschedule') ? true : false
  const isConfirmRescheduleLink = urlParams.get('confirm-reschedule') ? true : false
  const bookingToken = urlParams.get('token')
  const linkExpiryTime = urlParams.get('expires')
  const newBookingState = useAppSelector(newBookingData)
  const { isValidRescheduleBookingMailLink, isValidConfirmRescheduleLink } = newBookingState

  useEffect(() => {
    if (isRescheduleLink) {
      if (linkExpiryTime && bookingToken) {
        const rescheduleData = {
          expires: linkExpiryTime,
          token: bookingToken,
        }

        dispatch(checkRescheduleBookingMailLink(rescheduleData))
      }
    }

    if (isConfirmRescheduleLink) {
      if (linkExpiryTime && bookingToken) {
        const rescheduleData = {
          expires: linkExpiryTime,
          token: bookingToken,
        }

        dispatch(checkConfirmRescheduleBookingMailLink(rescheduleData))
      }
    }
  }, [bookingToken, dispatch, isConfirmRescheduleLink, isRescheduleLink, linkExpiryTime])

  return (
    <>
      {!isRescheduleLink && !isConfirmRescheduleLink && children}
      {isRescheduleLink && !isConfirmRescheduleLink && isValidRescheduleBookingMailLink && children}
      {isConfirmRescheduleLink && !isRescheduleLink && isValidConfirmRescheduleLink && children}
    </>
  )
}

export default CheckEmailLinkValidity
