import { Box, Button, ClickAwayListener, Grid, Stack, Typography, useTheme } from '@mui/material'
import { ReactNode } from 'react'
import { useAppDispatch, useAppSelector } from '../../_helpers/hooks'
import CustomModal from '../../components/@extended/modal'
import {
  BookingManagementIcon,
  ProIcon,
  ScannerIcon,
  UserManagementIcon,
} from '../../components/icons'
import { closablePricingPage, getPricingDetails } from '../../slices/pricingSlice'
interface IPricingAlertModal {
  showAlert: boolean
  content: string
  onAlertConfirm?: () => void
  onAlertClose?: () => void
  children?: ReactNode
  modalContainerMaxWidth?: string
}

const PricingAlertModal = (props: IPricingAlertModal) => {
  const { modalContainerMaxWidth, onAlertClose, showAlert, content } = props
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const translatedInput = useAppSelector((state) => state?.translation.translatedData)
  const confirmAlertModal = (): void => {
    dispatch(getPricingDetails())
    dispatch(closablePricingPage())
    onAlertClose?.()
  }

  const closeAlertModal = (): void => {
    onAlertClose?.()
  }

  return (
    <CustomModal isOpen={showAlert}>
      <ClickAwayListener onClickAway={closeAlertModal}>
        <Grid
          container
          sx={{
            maxWidth: {
              sm: modalContainerMaxWidth ? modalContainerMaxWidth : '25rem',
              md: modalContainerMaxWidth ? modalContainerMaxWidth : '25rem',
              lg: modalContainerMaxWidth ? modalContainerMaxWidth : '25rem',
            },
            minWidth: { sm: '25rem', md: '25rem', lg: '25rem' },
          }}
        >
          <Grid item xs={12}>
            <Stack justifyContent='center' alignItems='center'>
              <Box
                sx={{
                  height: '2.875rem',
                  width: '2.875rem',
                  mb: '1.5rem',
                }}
              >
                <ProIcon
                  width='3.125rem'
                  height='3.125rem'
                  fill={theme.palette.pricingAlert.main}
                  stoke={theme.palette.pricingAlert.main}
                  backgroundColor={theme.palette.common.white}
                />
              </Box>
              <Stack direction={'row'} spacing={1} justifyContent='center' alignItems='center'>
                <Typography
                  sx={{
                    fontSize: '1.375rem',
                    lineHeight: { xs: '1.3', sm: '0.73' },
                  }}
                  variant='h4'
                  textAlign='center'
                  color='common.black'
                >
                  {translatedInput?.alertCard?.upgradeBtn}
                </Typography>
                <Typography
                  sx={{
                    borderRadius: '1.75rem',
                    py: 0.5,
                    px: 2,
                    backgroundImage: `linear-gradient(to bottom, ${theme.palette.primary.main}, ${theme.palette.chart.main})`,
                  }}
                  variant='h5'
                  fontSize={12}
                  color={theme.palette.common.white}
                >
                  {translatedInput?.landingScreen?.proText}
                </Typography>
              </Stack>
              <Box py={3} maxWidth={'70%'}>
                <Typography variant='subtitle1' color='secondary.main' textAlign='center'>
                  {content || translatedInput?.pricingPage?.alertDescription}
                </Typography>
              </Box>

              <Box
                sx={{
                  backgroundColor: theme.palette.pricingAlert.colorOne,
                  width: '100%',
                  p: 2,
                  borderRadius: '0.625rem',
                }}
              >
                <Box display={'flex'} p={1} mt={1}>
                  <Box
                    sx={{
                      backgroundColor: theme.palette.pricingAlert.colorTwo,
                      height: '2.875rem',
                      width: '2.875rem',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: '50%',
                      mr: 2,
                    }}
                  >
                    <BookingManagementIcon
                      height='1.875rem'
                      width='1.875rem'
                      stoke={theme.palette.pricingAlert.main}
                    />
                  </Box>
                  <Box>
                    <Typography variant='h4'>
                      {translatedInput?.appHeader?.bookingManagement}
                    </Typography>
                    <Typography variant='subtitle1' fontWeight={300} color='secondary.main'>
                      {translatedInput?.pricingPage?.bookingManagementDescription}
                    </Typography>
                  </Box>
                </Box>
                <Box display={'flex'} p={1} mt={1}>
                  <Box
                    sx={{
                      backgroundColor: theme.palette.pricingAlert.colorThree,
                      height: '2.875rem',
                      width: '2.875rem',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: '50%',
                      mr: 2,
                    }}
                  >
                    <ScannerIcon
                      height='1.875rem'
                      width='1.875rem'
                      stoke={theme.palette.pricingAlert.colorFour}
                    />
                  </Box>
                  <Box>
                    <Typography variant='h4'>{translatedInput?.appHeader?.scanner}</Typography>
                    <Typography variant='subtitle1' fontWeight={300} color='secondary.main'>
                      {translatedInput?.pricingPage?.scannerDescription}
                    </Typography>
                  </Box>
                </Box>{' '}
                <Box display={'flex'} p={1} mt={1}>
                  <Box
                    sx={{
                      backgroundColor: theme.palette.toggleButtonGroup.dark,
                      height: '2.875rem',
                      width: '2.875rem',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: '50%',
                      mr: 2,
                    }}
                  >
                    <UserManagementIcon
                      height='1.875rem'
                      width='1.875rem'
                      stoke={theme.palette.pricingAlert.colorFive}
                    />
                  </Box>
                  <Box>
                    <Typography variant='h4'>{translatedInput?.sideBar?.userManagement}</Typography>
                    <Typography variant='subtitle1' fontWeight={300} color='secondary.main'>
                      {translatedInput?.pricingPage?.userManagementDescription}
                    </Typography>
                  </Box>
                </Box>
              </Box>

              <Box p={'0.625rem'} width={'100%'} mt={2}>
                <Grid container spacing={'1.25rem'}>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Button
                      sx={{ width: '100%' }}
                      variant='outlined'
                      size='large'
                      onClick={closeAlertModal}
                    >
                      {translatedInput?.common?.cancelBtn}
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Button
                      sx={{ width: '100%' }}
                      variant='contained'
                      size='large'
                      type='submit'
                      onClick={confirmAlertModal}
                    >
                      {translatedInput?.pricingPage?.upgradeNow}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </ClickAwayListener>
    </CustomModal>
  )
}

export default PricingAlertModal
