import { IIcon } from './iconConstants'
const ScanIcon = (props: IIcon) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox='0 0 14 14'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g fill='none' fillRule='evenodd'>
        <path d='M-2-2h18v18H-2z' />
        <path
          d='M9.25.25h4.5V4h-1.5V1.75h-3V.25zm-4.5 0v1.5h-3V4H.25V.25h4.5zm4.5 13.5v-1.5h3V10h1.5v3.75h-4.5zm-4.5 0H.25V10h1.5v2.25h3v1.5zm-4.5-7.5h13.5v1.5H.25v-1.5z'
          fill='#13974C'
          fillRule='nonzero'
        />
      </g>
    </svg>
  )
}
export default ScanIcon
