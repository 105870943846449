/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { handleMessages } from '../_helpers/utilities'
import { IFilters } from '../_models/commonInterface'

import {
  ICheckFacilityParams,
  IConnectFacilityParams,
  IDetectSeats,
  IDuplicateFloorNameCheckDetails,
  IFloorMapFacility,
  IFloorPlanFacilityParams,
  IFloorState,
  IGetSlotsMap,
  ISeatDetailsMap,
} from '../_models/floorInterface'

import {
  ICheckEditFloorActiveBookingsData,
  ICreateCSVFloorData,
  ICreateSVGFloorData,
  IEditCSVFloorData,
  IEditSVGFloorData,
} from '../_models/floorEditorInterface'
import floorServices from '../_services/floorServices'
import { RootState } from '../_store/store'
import { startLoader, stopLoader } from './loaderSlice'
import { getPricingLimits } from './pricingSlice'
import { errorToast, successToast } from './toastSlice'

const initialState: IFloorState = {
  floors: {
    current_page: 1,
    data: [],
    total: 20,
    per_page: 10,
    last_page: 0,
  },
  editorHelpers: {
    csv_template_url: '',
    seat_attributes: [],
  },
  filters: {
    page: 1,
    per_page: 10,
    search: '',
  },
  facilities: [],
  floorsOfSelectedFacility: [],
  slotsMap: {},
  mapSeatDetails: {
    availableSeats: 0,
    totalSeats: 0,
    image: '',
    colorCodes: {},
    bookedIds: [],
    bookedSeats: 0,
    seats: [],
  },
  fileFormat: 'svg',
  floorPlanFile: null,
  floorPlanDrawer: false,
  currentActiveStep: 0,
  isSeatDetected: false,
  mapFormat: 0,
  floorId: 0,
  parsedFileData: '',
  mode: 'create',
  floorName: '',
  isLoadingFloorDetails: false,
  deleteModalStatus: false,
  isActiveBookings: false,
  BookingsCountDelete: 0,
  isLastPage: false,
  searchSeatFilter: '',
  isDuplicateFloorName: false,
  isFloorActiveBookingAlert: false,

  floorFacilities: [],
  bookingCount: {
    active: 0,
    blocked: 0,
  },
  alertModalState: false,
  popperStatus: false,
  connectFacility: false,

  isLoadingFloorsMap: false,
  isLoadingSlotMap: false,
}

// GET ALL FLOORS

export const getAllFloors = createAsyncThunk(
  '/getAllFloors',
  async (filters: IFilters, { dispatch, rejectWithValue }) => {
    dispatch(getEditorHelpers())
    try {
      const response = await floorServices.getAllFloors(filters)
      dispatch(getPricingLimits({}))
      return response
    } catch (err: any) {
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)
// To check active bookings in the Floor - Delete Pop Up
export const checkActiveBookingsDelete = createAsyncThunk(
  '/checkActiveBookings',
  async (floorId: string | number, { dispatch, rejectWithValue }) => {
    dispatch(startLoader())
    try {
      const response = await floorServices.checkActiveBookingsDelete(floorId)
      dispatch(stopLoader())
      return response
    } catch (err: any) {
      dispatch(stopLoader())
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)

// Delete floor

export const deleteFloorPlan = createAsyncThunk(
  '/deleteFloor',
  async (floorId: string | number, { dispatch, rejectWithValue, getState }) => {
    dispatch(startLoader())
    try {
      const response = await floorServices.deleteFloor(floorId)
      dispatch(successToast(handleMessages(response.message)))
      const { floor } = getState() as { floor: IFloorState }
      if (floor.isLastPage) {
        if (floor.floors.data.length === 1) {
          //  dispatch(changeFilters({ ...filters, page }))
          dispatch(getAllFloors({ ...floor.filters, page: floor.filters.page - 1 }))
        } else {
          dispatch(getAllFloors(floor.filters))
        }
      } else {
        dispatch(getAllFloors(floor.filters))
      }
      // floor.floors.data.length === 1 && floor.isLastPage
      //   ? dispatch(getAllFloors(floor.filters))
      //   : dispatch(getAllFloors(floor.filters))
      dispatch(closeDeleteFloorModal())
      dispatch(stopLoader())
      return response
    } catch (err: any) {
      dispatch(stopLoader())
      dispatch(closeDeleteFloorModal())
      const { floor } = getState() as { floor: IFloorState }
      dispatch(getAllFloors(floor.filters))
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)

// Get facilities in the map view
export const getAllFacilitiesMap = createAsyncThunk(
  '/getAllFacilitiesMap',
  async (onlyMap: boolean, { dispatch, rejectWithValue }) => {
    try {
      const response = await floorServices.getAllFacilitiesMap(onlyMap)
      return response
    } catch (err: any) {
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)
// Get floors linked in the selected facility-Map View
export const getFloorsMapFacility = createAsyncThunk(
  '/getFloorsMap',
  async (facilityIdData: IFloorMapFacility, { dispatch, rejectWithValue }) => {
    try {
      const response = await floorServices.getFloorsMap(facilityIdData)
      return response
    } catch (err: any) {
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)

// Get slots in map view
export const getSlotsMapFacility = createAsyncThunk(
  '/getSlotsMap',
  async (getSlotsData: IGetSlotsMap, { dispatch, rejectWithValue }) => {
    try {
      const response = await floorServices.getSlotsMap(getSlotsData)
      return response
    } catch (err: any) {
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)

// Get seat details after selecting slot in map view

export const getSeatDetailsMap = createAsyncThunk(
  '/getSeatDetailsMap',
  async (getSeatsData: ISeatDetailsMap, { dispatch, rejectWithValue }) => {
    try {
      const response = await floorServices.getSeatDetailsMap(getSeatsData)
      return response
    } catch (err: any) {
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)

export const checkDuplicateFloorName = createAsyncThunk(
  '/checkDuplicateFloorName',
  async (floorDetails: IDuplicateFloorNameCheckDetails, { dispatch, rejectWithValue }) => {
    try {
      const response = await floorServices.checkDuplicateFloorName(floorDetails)
      return response
    } catch (err: any) {
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)

export const createSVGFloor = createAsyncThunk(
  '/createCSVFloor',
  async (floorData: ICreateSVGFloorData, { dispatch, rejectWithValue }) => {
    dispatch(startLoader())
    try {
      const response = await floorServices.createSVGFloor(floorData)
      dispatch(stopLoader())
      dispatch(successToast(handleMessages(response.message)))
      dispatch(changeValue({ floorPlanDrawer: false }))
      dispatch(changeValue({ ...initialState }))
      dispatch(getAllFloors(initialState.filters))
      return response
    } catch (err: any) {
      dispatch(stopLoader())
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)

export const createCSVFloor = createAsyncThunk(
  '/createCSVFloor',
  async (floorData: ICreateCSVFloorData, { dispatch, rejectWithValue }) => {
    dispatch(startLoader())
    try {
      const response = await floorServices.createCSVFloor(floorData)
      dispatch(stopLoader())
      dispatch(successToast(handleMessages(response.message)))
      dispatch(changeValue({ floorPlanDrawer: false }))
      dispatch(changeValue({ ...initialState }))
      dispatch(getAllFloors(initialState.filters))
      return response
    } catch (err: any) {
      dispatch(stopLoader())
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)

export const getFloorDetails = createAsyncThunk(
  '/getFloorDetails',
  async (floorIdData: { floor_id: number }, { dispatch, rejectWithValue }) => {
    dispatch(startLoader())
    try {
      const response = await floorServices.getFloorDetails(floorIdData)
      dispatch(changeValue({ floorPlanDrawer: true }))
      dispatch(stopLoader())
      return response
    } catch (err: any) {
      dispatch(stopLoader())
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)

export const detectSeats = createAsyncThunk(
  '/detectSeats',
  async (floorData: IDetectSeats, { dispatch, rejectWithValue }) => {
    dispatch(startLoader())
    try {
      const response = await floorServices.detectSeats(floorData)
      dispatch(stopLoader())
      return response
    } catch (err: any) {
      dispatch(stopLoader())
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)

export const editSVGFloor = createAsyncThunk(
  '/editSVGFloor',
  async (floorData: IEditSVGFloorData, { dispatch, rejectWithValue, getState }) => {
    dispatch(startLoader())
    try {
      const response = await floorServices.editSVGFloor(floorData)
      dispatch(stopLoader())
      dispatch(successToast(handleMessages(response.message)))
      dispatch(changeValue({ floorPlanDrawer: false }))
      const { floor } = getState() as { floor: IFloorState }
      dispatch(getAllFloors(floor.filters))
      return response
    } catch (err: any) {
      dispatch(stopLoader())
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)

export const editCSVFloor = createAsyncThunk(
  '/editCSVFloor',
  async (floorData: IEditCSVFloorData, { dispatch, rejectWithValue, getState }) => {
    dispatch(startLoader())
    try {
      const response = await floorServices.editCSVFloor(floorData)
      dispatch(stopLoader())
      dispatch(successToast(handleMessages(response.message)))
      dispatch(changeValue({ floorPlanDrawer: false }))
      const { floor } = getState() as { floor: IFloorState }
      dispatch(getAllFloors(floor.filters))
      return response
    } catch (err: any) {
      dispatch(stopLoader())
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)

export const getFacilitiesFloor = createAsyncThunk(
  '/getFacilitiesFloor',
  async (params: IFloorPlanFacilityParams, { dispatch, rejectWithValue }) => {
    dispatch(startLoader())
    try {
      const response = await floorServices.getFacilitiesFloor(params)
      dispatch(stopLoader())
      dispatch(openPopper())
      return response
    } catch (err: any) {
      dispatch(closePopper())
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)

export const checkEditFloorActiveBookingsSVG = createAsyncThunk(
  '/checkEditFloorActiveBookingsSVG',
  async (floorData: IEditSVGFloorData, { dispatch, rejectWithValue }) => {
    try {
      dispatch(startLoader())
      const response = await floorServices.checkEditFloorActiveBookingsSVG(floorData)
      if (response && response.data.has_booking) {
        dispatch(stopLoader())
        dispatch(changeValue({ isFloorActiveBookingAlert: true }))
      } else {
        dispatch(stopLoader())
        dispatch(editSVGFloor(floorData))
        return response
      }
    } catch (err: any) {
      dispatch(stopLoader())
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)

export const checkEditFloorActiveBookingsCSV = createAsyncThunk(
  '/checkEditFloorActiveBookings',
  async (floorData: ICheckEditFloorActiveBookingsData, { dispatch, rejectWithValue, getState }) => {
    try {
      const { floor } = getState() as { floor: IFloorState }
      dispatch(startLoader())
      // check if CSV floor has any active bookings
      const response = await floorServices.checkEditFloorActiveBookingsCSV({
        floor_id: floor.floorId,
        seats: floorData.seatsData,
      })
      if (response && response.data.has_booking) {
        dispatch(stopLoader())
        dispatch(changeValue({ isFloorActiveBookingAlert: true }))
      } else {
        dispatch(stopLoader())
        dispatch(
          editCSVFloor({
            floor_id: floor.floorId,
            floor_name: floorData.floor_name,
            seats: floorData.seatsData,
          }),
        )
        return response
      }
    } catch (err: any) {
      dispatch(stopLoader())
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)

export const checkFacilityBookingStatus = createAsyncThunk(
  '/checkFacilityBookingStatus',
  async (params: ICheckFacilityParams, { dispatch, rejectWithValue }) => {
    dispatch(startLoader())
    try {
      const response = await floorServices.checkFacilityBookingStatus(params)
      dispatch(stopLoader())
      return response
    } catch (err: any) {
      dispatch(stopLoader())
      // dispatch(errorToast(handleMessages(err.message)))
      dispatch(closePopper())
      return rejectWithValue(err)
    }
  },
)

export const getEditorHelpers = createAsyncThunk(
  '/editorHelpers',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(startLoader())
    try {
      const response = await floorServices.getEditorHelpers()
      dispatch(stopLoader())
      return response
    } catch (err: any) {
      dispatch(stopLoader())
      return rejectWithValue(err)
    }
  },
)

export const connectFacilityToFloors = createAsyncThunk(
  '/connectFacilityToFloors',
  async (params: IConnectFacilityParams, { dispatch, rejectWithValue, getState }) => {
    const { floor } = getState() as { floor: IFloorState }
    dispatch(startLoader())
    try {
      const response = await floorServices.connectFacilityToFloors(params)
      dispatch(stopLoader())
      dispatch(successToast(handleMessages(response.message)))
      dispatch(getAllFloors(floor.filters))
      dispatch(resetAlertModal())
      dispatch(closePopper())
      return response
    } catch (err: any) {
      dispatch(stopLoader())
      dispatch(errorToast(handleMessages(err.message)))
      return rejectWithValue(err)
    }
  },
)

export const floorSlice = createSlice({
  name: 'floor',
  initialState,
  reducers: {
    changeFilters: (state, action) => {
      return {
        ...state,
        filters: action.payload,
      }
    },
    setMapTypeAndFile: (state, action) => {
      ;(state.parsedFileData = action.payload.parsedFileData),
        (state.floorName = action.payload.floorName)
    },

    resetFilters: (state) => {
      state.filters = initialState.filters
    },
    setSearchSeatFilter: (state, action) => {
      return {
        ...state,
        searchSeat: action.payload,
      }
    },
    reset: (state) => {
      state.floors = initialState.floors
      state.filters = initialState.filters
      state.isLoadingFloorDetails = initialState.isLoadingFloorDetails
      state.BookingsCountDelete = initialState.BookingsCountDelete
      state.floorPlanDrawer = initialState.floorPlanDrawer
    },
    openDeleteFloorModal: (state) => {
      state.deleteModalStatus = true
    },
    closeDeleteFloorModal: (state) => {
      state.deleteModalStatus = false
    },
    changeValue: (state, action) => {
      return {
        ...state,
        ...action.payload,
      }
    },
    resetFloorDrawerDetails: (state) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { filters, floors, editorHelpers, isLoadingFloorDetails, ...rest } = initialState
      return {
        ...state,
        ...rest,
      }
    },
    resetAlertModal: (state) => {
      state.alertModalState = false
    },
    closePopper: (state) => {
      state.popperStatus = false
    },
    openPopper: (state) => {
      state.popperStatus = true
    },
    resetMapFloorsSlots: (state) => {
      state.slotsMap = initialState.slotsMap
      state.floorsOfSelectedFacility = initialState.floorsOfSelectedFacility
      state.mapSeatDetails = initialState.mapSeatDetails
    },
    resetMapSeat: (state) => {
      state.mapSeatDetails.seats = initialState.mapSeatDetails.seats
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllFloors.pending, (state) => {
        state.isLoadingFloorDetails = true
      })
      .addCase(getAllFloors.fulfilled, (state, action) => {
        state.floors = action.payload.data.data
        if (state.floors.current_page === state.floors.last_page && state.floors.last_page !== 1) {
          state.isLastPage = true
        } else {
          state.isLastPage = false
        }
        state.isLoadingFloorDetails = false
      })
      .addCase(getAllFloors.rejected, (state) => {
        state.isLoadingFloorDetails = false
      })
      .addCase(checkActiveBookingsDelete.fulfilled, (state, action) => {
        if (action.payload.data.data.active_booking_count > 0) {
          state.isActiveBookings = true
          state.BookingsCountDelete = action.payload.data.data.active_booking_count
        } else {
          state.isActiveBookings = false
        }
        state.deleteModalStatus = true
      })
      .addCase(checkDuplicateFloorName.fulfilled, (state, action) => {
        state.isDuplicateFloorName = action.payload.data.floor_exists
      })
      .addCase(getFloorDetails.fulfilled, (state, action) => {
        state.floorName = action.payload.data.floor.name
        if (action.payload.data.floor.type === 1) {
          state.parsedFileData = action.payload.data.image
        } else {
          state.parsedFileData = action.payload.data.seats
        }
        state.fileFormat = action.payload.data.floor.type === 1 ? 'svg' : 'csv'
        state.mapFormat = action.payload.data.floor.type === 1 ? 0 : 1
        state.mode = 'edit'
      })
      .addCase(getFacilitiesFloor.fulfilled, (state, action) => {
        state.floorFacilities = action.payload.data.facilities
      })
      .addCase(checkFacilityBookingStatus.fulfilled, (state, action) => {
        state.bookingCount.active = action.payload.data.data.active_booking_count
        state.bookingCount.blocked = action.payload.data.data.temp_seat_booking_count

        if (state.bookingCount.active || state.bookingCount.blocked) {
          state.alertModalState = true
          state.connectFacility = false
        } else {
          state.alertModalState = false
          state.connectFacility = true
        }
      })
      .addCase(connectFacilityToFloors.fulfilled, (state) => {
        state.connectFacility = initialState.connectFacility
      })
      .addCase(getAllFacilitiesMap.fulfilled, (state, action) => {
        state.facilities = action.payload.data.facilities
      })

      .addCase(getFloorsMapFacility.pending, (state) => {
        state.isLoadingFloorsMap = true
      })
      .addCase(getFloorsMapFacility.fulfilled, (state, action) => {
        state.floorsOfSelectedFacility = action.payload.data.data
        state.isLoadingFloorsMap = false
      })
      .addCase(getFloorsMapFacility.rejected, (state) => {
        state.isLoadingFloorsMap = false
      })
      .addCase(getSlotsMapFacility.fulfilled, (state, action) => {
        state.slotsMap = action.payload.data.slots
      })
      .addCase(getSeatDetailsMap.pending, (state) => {
        state.isLoadingSlotMap = true
      })
      .addCase(getSeatDetailsMap.fulfilled, (state, action) => {
        state.mapSeatDetails = action.payload.data.data
        state.isLoadingSlotMap = false
      })
      .addCase(getSeatDetailsMap.rejected, (state) => {
        state.isLoadingSlotMap = false
      })
      .addCase(detectSeats.fulfilled, (state, action) => {
        state.parsedFileData = action.payload.data.image
        state.isSeatDetected = true
      })
      .addCase(getEditorHelpers.fulfilled, (state, action) => {
        state.editorHelpers = action.payload.data
      })
  },
})

export const {
  changeFilters,
  reset,
  setMapTypeAndFile,
  resetFilters,
  changeValue,
  openDeleteFloorModal,
  closeDeleteFloorModal,
  setSearchSeatFilter,
  resetFloorDrawerDetails,
  resetAlertModal,
  closePopper,
  openPopper,
  resetMapFloorsSlots,
  resetMapSeat,
} = floorSlice.actions

export const floorState = (state: RootState) => state.floor
export default floorSlice.reducer
