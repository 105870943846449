import { IIcon } from './iconConstants'
const ArtBoardLineIcon = (props: IIcon) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox='0 0 17 18'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g fill='none' fillRule='evenodd' opacity={props.opacity ? props.opacity : '.5'}>
        <path d='M-3-2h22v22H-3z' />
        <path
          d='M5.276 13.507H0v-1.93h17v1.93h-5.276l3.063 3.129L13.452 18l-4.008-4.094v2.495H7.556v-2.495L3.548 18l-1.335-1.364 3.063-3.129zM1.889 0H15.11c.522 0 .945.432.945.965v9.648H.944V.965C.944.432 1.367 0 1.89 0zm.944 1.93v6.753h11.334V1.93H2.833z'
          fill={props.fill}
          fillRule='nonzero'
        />
      </g>
    </svg>
  )
}
export default ArtBoardLineIcon
