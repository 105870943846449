import { IIcon } from './iconConstants'
const CalenderWithCrossIcon = (props: IIcon) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox='0 0 39 39'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g fill='none' fillRule='evenodd' opacity={props.opacity ? props.opacity : '.565'}>
        <path d='M-3-1h45.6v45.6H-3z' />
        <path
          d='M14.1.9v3.8h11.4V.9h3.8v3.8h7.6c1.05 0 1.9.85 1.9 1.9V37a1.9 1.9 0 0 1-1.9 1.9H2.7A1.9 1.9 0 0 1 .8 37V6.6c0-1.05.85-1.9 1.9-1.9h7.6V.9h3.8zM35 18H4.6v17.1H35V18zM10.3 8.5H4.6v5.7H35V8.5h-5.7v1.9h-3.8V8.5H14.1v1.9h-3.8V8.5z'
          fill={props.fill}
          fillRule='nonzero'
        />
        <path
          d='m21.913 26 4.87-4.891a.63.63 0 0 0 0-.913.63.63 0 0 0-.914 0L21 25.087l-4.89-4.891a.63.63 0 0 0-.913 0 .63.63 0 0 0 0 .913L20.066 26l-4.87 4.891a.63.63 0 0 0 0 .913c.13.13.304.196.456.196a.663.663 0 0 0 .457-.196L21 26.913l4.891 4.891c.13.13.305.196.457.196a.663.663 0 0 0 .456-.196.63.63 0 0 0 0-.913L21.913 26z'
          stroke={props.stoke}
          strokeWidth='2'
          fill={props.fill}
          fillRule='nonzero'
        />
      </g>
    </svg>
  )
}
export default CalenderWithCrossIcon
