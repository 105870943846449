import { lazy } from 'react'
import { Navigate } from 'react-router-dom'
import { PERMISSIONS } from '../_constants'
import AdminLayout from '../layout/adminLayout'
import LazyLoad from './utils/LazyLoad'
import CheckEmailLinkValidity from './utils/checkEmailLinkValidity'
import RequireUserPermissionsAuth from './utils/requireUserPermissionsAuth'
import RequireUserTypeAuth from './utils/requireUserTypeAuth'
const Dashboard = LazyLoad(lazy(() => import('../pages/admin/dashboard')))
const FacilitiesPage = LazyLoad(lazy(() => import('../pages/admin/facilitiesPage')))
const TokenBoard = LazyLoad(lazy(() => import('../pages/admin/tokenBoard')))
const SettingsPage = LazyLoad(lazy(() => import('../pages/admin/settingsPage')))
const BusinessSettingsPage = LazyLoad(lazy(() => import('../pages/admin/businessSettingsPage')))
const BookingManagement = LazyLoad(lazy(() => import('../pages/admin/bookingManagement')))
const FloorPlan = LazyLoad(lazy(() => import('../pages/admin/floorPlan')))
const PermissionsPage = LazyLoad(lazy(() => import('../pages/admin/permissionsPage')))
const BusinessDetailsPage = LazyLoad(lazy(() => import('../pages/admin/businessDetailsPage')))
const ProfileSettingsPage = LazyLoad(lazy(() => import('../pages/admin/profileSettingsPage')))
const ContactUs = LazyLoad(lazy(() => import('../pages/common/contactUs')))
const {
  BUSINESS_BOOKING_VIEW,
  BOOKING_MANAGEMENT_VIEW,
  VIEW_BUSINESS_PROFILE,
  FACILITY_VIEW,
  FLOOR_VIEW,
  TOKEN_BOARD_VIEW,
  USER_MANAGEMENT_BUSINESS_USERS_VIEW,
  USER_MANAGEMENT_ROLES_VIEW,
  VIEW_BUSINESS_SETTINGS,
  SUBSCRIPTION_VIEW,
} = PERMISSIONS

const AdminRoutes = {
  path: '/admin',
  element: (
    <RequireUserTypeAuth authorizedUserType={'business'}>
      <AdminLayout />
    </RequireUserTypeAuth>
  ),

  children: [
    {
      path: '',
      element: <Navigate to='dashboard' />,
    },
    {
      path: 'dashboard',
      element: (
        <RequireUserPermissionsAuth authorizedUserPermissions={[BUSINESS_BOOKING_VIEW]}>
          <Dashboard />
        </RequireUserPermissionsAuth>
      ),
    },
    {
      path: 'booking-management',

      element: (
        <RequireUserPermissionsAuth authorizedUserPermissions={[BOOKING_MANAGEMENT_VIEW]}>
          <BookingManagement />
        </RequireUserPermissionsAuth>
      ),
    },
    {
      path: 'business/:businessName',
      element: (
        // <RequireUserPermissionsAuth authorizedUserPermissions={[]}>
        <CheckEmailLinkValidity>
          <BusinessDetailsPage />
        </CheckEmailLinkValidity>
        // </RequireUserPermissionsAuth>
      ),
    },
    {
      path: 'settings',
      element: (
        <RequireUserPermissionsAuth
          authorizedUserPermissions={[
            VIEW_BUSINESS_SETTINGS,
            SUBSCRIPTION_VIEW,
            FACILITY_VIEW,
            FLOOR_VIEW,
            TOKEN_BOARD_VIEW,
            USER_MANAGEMENT_BUSINESS_USERS_VIEW,
            USER_MANAGEMENT_ROLES_VIEW,
            VIEW_BUSINESS_PROFILE,
          ]}
        >
          <SettingsPage />
        </RequireUserPermissionsAuth>
      ),
      children: [
        {
          path: '',
          element: <Navigate to='profile-settings' />,
        },
        {
          path: 'profile-settings',
          element: (
            <RequireUserPermissionsAuth authorizedUserPermissions={[VIEW_BUSINESS_PROFILE]}>
              <ProfileSettingsPage />
            </RequireUserPermissionsAuth>
          ),
        },
        {
          path: 'business-settings',
          element: (
            <RequireUserPermissionsAuth
              authorizedUserPermissions={[VIEW_BUSINESS_SETTINGS, SUBSCRIPTION_VIEW]}
            >
              <BusinessSettingsPage />
            </RequireUserPermissionsAuth>
          ),
        },
        {
          path: 'facilities',
          element: (
            <RequireUserPermissionsAuth authorizedUserPermissions={[FACILITY_VIEW]}>
              <FacilitiesPage />
            </RequireUserPermissionsAuth>
          ),
        },
        {
          path: 'floor-plan',
          element: (
            <RequireUserPermissionsAuth authorizedUserPermissions={[FLOOR_VIEW]}>
              <FloorPlan />
            </RequireUserPermissionsAuth>
          ),
        },
        {
          path: 'tokenboard',
          element: (
            <RequireUserPermissionsAuth authorizedUserPermissions={[TOKEN_BOARD_VIEW]}>
              <TokenBoard />
            </RequireUserPermissionsAuth>
          ),
        },
        {
          path: 'permissions',
          element: (
            <RequireUserPermissionsAuth
              authorizedUserPermissions={[
                USER_MANAGEMENT_BUSINESS_USERS_VIEW,
                USER_MANAGEMENT_ROLES_VIEW,
              ]}
            >
              <PermissionsPage />
            </RequireUserPermissionsAuth>
          ),
        },
      ],
    },
    {
      path: 'contact-us',
      element: <ContactUs />,
    },
  ],
}
export default AdminRoutes
