// ==============================|| OVERRIDES - OUTLINED INPUT ||============================== //

export default function OutlinedInput(theme: any) {
  return {
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          border: `1px solid ${theme.palette.secondary.light}`,
          borderRadius: '10px',
        },
        root: {
          '&.Mui-disabled': {
            '&:hover .MuiOutlinedInput-notchedOutline': {
              border: `1px solid ${theme.palette.secondary.light}`,
            },
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${theme.palette.common.black}`,
          },
          '&.Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
              border: `1px solid ${theme.palette.common.black}`,
            },
          },
          '&.Mui-error': {
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.error.light,
            },
            '&.Mui-focused': {
              '& .MuiOutlinedInput-notchedOutline': {
                border: `1px solid ${theme.palette.error.light}`,
              },
            },
          },
        },
        input: {
          '&.Mui-disabled': {
            cursor: 'not-allowed',
          },
        },
        textarea: {
          '&.Mui-disabled': {
            cursor: 'not-allowed',
          },
        },
      },
    },
  }
}
