import slider3 from '../../images/customer.png'
import slider2 from '../../images/floorplan.png'
import slider1 from '../../images/slider-ss1.webp'
// import { useAppSelector } from '../../_helpers/hooks'
// const translatedInput = useAppSelector((state) => state?.translation.translatedData)
export const items = [
  {
    image: slider1,
    alt: 'Slider 1',
    content: 'Smarter crowds. Intelligent tokens.',
    maxWidth: '20rem',
  },
  {
    image: slider2,
    alt: 'Slider 2',
    content: 'Effortless floor plan creation. Any source, any format.',
    maxWidth: '32rem',
  },
  {
    image: slider3,
    alt: 'Slider 3',
    content: 'Simplifying bookings. Anywhere. Anytime.',
    maxWidth: '24rem',
  },
]
