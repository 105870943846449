import { Box, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import { useAppSelector } from '../../../_helpers/hooks'
import medical from '../../../images/Doctor_Earphone_Icon_UIA.png'
import medicalWhite from '../../../images/Doctor_Earphone_Icon_UIAwhite.png'
import face from '../../../images/Gender_Face_Comp_UIA.png'
import faceWhite from '../../../images/Gender_Face_Comp_UIAwhite.png'
import Bg from '../../../images/bluebg.png'
import BgWhite from '../../../images/category-cards-white.png'
import BgBlue from '../../../images/category-cards.png'
import microphone from '../../../images/microphone.png'
import microphoneWhite from '../../../images/microphonewhite.png'
import people from '../../../images/people.png'
import peopleWhite from '../../../images/peoplewhite.png'
import sportsWhite from '../../../images/sports-icons-white.png'
import sports from '../../../images/sports-icons.png'
import teacher from '../../../images/teacher.png'
import teacherWhite from '../../../images/teacherwhite.png'
const responsive = {
  large: {
    breakpoint: {
      max: 4000,
      min: 2000,
    },
    items: 5,
    partialVisibilityGutter: 30,
  },
  desktop: {
    breakpoint: {
      max: 2000,
      min: 1650,
    },
    items: 5,
    partialVisibilityGutter: 30,
  },
  laptops: {
    breakpoint: {
      max: 1650,
      min: 1400,
    },
    items: 4,
    partialVisibilityGutter: 10,
  },
  mediumLaptops: {
    breakpoint: {
      max: 1400,
      min: 1360,
    },
    items: 4,
    partialVisibilityGutter: 10,
  },
  md: {
    breakpoint: {
      max: 1360,
      min: 1100,
    },
    items: 3,
    partialVisibilityGutter: 10,
  },
  tablet: {
    breakpoint: {
      max: 1100,
      min: 800,
    },
    items: 2,
    partialVisibilityGutter: 10,
  },
  mobile: {
    breakpoint: {
      max: 800,
      min: 0,
    },
    items: 1,
    partialVisibilityGutter: 0,
  },
  small: {
    breakpoint: {
      max: 550,
      min: 0,
    },
    items: 1,
    partialVisibilityGutter: 0,
  },
}

const CarouselSection: React.FC = () => {
  const theme = useTheme()
  const [activeSlide, setActiveSlide] = useState(0)
  const [centerMode, setCenterMode] = useState(true)
  const translatedInput = useAppSelector((state) => state?.translation.translatedData)
  const itemsData = [
    {
      label: translatedInput?.landingScreen?.perfectSolutionLabel1,
      icon: medical,
      iconActive: medicalWhite,
    },
    {
      label: translatedInput?.landingScreen?.perfectSolutionLabel2,
      icon: people,
      iconActive: peopleWhite,
    },
    {
      label: translatedInput?.landingScreen?.perfectSolutionLabel3,
      icon: sports,
      iconActive: sportsWhite,
    },
    {
      label: translatedInput?.landingScreen?.perfectSolutionLabel4,
      icon: face,
      iconActive: faceWhite,
    },
    {
      label: translatedInput?.landingScreen?.perfectSolutionLabel5,
      icon: teacher,
      iconActive: teacherWhite,
    },
    {
      label: translatedInput?.landingScreen?.perfectSolutionLabel6,
      icon: microphone,
      iconActive: microphoneWhite,
    },
  ]
  const handleBeforeChange = (nextSlide: number) => {
    setActiveSlide(nextSlide)
  }
  const getItemsToShow = () => {
    const width = window.innerWidth
    if (width >= responsive.large.breakpoint.min) {
      return responsive.large.items
    } else if (width >= responsive.desktop.breakpoint.min) {
      return responsive.desktop.items
    } else if (width >= responsive.laptops.breakpoint.min) {
      return responsive.laptops.items
    } else if (width >= responsive.md.breakpoint.min) {
      return responsive.md.items
    } else if (width >= responsive.tablet.breakpoint.min) {
      return responsive.tablet.items
    } else if (width >= responsive.mobile.breakpoint.min) {
      return responsive.mobile.items
    } else {
      return responsive.small.items
    }
  }
  const getCenterIndex = () => {
    const itemsToShow = getItemsToShow()
    if (itemsToShow > 2) {
      return (activeSlide + Math.floor(itemsToShow / 2)) % itemsData.length
    } else if (itemsToShow === 2) {
      return (activeSlide + 2) % itemsData.length
    } else {
      return (activeSlide + 4) % itemsData.length
    }
  }
  const centerIndex = getCenterIndex()
  // Update centerIndex when window is resized
  useEffect(() => {
    const handleResize = () => {
      setActiveSlide((prev) => prev) // Trigger re-calculation of centerIndex
      setCenterMode(window.innerWidth > responsive.small.breakpoint.max)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <Box
      sx={{
        margin: '0 auto',
        maxWidth: '118.75rem',
        minHeight: '37.5rem',
      }}
    >
      <Box
        mt={{ xs: 0, md: 5, lg: 10 }}
        pb={5}
        sx={{
          height: '75vh',
          backgroundImage: `url(${Bg})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          [theme.breakpoints.up(2000)]: {
            height: '100%',
            mt: 20,
          },
          [theme.breakpoints.up(3000)]: {
            height: '100%',
            mt: 5,
          },
        }}
        pt={10}
      >
        <Box mb={3}>
          <Typography
            variant='h2'
            sx={{ mb: 1, color: 'publicPages.light' }}
            fontSize={{ xs: '2rem', md: '2.5rem' }}
            lineHeight={{ xs: '3rem', md: '3.5rem' }}
            fontWeight={600}
          >
            {translatedInput?.landingScreen?.perfectSolutionText} <br />{' '}
            {translatedInput?.landingScreen?.forText}
            <span style={{ color: theme.palette.publicPages.contrastText }}>
              {' '}
              {translatedInput?.landingScreen?.serviceBusinessesText}
            </span>
          </Typography>
          <Typography
            variant='h5'
            sx={{ color: 'publicPages.main' }}
            fontSize={{ xs: '.8rem', md: '1rem' }}
            lineHeight={{ xs: '1.5rem', md: '1.5rem' }}
            fontWeight={'normal'}
            mt={2}
          >
            {translatedInput?.landingScreen?.perfectSolutionText1}
          </Typography>
        </Box>
        <Box
          sx={{
            [theme.breakpoints.down(550)]: {
              ml: 12,
            },
            [theme.breakpoints.down(400)]: {
              ml: 5,
            },
            [theme.breakpoints.down(300)]: {
              ml: 2,
            },
          }}
        >
          <Carousel
            additionalTransfrom={0}
            arrows={false}
            autoPlaySpeed={3000}
            centerMode={centerMode}
            autoPlay
            className=''
            containerClass='carousel-container'
            dotListClass=''
            draggable
            focusOnSelect={false}
            infinite={true}
            keyBoardControl
            renderArrowsWhenDisabled={false}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            responsive={responsive}
            rtl={false}
            shouldResetAutoplay
            showDots={false}
            sliderClass=''
            slidesToSlide={1}
            swipeable
            beforeChange={handleBeforeChange}
            pauseOnHover={true}
          >
            {itemsData.map((item, index) => (
              <Box
                key={index}
                sx={{
                  width: '16.25rem',
                  height: '16.25rem',
                  margin: '0 0 0 1.25rem',
                  padding: '1.75rem',
                  borderRadius: centerIndex === index ? '1.75rem' : '1.25rem',
                  backgroundImage: centerIndex === index ? `url(${BgBlue})` : `url(${BgWhite})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  transition: 'background-image 0.5s ease-in-out',
                  textAlign: 'left',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  overflow: 'hidden',
                  boxShadow:
                    centerIndex === index
                      ? '0 0.375rem 0.8125rem 0 rgba(0, 0, 0, 0.04), 0.0625rem 1.5rem 1.5rem 0 rgba(0, 0, 0, 0.09), 0.1875rem 1.5rem 2rem 0 rgba(0, 0, 0, 0.05), 0.375rem 1.25rem 2.375rem 0 rgba(0, 0, 0, 0.01), 0.5625rem 0.875rem 2.625rem 0 rgba(0, 0, 0, 0)'
                      : 'none',
                  [theme.breakpoints.down(550)]: {
                    width: '60vw',
                  },
                }}
              >
                <Box
                  component='img'
                  src={centerIndex === index ? item.iconActive : item.icon}
                  width={52}
                />
                <Typography
                  variant='h5'
                  sx={{
                    color: centerIndex === index ? 'common.white' : 'publicPages.colorOne',
                    transition: 'color 0.5s ease-in-out',
                  }}
                  lineHeight={'1.563rem'}
                  fontSize={'1.375rem'}
                  fontWeight={'normal'}
                  mt={'auto'}
                >
                  {item.label}
                </Typography>
              </Box>
            ))}
          </Carousel>
        </Box>
      </Box>
    </Box>
  )
}

export default CarouselSection
