import { IIcon } from './iconConstants'
const MailEnvelopeWithTickIcon = (props: IIcon) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox='0 0 54 54'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g fill='none' fillRule='evenodd'>
        <g stroke={props.stoke} strokeLinecap='round' strokeLinejoin='round' strokeWidth='2.4'>
          <path d='m2 19.308 25 15.384 25-15.384' />
          <path d='M9.692 24.115V5.846A3.857 3.857 0 0 1 13.538 2h26.924a3.857 3.857 0 0 1 3.846 3.846v18.27' />
          <path d='M44.308 9.692c4.23 0 7.692 3.462 7.692 7.693v26.923C52 48.538 48.538 52 44.308 52H9.692C5.462 52 2 48.538 2 44.308V17.385c0-4.231 3.462-7.693 7.692-7.693' />
        </g>
        <g fillRule='nonzero'>
          <path
            d='M27 26c-4.965 0-9-4.035-9-9s4.035-9 9-9 9 4.035 9 9-4.035 9-9 9z'
            fill={props.fill}
          />
          <path
            d='m30.965 16.001-4.019 3.995a1.004 1.004 0 0 1-.708.295c-.256 0-.512-.1-.708-.295L23.521 18a.997.997 0 0 1 0-1.413 1.01 1.01 0 0 1 1.422 0l1.3 1.293 3.31-3.29a.989.989 0 0 1 1.412 0 .996.996 0 0 1 0 1.412z'
            fill={props.backgroundColor}
          />
        </g>
      </g>
    </svg>
  )
}
export default MailEnvelopeWithTickIcon
