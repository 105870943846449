import { useTheme } from '@mui/material'
import Drawer from '@mui/material/Drawer'
import { FC, ReactNode } from 'react'

interface ICustomDrawer {
  drawerStatus: boolean
  children: ReactNode
  sx?: Record<string, unknown>
  drawerPaperProps?: Record<string, unknown>
  handleClose: () => void
  handleScrollCapture?: () => void
}

const CustomDrawer: FC<ICustomDrawer> = (props) => {
  const { drawerStatus, children, sx, drawerPaperProps, handleClose, handleScrollCapture } = props
  const theme = useTheme()
  const defaultDrawerStyles = {
    zIndex: 1,
    '& .MuiDrawer-paper': {
      width: { xs: '100%', sm: '100%', md: '59.938rem', xl: '50%' },
      pt: '4.75rem',
      [theme.breakpoints.up(1536)]: {
        width: '60%',
      },
      [theme.breakpoints.up(1670)]: {
        width: '50%',
      },
    },
  }

  return (
    <>
      <Drawer
        anchor={'right'}
        open={drawerStatus}
        onClose={handleClose}
        onScrollCapture={handleScrollCapture}
        sx={{ ...defaultDrawerStyles, ...sx }}
        componentsProps={{
          backdrop: { style: { backgroundColor: 'rgba(255,255,255, 0.6)' } },
        }}
        PaperProps={{ ...drawerPaperProps }}
      >
        {children}
      </Drawer>
    </>
  )
}

export default CustomDrawer
