/* eslint-disable camelcase */
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Paper,
  Tab,
  Tabs,
  TextField,
  Typography,
  styled,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { CountryData } from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../_helpers/hooks'

import PhoneNumberInput from '../../../components/phoneInput'
import CopyRight from '../../../features/copyRight/CopyRight'
import PasswordInfo from '../../../features/passwordValidationInfo'
import Logo from '../../../images/logo.svg'
import { authenticationState, signUp } from '../../../slices/authenticationSlice'
import './Phone-Input.scss'

const StyledTab = styled(Tabs)(({ theme }) => ({
  '& .MuiTab-root': {
    backgroundColor: 'label.main',
  },
  '& .Mui-selected': {
    backgroundColor: `${theme.palette.common.white} !important`,
    color: `${theme.palette.common.black} !important`,
    borderRadius: '0.5rem !important',
    boxShadow: '0 0.125rem 0.313rem 0 rgba(0, 0, 0, 0.07)',
  },
}))

const SignUpPage = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const translatedInput = useAppSelector((state) => state?.translation.translatedData)
  const [showPassword, setShowPassword] = useState(false)
  const [countryData, setCountryData] = useState<CountryData | Record<string, never>>()
  const locationState = useLocation().state as LocationState
  const [viewSwitchTabValue, setViewSwitchTabValue] = useState<number>(
    locationState?.userType === 'BUSINESS' ? 1 : 0,
  )
  interface LocationState {
    userType: string
  }

  const [userType, setUserType] = useState(locationState?.userType || 'CUSTOMER')
  const userTypeLabel = () =>
    userType === 'BUSINESS' ? translatedInput?.common?.your : translatedInput?.signUpPage?.full

  type FormInputs = {
    name: string
    business_name?: string
    email: string
    phone: string
    password: string
  }

  const handleClickShowPassword = () => {
    setShowPassword((prevValue) => !prevValue)
  }
  const {
    handleSubmit,
    control,
    formState: { isSubmitted },
    setError,
  } = useForm<FormInputs>({
    mode: 'onChange',
  })

  const onSubmit: SubmitHandler<FormInputs> = (data) => {
    if (countryData?.dialCode !== undefined) {
      const modifiedSignUpFormData = {
        ...data,
        phone: data.phone.replace(countryData.dialCode, ''),
        country_code: countryData.dialCode,
        iso_code: countryData?.countryCode,
        role_id: userType,
      }
      dispatch(signUp(modifiedSignUpFormData))
    }
  }

  const handleChange = (data: CountryData | Record<string, never>) => {
    setCountryData(data)
  }

  const { signUpFormErrors } = useAppSelector(authenticationState)
  useEffect(() => {
    if (signUpFormErrors.message) {
      for (const i in signUpFormErrors.message) {
        setError(i as 'name' | 'business_name' | 'email' | 'password' | 'phone', {
          message: signUpFormErrors.message[i][0],
        })
      }
    }
  }, [setError, signUpFormErrors])
  const handleViewSwitchTabValueChange = (event: React.SyntheticEvent, newValue: number) => {
    setViewSwitchTabValue(newValue)
    if (newValue === 0) {
      setUserType('CUSTOMER')
    } else {
      setUserType('BUSINESS')
    }
  }
  function a11yProps(index: number) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    }
  }

  return (
    <Grid item xs={12} sm={6} md={5} component={Paper} elevation={0} square>
      <Grid
        container
        direction='column'
        alignItems='center'
        height='100%'
        justifyContent='center'
        sx={{
          mx: { xs: 'auto' },
          p: { xs: 4, sm: 4, md: 5, lg: 10 },
          maxWidth: '90%',
        }}
      >
        <Grid item sx={{ flexGrow: 1 }}>
          <Grid
            container
            direction='column'
            alignItems='center'
            height='100%'
            justifyContent='center'
          >
            <Grid item>
              <Box
                component='img'
                sx={{
                  height: 33,
                  marginBottom: { xs: 5, md: 4 },
                  cursor: 'pointer',
                }}
                alt='Logo'
                src={Logo}
                onClick={() => navigate('/')}
              />
              <Typography variant='h2' sx={{ mb: 2 }}>
                {translatedInput?.login?.signUp}
              </Typography>
              <StyledTab
                value={viewSwitchTabValue}
                onChange={handleViewSwitchTabValueChange}
                aria-label='full width tabs example'
                sx={{
                  padding: '0.313rem',
                  borderRadius: '0.75rem',
                  backgroundColor: 'label.main',
                  width: '100%',

                  maxHeight: 50,

                  mt: 1,
                  pl: 1,
                }}
              >
                <Tab
                  sx={{
                    width: '50%',
                  }}
                  label={'Personal'}
                  {...a11yProps(0)}
                />
                <Tab
                  sx={{
                    width: '50%',
                  }}
                  label={'Business'}
                  {...a11yProps(1)}
                />
              </StyledTab>
              <Box component={'span'} color='secondary.main'>
                <Typography variant='subtitle1' sx={{ mt: 2 }}>
                  {`${translatedInput?.signUpPage?.signUpText1} ${userType.toLowerCase()} ${
                    translatedInput?.signUpPage?.signUpText2
                  }!`}
                </Typography>
              </Box>

              <form noValidate onSubmit={handleSubmit(onSubmit)}>
                <Box sx={{ mt: 1 }}>
                  {userType === 'BUSINESS' && (
                    <Controller
                      name='business_name'
                      control={control}
                      defaultValue=''
                      rules={{
                        required: `${translatedInput?.common?.businessTxt} ${translatedInput?.signUpPage?.nameRequired}`,
                        minLength: {
                          value: 1,
                          message: `${translatedInput?.common?.businessNameMinMaxLengthError} `,
                        },
                        maxLength: {
                          value: 50,
                          message: `${translatedInput?.common?.businessNameMinMaxLengthError} `,
                        },
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          margin='dense'
                          fullWidth
                          label={`${translatedInput?.businessDetails?.businessDetailsBusinessNameLabel}*`}
                          error={isSubmitted && !!error}
                          helperText={isSubmitted && error ? error.message : null}
                        />
                      )}
                    />
                  )}
                  <Controller
                    name='name'
                    control={control}
                    defaultValue=''
                    rules={{
                      required: `${userTypeLabel()} ${translatedInput?.signUpPage?.nameRequired}`,
                      minLength: {
                        value: 1,
                        message: `${userTypeLabel()} ${translatedInput?.signUpPage?.nameMinMax}`,
                      },
                      maxLength: {
                        value: 50,
                        message: `${userTypeLabel()} ${translatedInput?.signUpPage?.nameMinMax}`,
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        margin='normal'
                        fullWidth
                        // label={userType === 'BUSINESS' ? 'Your Name' : 'Full Name'}
                        label={`${userTypeLabel()} ${translatedInput?.common?.name}*`}
                        error={isSubmitted && !!error}
                        helperText={isSubmitted && error ? error.message : null}
                      />
                    )}
                  />

                  <Box sx={{ mt: 1.5 }}>
                    <Controller
                      name='phone'
                      control={control}
                      defaultValue=''
                      rules={{
                        required: translatedInput?.common?.phoneNumberRequired,
                        pattern: {
                          value: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4,9}$/,
                          message: `${translatedInput?.common?.phoneErrorMsg}`,
                        },
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <Box component='div' className={error && isSubmitted ? 'error' : ''}>
                          <PhoneNumberInput
                            style={{ width: '100%' }}
                            phoneField={{
                              name: field.name,
                              value: field.value,
                              onBlur: field.onBlur,
                              isValid: !(isSubmitted && error?.message),
                              onChange: (value, data) => {
                                field.onChange(value)
                                handleChange(data)
                              },
                            }}
                            isMandatory={true}
                          />
                          {isSubmitted && error?.message ? (
                            <Typography variant='caption' color='error.main' sx={{ ml: 1.5 }}>
                              {error.message}
                            </Typography>
                          ) : null}
                        </Box>
                      )}
                    />
                  </Box>
                  <Controller
                    name='email'
                    control={control}
                    defaultValue=''
                    rules={{
                      required: translatedInput?.common?.emailAddressRequired,
                      maxLength: {
                        value: 64,
                        message: translatedInput?.common?.emailMaxLengthError,
                      },
                      pattern: {
                        value: /^[a-zA-Z0-9._+]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/,
                        // production_value_: /^[a-zA-Z0-9._]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/
                        // value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: translatedInput?.common?.emailValid,
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        margin='normal'
                        fullWidth
                        label={`${translatedInput?.common?.emailPlaceholder}*`}
                        error={isSubmitted && !!error}
                        helperText={isSubmitted && error ? error.message : null}
                      />
                    )}
                  />

                  <Controller
                    name='password'
                    control={control}
                    defaultValue=''
                    rules={{
                      required: translatedInput?.common?.passwordRequired,
                      pattern: {
                        value: /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                        message: translatedInput?.common?.validPasswordRequired,
                      },
                      minLength: {
                        value: 8,
                        message: translatedInput?.common?.passwordMinCharacterError,
                      },
                      maxLength: {
                        value: 16,
                        message: translatedInput?.common?.passwordMaxCharacterError,
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        margin='dense'
                        fullWidth
                        label={`${translatedInput?.common?.passwordLabel}*`}
                        type={!showPassword ? 'password' : 'text'}
                        error={isSubmitted && !!error}
                        helperText={isSubmitted && error ? error.message : null}
                        InputProps={{
                          endAdornment: (
                            <>
                              <InputAdornment position='end'>
                                <IconButton
                                  aria-label='toggle password visibility'
                                  onClick={handleClickShowPassword}
                                  edge='end'
                                  sx={{ color: 'secondary.light', mr: '2px' }}
                                >
                                  {showPassword ? (
                                    <VisibilityOutlined />
                                  ) : (
                                    <VisibilityOffOutlined />
                                  )}
                                </IconButton>
                              </InputAdornment>
                              <InputAdornment position='start'>
                                {field.value && !error ? (
                                  <IconButton>
                                    <CheckCircleOutlineIcon fontSize='small' color='success' />
                                  </IconButton>
                                ) : (
                                  <PasswordInfo />
                                )}
                              </InputAdornment>
                            </>
                          ),
                        }}
                      />
                    )}
                  />

                  <Grid container>
                    <Grid
                      container
                      item
                      sx={{ justifyContent: 'center', textAlign: 'center', mt: 3 }}
                    >
                      <Typography variant='subtitle2'>
                        {translatedInput?.registration?.registrationTermsCondition} &nbsp;
                        <Link underline='hover' onClick={() => navigate('/termsofservice')}>
                          {translatedInput?.registration?.registrationTermsCondition2}&nbsp;
                        </Link>
                        {translatedInput?.common?.and}&nbsp;
                        <Link underline='hover' onClick={() => navigate('/privacy-policy')}>
                          {translatedInput?.privacyPolicyPage?.privacyPolicyTitle}
                        </Link>
                      </Typography>
                    </Grid>
                  </Grid>
                  <Button
                    type='submit'
                    fullWidth
                    variant='contained'
                    sx={{ mt: 3, mb: 2 }}
                    size='large'
                  >
                    <Typography variant='h5'>
                      {' '}
                      {translatedInput?.registration?.signupBtn}
                    </Typography>
                  </Button>
                  <Grid container>
                    <Grid container item sx={{ justifyContent: 'center', textAlign: 'center' }}>
                      <Typography variant='body2'>
                        {translatedInput?.registration?.registrationPageLoginLinkText}
                        &nbsp;
                        <Link variant='body2' underline='hover' onClick={() => navigate('/login')}>
                          {translatedInput?.loginPage?.signInTitle}
                        </Link>
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </form>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <CopyRight sx={{ mb: 2 }} />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default SignUpPage
