import { IIcon } from './iconConstants'
const StarWithCloseIcon = ({ width, height, stoke, fill }: IIcon) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 46 46'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g
        clipPath='url(#0rko2sb9pa)'
        stroke={stoke}
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      >
        <path d='m17.647 36.808-.76.4-6.076 3.195 2.32-13.53L3.3 17.297l13.588-1.987L22.964 3l6.076 12.31 13.589 1.987-4.916 4.788-.615.598' />
        <path d='M30.889 43.247a8.926 8.926 0 1 0 0-17.851 8.926 8.926 0 0 0 0 17.851zM34.01 31.197l-6.248 6.248M27.762 31.197l6.248 6.248' />
      </g>
      <defs>
        <clipPath id='0rko2sb9pa'>
          <path fill={fill} d='M0 0h46v46H0z' />
        </clipPath>
      </defs>
    </svg>
  )
}
export default StarWithCloseIcon
