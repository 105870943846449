import { Alert, AlertColor, Box, Snackbar, Stack, Typography, useTheme } from '@mui/material'
import Slide, { SlideProps } from '@mui/material/Slide'
import { useLayoutEffect, useState } from 'react'

import { useAppDispatch, useAppSelector } from '../../_helpers/hooks'
import { InfoIcon, SuccessIcon, WarningIcon } from '../../components/icons'
import { hideToast, toastData } from '../../slices/toastSlice'

const SlideTransition = (props: SlideProps) => (
  <Slide {...props} direction='down' mountOnEnter unmountOnExit />
)

const ToastAlert = () => {
  const toastState = useAppSelector(toastData)
  const dispatch = useAppDispatch()
  const { toastMessage, toastType, showToast } = toastState
  const [alertVariant, setAlertVariant] = useState<AlertColor>()
  const [icon, setIcon] = useState<JSX.Element>()
  const theme = useTheme()
  useLayoutEffect(() => {
    if (toastType == 'success') {
      setAlertVariant('success')
      setIcon(
        <SuccessIcon
          fill={theme.palette.success.light}
          width='25'
          height='25'
          stoke={theme.palette.common.white}
        />,
      )
    }
    if (toastType == 'error') {
      setAlertVariant('error')
      setIcon(
        <WarningIcon
          fill={theme.palette.error.dark}
          width='25'
          height='25'
          stoke={theme.palette.common.white}
        />,
      )
    }
    if (toastType == 'warning') {
      setAlertVariant('warning')
      setIcon(
        <WarningIcon
          fill={theme.palette.iconColors.contrastText}
          width='25'
          height='25'
          stoke={theme.palette.common.white}
        />,
      )
    }
    if (toastType == 'information') {
      setAlertVariant('info')
      setIcon(<InfoIcon fill={theme.palette.alertBackdrop.contrastText} width='25' height='25' />)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toastType])

  const removeToast = () => {
    dispatch(hideToast())
  }

  return (
    <Snackbar
      open={showToast}
      onClose={removeToast}
      autoHideDuration={6000}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      TransitionComponent={SlideTransition}
    >
      <Alert
        onClose={removeToast}
        severity={alertVariant}
        variant='standard'
        color={alertVariant}
        sx={{
          width: '100%',
          maxWidth: '35rem',
          minWidth: { xs: '10rem', sm: '28rem' },
          position: 'absolute',
          top: '5rem',
          // right: { xs: '0rem', sm: '3rem' },
        }}
        icon={false}
        // icon={<SuccessIcon/>}
      >
        <Stack display='flex' alignItems='center' spacing={2} direction='row'>
          <Box pt={1}>{icon}</Box>

          <Box ml={1}>
            {toastMessage &&
              toastMessage.map((item: string) => (
                <Typography variant='subtitle1' textAlign='center' key={item}>
                  {item}
                </Typography>
              ))}
          </Box>
        </Stack>
      </Alert>
    </Snackbar>
  )
}

export default ToastAlert
