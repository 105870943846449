import { TableCell, tableCellClasses } from '@mui/material'
import { styled } from '@mui/material/styles'
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.table.main,
    color: 'table.light',
    borderBottom: 'none',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    paddingTop: 10,
    paddingBottom: 10,
    fontFamily: 'Lexend',
    fontSize: '0.875rem',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: '0.875rem',
  },
}))

export { StyledTableCell }
