import { IIcon } from './iconConstants'
const MapViewIcon = (props: IIcon) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox='0 0 18 18'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0 2.7 6.3 0l5.4 2.7L17.373.27A.45.45 0 0 1 18 .682V15.3L11.7 18l-5.4-2.7-5.673 2.43A.45.45 0 0 1 0 17.318V2.7zm12.6 12.956 3.6-1.543V2.73l-3.6 1.542v11.384zm-1.8-.118V4.262l-3.6-1.8v11.276l3.6 1.8zm-5.4-1.81V2.345L1.8 3.886V15.27l3.6-1.542z'
        fill={props.fill}
        fillRule='nonzero'
        opacity={props.opacity ? props.opacity : 1}
      />
    </svg>
  )
}
export default MapViewIcon
