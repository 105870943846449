import MailOutlineIcon from '@mui/icons-material/MailOutline'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import {
  Autocomplete,
  Box,
  CircularProgress,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import { CountryData } from 'react-phone-input-2'
import { useAppDispatch, useAppSelector } from '../../_helpers/hooks'
import { IFacility } from '../../_models/facilityInterface'
import {
  IGuestUserDetails,
  INewBookingLocations,
  IPhoneDetails,
} from '../../_models/newBookingInterface'
import { BuildingIcon, LocationIcon } from '../../components/icons'
import PhoneNumberInput from '../../components/phoneInput'
import useDebounce from '../../hooks/useDebounce'
import { facilityState, resetFacilityDropdown } from '../../slices/facilitySlice'
import {
  getPhoneNumber,
  newBookingData,
  validatePhoneNumberForGuestUser,
} from '../../slices/newBookingSlice'
import { COMMON } from '../../_constants'

type FormInputs = {
  email: string
  name: string
  phone: string
  location: INewBookingLocations | null
  facility: IFacility | null
}
interface IFormFields {
  form: UseFormReturn<FormInputs>
  isSubmitted: boolean
  onLocationChange: (locationId: number) => void
  onFacilityChange: () => void
  updatePhoneDetails: (countryCodeData: string, phoneNumberData: string, isoCode: string) => void
  isExpanded: boolean
  isBookForOthers: boolean
  setNumberUpdated: React.Dispatch<React.SetStateAction<boolean>>
  phoneDetails: IPhoneDetails | undefined
  setShowErrorData: React.Dispatch<React.SetStateAction<string>>
  showErrorData: string
  setPhoneDetails: React.Dispatch<React.SetStateAction<IPhoneDetails | undefined>>
}
const FormFields = (props: IFormFields) => {
  const {
    form,
    isSubmitted,
    isBookForOthers,
    onFacilityChange,
    onLocationChange,
    isExpanded,
    updatePhoneDetails,
    setNumberUpdated,
    phoneDetails,
    setShowErrorData,
    showErrorData,
    setPhoneDetails,
  } = props
  const {
    control,
    formState: { errors },
    getValues,
    setValue,
    // clearErrors,
    // setError,
  } = form
  const newBookingState = useAppSelector(newBookingData)
  const {
    drawerType,
    newBookingLocationInformation,
    isLoadingFacilities,
    isLoadingLocations,
    phoneNumberInformation,
    isRegisteredMail,
    bookingDetails,
    isDifferentPhoneNumber,
    isDuplicatePhoneNumber,
    phoneResponseLoading,
  } = newBookingState
  const facilityData = useAppSelector(facilityState)

  const { bookingFacilities } = facilityData
  const translatedInput = useAppSelector((state) => state?.translation.translatedData)
  const dispatch = useAppDispatch()
  const facilityMap: IFacility[] | [] = bookingFacilities?.data
  const theme = useTheme()
  const debouncedValue = useDebounce<string | number | undefined>(getValues('phone'), 500)
  const [isoCode, setIsoCode] = useState<string>(
    phoneDetails?.isoCode ? phoneDetails.isoCode : COMMON.DEFAULT_ISO_CODE,
  )
  useEffect(() => {
    if (drawerType === 'create') {
      if (isRegisteredMail) {
        const phoneData = phoneNumberInformation?.country_code + phoneNumberInformation?.phone
        setValue('phone', phoneData ? phoneData : '1', { shouldValidate: true })
        setValue('name', phoneNumberInformation?.name, { shouldValidate: true })
        setShowErrorData('')
        setIsoCode(phoneNumberInformation?.iso_code)
      } else {
        setValue('phone', '1')
        setValue('name', '')
        setIsoCode(COMMON.DEFAULT_ISO_CODE)
      }
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneNumberInformation, isRegisteredMail])

  useEffect(() => {
    if (!phoneResponseLoading) {
      if (phoneNumberInformation?.phone) {
        setShowErrorData('')
        setNumberUpdated(false)
        setPhoneDetails(undefined)
      } else {
        validatePhoneNumber()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneResponseLoading, phoneNumberInformation?.phone])

  const handleEmailId = () => {
    if (getValues('email') && !errors?.email) {
      dispatch(getPhoneNumber(getValues('email')))
    }
  }
  const handlePhone = (data: CountryData | Record<string, never>, value: string) => {
    const currentPhone: string = value.replace(data.dialCode, '')
    const currentCountryCode: string = data.dialCode
    const isoCode: string = data.countryCode
    if (currentPhone && currentCountryCode && isoCode) {
      updatePhoneDetails(currentCountryCode, currentPhone, isoCode)
    }
  }
  const validatePhoneNumber = () => {
    if (phoneDetails && getValues('phone') && getValues('phone') !== '1') {
      const guestUserDetails: IGuestUserDetails = {
        // eslint-disable-next-line camelcase
        country_code: Number(phoneDetails?.countryCode),
        phone: phoneDetails?.phoneNumber.toString(),
        email: getValues('email'),
        // eslint-disable-next-line camelcase
        iso_code: phoneDetails?.isoCode,
      }
      dispatch(validatePhoneNumberForGuestUser(guestUserDetails))
    }
  }
  useEffect(() => {
    if (isDifferentPhoneNumber) {
      setShowErrorData(translatedInput?.common?.emailAddressRegisteredWithDiffPhoneAlert)
    }

    if (isDuplicatePhoneNumber) {
      setShowErrorData(translatedInput?.common?.phoneAlreadyUsedAlert)
    }

    if (!isDifferentPhoneNumber && !isDuplicatePhoneNumber) {
      setShowErrorData('')
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDifferentPhoneNumber, isDuplicatePhoneNumber])
  useEffect(() => {
    if (errors?.phone) {
      setShowErrorData('')
    } else validatePhoneNumber()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue])

  const handleLocationSelect = (locationId: number | undefined) => {
    if (locationId) onLocationChange(locationId)
    setValue('facility', null, { shouldValidate: true })
    dispatch(resetFacilityDropdown())
  }
  const handleFacilitySelect = () => {
    onFacilityChange()
  }

  return (
    <form>
      {!isBookForOthers && (
        <>
          <Grid
            container
            columnSpacing={'1.25rem'}
            width={isExpanded ? { xs: '100%', md: '50%' } : '100%'}
          >
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} mt={2}>
              <Controller
                name='email'
                control={control}
                defaultValue=''
                rules={{
                  required: translatedInput?.common?.emailAddressRequired,
                  maxLength: {
                    value: 64,
                    message: translatedInput?.common?.emailMaxLengthError,
                  },
                  pattern: {
                    value: /^[a-zA-Z0-9._+]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/,
                    message: translatedInput?.common?.emailValid,
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    disabled={drawerType === 'edit' ? true : false}
                    {...field}
                    id='outlined-basic'
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <MailOutlineIcon />
                        </InputAdornment>
                      ),
                    }}
                    label={`${translatedInput?.common?.emailLabel}*`}
                    variant='outlined'
                    sx={{ width: '100%', mt: '1rem' }}
                    onBlur={handleEmailId}
                    error={isSubmitted && !!error}
                    helperText={(isSubmitted || errors?.email) && error ? error.message : null}
                  />
                )}
              />
            </Grid>
            {bookingDetails?.is_random_phone || phoneNumberInformation?.user_is_random_phone ? (
              <></>
            ) : (
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6} mt={2}>
                <Controller
                  name='name'
                  control={control}
                  defaultValue=''
                  rules={{
                    required: translatedInput?.common?.nameIsRequired,
                    minLength: {
                      value: 1,
                      message: translatedInput?.common?.nameLengthMinMax,
                    },
                    maxLength: {
                      value: 50,
                      message: translatedInput?.common?.nameLengthMinMax,
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      disabled={drawerType === 'edit' || isRegisteredMail ? true : false}
                      {...field}
                      margin='normal'
                      id='outlined-basic'
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <PersonOutlineIcon />
                          </InputAdornment>
                        ),
                      }}
                      label={`${translatedInput?.common?.name}*`}
                      variant='outlined'
                      sx={{ width: '100%' }}
                      error={isSubmitted && !!error}
                      helperText={(isSubmitted || errors?.name) && error ? error.message : null}
                    />
                  )}
                />
              </Grid>
            )}
          </Grid>
          <Grid
            container
            columnSpacing={'1.25rem'}
            width={isExpanded ? { xs: '100%', md: '50%' } : '100%'}
          >
            {bookingDetails?.is_random_phone || phoneNumberInformation?.user_is_random_phone ? (
              <></>
            ) : (
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6} mt={4}>
                <Controller
                  name='phone'
                  control={control}
                  rules={{
                    required: translatedInput?.common?.phoneNumberRequired,
                    pattern: {
                      value: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4,9}$/,
                      message: translatedInput?.common?.phoneErrorMsg,
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <Box
                      component='div'
                      className={isSubmitted && (showErrorData || error) ? 'error' : ''}
                    >
                      <PhoneNumberInput
                        style={{ width: '100%' }}
                        phoneField={{
                          name: field.name,
                          value: field.value ? field.value : '',
                          onBlur: () => {
                            field.onBlur()
                            if (!showErrorData) {
                              validatePhoneNumber()
                            }
                          },
                          isValid: !(isSubmitted && (showErrorData || error?.message)),
                          onChange: (value, data) => {
                            field.onChange(value)
                            handlePhone(data, value)
                          },
                          disabled: drawerType === 'edit' || isRegisteredMail ? true : false,
                        }}
                        isMandatory={true}
                        isoCode={isoCode}
                      />
                      {(isSubmitted && error?.message) || showErrorData ? (
                        <Typography variant='caption' color='error.main' sx={{ ml: 1.5 }}>
                          {error?.message || showErrorData}
                        </Typography>
                      ) : null}
                    </Box>
                  )}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} mt={2} />
          </Grid>
        </>
      )}

      <Grid
        container
        columnSpacing={'1.25rem'}
        width={isExpanded ? { xs: '100%', md: '50%' } : '100%'}
      >
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} mt={4}>
          <Controller
            name='location'
            control={control}
            // defaultValue={null}
            rules={{
              required: translatedInput?.basicInfo?.basicInfoLocationRequired,
            }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Autocomplete
                id='combo-box-demo'
                key={value?.id}
                value={value}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                getOptionLabel={(option) => option.name}
                options={newBookingLocationInformation}
                clearOnBlur
                loading={isLoadingLocations}
                onChange={(
                  event: React.SyntheticEvent<Element, Event>,
                  value: INewBookingLocations | null,
                ) => {
                  onChange(value)
                  handleLocationSelect(value?.id)
                }}
                renderOption={(props, option) => (
                  <li {...props} key={option?.id} style={{ wordBreak: 'break-all' }}>
                    {option?.name}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={`${translatedInput?.common?.locationsLabel}*`}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position='start'>
                          <LocationIcon
                            fill={theme.palette.toggleButtonGroup.contrastText}
                            width='16'
                            height='19'
                          />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <>
                          {isLoadingLocations ? (
                            <CircularProgress color='inherit' size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                    error={isSubmitted && !!error}
                    helperText={isSubmitted && error ? error.message : null}
                  />
                )}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} mt={4}>
          <Controller
            name='facility'
            control={control}
            // defaultValue={null}
            rules={{
              required: translatedInput?.newBooking?.facilityRequired,
            }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Autocomplete
                id='combo-box-demo'
                onChange={(
                  event: React.SyntheticEvent<Element, Event>,
                  value: IFacility | null,
                ) => {
                  handleFacilitySelect()
                  onChange(value)
                }}
                value={value}
                key={value?.id_hash}
                isOptionEqualToValue={(option, value) => option?.id_hash === value?.id_hash}
                getOptionLabel={(option) => option?.name}
                options={facilityMap}
                clearOnBlur
                loading={isLoadingFacilities}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={translatedInput?.common?.selectFacilityLabel}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position='start'>
                          <BuildingIcon
                            fill={theme.palette.toggleButtonGroup.contrastText}
                            width='17'
                            height='17'
                            opacity='.99'
                          />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <>
                          {isLoadingFacilities ? (
                            <CircularProgress color='inherit' size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                    error={isSubmitted && !!error}
                    helperText={isSubmitted && error ? error.message : null}
                  />
                )}
              />
            )}
          />
        </Grid>
      </Grid>
    </form>
  )
}

export default FormFields
