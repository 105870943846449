import { Backdrop } from '@mui/material'
import React from 'react'
import { loaderData } from '../../slices/loaderSlice'
import { useAppSelector } from '../../_helpers/hooks'
import './loader.scss'
const Loader = () => {
  const loaderState = useAppSelector(loaderData)
  const { isLoading } = loaderState

  return (
    <>
      <Backdrop
        sx={{
          background: (theme) => theme.palette.backdrop.main,
          zIndex: 2000,
        }}
        open={isLoading}
      >
        <div className='dots' />
      </Backdrop>
    </>
  )
}

export default Loader
