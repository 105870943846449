export default function DatePicker(theme: any) {
  return {
    MuiPickersDay: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: theme.palette.info.main,
          },
        },
      },
    },
  }
}
