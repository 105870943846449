import { IIcon } from './iconConstants'
const InfoIcon = ({ width, height, fill }: IIcon) => {
  return (
    <svg width={width} height={height} viewBox='0 0 25 25' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12.5 25C5.596 25 0 19.404 0 12.5S5.596 0 12.5 0 25 5.596 25 12.5 19.404 25 12.5 25zm0-2.5c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10zM11.25 6.25h2.5v2.5h-2.5v-2.5zm0 5h2.5v7.5h-2.5v-7.5z'
        fill={fill}
        fillRule='nonzero'
      />
    </svg>
  )
}
export default InfoIcon
