/* eslint-disable camelcase */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box } from '@mui/material'
import { InteractiveFloorComponentMapView } from 'floor-plan-editor'
import { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
interface mobileDataParams {
  totalSeats: number
  availableSeats: number
  image: string
  bookedIds: Record<string, string>
  loadingInMobileView: boolean
  colorCodes: Record<string, string>
  seatToFocus: string
  seatToSearch: string
}
const FloorPlanMapView = () => {
  const [initialDataFromMobileView, setInitialDataFromMobileView] = useState<mobileDataParams>({
    totalSeats: 0,
    availableSeats: 0,
    image: '',
    bookedIds: {},
    loadingInMobileView: true,
    colorCodes: {},
    seatToFocus: '',
    seatToSearch: '',
  })
  useEffect(() => {
    if ((window as any).initialData) {
      setInitialDataFromMobileView({
        ...initialDataFromMobileView,
        ...(window as any).initialData,
      })
    }
  }, [])

  const mapViewRef = useRef(null)
  ;(window as any).searchSeat = (seatName: string) => {
    if (mapViewRef?.current) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const currentRef = mapViewRef.current as any
      currentRef.findPathAndNavigateWithSeatName(seatName, true)
    }
  }
  ;(window as any).updateInitialState = (newState: any) => {
    setInitialDataFromMobileView({ ...initialDataFromMobileView, ...newState })
  }
  return (
    <>
      <Helmet>
        <meta
          name='viewport'
          content='width=device-width, initial-scale=0.70, minimum-scale=0.70, maximum-scale=1.0, user-scalable=no'
        />
      </Helmet>
      <Box sx={{ height: '100vh' }}>
        <InteractiveFloorComponentMapView
          ref={mapViewRef}
          totalSeats={initialDataFromMobileView.totalSeats}
          availableSeats={initialDataFromMobileView.availableSeats}
          image={initialDataFromMobileView?.image || ''}
          bookedIds={initialDataFromMobileView.bookedIds}
          loadingInMobileView={initialDataFromMobileView.loadingInMobileView}
          seatToFocus={initialDataFromMobileView.seatToFocus}
          seatToSearch={initialDataFromMobileView.seatToSearch}
          colorCodes={initialDataFromMobileView.colorCodes}
        />
      </Box>
    </>
  )
}

export default FloorPlanMapView
