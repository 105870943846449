/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Typography } from '@mui/material'
import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../_helpers/hooks'
import { handleMessages } from '../../_helpers/utilities'
import Counter from '../../components/counter'
import { newBookingData } from '../../slices/newBookingSlice'
import { errorToast } from '../../slices/toastSlice'
import { voyagerState } from '../../slices/voyagerSlice'
interface ICounterNewBooking {
  seatCount: number
  recurringCount: number
  drawerType?: string
  setCounterError: React.Dispatch<React.SetStateAction<boolean>>
  setSeatCount: React.Dispatch<React.SetStateAction<number>>
  setRecurringCount: React.Dispatch<React.SetStateAction<number>>
  onCountersChange: () => void
  slotAvailability: number
  isBookForOthers?: boolean
  maxBulkBookings?: number
}

const Counters = (props: ICounterNewBooking) => {
  const {
    seatCount,
    recurringCount,
    setCounterError,
    setRecurringCount,
    setSeatCount,
    slotAvailability,
    drawerType,
    onCountersChange,
    isBookForOthers,
    maxBulkBookings,
  } = props
  const dispatch = useAppDispatch()
  const translatedInput = useAppSelector((state) => state?.translation.translatedData)
  const { businessConfig } = useAppSelector(voyagerState)
  const { customerFacilityData } = useAppSelector(newBookingData)

  // In the customer flow, restrict customer bookings to maximum bulk bookings of the facility
  const maxSeatLimit = maxBulkBookings
    ? Math.min(maxBulkBookings, businessConfig?.max_bulk_bookings)
    : businessConfig?.max_bulk_bookings
  // In the customer flow, restrict customer recurring bookings to maximum recurring bookings of the facility
  const maxRecurringLimit =
    Math.min(
      customerFacilityData?.max_recurring_bookings,
      businessConfig?.max_recurring_bookings,
    ) || businessConfig?.max_recurring_bookings

  useEffect(() => {
    if (slotAvailability > 0 && seatCount > slotAvailability) {
      setSeatCount(slotAvailability)
      dispatch(
        errorToast(
          handleMessages(translatedInput?.newBookingScreenText?.seatValueExceededSlotAlert),
        ),
      )
    }
  }, [slotAvailability])

  const handleSeatDecrement = (count: number) => {
    setSeatCount(count)
    onCountersChange()
  }

  const handleSeatIncrement = (count: number) => {
    setSeatCount(count)
    onCountersChange()
  }

  const handleRecurringCounter = (count: number) => {
    setRecurringCount(count)
    onCountersChange()
  }

  const handleSeatCountOnChange = (value: string) => {
    onCountersChange()
    const newSeatCount = parseInt(value)
    if (slotAvailability) {
      const maxLimit = Math.min(slotAvailability, maxSeatLimit)
      if (newSeatCount > maxLimit) {
        const toastMessage = `${translatedInput?.newBookingScreenText?.selectSeatNumberLessEqualText} ${maxLimit}`
        dispatch(errorToast(handleMessages(toastMessage)))
        setSeatCount(maxLimit)
      } else setSeatCount(newSeatCount)
    } else setSeatCount(newSeatCount)
  }
  const handleRecurringOnChange = (value: string) => {
    setRecurringCount(parseInt(value))
    onCountersChange()
  }
  useEffect(() => {
    if (seatCount > maxSeatLimit || recurringCount > maxRecurringLimit) {
      setCounterError(true)
    } else setCounterError(false)
  }, [seatCount, recurringCount, maxSeatLimit, maxRecurringLimit])

  return (
    <Grid container columnSpacing={'1.25rem'}>
      {!isBookForOthers && (
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4} mt={4}>
          <Typography variant='subtitle1' color='table.light'>
            {translatedInput?.newBooking?.noOfSeatsTitle}
          </Typography>

          <Counter
            count={seatCount}
            maxLimit={maxSeatLimit}
            onIncrement={handleSeatIncrement}
            onDecrement={handleSeatDecrement}
            onChange={handleSeatCountOnChange}
            maximumSlotAvailable={slotAvailability}
          />
          {seatCount > maxSeatLimit && (
            <Typography variant='caption' color='error.main'>
              {`${translatedInput?.facilities?.maxValueValidationError}
            ${maxSeatLimit}`}
            </Typography>
          )}
        </Grid>
      )}

      {drawerType === 'create' && (
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4} mt={4}>
          <Typography variant='subtitle1' color='table.light'>
            {translatedInput?.newBooking?.noOfDaysRecurringTitle}
          </Typography>

          <Counter
            count={recurringCount}
            maxLimit={maxRecurringLimit}
            onIncrement={handleRecurringCounter}
            onDecrement={handleRecurringCounter}
            onChange={handleRecurringOnChange}
          />
          {recurringCount > maxRecurringLimit && (
            <Typography variant='caption' color='error.main'>
              {maxRecurringLimit === 1
                ? translatedInput.facilityConfiguration.maxValueOneValidationError
                : `${translatedInput.facilities.maxValueValidationError}
              ${maxRecurringLimit}`}
            </Typography>
          )}
        </Grid>
      )}
    </Grid>
  )
}

export default Counters
