import { ITab } from '../../../_models/tabsInterface'

export const footerLinks: ITab[] = [
  {
    id: 1,
    label: 'Contact',
    path: '/customer/business',
  },
  {
    id: 2,
    label: 'FAQ',
    path: '/customer/business',
  },
  // {
  //   id: 3,
  //   label: 'Blog',
  //   path: '/customer/business',
  // },
]
