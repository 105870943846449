// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function Pagination(theme: any) {
  return {
    MuiPagination: {
      styleOverrides: {
        ul: {
          ' li': {
            '&:first-of-type': {
              display: 'inline-flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              '> button::after': {
                marginLeft: '0.625rem',
                content: '"Prev"',
              },
            },
            '&:last-of-type': {
              display: 'inline-flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              '> button::before': {
                marginRight: '0.625rem',
                content: '"Next"',
              },
            },
          },
          '@media (max-width: 770px)': {
            ' li': {
              '&:first-of-type > button::after': {
                content: '""',
              },
              '&:last-of-type > button::before': {
                content: '""',
              },
            },
          },
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: theme.palette.primary.light,
          },
        },
      },
    },
  }
}
