/* eslint-disable react-hooks/exhaustive-deps */

import { IconButton, InputBase, Paper, useTheme } from '@mui/material'
import { FC, ReactNode, useEffect, useState } from 'react'
import useDebounce from '../../hooks/useDebounce'
import { SearchIcon, SlidersIcon } from '../icons'

interface ISearchOptions {
  children?: ReactNode
  needAdvanceFilter: boolean
  searchDefaultValue: string | number
  placeholder: string
  handleOpenAdvanceFilter: (event: React.MouseEvent<HTMLElement>) => void
  handleSimpleSearch: (searchValue: string | number) => void
  searchChipValue?: string
}

const SearchContainer: FC<ISearchOptions> = (props: ISearchOptions) => {
  const theme = useTheme()
  const [searchValue, setSearchValue] = useState<string | number | undefined>(
    props.searchDefaultValue,
  )
  const debouncedValue = useDebounce<string | number | undefined>(searchValue, 500)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    props.handleOpenAdvanceFilter(event)
  }
  // to handle simple search
  const handleSearchKey = () => {
    if (searchValue) {
      props.handleSimpleSearch(searchValue)
    } else if (searchValue === '') {
      props.handleSimpleSearch('')
    }
  }
  useEffect(() => {
    handleSearchKey()
  }, [debouncedValue])
  useEffect(() => {
    if (props.searchChipValue == '') setSearchValue('')
  }, [props.searchChipValue])

  const onEnterKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      if (searchValue) {
        handleSearchKey()
      }
    }
  }
  return (
    <>
      <Paper
        component='form'
        sx={{
          p: '0.282rem 0.25rem',
          display: 'flex',
          alignItems: 'center',
          width: { sm: '100%' },
          marginRight: { xs: 0, sm: 4 },
          boxShadow: 'none',
          border: 'solid 0.063rem',
          borderColor: 'secondary.light',
          borderRadius: '0.625rem',
        }}
      >
        <IconButton sx={{ p: '0.625rem' }}>
          <SearchIcon fill={theme.palette.secondary.dark} width='16' height='16' />
        </IconButton>
        <InputBase
          sx={{ ml: 1, flex: 1, fontSize: '0.875rem', fontFamily: 'Lexend' }}
          placeholder={props.placeholder}
          inputProps={{ 'aria-label': props.placeholder }}
          value={searchValue}
          onChange={(event) => {
            const inputValue = event.target.value.slice(0, 25)
            setSearchValue(inputValue)
          }}
          onKeyPress={onEnterKeyPress}
        />
        {props.needAdvanceFilter && (
          <IconButton type='button' sx={{ p: '0.625rem' }} onClick={handleClick}>
            <SlidersIcon fill={theme.palette.iconColors.main} width='21' height='16' />
          </IconButton>
        )}
      </Paper>
    </>
  )
}

export default SearchContainer
