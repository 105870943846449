import { IIcon } from './iconConstants'
const LinkIcon = (props: IIcon) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox='0 0 18 18'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='m9.883 5.758 1.18 1.18a5.833 5.833 0 0 1 0 8.25l-.296.294a5.834 5.834 0 0 1-8.25-8.25l1.18 1.179a4.167 4.167 0 1 0 5.892 5.892l.295-.295a4.167 4.167 0 0 0 0-5.891l-1.179-1.18 1.18-1.178h-.002zm5.599 5.01-1.179-1.179a4.167 4.167 0 1 0-5.892-5.892l-.295.295a4.167 4.167 0 0 0 0 5.891l1.179 1.18-1.18 1.178-1.178-1.178a5.833 5.833 0 0 1 0-8.25l.295-.295a5.834 5.834 0 0 1 8.25 8.25z'
        fill={props.fill}
        fillRule='nonzero'
      />
    </svg>
  )
}
export default LinkIcon
