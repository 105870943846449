import { IIcon } from './iconConstants'
const FloorPlanWithPenIcon = (props: IIcon) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox='0 0 36 38'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M33.751 10.844h-9.288l4.903-6.158a.846.846 0 0 0-.135-1.185l-2.91-2.317a.844.844 0 0 0-1.186.134l-7.584 9.526H1.844a.843.843 0 0 0-.844.843v24.47c0 .466.377.844.844.844h31.908a.844.844 0 0 0 .844-.844v-24.47a.845.845 0 0 0-.845-.843zM32.908 26.7h-4.969V12.531h4.969v14.17zM25.93 3.03l1.59 1.266-7.9 9.92-1.66.455.07-1.72 7.9-9.921zM9.613 12.53v7.318H2.688V12.53h6.925zM2.688 32.058h8.943a.844.844 0 1 0 0-1.688H2.688v-3.255h13.287v8.198H2.688v-3.255zm14.975 3.255v-9.042a.844.844 0 0 0-.844-.843H2.688v-3.892h7.769a.844.844 0 0 0 .844-.843V12.53h5.07c-.003.025-.013.049-.015.074l-.13 3.15a.842.842 0 0 0 1.066.848l3.04-.832a.845.845 0 0 0 .437-.288l2.35-2.952h3.133v5.36h-7.574a.843.843 0 1 0 0 1.687h7.574v7.965c0 .466.378.844.844.844h5.812v6.926H17.663z'
        fill={props.fill}
        fillRule='nonzero'
        stroke={props.stoke}
        opacity='.5'
      />
    </svg>
  )
}
export default FloorPlanWithPenIcon
