/* eslint-disable @typescript-eslint/no-unused-vars */
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import {
  Box,
  Button,
  Chip,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  styled,
  useTheme,
} from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { useAppDispatch, useAppSelector } from '../../_helpers/hooks'

import CloseIcon from '@mui/icons-material/Close'
import { handleMessages } from '../../_helpers/utilities'
import { errorToast } from '../../slices/toastSlice'

type FormInputs = {
  emailForOthers: string
}
interface IFormFields {
  isExpanded: boolean
  userEmails: string[]
  handleAddUserEmailButton: (email: string) => void
  handleEmailClearClick: (email: string) => void
}
const StyledChip = styled(Chip)(({ theme }) => ({
  color: theme.palette.secondary.main,
  backgroundColor: theme.palette.label.main,
  borderRadius: '0.5rem',
  padding: '1.2rem 1rem 1.2rem 1rem',
  fontSize: '0.875rem',
  fontFamily: 'Lexend',

  '.MuiChip-deleteIcon	': {
    marginLeft: '.75rem',
    fill: `${theme.palette.primary.main} !important`,
  },
}))
const BookForOthersFormFields = (props: IFormFields) => {
  const { isExpanded, userEmails, handleAddUserEmailButton, handleEmailClearClick } = props
  const dispatch = useAppDispatch()
  const {
    control,
    getValues,
    setValue,
    formState: { errors },
    trigger,
  } = useForm<FormInputs>()

  const translatedInput = useAppSelector((state) => state?.translation.translatedData)
  const theme = useTheme()
  const handleAddEmailButton = () => {
    const emailValue = getValues('emailForOthers')
    if (emailValue.trim() === '') {
      dispatch(
        errorToast(
          handleMessages(translatedInput?.newBookingScreenText?.emailAddressRequiredAlert),
        ),
      )
    } else {
      trigger('emailForOthers').then((isValid) => {
        if (isValid && emailValue) {
          handleAddUserEmailButton(emailValue)
          setValue('emailForOthers', '')
        }
      })
    }
  }
  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleAddEmailButton()
    }
  }
  return (
    <form>
      <Grid
        container
        columnSpacing={'1.25rem'}
        width={isExpanded ? { xs: '100%', md: '50%' } : '100%'}
        mt={3}
      >
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} mt={2}>
          <Controller
            name='emailForOthers'
            control={control}
            defaultValue=''
            rules={{
              maxLength: {
                value: 64,
                message: translatedInput?.common?.emailMaxLengthError,
              },
              pattern: {
                value: /^[a-zA-Z0-9._+]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/,
                message: translatedInput?.common?.emailValid,
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                id='outlined-basic'
                onKeyDown={handleKeyPress}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <MailOutlineIcon />
                    </InputAdornment>
                  ),
                }}
                label={`${translatedInput?.common?.emailLabel}*`}
                variant='outlined'
                sx={{ width: '100%' }}
                error={!!errors.emailForOthers} // Setting error prop based on validation errors
                helperText={errors.emailForOthers?.message} // Displaying error message
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} mt={2.3}>
          <Button
            sx={{
              width: '30%',
              backgroundColor: 'common.black',
              '&:hover': {
                backgroundColor: 'common.black',
              },
            }}
            variant='contained'
            size='large'
            onClick={handleAddEmailButton}
          >
            {translatedInput?.newBookingScreenText?.addButton}
          </Button>
        </Grid>
      </Grid>
      <Box display='flex' mt={1}>
        <Box
          display='flex'
          flexWrap='wrap'
          justifyContent='flex-start'
          alignItems='flex-start'
          gap={2}
          mt={1}
        >
          {' '}
          {userEmails.map((data) => (
            <StyledChip
              deleteIcon={
                <CloseIcon style={{ fill: theme.palette.common.white, width: 20, height: 20 }} />
              }
              key={data}
              label={
                <Box display='flex'>
                  <Typography variant='subtitle2'>{data}</Typography>
                </Box>
              }
              onDelete={() => handleEmailClearClick(data)}
            />
          ))}{' '}
        </Box>
      </Box>
    </form>
  )
}

export default BookForOthersFormFields
