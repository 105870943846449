import { CloseOutlined } from '@mui/icons-material'
import {
  Badge,
  Box,
  Button,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import PLAN from '../../../_constants/pricingPlanConstants'
import { useAppDispatch, useAppSelector } from '../../../_helpers/hooks'
import { ITab } from '../../../_models/tabsInterface'
import { IUserData } from '../../../_models/userInterface'
import ProBadge from '../../../components/common/proBadge'
import ProfileDrawer from '../../../features/profileDrawer'
import { useUserPermissionAuthorization } from '../../../hooks'
import { usePricingLimits } from '../../../hooks/pricingLimits'
import { openScannerModal } from '../../../slices/dashboardSlice'
import { createNewBookingDrawer, getLocationsNewBooking } from '../../../slices/newBookingSlice'
import {
  pricingData,
  setPlanLimitExceededAlert,
  setPricingAlertModal,
} from '../../../slices/pricingSlice'
import { getAllScannerFacilities, getPusherConfigData } from '../../../slices/scannerSlice'
import { headerTabs } from '../items'

interface IMainDrawerProps {
  window?: () => Window
  handleDrawerToggle: () => void
  mobileOpen: boolean
  userData: IUserData | Record<string, never>
  handleDrawerOnPopoverClick: () => void
}

const AdminDrawer = (props: IMainDrawerProps) => {
  const dispatch = useAppDispatch()
  const [isPermitted] = useUserPermissionAuthorization()
  const translatedInput = useAppSelector((state) => state?.translation.translatedData)
  const { pricingLimits, subscriptionPlanDetails } = useAppSelector(pricingData)
  const { bookingsLimitCheck } = usePricingLimits()
  const { window, handleDrawerToggle, mobileOpen, handleDrawerOnPopoverClick, userData } = props
  const navigate = useNavigate()
  const container = window !== undefined ? () => window().document.body : undefined
  const drawerWidth = '100vw'
  const popoverPlacement = 'center'

  const openNewBookingDrawer = () => {
    if (bookingsLimitCheck()) {
      // if the user is on a pro plan, show limit reached alert
      if (
        subscriptionPlanDetails?.subscription?.slug === PLAN.MONTHLY_PRO ||
        subscriptionPlanDetails?.subscription?.slug === PLAN.YEARLY_PRO
      ) {
        dispatch(
          setPlanLimitExceededAlert({
            status: true,
            content: translatedInput?.pricingPage?.bookingLimitReachedDescription,
          }),
        )
      }
      // else show upgrade subscription alert
      else {
        dispatch(
          setPricingAlertModal({
            status: true,
            content: translatedInput?.subscription?.pleaseUpgradeSubscription,
          }),
        )
      }
    } else {
      dispatch(createNewBookingDrawer())
      dispatch(getLocationsNewBooking())
    }
  }
  const handleClickOnListItem = (item: ITab) => {
    handleDrawerToggle()
    // Booking management and Scanner features are not available for Basic plan
    if (item.id === 2 && pricingLimits?.plan_limits?.booking_management?.has_permission === false)
      dispatch(
        setPricingAlertModal({
          status: true,
          content: translatedInput?.subscription?.pleaseUpgradeSubscriptionForFeature,
        }),
      )
    else if (item.id === 3 && pricingLimits?.plan_limits?.scanner?.has_permission === false)
      dispatch(
        setPricingAlertModal({
          status: true,
          content: translatedInput?.subscription?.pleaseUpgradeSubscriptionForFeature,
        }),
      )
    else if (item.id === 3) {
      dispatch(getPusherConfigData())
      dispatch(getAllScannerFacilities())
      dispatch(openScannerModal())
    } else navigate(item.path)
  }
  const filteredHeaderTabs = headerTabs.filter((tabItem) => {
    // If the menu item has permission requirement and permission is not satisfied, filter it out
    return !tabItem.permissions || isPermitted(tabItem.permissions) || userData?.is_business_admin
  })

  const showProBadge = (item: ITab) => {
    if (pricingLimits?.plan_limits) {
      if (item?.id === 2 && !pricingLimits?.plan_limits?.booking_management?.has_permission)
        return true
      else if (item?.id === 3 && !pricingLimits?.plan_limits?.scanner?.has_permission) {
        return true
      }
    }
    return false
  }
  // Drawer - mobile view
  const drawer = (
    <>
      <Box onClick={handleDrawerToggle} sx={{ textAlign: 'end' }}>
        <Box>
          <IconButton
            color='primary'
            aria-label='open drawer'
            edge='end'
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: 'none' } }}
          >
            <CloseOutlined />
          </IconButton>
        </Box>
      </Box>
      <Box display='flex' alignItems='center' justifyContent='center' sx={{ my: 2 }}>
        <ProfileDrawer
          handleDrawerToggle={handleDrawerToggle}
          userData={props?.userData}
          popoverPlacement={popoverPlacement}
          handleDrawerOnPopoverClick={handleDrawerOnPopoverClick}
        />
      </Box>
      <Box onClick={handleDrawerToggle} sx={{ textAlign: 'end' }}>
        <List>
          {filteredHeaderTabs.map((item: ITab, index: number) => (
            <ListItem key={index}>
              <ListItemButton
                sx={{ textAlign: 'center' }}
                onClick={() => handleClickOnListItem(item)}
              >
                <Box sx={{ textAlign: 'center', width: '100%' }}>
                  <Badge
                    invisible={!showProBadge(item)}
                    badgeContent={
                      <ProBadge positionStyles={{ transform: 'translate(50%, -10%)' }} />
                    }
                  >
                    <ListItemText primary={translatedInput?.appHeader[item?.label]} />
                  </Badge>
                </Box>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
      <Stack display='flex' sx={{ mx: 4 }}>
        <Button
          variant='contained'
          sx={{ mt: 2, pl: 4 }}
          size='large'
          onClick={() => {
            handleDrawerToggle()
            openNewBookingDrawer()
          }}
        >
          {translatedInput?.bookings?.newBooking}
        </Button>
      </Stack>
    </>
  )
  return (
    <>
      <Drawer
        container={container}
        variant='temporary'
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: 'block', md: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
      >
        {drawer}
      </Drawer>
    </>
  )
}

export default AdminDrawer
