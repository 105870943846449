import { IIcon } from './iconConstants'
const MoonIcon = ({ width, height, fill }: IIcon) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.175 7a7 7 0 0 0 12 4.9v.1c0 5.523-4.477 10-10 10s-10-4.477-10-10 4.477-10 10-10h.1a6.98 6.98 0 0 0-2.1 5zm-6 5a8 8 0 0 0 15.062 3.762A9 9 0 0 1 8.413 4.938 7.999 7.999 0 0 0 4.175 12z'
        fill={fill}
      />
    </svg>
  )
}
export default MoonIcon
