/* eslint-disable @typescript-eslint/no-explicit-any */
import { useTheme } from '@mui/material'
import ContentLoader from 'react-content-loader'

const TokeBoardDetailSkeleton = (props: any) => {
  const theme = useTheme()
  return (
    <ContentLoader
      speed={2}
      width={'100%'}
      height={'100%'}
      viewBox='0 0 100% 100%'
      backgroundColor={theme.palette.facilityList.colorTwo}
      foregroundColor={theme.palette.facilityList.colorThree}
      {...props}
    >
      <rect x='30' y='30' rx='3' ry='3' width='22%' height='40' />
      <rect x='0' y='100' rx='3' ry='3' width='100%' height='2' />
      <rect x='30' y='120' rx='3' ry='3' width='95%' height='21' />
      <rect x='30' y='170' rx='3' ry='3' width='22%' height='21' />
      <rect x='30' y='210' rx='3' ry='3' width='95%' height='60' />
    </ContentLoader>
  )
}

export default TokeBoardDetailSkeleton
