import { IIcon } from './iconConstants'
const PlusIcon = (props: IIcon) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox='0 0 14 14'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='m7.83 7.83 4.752-.012c.308 0 .551-.243.538-.538a.525.525 0 0 0-.538-.538l-4.751.013V1.99a.525.525 0 0 0-.538-.538.525.525 0 0 0-.538.538l-.013 4.752-4.752.013a.525.525 0 0 0-.538.538c0 .154.064.294.154.384.09.09.23.154.384.154h4.765v4.764c0 .154.064.295.154.385.09.09.23.153.384.153.307 0 .55-.243.538-.538V7.831z'
        fill={props.fill}
        fillRule='nonzero'
        stroke={props.stoke}
      />
    </svg>
  )
}
export default PlusIcon
