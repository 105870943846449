/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react'
import { useAppDispatch, useAppSelector } from '../../../_helpers/hooks'
import { newBookingData } from '../../../slices/newBookingSlice'

import { InteractiveFloorComponentBookingView } from 'floor-plan-editor'
import { RefObject, useEffect } from 'react'
import { handleMessages } from '../../../_helpers/utilities'
import { ILockedSeats, ISeats } from '../../../_models/newBookingInterface'
import { errorToast } from '../../../slices/toastSlice'
import { userState } from '../../../slices/userSlice'

interface ISeatSelectionArea {
  noOfSeat: number
  handleSVGSeatSelectionChange: (seatDetails: ISeats, action: string) => void
  selectedSeats: ISeats[]
  seatSelectionRef: RefObject<HTMLElement>
  isBookForOthers: boolean
  userEmails: string[] | []
}

const SVGSeatSelection: React.FC<ISeatSelectionArea> = ({
  noOfSeat,
  selectedSeats,
  handleSVGSeatSelectionChange,
  seatSelectionRef,
  userEmails,
  isBookForOthers,
}) => {
  const dispatch = useAppDispatch()
  const newBookingState = useAppSelector(newBookingData)
  const { userData } = useAppSelector(userState)
  const { floorDetails, drawerType, bookingDetails, bookingDetailsGuest } = newBookingState
  const { seats, colorCodes, availableSeats, seatToFocus, image, bookedIds } = floorDetails
  const { map_details } = bookingDetails
  let bookedIdsObj = {} // this object is send to the svg component for marking the booked seats by others
  if (bookedIds && Object.keys(bookedIds).length > 0) {
    if (drawerType === 'edit') {
      Object.entries(bookedIds).forEach(([bookedSeatName, bookedSeatDetails]) => {
        // this if will work in normal edit flow
        if (
          map_details &&
          map_details.lockedSeats &&
          !map_details.lockedSeats.some((seat: ILockedSeats) => seat.seat_name === bookedSeatName)
        ) {
          bookedIdsObj = {
            ...bookedIdsObj,
            [bookedSeatName]: bookedSeatDetails,
          }
        }
        // this added for the reschedule through mail ( business url) flow
        // the bookingDetailsGuest.map_details.lockedSeats have the details of seats booked by the guest user
        else if (
          bookingDetailsGuest &&
          bookingDetailsGuest.map_details &&
          !bookingDetailsGuest.map_details.lockedSeats.some(
            (seat: ILockedSeats) => seat.seat_name === bookedSeatName,
          )
        ) {
          bookedIdsObj = {
            ...bookedIdsObj,
            [bookedSeatName]: bookedSeatDetails,
          }
        }
      })
    }
    // if the current user doesn't have any bookings and the floor has some bookings made by others
    if (!map_details && !bookingDetailsGuest.map_details && drawerType === 'edit') {
      bookedIdsObj = bookedIds
    }
    // end
    if (drawerType === 'create') {
      bookedIdsObj = bookedIds
    }
  }

  // transform selectedSeatsData to LockedSeatsFormat
  const lockedSeats = selectedSeats.map((seat: ISeats) => {
    return { userEmailId: seat.user_email, seatName: seat.seat_name, capacity: seat.capacity }
  })
  // make blocked seats as unavailable
  const seatsLockedByOthers = seats
    .filter((seat: ISeats) => seat.status === 3)
    .map((seat: ISeats) => seat.seat_name)

  // seat selection from interactive component
  const selectSeat = (
    seatName: string,
    capacity: number,
    previousSelection: string[],
    userEmailId: string,
  ) => {
    const selectedSeat = seats.find((seat: ISeats) => seat.seat_name === seatName)
    if (selectedSeat) {
      if (isBookForOthers) {
        const modifiedSeatData = { ...selectedSeat, user_email: userEmailId }

        handleSVGSeatSelectionChange(modifiedSeatData, 'select')
      } else {
        handleSVGSeatSelectionChange(selectedSeat, 'select')
      }
    }
  }

  // seat un selection from interactive component
  const unSelectSeat = (seatName: string, previousSelectedSeatIds: string[]) => {
    const unSelectedSeat = seats.find((seat: ISeats) => seat.seat_name === seatName)
    if (unSelectedSeat) handleSVGSeatSelectionChange(unSelectedSeat, 'unSelect')
  }

  // alert handler - for interactive component
  const handleAlert = (alertMessage: string) => {
    dispatch(errorToast(handleMessages(alertMessage)))
  }

  useEffect(() => {
    if (seatSelectionRef?.current && seatToFocus) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const currentRef = seatSelectionRef.current as any
      currentRef.findPathAndNavigateWithSeatName(seatToFocus, false)
    }
  }, [seatSelectionRef, seatToFocus])

  return (
    <InteractiveFloorComponentBookingView
      ref={seatSelectionRef}
      colorCodes={colorCodes}
      lockedSeats={lockedSeats}
      availableSeats={availableSeats}
      sendAlert={handleAlert}
      image={image}
      bookingLimit={noOfSeat}
      seatSelectionCallback={selectSeat}
      removeSeatCallback={unSelectSeat}
      bookedIds={bookedIdsObj}
      otherUserEmailIds={userEmails}
      currentUserEmailId={userData.email}
      isBusinessManager={false}
      loadingInMobileView={false}
      seatToFocus={seatToFocus}
      lockedSeatsByOthers={seatsLockedByOthers}
      isBookForOthers={isBookForOthers}
    />
  )
}

export default SVGSeatSelection
