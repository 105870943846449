import { IIcon } from './iconConstants'
const SuccessIcon = (props: IIcon) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox='0 0 46 46'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g fillRule='nonzero' fill='none'>
        <path
          d='M23 1.971c-5.8 0-11.057 2.358-14.864 6.165C4.33 11.943 1.971 17.2 1.971 23c0 5.8 2.358 11.057 6.165 14.864C11.943 41.67 17.2 44.029 23 44.029c5.8 0 11.057-2.358 14.864-6.165C41.67 34.057 44.029 28.8 44.029 23c0-5.8-2.358-11.057-6.165-14.864C34.057 4.33 28.8 1.971 23 1.971z'
          stroke={props.fill}
          strokeWidth='3.943'
        />
        <path
          d='M32.2 16.586a2.216 2.216 0 0 0-1.604-.696c-.578 0-1.154.232-1.591.694l-8.524 8.767-3.457-3.557a2.241 2.241 0 0 0-3.224 0 2.386 2.386 0 0 0-.67 1.668c0 .604.223 1.208.67 1.668l5.063 5.207a2.24 2.24 0 0 0 1.605.693 2.24 2.24 0 0 0 1.605-.693L32.2 19.922c.447-.46.67-1.064.67-1.668 0-.604-.223-1.209-.67-1.668z'
          stroke={props.stoke}
          strokeWidth='.5'
          fill={props.fill}
        />
      </g>
    </svg>
  )
}
export default SuccessIcon
