/* eslint-disable @typescript-eslint/no-explicit-any */
import { axiosInstance, handleResponse } from '.'
import { IFilters } from './../_models/commonInterface'
import { axiosFileInstance } from './index'

export const post = async function (url: string, body?: any) {
  const Axios = await axiosInstance()
  try {
    const resp = await Axios.post(url, body)
    return handleResponse(resp)
  } catch (err: any) {
    return handleResponse(err.response)
  }
}

export const postFileData = async function (url: string, body?: any) {
  const Axios = await axiosFileInstance()
  try {
    const resp = await Axios.post(url, body)
    return handleResponse(resp)
  } catch (err: any) {
    return handleResponse(err.response)
  }
}

export const get = async (url: string, params: IFilters = {}) => {
  const Axios = await axiosInstance()
  try {
    const resp = await Axios.get(url, { params })
    return handleResponse(resp)
  } catch (err: any) {
    return handleResponse(err.response)
  }
}

export const put = async function (url: string, body?: any) {
  console.log('body', body)
  const Axios = await axiosInstance()
  try {
    const resp = await Axios.put(url, body)
    return handleResponse(resp)
  } catch (err: any) {
    return handleResponse(err.response)
  }
}

export const _delete = async (url: string) => {
  const Axios = await axiosInstance()
  try {
    const resp = await Axios.delete(url)
    return handleResponse(resp)
  } catch (err: any) {
    return handleResponse(err.response)
  }
}
